import { useContext } from "react";
import NotificationIcon from "../../Img/pajamas_notifications.svg";
import Images from "../../Img/blank-profile-picture-973460__340.webp";
import Ongoing from "./Ongoing";
import { AuthContext } from "../Utils/AuthContextProvider";
import Upcoming from "./Upcoming";
import Closed from './Closed'

const ComplaintsData = () => {
  const { logoutFun } = useContext(AuthContext);
  const { handleShowChangePass } = useContext(AuthContext);

  const notification = 40;

  return (
    <>
      <div
        className="row mt-3 mb-2"
      
      >
        <div className="col-6">
        <div className="" style={{width: "150px"}}>
                <span className="styled-heading">Complaints</span>
              </div>
          {/* <div className="row ">
            <div className="col-12">
              <div style={{ display: "flex", alignItems: "center" }}>
               

                <button
                  className="ms-4"
                  style={{
                    backgroundColor: "#EDF3FF",
                    border: "1px solid #5f90be",
                    color: "#2188E7",
                    padding: "8px 15px",
                    borderRadius: "8px",
                    cursor: "pointer"
                  }}
                >
                 Back
                </button>
              </div>
            </div>
          </div> */}
        </div>
        <div className="col-6">
          <div
            className="d-flex"
            style={{
              justifyContent: "end",
              textAlign: "center",
              alignItems: "center",
            }}
          >
            {/* <div
              className="d-flex"
              style={{
                width: "80px",
                height: "40px",
                borderRadius: 20,
                border: "1px solid #D9D9D9",
                justifyContent: "center",
                backgroundColor: "#F5F5F5",
              }}
            >
              <div className="mt-1">
                <img alt="#" src={NotificationIcon} />
              </div>
              <div
                className="ms-3"
                style={{
                  width: "35px",
                  marginTop: "1px",
                  height: "35px",
                  borderRadius: "50%",
                  border: "1px solid #D9D9D9",
                  justifyContent: "center",
                  backgroundColor: "#CC1313",
                }}
              >
                <p
                  style={{
                    justifyContent: "center",
                    marginTop: "5px",
                    justifyItems: "center",
                    textAlign: "center",
                    color: "#fff",
                  }}
                >
                  {notification} +{" "}
                </p>
              </div>
            </div> */}
            <div
              className="d-flex ms-4"
              style={{ justifyContent: "space-between", marginRight: "30px" }}
            >
              <div
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  justifyContent: "center",
                  overflow: "hidden",
                }}
              >
                <img alt="" src={Images} width="100%" />
              </div>
              <div class="nav-item dropdown ms-2 mt-2 ">
                <span
                  class="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                ></span>
                <ul class="dropdown-menu" style={{ backgroundColor: "#000" }}>
                <li class="dropdown-item text-white" style={{cursor:"pointer"}} onClick={handleShowChangePass}>Change Password</li>

                  <hr style={{ color: "#fff" }} />
                  <li class="dropdown-item text-white">
                    <span onClick={() => logoutFun()}>Log-Out</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

     <div className="container d-flex justify-space-between"  style={{
    boxShadow: "0 4px 8px rgba(0, 0, 0, 2.1)",
    // padding: "20px",
    borderRadius: "5px",
    height: "85vh",
    backgroundColor: "#5e5959",
  }}>
      <Ongoing />
      <Upcoming />
      <Closed />
     </div>
    </>
  );
};

export default ComplaintsData;
