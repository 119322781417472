import React, { useEffect, useRef, useState } from "react";
import { BiFilter } from "react-icons/bi";
import { FaUserAlt } from "react-icons/fa";
import "./userdata.css";
import Just from "../../Img/Just.svg";
import Just1 from "../../Img/Just1.svg";
import { MdEmail } from "react-icons/md";
import { MdFileUpload } from "react-icons/md";
import { FaBirthdayCake } from "react-icons/fa";
import { GiWorld } from "react-icons/gi";
import State from "../../Img/state.svg";
import { GiModernCity } from "react-icons/gi";
import { FaMapPin } from "react-icons/fa";
import { ImLocation2 } from "react-icons/im";
import { ImHome3 } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ImWhatsapp } from "react-icons/im";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserCard from "./UserCard";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Spinner } from "react-bootstrap";
import data from "./CountryData.json";
import LoaderComponent from "../Utils/LoaderComponent";
import { AUTH_BASE_URL, BB_URl } from "../Utils/AuthContextProvider";
import SearchIcon from "../../Img/ri_search-line.svg";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { IoIosArrowRoundBack } from "react-icons/io";
// user before fill the form
const initUser = {
  name: "",
  qualification: "",
  institute_name: "",
  contact: "",
  emergency_contact: "",
  email: "",
  dob: "",
  resume: "",
  father_name: "",
  father_profession: "",
  father_intuition: "",
  father_contact: "",
  father_whatsapp: "",
  father_email: "",
  father_dob: "",
  mother_name: "",
  mother_profession: "",
  mother_intuition: "",
  mother_contact: "",
  mother_whatsapp: "",
  mother_email: "",
  mother_dob: "",
  country: "",
  state: "",
  city: "",
  pincode: "",
  locality: "",
  flat_number: "",
  department_id: "",
  role_id: "",
  salary: "",
  token: localStorage.getItem("token"),
};

const initLoading = {
  isLoading: false,
  isError: false,
};

const UserData = () => {
  const [user, setUser] = useState(initUser);
  const [Value, setValue] = useState(0);
  const [users1, setUsers1] = useState([]);
  const navigate = useNavigate();
  const [departments, setDeparments] = useState([]);
  const [roles, setRoles] = useState([]);
  const [noofUsers, setNoOfUsers] = useState(0);
  const [search, setSearch] = useState("");
  const [timer, setTimer] = useState(2);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [selctCountry, setSelectCountry] = useState({});
  const [selectState, setSelectState] = useState([]);
  // const { country_and_states } = data;
  const [loader, setLoader] = useState(initLoading);
  const [totalPages, setTotalPages] = useState("")
  const { isLoading, isError } = loader;
  const [username, setusername] = useState("")
  const [user_qualification, setuser_qualification] = useState("")
  const [user_institutename, setuser_instsitutename] = useState("")
  const [user_contact, setuser_contact] = useState("")
  const [user_email, setuser_email] = useState("")
  const [user_dob, setuser_dob] = useState("")
  const [user_country, setuser_country] = useState("")
  const [user_state, setuser_state] = useState("")
  const [user_city, setuser_city] = useState("")
  const [user_pincode, setuser_pincode] = useState("")
  const [user_locality, setuser_locality] = useState("")
  const [user_housenumber, setuser_housenumber] = useState("")
  const [user_departmentId, setuser_departmentId] = useState("")
  const [user_salary, setuser_salary] = useState("")
  const [yearlyCTC, setYearlyCTC] = useState(0);
  const [user_accountNo, setuser_accountNo] = useState("")
  const [user_resume, setuser_resume] = useState("")
  const [data, setdata] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [isloader, setIsLoading] = useState(false)
  const [searchQuery, setSearchQuery] = useState("")
  // const [totalPages,setTotalPages] = useState("")
  // const [currentPage,setCurrentPage] = useState(1);
  // const usersPerPage = 3;
  // const usersPerPage = 6; 
  // const pagesPerPagination = 4;
  const {
    name,
    qualification,
    institute_name,
    contact,
    emergency_contact,
    email,
    dob,
    resume,
    father_name,
    father_profession,
    father_intuition,
    father_contact,
    father_whatsapp,
    father_email,
    father_dob,
    mother_name,
    mother_profession,
    mother_intuition,
    mother_contact,
    mother_whatsapp,
    mother_email,
    mother_dob,
    country,
    state,
    city,
    pincode,
    locality,
    flat_number,
    department_id,
    role_id,
    salary,
    token,
  } = user;

  const handleSearch = () => {
    // setSearch(e.target.value);
    setCurrentPage(1)
    get_user()
  }


  const handleChangePagi = (event, value) => {
    setCurrentPage(value);
  };
  // function for navigation
  const navigatetoDepartment = () => {
    navigate("/department");
  };
  // const handleClose = () => setShow(false);
  const fileInputRef = useRef(null);

  // const handleChangePagi = (event, value) => {
  //   setCurrentPage(value);
  // };

  // function for starttimer for the last offcanvas of add role
  const startTimer = () => {
    let interval = setInterval(() => {
      setTimer((lastTimerCount) => {
        lastTimerCount <= 1 && clearInterval(interval);
        return lastTimerCount - 1;
      });
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  };


  // function for change data in inputs
  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    type === "file"
      ? setUser({
        ...user,
        [name]: files[0],
      })
      : setUser({
        ...user,
        [name]: value,
      });
  };

  //function for get departments
  const getDepartments = async () => {
    try {
      let res = await axios.post(
        `${BB_URl}/api/admin/get-departments-roles`,
        {
          token: localStorage.getItem("token"),
        }
      );
      setDeparments(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  //function for handle state on the basis of selected of country


  //function for get departments
  const getRoles = async (id) => {
    try {
      let res = await axios.post(
        `${BB_URl}/api/admin/get-departments-roles`,
        {
          token: localStorage.getItem("token"),
          id: id,
        }
      );
      // console.log(res.data.dta);
      setRoles(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  // function for final creation of user
  const addUser = async () => {
    var formdata = new FormData();
    Object.entries(user).forEach(([key, value]) => {
      formdata.append(key, value);
    });
    setLoading(true);
    try {
      let res = await axios.post(
        `${BB_URl}/api/admin/add-user`,
        formdata
      );

      if (res.data.status === 1) {
        setLoading(false);
        setError(false);
        toast(res.data.message);
        setShow(false);
      } else {
        setLoading(false);
        setError(true);
        toast(res.data.message);
      }

      return res.data.status;
    } catch (err) {
      setError(true);
      setLoading(false);
      console.log(err);
    }
  };


  const add_user = async () => {
    setIsLoading(true); // Start loading
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")} `);

      const formdata = new FormData();
      formdata.append("name", username);
      formdata.append("qualification", user_qualification);
      formdata.append("instituteName", user_institutename);
      formdata.append("contact", user_contact);
      formdata.append("email", user_email);
      formdata.append("dob", user_dob);
      // formdata.append("country", user_country);
      formdata.append("state", user_state);
      formdata.append("city", user_city);
      formdata.append("pincode", user_pincode);
      formdata.append("locality", user_locality);
      formdata.append("houseNumber", user_housenumber);
      formdata.append("departmentId", user_departmentId);
      formdata.append("salary", user_salary);
      formdata.append("AccountNo", user_accountNo);
      formdata.append("resume", user_resume);
      // formdata.append("AccountNo", String(user_accountNo));

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow"
      };

      const response = await fetch(`${AUTH_BASE_URL}/userpanel/add/user`, requestOptions);
      const result = await response.json();
      if (result.status == 1) {
        toast.success(result.message);
        handleClose();
        get_user();
      } else if (result.status == "VAL_ERR") {
        toast.error(result.Backend_Error);
      } else if (result.status == 0) {
        toast.error(result.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const view_department = async () => {

    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

      const response = await fetch(`${AUTH_BASE_URL}/departmentpanel/view/department`, requestOptions)
      const result = await response.json();
      if (result.status == 1) {
        setdata(result.data)
      } else {
        toast.error("Failed to retrieve departments");
      }
    } catch (error) {
      console.log(error)
    }
  }

  const get_user = async () => {
    setLoader({
      ...loader,
      isLoading: true,
    });
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

      const response = await fetch(`${AUTH_BASE_URL}/userpanel/get/users?searchQuery=${search}&pageNo=${currentPage}&pageSize=10`, requestOptions)
      const result = await response.json();
      if (result.status == 1) {
        setUsers1(result.users)
        setNoOfUsers(result.users.length);
        setTotalPages(result.TotalPages);
        // const totalPages = Math.ceil(result.users.length / usersPerPage);
        // setTotalPages(totalPages);
        setLoader({
          ...loader,
          isLoading: false,
        });
      } else {
        setLoader({
          ...loader,
          isLoading: false,
        });
      }
    } catch (error) {
      console.log(error)
      setLoader({
        ...loader,
        isLoading: false,
      });
    }
  }
  const handleInputChange = (e) => {
    const salary = e.target.value;
    setuser_salary(salary);

    // Convert to number and calculate yearly CTC
    const numericSalary = parseFloat(salary.replace(/,/g, '')); // Remove commas if any
    if (!isNaN(numericSalary)) {
      setYearlyCTC(numericSalary * 12);
    } else {
      setYearlyCTC(0);
    }
  };
  const handleClose = (e) => {
    setValue(0);
    setUser(initUser);
    setShow(false);
    setusername("");
    setuser_qualification("");
    setuser_instsitutename("");
    setuser_contact("");
    setuser_city("");
    setuser_state("");
    setuser_email("");
    setuser_dob("");
    setuser_contact("");
    setuser_pincode("");
    setuser_locality("");
    setuser_housenumber("");
    setuser_accountNo("");
    setuser_departmentId("");
    setuser_salary("");
  }
  useEffect(() => {
    get_user()
  }, [currentPage]);

  useEffect(() => {
    if (
      search == ""
    ) {
      get_user();
    }
  }, [search])

  return (
    <div>
      <div className="row pb-3" style={{overflow: "hidden", height: "17vh"}}>
        <div className="col-lg-4 col-sm-12">
          <h2 className="mt-4" style={{fontSize: "21px"}}>
            Showing total
            <span style={{ color: "#cc1313", fontWeight: 600 }}>
              {" "}
              {noofUsers}{" "}
            </span>
            Users
          </h2>
        </div>

        {/* <div className="col-lg-2 col-sm-12">
          <button
            type="button"
            className=" mt-4"
            style={{
              fontSize: 18,
              backgroundColor: "#EFEFEF",
              padding: "8px 17px",
              border: "none",
              borderRadius: 10,
            }}
          >
            <BiFilter /> Filters
          </button>
        </div> */}

        <div className="col-lg-2 col-sm-12"></div>

        <div className="col-lg-1 col-sm-12">
          <button
            type="button"
            className=" mt-4"
            style={{
              fontSize: "15px",
              backgroundColor: "#EDF7FF",
              padding: "8px 17px",
              border: "1px solid #2188E7",
              borderRadius: 10,
              color: "#2188E7",
              cursor: "pointer"
            }}
            onClick={() =>  navigate("/department")}
            // onClick={"/department"}
          >
            Departments
          </button>
        </div>

        <div className="col-lg-1 col-sm-12"></div>

        <div className="col-lg-2 col-sm-12">
          <button
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasRight"
            aria-controls="offcanvasRight"
            onClick={() => setShow(true)}
            className=" mt-4"
            style={{
              fontSize: "15px",
              backgroundColor: "#2F8460",
              padding: "8px 17px",
              border: "none",
              borderRadius: 10,
              color: "#fff",
            }}
          >
            + Add User
          </button>
        </div>

        <div className="col-12">
          <div className="row mt-3">
            <div className="col-8"></div>
            <div className="col-3">
              <div
                className="row"
                style={{
                  border: "1px solid #D9D9D9",
                  height: "40px",
                  borderRadius: "7px",
                }}
              >
                <div className="col-2 mt-1">
                  <img alt="SearchIcon" src={SearchIcon} />
                </div>
                <div className="col-10 mt-1 ">
                  <input
                    className="border-0 w-100"
                    placeholder="Search for User"
                    value={search}
                    style={{
                      textDecoration: "none",
                      outline: "none",
                      color: "#272727",
                    }}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="col-1">
              <button
                className="border-0 bg-white"
                style={{
                  background:
                    "linear-gradient(180deg, rgba(35,176,101,1) 0%, rgba(46,135,96,1) 100%)",
                  color: "#fff",
                  height: "40px",
                  borderTopRightRadius: 10,
                  borderBottomRightRadius: 10,
                  marginLeft: "-20px",
                }}
                onClick={handleSearch}
              >
                Search
              </button>
            </div>
          </div>
        </div>




      </div>

      {/* offcanvas start  */}

      <Offcanvas
        show={show}
        placement="end"
        style={{
          width: "60%",
          borderTopLeftRadius: "20px",
          overflow: "hidden",
        }}
      >
        <Offcanvas.Header
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "1.5rem",
            margin: "1rem",
            marginBottom: ".25rem",
          }}
        >
          <div className="d-flex justify-content-between align-items-center">
            <IoIosArrowRoundBack style={{ fontSize: '32px', cursor: "pointer" }} onClick={() => { setValue(0) }} />
            <button
              type="button"
              className="btn-close text-reset ml-auto"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              style={{ cursor: "pointer", marginLeft: "32px" }}
              onClick={handleClose}
            ></button>
          </div>

          <h5 id="offcanvasRightLabel" style={{ fontWeight: 600 }}>
            Add User
          </h5>

        </Offcanvas.Header>
        <div className="offcanvas-body">
          {/* <arrow steps start */}

          <div className="container">
            <div className="wrapper">
              <div
                className="arrow-steps clearfix"
                style={{ position: "fixed", zIndex: 1 }}
              >
                <div className={Value >= 0 ? "step current" : "step"}>
                  <span> Personal Details</span>
                </div>
                {/* <div className={Value >= 1 ? "step current" : "step"}>
                  <span>Family Details</span>
                </div> */}
                <div className={Value >= 1 ? "step current" : "step"}>
                  <span> Residential Details</span>
                </div>
                <div className={Value >= 2 ? "step current" : "step"}>
                  <span>Role Assigning</span>
                </div>
              </div>
            </div>
          </div>

          {Value === 0 ? (
            <>
              <div className="col-lg-12 col-sm-12 my-5">
                <div className="d-flex mx-5" style={{ backgroundColor: "" }}>
                  <span style={{ marginTop: "30px" }}>
                    <FaUserAlt style={{ color: "#eee" }} />
                  </span>
                  <input
                    placeholder="Enter Your Name"
                    className="mt-4 w-100"
                    name="name"
                    value={username}
                    onChange={(e) => setusername(e.target.value)}
                    style={{
                      padding: "8px 12px",
                      color: "#000",
                      width: "100%",
                      borderBottom: "2px solid #eee",
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      outline: 1,
                    }}
                  />
                  <br />
                </div>
                <div className="d-flex mx-5">
                  <span style={{ marginTop: "25px" }}>
                    <img src={Just} alt="###" style={{ width: "20px" }} />
                  </span>
                  <input
                    placeholder="Educational Qualification"
                    name="qualification"
                    value={user_qualification}
                    onChange={(e) => setuser_qualification(e.target.value)}
                    className="mt-3 w-100"
                    style={{
                      padding: "8px 12px",
                      color: "#000",
                      width: "100%",
                      borderBottom: "2px solid #eee",
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      outline: 1,
                    }}
                  />
                  <br />
                </div>
                <div className="d-flex mx-5">
                  <span style={{ marginTop: "25px" }}>
                    <img src={Just1} alt="###" style={{ width: "20px" }} />
                  </span>
                  <input
                    placeholder="Name of Institution"
                    name="institute_name"
                    value={user_institutename}
                    onChange={(e) => setuser_instsitutename(e.target.value)}
                    className="mt-3 w-100"
                    style={{
                      padding: "8px 12px",
                      color: "#000",
                      width: "100%",
                      borderBottom: "2px solid #eee",
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      outline: 1,
                    }}
                  />
                  <br />
                </div>
                <div
                  className="d-flex mx-4"
                  style={{ justifyContent: "space-around" }}
                >
                  <div className="col-10 d-flex">
                    <span
                      className="mt-3"
                      style={{
                        border: "1px solid #eee",
                        backgroundColor: "#FAF8F8",
                        height: "40px",
                      }}
                    >
                      <p className="mt-2">+91</p>
                    </span>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <input
                        placeholder="Contact Number"
                        type="text"
                        className="mt-3 w-100"
                        name="contact"
                        value={user_contact}
                        maxLength={10}
                        onChange={(e) => setuser_contact(e.target.value)}
                        style={{
                          padding: "8px 12px",
                          color: "#000",
                          width: "100%",
                          border: "2px solid #F2F2F2",
                          height: "40px",
                        }}
                      />
                      <span
                        style={{
                          color: "red",
                          fontSize: "10px",
                        }}
                      >
                        {contact.length !== 10
                          ? "contact should contain 10 digit"
                          : ""}
                      </span>
                    </div>
                  </div>

                  {/* <div className="col-5 d-flex ">
                    <span
                      className="mt-3"
                      style={{
                        border: "1px solid #eee",
                        backgroundColor: "#FAF8F8",
                        height: "40px",
                      }}
                    >
                      <p className="mt-2">+91</p>
                    </span>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <input
                        placeholder="Emergency Contact Number"
                        className="mt-3 w-100"
                        name="emergency_contact"
                        value={emergency_contact}
                        onChange={handleChange}
                        style={{
                          padding: "8px 12px",
                          color: "#000",
                          width: "100%",
                          border: "2px solid #F2F2F2",
                          height: "40px",
                        }}
                      />
                      <span
                        style={{
                          color: "red",
                          fontSize: "10px",
                        }}
                      >
                        {emergency_contact.length !== 10
                          ? "contact should contain 10 digit"
                          : ""}
                      </span>
                    </div>
                  </div> */}
                </div>
                <div className="d-flex mx-5">
                  <span style={{ marginTop: "25px" }}>
                    <MdEmail color="#808080" />
                  </span>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <input
                      placeholder="Enter E-mail"
                      className="mt-3 w-100"
                      name="email"
                      value={user_email}
                      onChange={(e) => setuser_email(e.target.value)}
                      style={{
                        padding: "8px 12px",
                        color: "#000",
                        width: "100%",
                        borderBottom: "2px solid #eee",
                        borderTop: "none",
                        borderLeft: "none",
                        borderRight: "none",
                        outline: 1,
                      }}
                    />
                    <span style={{ color: "red", fontSize: "10px" }}>
                      {!email.includes("@") ? "email should contain @" : ""}
                    </span>
                  </div>
                  <br />
                </div>
                <div className="d-flex mx-5">
                  <span style={{ marginTop: "25px" }}>
                    {/* <FaBirthdayCake color="#808080" /> */}
                  </span>
                  <input
                    placeholder="Joining date :  DD / MM / YYYY "
                    name="dob"
                    value={user_dob}
                    onChange={(e) => setuser_dob(e.target.value)}
                    className="mt-3 w-100"
                    type="date"
                    style={{
                      padding: "8px 12px",
                      color: "#000",
                      width: "100%",
                      borderBottom: "2px solid #eee",
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      outline: 1,
                    }}
                  />
                  <br />
                </div>

                <div
                  style={{
                    width: "60%",
                    margin: "auto",
                    marginTop: "30",
                    height: "40px",
                  }}
                >
                  <label className="filelabel10">
                    {user_resume ? (
                      <img src={user_resume} alt="" style={{ marginRight: '5px' }} />
                    ) : (
                      <MdFileUpload color="#808080" />
                    )}
                    <span className="title" style={{ color: "#808080" }}>
                      {user_resume.name ? user_resume.name : "Upload Resume"}
                    </span>
                    <input
                      name="resume"
                      onChange={(e) => { setuser_resume(e.target.files[0]) }}
                      type="file"
                      className="FileUpload10"
                      id="FileInput"
                      accept=".pdf"
                    />
                  </label>
                </div>

                <div
                  className="row mt-4"
                  style={{ justifyContent: "center", alignItems: "center" }}
                >
                  {/* <div style={{justifyContent:"center",alignItems:"center"}}> */}
                  <button
                    className="border-0"
                    style={{
                      width: "200px",
                      height: "40px",
                      backgroundColor: "#2188E7",
                      color: "#fff",
                      borderRadius: 10,
                    }}
                    onClick={() => {
                      if (username <= 0) {
                        toast.error("pls fill username")
                      } else if (user_qualification <= 0) {
                        toast.error("pls fill qualification")
                      }
                      else if (user_institutename <= 0) {
                        toast.error("pls fill institute name")
                      }
                      else if (user_contact <= 0) {
                        toast.error("pls fill contact number")
                      }
                      else if (user_email <= 0) {
                        toast.error("pls fill email")
                      }
                      else if (user_dob <= 0) {
                        toast.error("pls fill dob")
                      }
                      else if (user_resume <= 0) {
                        toast.error("pls fill resume")
                      }

                      else {
                        setValue(1);
                      }
                    }}
                  >
                    process
                  </button>
                  {/* </div> */}
                </div>
              </div>
            </>
          ) : (
            <></>
          )}



          {Value === 1 ? (
            <>
              <div className="col-lg-12 col-sm-12 my-5">
                {/* <div className="d-flex mx-5" style={{ backgroundColor: "" }}>
                  <span style={{ marginTop: "30px" }}>
                    <GiWorld style={{ color: "#BDBDBD" }} />
                  </span>
                  <select
                    name="country"
                    className="mt-4 w-100"
                    value={user_country}
                    onChange={(e)=>{setuser_country(e.target.value)}}
                    onClick={handleSelectedCountry}
                    style={{
                      padding: "8px 12px",
                      color: "#000",
                      width: "100%",
                      borderBottom: "2px solid #eee",
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      outline: 1,
                    }}
                  >
                    <option value=""> Select Country</option>
                    {
                      // country_list.map((el)=><option key={el} value={el}>{el}</option>)
                      Object.entries(selctCountry).map(
                        ([countryID, countryName]) => (
                          <option
                            key={countryID}
                            value={`${countryName}(${countryID})`}
                          >
                            {countryName}
                          </option>
                        )
                      )
                    }
                  </select>
                </div> */}
                <div className="d-flex mx-5">
                  <span style={{ marginTop: "25px" }}>
                    <img src={State} alt="" style={{ width: "20px" }} />
                  </span>
                  <input
                    placeholder="State"
                    className="mt-4 w-100"
                    type="text"
                    value={user_state}
                    onChange={(e) => setuser_state(e.target.value)}
                    style={{
                      padding: "8px 12px",
                      color: "#000",
                      width: "100%",
                      borderBottom: "2px solid #eee",
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      outline: 1,
                    }}
                  />
                </div>
                <div className="d-flex mx-5">
                  <span style={{ marginTop: "25px" }}>
                    <GiModernCity style={{ color: "#BDBDBD" }} />
                  </span>
                  <input
                    name="city"
                    value={user_city}
                    onChange={(e) => { setuser_city(e.target.value) }}
                    placeholder="City"
                    className="mt-3 w-100"
                    style={{
                      padding: "8px 12px",
                      color: "#000",
                      width: "100%",
                      borderBottom: "2px solid #eee",
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      outline: 1,
                    }}
                  />
                  <br />
                </div>

                <div className="d-flex mx-5">
                  <span style={{ marginTop: "25px" }}>
                    <FaMapPin color="#808080" />
                  </span>
                  <input
                    placeholder="Pincode"
                    className="mt-3 w-100"
                    name="pincode"
                    value={user_pincode}
                    onChange={(e) => { setuser_pincode(e.target.value) }}
                    style={{
                      padding: "8px 12px",
                      color: "#000",
                      width: "100%",
                      borderBottom: "2px solid #eee",
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      outline: 1,
                    }}
                  />
                  <br />
                </div>

                <div className="d-flex mx-5">
                  <span style={{ marginTop: "25px" }}>
                    <ImLocation2 color="#808080" />
                  </span>
                  <input
                    placeholder="Locality"
                    className="mt-3 w-100"
                    name="locality"
                    value={user_locality}
                    onChange={(e) => { setuser_locality(e.target.value) }}
                    style={{
                      padding: "8px 12px",
                      color: "#000",
                      width: "100%",
                      borderBottom: "2px solid #eee",
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      outline: 1,
                    }}
                  />
                  <br />
                </div>
                <div className="d-flex mx-5">
                  <span style={{ marginTop: "25px" }}>
                    <ImHome3 color="#808080" />
                  </span>
                  <input
                    placeholder="Enter House Number / Flat Number"
                    className="mt-3 w-100"
                    name="flat_number"
                    value={user_housenumber}
                    onChange={(e) => { setuser_housenumber(e.target.value) }}
                    style={{
                      padding: "8px 12px",
                      color: "#000",
                      width: "100%",
                      borderBottom: "2px solid #eee",
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      outline: 1,
                    }}
                  />
                  <br />
                </div>
              </div>
              <div
                className="row mt-4"
                style={{ justifyContent: "center", alignItems: "center" }}
              >
                {/* <div style={{justifyContent:"center",alignItems:"center"}}> */}
                <button
                  className="border-0"
                  style={{
                    width: "200px",
                    height: "40px",
                    backgroundColor: "#2188E7",
                    color: "#fff",
                    borderRadius: 10,
                  }}
                  onClick={() => {
                    if (user_state <= 0) {
                      toast.error("pls fill state")
                    } else if (user_city <= 0) {
                      toast.error("pls fill city")
                    }
                    else if (user_pincode <= 0) {
                      toast.error("pls fill pincode")
                    }
                    else if (user_locality <= 0) {
                      toast.error("pls fill locality")
                    }
                    else if (user_housenumber <= 0) {
                      toast.error("pls fill house number")
                    }
                    else {
                      view_department()
                      setValue(2);
                    }
                  }}
                >
                  process
                </button>
              </div>
            </>
          ) : (
            <></>
          )}

          {Value === 2 ? (
            <>
              <div style={{ marginTop: "80px" }}>
                <div className="row ms-3">
                  <div className="col-12 mt-3">

                    <select
                      name="department_id"
                      onChange={(e) => { setuser_departmentId(e.target.value) }}
                      value={user_departmentId}
                      className="w-75"
                      style={{
                        border: "1px solid #BDBDBD",
                        height: "40px",
                        borderRadius: 5,
                      }}
                    >
                      <option value="">select department</option>
                      {
                        data?.map((res, value) => {
                          return (
                            <option key={value} value={res._id}>{res.name}</option>
                          )
                        })
                      }

                    </select>


                  </div>



                  <div className="col-12 mt-5">
                    <div className="row">
                      <div className="col-12">
                        <span
                          style={{
                            fontSize: 18,
                            fontFamily: 800,
                            color: "#BDBDBD",
                          }}
                        >
                          In hand Salary
                        </span>
                      </div>
                      <div
                        className="col-12"
                        style={{
                          justifyContent: "space-around",
                          alignItems: "space-around",
                        }}
                      >
                      </div>
                    </div>
                    <div className="row mt-3">
                      <span
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: 3,
                          border: "2px solid #BDBDBD",
                          height: 36,
                          width: "80%",
                          marginLeft: "13px",
                        }}
                      >
                        <select
                          style={{
                            borderRadius: 3,
                            border: "2px solid #BDBDBD",
                            height: 35,
                            marginLeft: "-13px",
                          }}
                        >
                          <option>₹</option>
                          <option>$</option>
                        </select>
                        <input
                          name="salary"
                          value={user_salary}
                          // onChange={(e) => { setuser_salary(e.target.value) }}
                          onChange={handleInputChange}
                          className="border-0"
                          style={{ outline: "none" }}
                          placeholder="10,00000"
                        />
                      </span>

                    </div>
                  </div>
                  <div className="col-12 mt-5">
                    <div className="row">
                      <div className="col-12">
                        <span
                          style={{
                            fontSize: 18,
                            fontFamily: 800,
                            color: "#BDBDBD",
                          }}
                        >
                          Account Number
                        </span>
                      </div>
                      <div
                        className="col-12"
                        style={{
                          justifyContent: "space-around",
                          alignItems: "space-around",
                        }}
                      >
                      </div>
                    </div>
                    <div className="row mt-3">
                      <span
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: 3,
                          border: "2px solid #BDBDBD",
                          height: 36,
                          width: "80%",
                          marginLeft: "13px",
                        }}
                      >

                        <input

                          name="AccountNo"
                          type="text"
                          value={user_accountNo}
                          onChange={(e) => { setuser_accountNo(e.target.value) }}
                          className="border-0"
                          style={{ outline: "none" }}
                          placeholder="Account No."
                        />
                      </span>

                    </div>
                  </div>

                  {/* <div className="col-6" style={{ marginTop: "65px" }}>
                    <button
                      className="border-0"
                      style={{
                        backgroundColor: "#F0F0F0",
                        borderRadius: 5,
                        color: "#000",
                        height: "40px",
                        width: "200px",
                        fontSize: 18,
                        fontWeight: 500,
                      }}
                    >
                      + Add Instrument
                    </button>
                  </div> */}

                  {/* <div className="col-6 mt-3">
                    <div className="row">
                      <div className="col-6">
                        <span
                          style={{
                            fontSize: 18,
                            fontFamily: 800,
                            color: "#BDBDBD",
                          }}
                        >
                          House Rent
                        </span>
                      </div>
                      <div
                        className="col-6"
                        style={{ justifyContent: "space-between" }}
                      >
                        <button
                          className="border-0"
                          style={{
                            backgroundColor: "#2188E7",
                            borderRadius: 3,
                            height: "35px",
                            color: "#fff",
                            width: "80px",
                          }}
                        >
                          Yearly
                        </button>
                        <button
                          className="border-0"
                          style={{ height: "35px", borderRadius: 5,marginLeft:"30px" }}
                        >
                          Monthly
                        </button>
                      </div>
                      <div className="row mt-3">
                      <span style={{justifyContent:"center",alignItems:"center",borderRadius:3,border:"2px solid #BDBDBD",height:36,width:"85%",marginLeft: "13px"}}>
                        <select style={{borderRadius:3,border:"2px solid #BDBDBD",height:35,marginLeft:"-13px"}}>
                          <option>₹</option>
                          <option>$</option>
                        </select>
                        <input className="border-0" style={{outline:"none"}} placeholder="     10,00000"/>
                      </span>
                    </div>
                    </div>
                  </div>*/}
                </div>
                <div
                  className="row ps-4"
                  style={{
                    marginTop: "150px",
                    justifyContent: "space-between",
                    alignItems: "space-between",
                  }}
                >
                  <div className="col-6">
                    <p
                      style={{
                        color: "#000000",
                        fontWeight: 600,
                        fontSize: 18,
                      }}
                    >
                      Date of Joining : {user_dob}
                    </p>
                  </div>
                  <div className="col-6">
                    <p
                      style={{
                        color: "#000000",
                        fontWeight: 600,
                        fontSize: 18,
                      }}
                    >
                      Total Yearly CTC : {yearlyCTC.toLocaleString()}
                    </p>
                  </div>
                </div>
                <div
                  className="row"
                  style={{ justifyContent: "center", alignItems: "center" }}
                >
                  <button
                    className="border-0"
                    onClick={() => {
                      add_user()
                    }}
                    disabled={isloader ? true : false}
                    style={{
                      backgroundColor: "#2188E7",
                      width: "200px",
                      color: "#fff",
                      borderRadius: 5,
                      height: 35,
                    }}
                  >

                    {isloader ? <Spinner /> : "+Add User"}
                  </button>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
        {/* </div> */}

        {/* offcanvas end */}
      </Offcanvas>

      {/* users are mapped here */}
      <div  style={{overflow: "scroll", height: "60vh"}}>
        <div className="row">
          {isLoading ? (
            <LoaderComponent />
          ) : (
            users1?.map((res, key) => <UserCard res={res} key={key} />)
          )}
        </div>
      </div>
      {/* <Stack spacing={2} className="d-flex justify-content-center mt-3 align-items-center" >
        <Pagination count={totalPages} page={currentPage} onChange={handleChangePagi} />
      </Stack> */}
      <ToastContainer />
      <Stack spacing={2} className="d-flex justify-content-center mt-3 align-items-center" >
        <Pagination count={totalPages} page={currentPage} onChange={handleChangePagi} />
      </Stack>
    </div>
  );
};

export default UserData;
