import { Button, Card, Offcanvas, Spinner } from "react-bootstrap";
import Image3 from "../../Img/bb coin 1.svg";
import Image4 from "../../Img/carbon_trophy-filled.svg";
import clock from "../../Img/clock.svg";
import { FaArrowRight, FaTrophy } from "react-icons/fa";
import ProgressBar from "react-bootstrap/ProgressBar";
import Wallet from "../../Img/Wallet.svg";
import DatesImg from "../../Img/Date.svg";
import Pagination from "../Utils/Pagination";
import { useContext, useEffect, useState } from "react";
import LoaderComponent from "../Utils/LoaderComponent";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { AuthContext, IMAGE_URL, QUIZ_BASE_URL } from "../Utils/AuthContextProvider";
import moment from "moment";

const initLoader = {
  isLoading: false,
  isError: false,
};

const QuizHistory = (props, totalItems, itemsPerPage,) => {
  const { Data } = props;
  const {
    currentPageHistory,
    setCurrentPageHistory,
    hasMore,
    setHasMore,
  } = useContext(AuthContext);
  const [loader, setLoader] = useState(initLoader);
  // const [currentPage, setCurrentPage] = useState(1);
  const [paginatedData, setPaginatedData] = useState(Data);
  const [detailedHistory, setDetailedHistory] = useState({});
  const [winners, setWinners] = useState("");
  const [participants, setParticipants] = useState("");
  const [show, setShow] = useState(false);
  const { isLoading, isError } = loader;

  useEffect(() => {
    setLoader({ ...loader, isLoading: true });
    setTimeout(() => {
      setPaginatedData(Data.slice(0, itemsPerPage));
      setLoader({ ...loader, isLoading: false });
    }, 100); // Simulating a delay to show the loader
  }, [Data, ]);


  const WinnerApi = async (id) => {
    setLoader({ ...loader, isLoading: true }); // Set isLoading to true
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")} `);

      const raw = JSON.stringify({
        subactive_quiz_id: id,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(`${QUIZ_BASE_URL}/active/quiz/view/winners/of/activequiz`, requestOptions);
      const result = await response.json();

      if (result.status === 1) {
        setWinners(result.winners);
        setParticipants(result.participants);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoader({ ...loader, isLoading: false }); // Set isLoading to false after the API call
    }
  };

  const loadMoreData = () => {
    setLoader({ ...loader, isLoading: true });
    setTimeout(() => {
      const nextPage = currentPageHistory + 1;
      const moreData = Data.slice(paginatedData.length, paginatedData.length + itemsPerPage);
      setPaginatedData([...paginatedData, ...moreData]);
      setCurrentPageHistory(nextPage);
      setLoader({ ...loader, isLoading: false });
      if (moreData.length < itemsPerPage) {
        setHasMore(false);
      }
    }, 1000);
  };

  return (
    <>
      <div style={{ height: "60vh", overflowY: "scroll" }}>
        {isLoading ? (
          <LoaderComponent />
        ) : paginatedData && paginatedData.length === 0 ? (
          <div style={{ textAlign: "center", marginTop: "20px", fontSize: "18px", color: "#888" }}>
            No data found
          </div>
        ) : (
          paginatedData.map((res, key) => (
            <div className="row mt-2 ms-2" key={key}>
              <Card className="col-8">
                <Card.Body>
                  <Card.Title className="d-flex" style={{ borderBottom: "1px solid #EFEFEF" }}>
                    <span className="col-2">
                      <div
                        className=""
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          justifyContent: "center",
                          overflow: "hidden",
                        }}
                      >
                        <img alt="#" src={`${IMAGE_URL}${res.banner}`} width="100%" />
                      </div>
                    </span>
                    <div className="col-8 mt-2">
                      <span className="ml-2">
                        <p style={{ fontSize: 16, fontWeight: 600 }}>{res.quiz_name}</p>
                      </span>
                    </div>
                    <span
                      className="col-2"
                      onClick={() => {
                        WinnerApi(res._id);
                        setDetailedHistory(res);
                        setShow(true);
                      }}
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <FaArrowRight />
                    </span>
                  </Card.Title>

                  <Card.Text>
                    <div className="row">
                      <div className="col-2 d-flex">
                        <img alt="#" src={Image3} style={{ width: 25 }} />
                        <p className="mt-3 ms-2">{res.entryFees}</p>
                      </div>
                      <div
                        className="col-6 d-flex"
                        style={{
                          flexDirection: "row",
                        }}
                      >
                        <div className="col-7 d-flex no-wrep">
                          <img src={DatesImg} alt="#" style={{ width: 25 }} />
                          <div
                            className="mt-3 ms-2"
                            style={{
                              fontSize: 16,
                              fontWeight: 600,
                              color: "#333333",
                              marginTop: 10,
                            }}
                          >
                            {res.sch_time.split(" ")[0]}
                          </div>
                        </div>
                        <div className="col-6 d-flex">
                          <img src={clock} alt="#" style={{ width: 25 }} />
                          <div
                            className="mt-3 ms-2"
                            style={{
                              fontSize: 16,
                              fontWeight: 600,
                              color: "#333333",
                              marginTop: 10,
                            }}
                          >
                            {res.sch_time.split(" ")[1]}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-2 mb-3">
                      <div className="col-4 d-flex">
                        <img alt="#" src={Wallet} style={{ width: 25 }} />
                        <div
                          className="ms-2"
                          style={{
                            fontSize: 16,
                            fontWeight: 600,
                            color: "#333333",
                          }}
                        >
                          <span style={{ color: "#2188E7" }}>{res.slot_aloted}</span>/{res.slots}
                        </div>
                      </div>
                      <ProgressBar now={res.slot_aloted} max={res.slots} style={{ height: "12px" }} />
                    </div>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          ))
        )}
      </div>
      <div className="pagination-controls" style={{ textAlign: "center", marginTop: "20px" }}>
        {hasMore &&  (
          <Button variant="primary" onClick={loadMoreData} disabled={isLoading}>
          {isLoading ? <Spinner /> : "Load More"}
        </Button>
        )}
      </div>
      {/* //offcanvas for Details */}

      <Offcanvas
        show={show}
        placement="end"
        style={{
          width: "50%",
          borderTopLeftRadius: 30,
        }}
      >
        <Offcanvas.Header>
          <span onClick={() => setShow(false)} style={{ color: "#000", fontWeight: "600", cursor: "pointer" }}>
            <HiArrowNarrowLeft size="30px" />
          </span>
          {/* <p style={{fontSize:20}}>The changes will reflected on 12:01 AM</p> */}
        </Offcanvas.Header>

        <Offcanvas.Body>
          <div className="row ">
            <div className="col-2">
              <img
                alt="#"
                src={`${IMAGE_URL}${detailedHistory.banner}`}
                style={{
                  width: "40px",
                  height: "40px",
                  objectFit: "cover",
                  borderRadius: "50%",
                }}
              />
            </div>
            <div className="col-6">
              <p style={{ fontSize: 20, fontWeight: 600 }}>{detailedHistory.quiz_name}</p>
            </div>
          </div>
          <div className="row mt-2">
            <span className="col-1"></span>
            <span className="col-3 d-flex no-wrep">
              <img src={DatesImg} alt="#" style={{ width: 25 }} />
              <p
                style={{
                  fontSize: 16,
                  fontWeight: 600,
                  color: "#8A8A8A",
                }}
                className="mt-3 ms-2"
              >
                {String(detailedHistory.sch_time).split(" ")[0]}
              </p>
            </span>

            <span className="col-3 d-flex no-wrep">
              <img src={clock} alt="#" style={{ width: 25 }} />
              <p
                className="mt-3 ms-2 "
                style={{
                  fontSize: 16,
                  fontWeight: 600,
                  color: "#8A8A8A",
                }}
              >
                {String(String(detailedHistory.sch_time).split(" ")[1]).split(":").slice(0, -1).join(":")}
              </p>
            </span>
          </div>

          <div className="row">
            <span
              className="col-2"
              style={{
                fontSize: 16,
                fontWeight: "600",
                cursor: "pointer",
              }}
            >
              details
            </span>
          </div>
          <div className="row">
            <div
              className="col-2 mt-2"
              style={{
                borderBottom: "1px solid #000000",
              }}
            ></div>
          </div>
          <hr style={{ marginTop: "0px" }} />

          <div>
            <div
              className="row"
              style={{
                fontWeight: "500",
                fontSize: "20px",
                lineHeight: "24px",
                marginTop: "1rem",
                color: "#333333",
              }}
            >
              <div className="col-3">Total Question</div>
              <div className="col-2">{detailedHistory.total_num_of_quest}</div>
            </div>

            <div
              style={{
                fontWeight: "500",
                fontSize: "20px",
                lineHeight: "24px",
                marginTop: "1rem",
                color: "#2E8760",
              }}
              className="row"
            >
              <div className="col-3">Income</div>
              <div className="col-2">{detailedHistory.income}</div>
            </div>

            <div
              style={{
                fontWeight: "500",
                fontSize: "20px",
                lineHeight: "24px",
                marginTop: "1rem",
                color: "#2188E7",
              }}
              className="row"
            >
              <div className="col-3">Collected</div>
              <div className="col-2">{detailedHistory.collected}</div>
            </div>

            <div
              style={{
                marginTop: "1rem",
                fontWeight: "500",
                fontSize: "20px",
                lineHeight: "24px",

                color: "#CC1313",
              }}
              className="row"
            >
              <div className="col-3">Distributed</div>
              <div className="col-2">{detailedHistory.distributed}</div>
            </div>
          </div>

          <div
            className="col-2 d-flex"
            style={{
              marginTop: "1rem",
            }}
          >
            <img alt="#" src={Image3} style={{ width: 25 }} />
            <p
              style={{
                color: "#fed143",
              }}
              className="mt-3 ms-2"
            >
              {detailedHistory.entryFees}
            </p>
          </div>

          <div className="mt-2 mb-3">
            <div className="col-4 d-flex">
              <img alt="#" src={Wallet} style={{ width: 25 }} />
              <div
                className="ms-2"
                style={{ fontSize: 16, fontWeight: 600, color: "#333333" }}
              >
                <span style={{ color: "#2188E7" }}>
                  {detailedHistory.slot_aloted}
                </span>
                /{detailedHistory.slots}
              </div>
            </div>
            <ProgressBar
              now={detailedHistory.slot_aloted}
              max={detailedHistory.slots}
              style={{ height: "12px" }}
            />
          </div>

          <div
            styele={{
              marginTop: "1rem",
            }}
          >
            <h5
              style={{
                fontWeight: 600,
                fontSize: "20px",
                lineHeight: "24px",
                color: "#333333",
              }}
            >
              Winners
            </h5>

            {winners &&
              winners.map((winners, index) => (
                <div
                  className="d-flex no-wrap"
                  styele={{
                    marginTop: ".5rem",
                  }}
                >
                  <span>{index + 1}</span>
                  <p key={index}>
                    .{""}
                    {winners.stu_name}
                  </p>
                  <span className="ps-3 pe-3" key={index}>
                    <FaTrophy color="#dd74ee"></FaTrophy>
                    <span className="ps-1">
                      {""}
                      {winners.reward}
                    </span>
                  </span>
                </div>
              ))}
          </div>

          <div
            styele={{
              marginTop: "1rem",
            }}
          >
            <h5
              style={{
                fontWeight: 600,
                fontSize: "20px",
                lineHeight: "24px",
                color: "#333333",
              }}
            >
              Participants
            </h5>

            {participants &&
              participants.map((participant, index) => (
                <div
                  className="d-flex no-wrap"
                  styele={{
                    marginTop: ".5rem",
                  }}
                >
                  <span>{index + 1}. </span>
                  <p key={index}>{participant}</p>
                </div>
              ))}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default QuizHistory;
