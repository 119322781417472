import { useEffect } from "react";
import ExamData from "../../components/Exam/ExamData";
import SideBar from "../../components/SideBar/SideBar";
import { useNavigate } from "react-router-dom";

const Exam = () => {
  const Navigate = useNavigate();



  useEffect(() => {
    if (!localStorage.getItem("token")) {
      Navigate("/");
    } else {
      // CheckToken(localStorage.getItem("token"));
    }
  }, []);

  return (
    <>
      <div style={{ overflow: "hidden" }}>
        <div className="row">
          <div className="col-2">
            <SideBar />
          </div>
          <div className="col-10">
            <div className="container">
              <ExamData />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Exam;
