import { useEffect, useState } from "react";
import { BiRightArrowAlt } from "react-icons/bi";

const TaskCard = ({ type,handleSelectChange,need }) => {
  return (
    <div className="card mt-3" style={{ border: "none" }}>
      {
        type?.map((res, index) => {
          return (
            <>
              <div
              key={index}
                className="card-body mt-2"
                style={{ border: "1px solid gray", borderRadius: "8px" }}
              >
                <div className="row">
                  <div className="col-2">
                    <p className="pt-1 " value={res._id}>{res.issue}</p>
                  </div>
                  <div className="col-2">

                    <select
                      className="form-select border-0"
                      style={{
                        width: "150px",
                        backgroundColor: `${res.selectValue === "Not Started" ? "#efefef" : "#edf7ff"}`,
                        color: `${res.selectValue === "Not Started" ? "#8A8A8A" : res.selectValue === "In Progress" ? "#2188E7" : "#2E8760"}`,
                      }}
                      onChange={(e)=>{handleSelectChange(e.target.value,res._id,res.due_date)}}
                    >
                      {
                        need?<>
                        <option selected={res.status==0} style={{ width: "150px", backgroundColor: "#efefef", color: "#8A8A8A" }} value='0'>Not Started</option>
                        <option selected={res.status==1} style={{ width: "150px", backgroundColor: "#edf7ff", color: "#2188E7" }} value="1">In Progress</option>
                        </>:<>
                        </>
                      }
                      
                      <option selected={res.status==2} style={{ width: "150px", backgroundColor: "#edf7ff", color: "#2E8760" }} value="2">Completed</option>
                      {
                        need==0?<>
<option selected={res.status==3} style={{ width: "150px", backgroundColor: "red", color: "#2E8760" }} value="3">Missed</option>
                        </>:<></>
                      }
                    </select>
                  </div>
                  <div className="col-2">

                    <p className=" pt-1 text-center">{res.assginedBy}</p>

                  </div>

                  <div className="col-3">

                    <p className="text-center pt-1" >{res.due_date}</p>


                  </div>
                  <div className="col-3">

                    <p className="text-center pt-1" >{res.attachment}</p>


                  </div>
               
                </div >
              </div>

            </>

          )
        })
      }




    </div >
  );
};

export default TaskCard;
