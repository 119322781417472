import { useState, useEffect, useContext } from "react";
import "./Active.css";
import "react-datepicker/dist/react-datepicker.css";
import Lottie from "lottie-react";
import Clebrate from "../../Img/lf20_IBRRl8.json";
//import { NavLink } from "react-router-dom";
import FreeTrivaEdit from "./FreeTrivaEdit";
import Offcanvas from "react-bootstrap/Offcanvas";
import ToastContainer from "react-bootstrap/ToastContainer";
import Toast from "react-bootstrap/Toast";
import { ToastContainer as Toaster, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RxCross2 } from "react-icons/rx";
import { AuthContext, IMAGE_URL, QUIZ_BASE_URL } from "../Utils/AuthContextProvider";
import Slider from "./Slider";
import DatePicker from "react-datepicker";
import Dates from "../../Img/Date.svg";
import { HiArrowNarrowLeft } from "react-icons/hi";
import moment from "moment";
import { Spinner } from "react-bootstrap";




const initColors = {
  min: "#B1B1B1",
  max: "#B1B1B1",
};

const FreeTrivia = () => {
  const Dt = new Date();
  const New =
    Dt.getDate() + "/" + (parseInt(Dt.getMonth()) + 1) + "/" + Dt.getFullYear();
  const [startDate, setStartDate] = useState(new Date());
  const [Sedule, setSedule] = useState(New);
  const [ClickUp, setClickUp] = useState(false);
  const [triviaName, setTriviaName] = useState("");
  const [Click, setClick] = useState(0);
  const [Select, setSelect] = useState("");
  const [Times, setTimes] = useState("");
  const [SelectSub, setSelectSub] = useState("");
  const [Trv, setTrv] = useState(10);
  const [Question, setQuestion] = useState("");
  const [Rules, setRules] = useState([""]);
  const [Range11, SetRange11] = useState(50);
  const [BB, setBB] = useState("");
  // const [CategoryImg, setCategoryImg] = useState("");
  const [SubCat, setSubCat] = useState("");
  const [Goy, setGoy] = useState(0);
  const [ValCat, setValCat] = useState("");
  const [TotalData, setTotalData] = useState([]);
  const [validquescolor, setValidQuescolor] = useState(initColors);
  const [rewardcolor, setRewardColor] = useState("#B1B1B1");
  const [show, setShow] = useState(false);
  const [message, setmessage] = useState("");
  const [show1, setShow1] = useState(false);
  const [Timer, setTimers] = useState(15);
  const handleClose = () => setShow(false);
  const [checkLoading, setCheckLoading] = useState(false); // Local loading state
  const handleShow = () => {
    setShow(true);
    set_cat_search('');
  };
  const [Value, setValue] = useState("");

  const [Banner, setBanner] = useState("");
  const [Banner1, setBanner1] = useState("");
  const [Repeat, setRepeat] = useState("never");


  const [filterCategory1, setFilterCategory1] = useState([]);
  const [filterSubCatrgory, setFilterSubCatrgory] = useState([]);
  const [cat_search, set_cat_search] = useState("")
  const [sub_cat_search, set_sub_cat_search] = useState("")


  const [subject_search, set_sub_search] = useState("");
  const [subjectsData, setSubjectsData] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState([]);

  const [subjectsComposition, setSubjectsComposition] = useState([]);
  const [ArrForm, setArrForm] = useState({});

  const { triviaRefresh, settriviaRefresh } = useContext(AuthContext);


  const getCategories = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("token")}`
      );

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `${QUIZ_BASE_URL}/formfill/get/all/category?search=${cat_search}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status == 1) {
            setFilterCategory1(result.categories);
          }
        })
        .catch((error) => console.log("error", error));
    } catch (e) { }
  };


  const getSubCategories = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("token")}`
      );

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `${QUIZ_BASE_URL}/formfill/get/all/subcategory?search=${sub_cat_search}&cat_id=${Select}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result?.status == 1) {
            setFilterSubCatrgory(result?.sub_categories);
          }
        })
        .catch((error) => console.log("error", error));
    } catch (e) { }
  };



  const getSubjects = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("token")}`
      );
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        "sub_ids": SelectSub
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: raw
      };

      fetch(
        `${QUIZ_BASE_URL}/formfill/get/all/subjects/from/subcategories?search=${subject_search}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result?.status == 1) {
            setSubjectsData(result?.data);
          }
        })
        .catch((error) => console.log("error", error));
    } catch (e) { }
  };


  const OnClickfun = () => {
    const outputObject = {};
    subjectsComposition.forEach((item) => {
      outputObject[item._id] = Number(item.value);
    });

    setArrForm(outputObject);
    setClick(Click + 1)
  };

  // const handleRadioClick = (categoryId, category_name) => {
  //   setSelectedCategoryId(categoryId);
  //   setSelectedCategoryName1(category_name);
  //   };


  const handleChangeCheck = (e, res) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedSubjects((prev) => [...prev, value]);
      setSubjectsComposition((prev) => [...prev, { ...res, value: 0 }])
    } else {
      setSelectedSubjects((prev) => prev.filter((item) => item !== value));
      setSubjectsComposition((prev) => prev.filter((item) => item._id !== value));
    }
  };

  function compositionHandler(id, value) {
    const newtag = subjectsComposition.map((tag) => {
      if (tag._id == id) {
        return { ...tag, value };
      } else {
        return tag;
      }
    });

    setSubjectsComposition(newtag);
  }

  const validationComposition = () => {

    let sum = subjectsComposition.reduce((total, Value) => total + Value.value, 0);

    if (sum !== 100) {
      toast("Sum of % must be 100%");
    }
    else if (subjectsComposition.some(subject => subject.value === 0)) {
      toast("All subjects should have a value greater than 0%");
    }
    else {
      OnClickfun()
    }
  };









  const NoofQuesValidation = (n) => {
    if (n < 10) {
      setValidQuescolor({
        ...validquescolor,
        min: "red",
        max: "#B1B1B1",
      });
    } else if (n >= 10 && n <= 500) {
      setValidQuescolor({
        ...validquescolor,
        min: "green",
        max: "green",
      });
    } else if (n > 500) {
      setValidQuescolor({
        ...validquescolor,
        max: "red",
        min: "#B1B1B1",
      });
    } else {
      setValidQuescolor({
        ...validquescolor,
        min: "#B1B1B1",
        max: "#B1B1B1",
      });
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    if (value === '' || (Number(value) > 0 && !isNaN(value))) {
      setQuestion(value);
      NoofQuesValidation(value);
    }
  };
  const handleChangeReward = (e) => {
    const value = e.target.value;
    if (value === '' || (Number(value) > 0 && Number(value) <= 50 && !isNaN(value))) {
      setBB(value);
      setRewardColorFun(value);
    } else if (Number(value) > 50) {
    }
  };

  const createDateFormate = (dateObject, time) => {

    var formattedDate1 = moment(dateObject).format("DD-MM-YYYY")
    return `${formattedDate1} ${time}:00`
  }

  //function for add free trivia 
  const SendFree = async () => {

    setCheckLoading(true);
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
    const formattedDate = createDateFormate(startDate, Times);

    var formdata = new FormData();
    formdata.append("category_id", Select);
    formdata.append("quiz_name", triviaName);
    formdata.append("sub_cat_id", SelectSub);
    formdata.append("question_composition", JSON.stringify(ArrForm));
    formdata.append("subjects_id", JSON.stringify(selectedSubjects));
    formdata.append("reward", BB);
    formdata.append("min_reward_per", Range11);
    formdata.append("time_per_question", Trv);
    formdata.append("total_num_of_quest", Question);
    formdata.append("banner", Banner1);
    formdata.append("rules", JSON.stringify(Rules));

    formdata.append("repeat", Repeat);
    formdata.append("sch_time", formattedDate);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    try {
      const response = await fetch(`${QUIZ_BASE_URL}/trivia/create/quizz`, requestOptions);
      const result = await response.json();

      if (result.status == 1) {
        setClick(Click + 1);
        toast.success(result.message);
        settriviaRefresh(false);
        let interval = setInterval(() => {
          setTimers((lastTimerCount) => {
            lastTimerCount <= 1 && clearInterval(interval);
            return lastTimerCount - 1;
          });
        }, 1000);
        return () => {
          clearInterval(interval);
        };
      } else {
        toast.error(result.Backend_Error);
        setShow1(true);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setCheckLoading(false);
    }
  };

  const setRewardColorFun = (n) => {
    if (n > 50) {
      setRewardColor("red");
    } else if (n > 0 && n <= 50) {
      setRewardColor("green");
    }
  };

  setTimeout(() => {
    if (Timer == 0) {
      setInitialData()
      setGoy(1);
    }
  }, 16);


  const handleRuleChange = (index, value) => {
    const newRules = [...Rules];
    newRules[index] = value;
    setRules(newRules);
  };

  const handleAddRule = () => {
    setRules([...Rules, ""]);
  };

  const handleRemoveRule = (index) => {
    const newRules = [...Rules];
    newRules.splice(index, 1);
    setRules(newRules);
  };

  console.log("sub123", selectedSubjects);
  console.log("suin433", subjectsComposition);

  const setInitialData = () => {
    setClick(0);
    setShow(false);
    setTotalData([]);
    setTimers(15);
    setRules([""])
    setBB("");
    SetRange11(50);
    setTrv(10);
    setQuestion("");
    setRewardColor("#B1B1B1");
    setClickUp(false);
    setTriviaName("")
    setTimes(0)
    setSelectSub("")
    setSubCat("")
    setGoy(0)
    setValCat("")
    setShow1(false)
    setTimers(15)

    setBanner("")
    setBanner1("")
    setRepeat("never")
    setStartDate(new Date())
    setSedule(New)
    setSubjectsComposition([])
    setArrForm({})
    setSelectedSubjects([])
  }

  // Function to handle preview quiz button click
  const handlePreviewQuiz = () => {
    const isAnyRuleEmpty = Rules?.some(rule => rule.trim() === '');

    if (isAnyRuleEmpty) {
      toast.error("Please enter rules for all fields.");
    } else {
      setClick(Click + 1);
      // Proceed with previewing the quiz
      // Add your logic here
    }
  };


  // Serach UseEffects
  useEffect(() => {
    getCategories()
  }, [cat_search]);

  useEffect(() => {
    if (Select) {

      getSubCategories()
    }
  }, [sub_cat_search]);

  useEffect(() => {
    if (SelectSub) {
      getSubjects()
    }
  }, [subject_search]);

  return (
    <div>
      <Offcanvas
        show={show}
        className="w-50"
        onHide={handleClose}
        placement="end"
        style={{ borderTopLeftRadius: 30 }}
      >
        <Offcanvas.Header>
          <div style={{ width: "100%", flex: 1 }}>
            <div className="d-flex" style={{ justifyContent: "space-between" }}>
              <div>
                <span
                  style={{
                    color: "#000",
                    fontWeight: "600",
                    display: Click === 7 ? "none" : "block",
                  }}
                  onClick={() => {
                    setInitialData()
                  }}
                >
                  <svg
                    width="35"
                    height="35"
                    viewBox="0 0 35 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M27.4913 9.40793C27.616 9.28337 27.7149 9.13547 27.7825 8.97267C27.85 8.80987 27.8849 8.63535 27.885 8.45909C27.8851 8.28283 27.8505 8.10827 27.7831 7.94539C27.7158 7.7825 27.617 7.63448 27.4924 7.50977C27.3679 7.38505 27.22 7.2861 27.0572 7.21854C26.8944 7.15099 26.7199 7.11617 26.5436 7.11606C26.3673 7.11595 26.1928 7.15056 26.0299 7.21791C25.867 7.28527 25.719 7.38404 25.5943 7.5086L17.4999 15.6029L9.40793 7.5086C9.15607 7.25673 8.81446 7.11523 8.45827 7.11523C8.10207 7.11523 7.76047 7.25673 7.5086 7.5086C7.25673 7.76047 7.11523 8.10207 7.11523 8.45827C7.11523 8.81446 7.25673 9.15607 7.5086 9.40793L15.6029 17.4999L7.5086 25.5919C7.38389 25.7166 7.28496 25.8647 7.21747 26.0276C7.14997 26.1906 7.11523 26.3652 7.11523 26.5416C7.11523 26.718 7.14997 26.8926 7.21747 27.0556C7.28496 27.2185 7.38389 27.3666 7.5086 27.4913C7.76047 27.7431 8.10207 27.8846 8.45827 27.8846C8.63464 27.8846 8.80928 27.8499 8.97222 27.7824C9.13517 27.7149 9.28322 27.616 9.40793 27.4913L17.4999 19.3969L25.5943 27.4913C25.8461 27.7428 26.1876 27.884 26.5436 27.8838C26.8996 27.8836 27.2409 27.742 27.4924 27.4901C27.744 27.2382 27.8852 26.8967 27.885 26.5408C27.8848 26.1848 27.7431 25.8435 27.4913 25.5919L19.3969 17.4999L27.4913 9.40793Z"
                      fill="black"
                    />
                  </svg>
                </span>
              </div>
              <div>
                <span className="bach_step"
                  style={{
                    display: Click <= 0 ? "none" : "block",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setClick(Click - 1)
                  }}
                >
                  <HiArrowNarrowLeft size="30px" style={{ display: Click === 7 ? "none" : "block" }} />
                </span>
              </div>
            </div>
            <h3
              style={{
                marginLeft: "6px",
                fontWeight: "600",
                display: Click >= 6 ? "none" : "block",
              }}
            >
              Add New Free Trivia
            </h3>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body style={{ marginTop: "-20px" }}>
          <div style={{ display: Click === 0 ? "block" : "none" }}>
            <div className="mb-5">
              <div className="progress" style={{ height: "10px" }}>
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{
                    width: "17%",
                    background:
                      "linear-gradient(to right, #f44cc5, #ec40cc, #e335d3, #d72adb, #c922e4)",
                  }}
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <p style={{ color: "#8A8A8A" }}>
                1/6 <span sytle={{ color: "red" }}>Steps Completed</span>
              </p>

              <div className="row">
                <div className="col-3"></div>
                <div
                  className="card col-6"
                  style={{ justifyConetnt: "center", textAlign: "center" }}
                >
                  <div>
                    <label
                      className="col-12 mb-2 mt-3"
                      style={{
                        textAlign: "start",
                        color: "#303030",
                        fontWeight: "600",
                      }}
                    >
                      Select Category
                    </label>
                    <br />
                    <input
                      className="col-12 border-0 mb-3"
                      style={{
                        textAlign: "start",
                        height: "50px",
                        backgroundColor: "#EFEFEF",
                        borderRadius: 7,
                        outline: 1,
                      }}
                      placeholder="  Search for Category"
                      type="search"
                      value={cat_search}
                      onChange={(e) => set_cat_search(e.target.value)}
                    />
                    <br />
                  </div>
                  <div
                    style={{
                      height: "160px",
                      overflowY: "scroll",
                      justifyContent: "start",
                      alignItems: "start",
                    }}
                  >
                    {
                      filterCategory1 <= 0 ? <>
                        <p>No results found</p>
                      </>
                        :
                        <>
                          {filterCategory1?.map((res, key) => {
                            return (
                              <>
                                <div className="d-flex mt-3" key={key}>
                                  <input
                                    style={{ color: "#F6F6F6" }}
                                    type="radio"
                                    name="Select"
                                    onClick={() => {
                                      setSelect(res._id);
                                      setValCat(res.category_name);
                                      setSubjectsComposition([])
                                      setArrForm({})
                                      setSelectedSubjects([])


                                    }}
                                  />
                                  <img
                                    alt="#"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      borderRadius: "50%",
                                    }}
                                    src={`${IMAGE_URL}${res.image}`}
                                  />
                                  <span className="ms-3 fw-bolder">
                                    {res.category_name}
                                  </span>
                                </div>
                              </>
                            );
                          })}
                        </>
                    }
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                justifyContent: "center",
                textAlign: "center",
                backgroundColor: "",
              }}
            >
              <button
                disabled={Select?.length >= 1 ? false : true}
                className="col-5 border-0 text-white"
                onClick={() => {
                  getSubCategories()
                  setClick(Click + 1);
                }}
                style={{
                  backgroundColor: "#2188E7",
                  height: "40px",
                  borderRadius: 7,
                }}
              >
                Proceed
              </button>
            </div>
          </div>

          <div style={{ display: Click === 1 ? "block" : "none" }}>
            <div className="mb-5">
              <div className="progress" style={{ height: "10px" }}>
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{
                    width: "28%",
                    background:
                      "linear-gradient(to right, #f44cc5, #ec40cc, #e335d3, #d72adb, #c922e4)",
                  }}
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <p style={{ color: "#8A8A8A" }}>
                2/6 <span sytle={{ color: "red" }}>Steps Completed</span>
              </p>
              <div>
                <p style={{ fontSize: 16, fontWeight: 600 }}>
                  Exam Category : <span>{ValCat}</span>
                </p>
              </div>
              <div className="row">
                <div className="col-3"></div>
                <div
                  className="card col-6"
                  style={{ justifyConetnt: "center", textAlign: "center" }}
                >
                  <div>
                    <label
                      className="col-12 mb-2 mt-3"
                      style={{
                        textAlign: "start",
                        color: "#303030",
                        fontWeight: "600",
                      }}
                    >
                      Select Sub Category
                    </label>
                    <br />
                    <input
                      className="col-12 border-0 mb-3"
                      style={{
                        textAlign: "start",
                        height: "50px",
                        backgroundColor: "#EFEFEF",
                        borderRadius: 7,
                        outline: 1,
                      }}
                      placeholder="  Search for Sub Category"
                      type="search"
                      value={sub_cat_search}
                      onChange={(e) => set_sub_cat_search(e.target.value)}
                    />
                    <br />
                  </div>
                  <div
                    style={{
                      height: "160px",
                      overflowY: "scroll",
                      justifyContent: "start",
                      alignItems: "start",
                    }}
                  >
                    {
                      filterSubCatrgory <= 0 ?
                        <>
                          <p>No results found</p>
                        </> :
                        <>

                          {filterSubCatrgory?.map((res, key) => {
                            return (
                              <>
                                <div className="d-flex mt-3" key={key}>
                                  <input
                                    style={{ color: "#F6F6F6" }}
                                    type="radio"
                                    name="Select"
                                    onClick={() => {
                                      setSelectSub(res._id);
                                      setSubCat(res.sub_category_name);
                                      setSubjectsComposition([])
                                      setArrForm({})
                                      setSelectedSubjects([])
                                    }}
                                  />
                                  <span className="ms-3 fw-bolder">
                                    {res.sub_category_name}
                                  </span>
                                </div>
                              </>
                            );
                          })}
                        </>
                    }
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                justifyContent: "center",
                textAlign: "center",
                backgroundColor: "",
              }}
            >

              <button
                disabled={SubCat?.length >= 1 ? false : true}
                className="col-5 border-0 text-white"
                onClick={() => {
                  getSubjects();
                  setClick(Click + 1)
                }}
                style={{
                  backgroundColor: "#2188E7",
                  height: "40px",
                  borderRadius: 7,
                }}
              >
                Proceed
              </button>
            </div>
          </div>





          <div style={{ display: Click === 2 ? "block" : "none" }}>
            <div className="mb-5">
              <div className="progress" style={{ height: "10px" }}>
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{
                    width: "42%",
                    background:
                      "linear-gradient(to right, #f44cc5, #ec40cc, #e335d3, #d72adb, #c922e4)",
                  }}
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <p style={{ color: "#8A8A8A" }}>
                3/6 <span sytle={{ color: "red" }}>Steps Completed</span>
              </p>
              <div className="row">
                <p
                  className="col-6"
                  style={{ fontSize: 16, fontWeight: 600, color: "#303030" }}
                >
                  Exam Category :
                  <span style={{ coor: "#000000" }}>{ValCat}</span>
                </p>
                <p
                  className="col-6"
                  style={{ fontSize: 16, fontWeight: 600, color: "#303030" }}
                >
                  Exam Sub Category :
                  <span style={{ coor: "#000000" }}>
                    {" "}
                    {ValCat} {SubCat}{" "}
                  </span>
                </p>
              </div>
              <div className="row">
                <div className="col-3"></div>
                <div
                  className="card col-6"
                  style={{ justifyConetnt: "center", textAlign: "center" }}
                >
                  <div>
                    <label
                      className="col-12 mb-2 mt-3"
                      style={{
                        textAlign: "start",
                        color: "#303030",
                        fontWeight: "600",
                      }}
                    >
                      Select Subjects
                    </label>
                    <br />
                    <input
                      className="col-12 border-0 mb-3"
                      style={{
                        textAlign: "start",
                        height: "50px",
                        backgroundColor: "#EFEFEF",
                        borderRadius: 7,
                        outline: 1,
                      }}
                      placeholder="  Search for Subject"
                      type="search"
                      value={subject_search}
                      onChange={(e) => set_sub_search(e.target.value)}
                    />
                    <br />
                  </div>

                  {/* here are tags of all selected subjects */}

                  <div
                    style={{
                      // display: "flex",
                      // flexFlow: "wrap",
                      gap: ".5rem",
                    }}
                  >
                    {subjectsData?.map((res) => (
                      <div className="d-flex mt-3" key={res._id}>
                        <input
                          style={{ color: "#F6F6F6" }}
                          type="checkbox"
                          id={res._id}
                          value={res._id}
                          checked={selectedSubjects?.includes(res._id)}
                          onChange={(e) => handleChangeCheck(e, res)}
                        />
                        <span className="ms-3 fw-bolder">{res.sub_name}</span>

                      </div>
                    ))}
                  </div>



                </div>
              </div>
            </div>
            <div
              style={{
                justifyContent: "center",
                textAlign: "center",
                backgroundColor: "",
              }}
            >
              <button
                disabled={selectedSubjects?.length >= 1 ? false : true}
                className="col-5 border-0 text-white"
                onClick={() => {
                  setClick(Click + 1)
                }}
                style={{
                  backgroundColor: "#2188E7",
                  height: "40px",
                  borderRadius: 7,
                }}
              >
                Proceed
              </button>
            </div>
          </div>

          <div style={{ display: Click === 3 ? "block" : "none" }}>
            <div className="mb-5">
              <div className="progress" style={{ height: "10px" }}>
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{
                    width: "56%",
                    background:
                      "linear-gradient(to right, #f44cc5, #ec40cc, #e335d3, #d72adb, #c922e4)",
                  }}
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <p style={{ color: "#8A8A8A" }}>
                4/6 <span sytle={{ color: "red" }}>Steps Completed</span>
              </p>
              <div className="row">
                <p
                  className="col-6"
                  style={{ fontSize: 16, fontWeight: 600, color: "#303030" }}
                >
                  Exam Category :
                  <span style={{ coor: "#000000" }}> {ValCat} </span>
                </p>
                <p
                  className="col-6"
                  style={{ fontSize: 16, fontWeight: 600, color: "#303030" }}
                >
                  Exam Sub Category :
                  <span style={{ coor: "#000000" }}>
                    {" "}
                    {ValCat} {ValCat}{" "}
                  </span>
                </p>
              </div>
              <div className="row">
                <div className="col-3"></div>
                <div
                  className="card col-6"
                  style={{ justifyConetnt: "center", textAlign: "center" }}
                >
                  <div>
                    <label
                      className="col-12 mb-2 mt-3"
                      style={{
                        textAlign: "start",
                        color: "#303030",
                        fontWeight: "600",
                      }}
                    >
                      Select Question Composition
                    </label>
                    <br />
                  </div>
                  <div
                    style={{
                      height: "200px",
                      overflowY: "scroll",
                      justifyContent: "start",
                      alignItems: "start",
                    }}
                  >



                    {subjectsComposition?.map((Value, index) => {
                      return (
                        <>
                          <div
                            className="mt-3"
                            style={{ textAlign: "start" }}
                          >
                            <div
                              className="d-flex"
                              style={{
                                justifyContent: "space-between",
                              }}
                            >
                              <span
                                className="ms-3 fw-bolder"
                                style={{ fontSize: 14 }}
                              >
                                {Value.sub_name}
                              </span>
                              <span style={{ marginLeft: "190px" }}>
                                {parseInt(Value.value)}%
                              </span>
                              <br />
                            </div>

                            <Slider
                              style={{
                                // accentColor: colors[index % 4],
                                cursor: "pointer",
                              }}
                              key={Value._id}
                              id={Value._id}
                              name={Value.sub_name}
                              value={Value.value}
                              total={subjectsComposition.reduce(
                                (total, Value) => total + Value.value,
                                0
                              )}
                              onChange={compositionHandler}
                            />
                          </div>
                        </>
                      );
                    })}




                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                justifyContent: "center",
                textAlign: "center",
                backgroundColor: "",
              }}
            >
              <button
                className="col-5 border-0 text-white"
                onClick={() => {
                  validationComposition()
                }}
                style={{
                  backgroundColor: "#2188E7",
                  height: "40px",
                  borderRadius: 7,
                }}
              >
                Proceed
              </button>
            </div>
          </div>

          <div style={{ display: Click === 4 ? "block" : "none" }}>
            <div className="progress" style={{ height: "10px" }}>
              <div
                className="progress-bar"
                role="progressbar"
                style={{
                  width: "50%",
                  background:
                    "linear-gradient(to right, #f44cc5, #ec40cc, #e335d3, #d72adb, #c922e4)",
                }}
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <p style={{ color: "#8A8A8A" }}>
              5/6 <span sytle={{ color: "red" }}>Steps Completed</span>
            </p>
            <div className="row" style={{ justifyContent: "space-evenly" }}>
              <p
                className="col-6"
                style={{ fontSize: 16, fontWeight: 600, color: "#303030" }}
              >
                Exam Category :
                <span style={{ coor: "#000000" }}> {ValCat}</span>
              </p>
              <p
                className="col-6"
                style={{ fontSize: 16, fontWeight: 600, color: "#303030" }}
              >
                Exam Sub Category :
                <span style={{ coor: "#000000" }}>
                  {" "}
                  {ValCat} {SubCat}
                </span>
              </p>
            </div>

            <div className="row" style={{ justifyContent: "space-between" }}>
              <div className="col-7 ms-2 card">


                <div>
                  <span
                    style={{ fontSize: 18, fontWeight: 600, marginTop: "10px" }}
                  >
                    Enter Total Number of Questions
                  </span>
                  <input
                    className="col-12 border-0 mb-3"
                    style={{
                      textAlign: "start",
                      height: "50px",
                      backgroundColor: "#EFEFEF",
                      borderRadius: 7,
                      outline: 1,
                    }}
                    value={Question}
                    type="number"
                    onChange={handleChange}
                  // onChange={(e) => {
                  //   setQuestion(e.target.value);
                  //   NoofQuesValidation(e.target.value);
                  // }}
                  />
                  <div className="d-flex" style={{ fontSize: 12 }}>
                    <span
                      className="fw-600 mt-1"
                      style={{ color: validquescolor.min }}
                    >
                      Minimum 10 Questions
                    </span>
                    <span
                      className="ms-3 fw-600 mt-1"
                      style={{ color: validquescolor.max }}
                    >
                      Maximum 500 Questions
                    </span>
                  </div>
                  <div
                    className="d-flex mt-1"
                    style={{ fontSize: 13, justifyContent: "space-between" }}
                  >
                    <span style={{ fontSize: 16, fontWeight: 600 }}>
                      Enter Time Per Question
                    </span>
                    <span>min 10 sec</span>
                  </div>
                  <span>
                    <input
                      className="w-100"
                      type="range"
                      min={10}
                      max={60}
                      value={Trv}
                      onChange={(e) => setTrv(e.target.value)}
                    />
                    <span className="col-12" style={{ textAlign: "end" }}>
                      <p>{Trv}s</p>
                    </span>
                  </span>

                  <span
                    className="col-12"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div className="row">
                      <p
                        className="col-10"
                        style={{ fontSize: 16, fontWeight: 600 }}
                      >
                        Select Minimum Reward Percentage
                      </p>
                      <p className="col-2">minimum 50%</p>
                    </div>
                  </span>
                  <span>
                    <input
                      className="w-100"
                      type="range"
                      min={50}
                      max={100}
                      value={Range11}
                      onChange={(e) => SetRange11(e.target.value)}
                    />
                    <span className="col-12" style={{ textAlign: "end" }}>
                      <p>{Range11}%</p>
                    </span>
                  </span>
                  <span
                    style={{ fontSize: 16, fontWeight: 600, marginTop: "10px" }}
                  >
                    Enter Reward
                  </span>
                  <input
                    className="col-12 border-0 mb-1"
                    style={{
                      textAlign: "start",
                      height: "50px",
                      backgroundColor: "#EFEFEF",
                      borderRadius: 7,
                      outline: 1,
                    }}
                    value={BB}
                    type="number"
                    onChange={handleChangeReward}
                  // onChange={(e) => {
                  //   setBB(e.target.value);
                  //   setRewardColorFun(e.target.value);
                  // }}
                  />

                  <span className="fw-600  mb-1" style={{ color: rewardcolor }}>
                    Maximum 50 BB Coins
                  </span>
                </div>


                {/* extra fields */}
                <div className="mt-2">
                  <span style={{ fontSize: 16, fontWeight: 600 }}>
                    Schedule Quiz
                  </span>
                  <div className="row ">
                    <span
                      className="col-4 ms-2 d-flex rounded"
                      style={{
                        backgroundColor: "#EFEFEF",
                        overflow: "hidden",
                        height: "35px",
                      }}
                    >
                      <img className="mt-1" src={Dates} alt="#" />
                      <DatePicker
                        className="Main"
                        dateFormat="yyyy/MM/dd"
                        selected={startDate}
                        minDate={new Date()}
                        onChange={(date) => {
                          setStartDate(date);
                          setSedule(
                            date.getDate() +
                            "-" +
                            (parseInt(date.getMonth()) + 1) +
                            "-" +
                            date.getFullYear()
                          );
                        }}
                      />
                    </span>
                    <span
                      className="col-4 ms-4 rounded"
                      style={{ backgroundColor: "#EFEFEF", height: "35px" }}
                    >
                      <input
                        className="border-0 rounded mt-1"
                        type="time"
                        style={{
                          backgroundColor: "#EFEFEF",
                          color: "#8A8A8A",
                          outline: "none",
                        }}
                        value={Times}
                        onChange={(e) => {
                          setTimes(e.target.value);
                        }}
                      />
                    </span>
                  </div>
                  <div className="mt-3 mb-2 d-flex">
                    <p
                      className="mt-2"
                      style={{
                        color: "#303030",
                        fontWeight: "bolder",
                        fontSize: 16,
                      }}
                    >
                      Repeat Quiz After Minutes
                      <span
                        style={{
                          color: "#303030",
                          fontWeight: 300,
                          fontSize: 16,
                        }}
                      >
                        {" "}
                        (optional)
                      </span>
                    </p>
                    <select
                      value={Repeat}
                      className="ms-5 rounded border-0"
                      style={{
                        background: "#EFEFEF",
                        color: "#00000",
                        height: "35px",
                        outline: "none",
                      }}
                      onChange={(e) => setRepeat(e.target.value)}
                    >
                      <option value="never">never</option>
                      <option value="5 mins">5 mins</option>
                      <option value="15 mins">15 mins</option>
                      <option value="30 mins">30 mins</option>
                      <option value="45 mins">45 mins</option>
                      <option value="1 hrs">1 hrs</option>
                      <option value="2 hrs">2 hrs</option>
                      <option value="3 hrs">3 hrs</option>
                      <option value="6 hrs">6 hrs</option>
                      <option value="1 days">1 days</option>
                      <option value="2 days">2 days</option>
                      <option value="3 days">3 days</option>
                      <option value="1 week">1 week</option>
                      <option value="2 week">2 week</option>
                      <option value="1 month">1 month</option>
                      <option value="2 month">2 month</option>
                      <option value="3 month">3 month</option>
                      <option value="6 month">6 month</option>
                      <option value="1 Year">1 Year</option>
                    </select>
                  </div>
                </div>



              </div>
              <div className="col-4 card" style={{ height: "180px" }}>
                {subjectsComposition?.map((res, key) => {
                  return (
                    <>
                      <div
                        className="mt-3"
                        key={key}
                        style={{ textAlign: "start" }}
                      >
                        <div
                          className="d-flex w-100"
                          style={{ justifyContent: "space-between" }}
                        >
                          <span
                            className="ms-3 fw-bolder"
                            style={{ fontSize: 14 }}
                          >
                            {res.sub_name}
                          </span>
                          <span>{res.value}%</span>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>

            <button
              className="border-0 col-7 mt-3 rounded"
              style={{
                backgroundColor: "#2188E7",
                color: "#fff",
                height: "35px",
              }}
              disabled={Question.length >= 1 && BB.length >= 1 && startDate && Times ? false : true}
              onClick={() => {
                setClick(Click + 1);
              }}
            >
              Proceed
            </button>
          </div>

          <div style={{ display: Click === 5 ? "block" : "none" }}>
            <div className="progress" style={{ height: "10px" }}>
              <div
                className="progress-bar"
                role="progressbar"
                style={{
                  width: "100%",
                  background:
                    "linear-gradient(to right, #f44cc5, #ec40cc, #e335d3, #d72adb, #c922e4)",
                }}
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <p style={{ color: "#8A8A8A" }}>
              6/6 <span sytle={{ color: "red" }}>Steps Completed</span>
            </p>
            <div className="row" style={{ justifyContent: "space-evenly" }}>
              <p
                className="col-6"
                style={{ fontSize: 16, fontWeight: 600, color: "#303030" }}
              >
                Exam Category :<span style={{ coor: "#000000" }}>{ValCat}</span>
              </p>
              <p
                className="col-6"
                style={{ fontSize: 16, fontWeight: 600, color: "#303030" }}
              >
                Exam Sub Category :
                <span style={{ coor: "#000000" }}> {ValCat} </span>
                <span style={{ coor: "#000000" }}> {SubCat} </span>
              </p>
            </div>

            <div className="row" style={{ justifyContent: "space-between" }}>





              <div className="col-7 ms-2 card">

                <span style={{ fontWeight: "bolder", fontSize: 20 }}>
                  Enter Name of Free Trivia
                </span>

                <input
                  className="col-12 border-0 mb-3"
                  style={{
                    textAlign: "start",
                    height: "50px",
                    backgroundColor: "#EFEFEF",
                    borderRadius: 7,
                    outline: 1,
                  }}
                  value={triviaName}
                  type="text"
                  onChange={(e) => {
                    setTriviaName(e.target.value)
                  }}
                />


                <span style={{ fontWeight: "bolder", fontSize: 20, marginTop: "10px" }}>
                  Enter Rules for Free Trivia
                </span>
                <div>
                  {Rules?.map((rule, index) => (
                    <div key={index}>
                      <textarea
                        className="border-0"

                        value={rule}
                        onChange={(event) => handleRuleChange(index, event.target.value)}
                        placeholder="Start Typing the Rule"
                        style={{
                          backgroundColor: "#F5F5F5",
                          height: "70px",
                          borderRadius: 7,
                          outline: 1,
                          overflow: "hidden",
                          width: "100%",
                          boxSizing: "border-box",

                        }}
                      />
                      <div className="mb-2">
                        {index === Rules?.length - 1 && (
                          <button class="btn btn-secondary btn-md ms-2" onClick={handleAddRule}>Add More Rule</button>
                        )}
                        {Rules?.length > 1 && (
                          <button class="btn btn-secondary btn-md ms-2" onClick={() => handleRemoveRule(index)}>Remove Rule</button>
                        )}
                      </div>
                    </div>
                  ))}
                </div>


                <span
                  className="mt-2"
                  style={{ fontWeight: "bolder", fontSize: 16 }}
                >
                  Upload Banner Image
                </span>

                <div className="row">
                  <label
                    className="filelabel"
                    style={{
                      display: Banner.length === 2 ? "none" : "block",
                      overflow: "hidden",
                    }}
                  >
                    {Banner ? (
                      <img
                        alt=""
                        src={Banner}
                        style={{ width: "90%", height: "150px" }}
                      />
                    ) : (
                      <h2
                        style={{
                          color: "gray",
                        }}
                      >
                        Upload file
                      </h2>
                    )}
                    <span className="title">
                      300px <span>×</span>150px
                    </span>
                    <input
                      className="FileUpload1"
                      id="FileInput"
                      type="file"
                      onChange={(e) => {
                        setBanner(
                          URL.createObjectURL(e.target.files[0]),
                          setBanner1(e.target.files[0])
                        );
                      }}
                    />
                  </label>
                </div>




              </div>



              <div className="col-4 card">
                {subjectsComposition?.map((res, key) => {
                  return (
                    <>
                      <div
                        className="mt-3"
                        key={key}
                        style={{ textAlign: "start" }}
                      >
                        <div
                          className="d-flex w-100"
                          style={{ justifyContent: "space-between" }}
                        >
                          <span
                            className="ms-3 fw-bolder"
                            style={{ fontSize: 14 }}
                          >
                            {res?.sub_name}
                          </span>
                          <span>{res.value}%</span>
                        </div>
                      </div>
                    </>
                  );
                })}
                <hr />
                <div className="row">
                  <p className="col-7 fw-bolder" style={{ fontSize: 14 }}>
                    <p className="ms-3">Total Questions</p>
                  </p>
                  <span className="col-5">
                    <p className="ms-5">{Question}</p>
                  </span>
                  <p className="col-6 ms-3 fw-bolder" style={{ fontSize: 14 }}>
                    Time/Question
                  </p>
                  <span className="col-5">
                    <p className="ms-4 w-100">{Trv} sec</p>
                  </span>
                  <p className="col-7 fw-bolder" style={{ fontSize: 14 }}>
                    <p className="ms-3"> Reward</p>
                  </p>
                  <span className="col-5">
                    <p className="ms-4 w-100">{BB}{` BB coins`}</p>
                  </span>
                </div>
              </div>



              <button
                className="border-0 col-7 mt-3 rounded"
                style={{
                  backgroundColor: "#2188E7",
                  color: "#fff",
                  height: "35px",
                }}
                disabled={Rules.length >= 1 && Banner.length >= 1 && triviaName.length >= 1 ? false : true}
                onClick={() => {
                  handlePreviewQuiz();
                }}
              >
                Preview Quiz
              </button>
            </div>

          </div>


          <div style={{ display: Click === 6 ? "block" : "none" }}>
            <div
              className="d-flex"
              style={{ justifyContent: "space-between", marginTop: "-10px" }}
            >
              <span style={{ fontSize: 25, fontWeight: 600, color: "#303030" }}>
                Preview
              </span>
              <span>
                <button
                  className="border-0"
                  style={{
                    backgroundColor: "#2188E7",
                    color: "#fff",
                    width: "150px",
                    height: "35px",
                    borderRadius: 7,
                  }}
                  // disabled={ClickUp == true ? true : false}
                  onClick={() => {
                    setClickUp(true);
                    SendFree();
                  }}
                >
                  {checkLoading ? <Spinner /> : "Publish Free Trivia"}

                </button>
              </span>
            </div>

            <div
              className="row mt-2"
              style={{ justifyContent: "space-evenly" }}
            >
              <p
                className="col-6"
                style={{ fontSize: 16, fontWeight: 600, color: "#303030" }}
              >
                Exam Category :<span style={{ coor: "#000000" }}>{ValCat}</span>
              </p>

              <p
                className="col-6"
                style={{ fontSize: 16, fontWeight: 600, color: "#303030" }}
              >
                Exam Sub Category :
                <span style={{ coor: "#000000" }}> {ValCat} </span>
                <span style={{ coor: "#000000" }}> {SubCat} </span>
              </p>
            </div>

            <div className="row" style={{ justifyContent: "space-between" }}>
              <div className="col-7 ms-2 card ">
                <span
                  className="mt-1"
                  style={{ fontSize: 18, fontWeight: 600, color: "#303030" }}
                >
                  Question Composition
                </span>
                {subjectsComposition?.map((res, key) => {
                  return (
                    <>
                      <div
                        className="mt-3"
                        key={key}
                        style={{ textAlign: "start" }}
                      >
                        <div
                          className="d-flex"
                          style={{ justifyContent: "space-between" }}
                        >
                          <span
                            className="ms-3 fw-bolder"
                            style={{ fontSize: 14 }}
                          >
                            {res?.subName}
                          </span>
                          <span style={{ marginLeft: "190px" }}>
                            {res?.value}%
                          </span>
                          <br />
                        </div>

                        <input
                          className="w-100"
                          type="range"
                          name="rangeInput"
                          value={res?.value}
                        />
                      </div>
                    </>
                  );
                })}
                <span
                  className="mt-2"
                  style={{ fontSize: 18, fontWeight: 600, color: "#303030" }}
                >
                  Banner Image
                </span>
                <img
                  className="rounded mt-1 mb-1"
                  src={Banner}
                  alt=""
                  style={{ width: "100%", height: "150px" }}
                />
                <span
                  className="mt-2"
                  style={{ fontSize: 18, fontWeight: 600, color: "#303030" }}
                >
                  Rules
                </span>
                {Rules}
              </div>
              <div className="col-4 card" style={{ height: "fit-content" }}>
                <div className="row">
                  <span
                    className="d-flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <p className="fw-bolder" style={{ fontSize: 14 }}>
                      Name
                    </p>
                    <p>{triviaName}</p>
                  </span>

                  <span
                    className="d-flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <p className="fw-bolder" style={{ fontSize: 14 }}>
                      Total Questions
                    </p>
                    <p>{Question}</p>
                  </span>

                  <span
                    className="d-flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <p className="fw-bolder" style={{ fontSize: 14 }}>
                      Time/Question
                    </p>
                    <p>{Trv} sec</p>
                  </span>

                  <hr />

                  <span
                    className="d-flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <p className="fw-bolder" style={{ fontSize: 14 }}>
                      Reward
                    </p>
                    <p>{BB}{` BB coins`}</p>
                  </span>

                  <span
                    className="d-flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <p className="fw-bolder" style={{ fontSize: 14 }}>
                      Repeat Time
                    </p>
                    <p>{Repeat}</p>
                  </span>

                  <span
                    className="d-flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <p className="fw-bolder" style={{ fontSize: 14 }}>
                      Quiz Schedule
                    </p>
                    <p>{`${Sedule} ${Times}`}</p>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div style={{ display: Click === 7 ? "block" : "none" }}>
            <div style={{ textAlign: "center", alignItems: "center" }}>
              <p
                className="mt-3"
                style={{ fontSize: "25px", fontWeight: "bolder" }}
              >
                Free Trivia Published
                <br />
                Successfully
              </p>
            </div>
            <div
              className="d-flex"
              style={{ textAlign: "center", alignItems: "center" }}
            >
              <div className="col-3 ms-5"></div>
              <Lottie
                animationData={Clebrate}
                loop={true}
                style={{ width: "40%" }}
              />
            </div>
            <div style={{ textAlign: "center", alignItems: "center" }}>
              <button
                className="border-0 col-4 mt-4  "
                type="button"
                style={{
                  backgroundColor: "#0CBC8B",
                  color: "#fff",
                  height: "40px",
                  borderRadius: 7,
                }}
                onClick={() => {
                  // setShow(false);
                  // setGoy(1);
                  // setClick(0);
                  // //return back here
                  // setTimeout(() => {
                  //   setGoy(0);
                  // }, 100);
                  // // setCategoryImg("");
                  // setValCat("");
                  // setSelect("");
                  // setSubCat("");
                  // setSelectSub("");
                  // setQuestion("");
                  // setClickUp(false);
                  settriviaRefresh(true);
                  setInitialData()
                }}
              >
                Back To Dashboard
              </button>
            </div>
            <div
              className="mt-5"
              style={{ textAlign: "center", alignItems: "center" }}
            >
              <p style={{ color: "#8A8A8A" }}>
                This tab will automatically close in
                <span style={{ color: "#CC1313", fontSize: 20 }}>
                  {" "}
                  {Timer}{" "}
                </span>
                Seconds
              </p>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <ToastContainer position="top-end" className="p-3">
        <Toast
          onClose={() => setShow1(false)}
          show={show1}
          delay={8000}
          autohide
          style={{ backgroundColor: "#f27474" }}
        >
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">brainbucks</strong>
            <small>Just Now</small>
          </Toast.Header>
          <Toast.Body className="text-white">{message}</Toast.Body>
        </Toast>
      </ToastContainer>

      <div style={{ overflowY: "scroll", height: "72vh", overflowX: "hidden" }}>
        <FreeTrivaEdit onpress={Goy} Handel={handleShow} />
      </div>

      <Toaster />
    </div>
  );
};

export default FreeTrivia;
