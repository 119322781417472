import { useState } from "react";

function Slider(props) {
  const {disabled} = props;
  const [value, setValue] = useState(props.value);

  //function for handlechanges for inputs
  function handleChange(event) {
    const newValue = parseInt(event.target.value);
    const oldValue = value;
    const total = props.total - oldValue + newValue;

    if (total <= 100) {
      setValue(newValue);
      props.onChange(props.id, newValue);
    } else {
      setValue(100 - props.total + oldValue);
      props.onChange(props.id, 100 - props.total + oldValue);
    }
  }

  setTimeout(() => {
    setValue(props.value);
  }, 1);

  
  return (
    <div className="slider">
    {
      disabled?  <input
      readOnly
     
      style={props.style}
        type="range"
        className="w-100 ranges"
        min={0}
        max={100}
        value={value}
       
      />:  <input
   
      style={props.style}
        type="range"
        className="w-100 ranges"
        min={0}
        max={100}
        value={value}
        onChange={handleChange}
      />
    }
    </div>
  );
}

export default Slider;
