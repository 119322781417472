// Modal.js
import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';

const Modal = ({ show, onClose, title, videoUrl }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal-content" style={{background: "#3a3a3a"}}>
        <div className="modal-header">
          <h5 className="modal-title">{title}</h5>
          <button type="button" className="btn" style={{ color: "#fff" }} onClick={onClose} aria-label="Close">
            Close
          </button>
        </div>
        <div className="modal-body">
        <ReactPlayer url={videoUrl} controls width="100%" height="500px" playing={true} />
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  videoUrl: PropTypes.string
};

export default Modal;