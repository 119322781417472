import React, { useState , useEffect, useContext } from "react";
import TaskCard from "./TaskCard";
import TaskTableHead from "./TaskTableHead";
import { AUTH_BASE_URL, AuthContext } from "../Utils/AuthContextProvider";
import { toast } from "react-toastify";
const NotStarted = () => {
  const [not_started, setnot_started] = useState([]);
  const {taskCount, setTaskCount} = useContext(AuthContext)
  const handleSelectChange = (value,id,date) => {
    update(value,id,date);
   
  }

  const update = async (value,id,date) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow"
      };

   const response = await fetch(` ${AUTH_BASE_URL}/taskpanel/change/status?id=${id}&status=${value}&due_date=${date}`, requestOptions)
    const result = await response.json();
    if(result.status==1){
      Not_Started()

    }else if(result.status=="INT_ERR"){
      toast.error(result.Backend_Error)
    }
    } catch (error) {
      console.log(error)
    }
  }



  const Not_Started = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
      
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

    const response = await  fetch(` ${AUTH_BASE_URL}/taskpanel/notStarted?searchQuery=&page=1&limit=9`, requestOptions)
    const result = await response.json();
    if(result.status==1){
      setnot_started(result.data)
      setTaskCount({
        ...taskCount , notStarted : result?.totalTasks
      })
    }
    else{
      toast.error("failed")
    }
  }
    catch(error){
          console.log(error)
    }
  }

  useEffect(() => {
      Not_Started()
  }, [])
  
  return (
    <div>
      <TaskTableHead />
      <TaskCard  type={not_started} handleSelectChange={handleSelectChange} need={1} />

    </div>
  );
};

export default NotStarted;
