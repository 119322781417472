import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SideBar from "../SideBar/SideBar";
import BannerData from "./BannerData";
import { BB_URl } from "../Utils/AuthContextProvider";

const Banner = () => {
  const Navigate = useNavigate();

  const CheckToken = async (Token) => {
    var formdata = new FormData();
    formdata.append("token", Token);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(`${BB_URl}/api/admin/check-token`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result)
        if (result.status == 1) {
          //  console.log("nice");
        } else {
      
        }
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
  
    } else {
      CheckToken(localStorage.getItem("token"));
    }
  }, []);
  
  return (
    <>
      <div style={{ overflow: "hidden" }}>
        <div className="row">
          <div className="col-2">
            <SideBar />
          </div>
          <div className="col-10">
            <div className="container">
              <BannerData />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
