import { useState, useEffect, useContext } from "react";
import SearchIcon from "../../Img/ri_search-line.svg";
import Lottie from "lottie-react";
import Clebrate from "../../Img/lf20_VuglDs.json";
import { FaArrowRight } from "react-icons/fa";
import "./Categories.css";
import { toast, ToastContainer } from 'react-toastify';
import pro from "../../Img/Group 133.png";
import Offcanvas from "react-bootstrap/Offcanvas";
import LoaderComponent from "../Utils/LoaderComponent";
import { AuthContext, BASE_URL, BB_URl, IMAGE_URL, QUIZ_BASE_URL } from "../Utils/AuthContextProvider";

// import { toast } from "react-toastify";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';


import { Navigate, useNavigate } from "react-router-dom";
import SideBar from "../SideBar/SideBar";
import { Spinner } from "react-bootstrap";
// export const BASE_URL = "http://98.70.11.123:3002/admin"

const initLoader = {
  isLoading: false,
  isError: false,
};

const initLoader1 = {
  isLoading1: false,
  isError1: false,
};

const Categories = () => {
  const { state } = useContext(AuthContext);
  const [AllSub, setAllSub] = useState();
  const [first, setfirst] = useState(0);
  const [Change, setChange] = useState("");
  const [Img, setImg] = useState();
  const [Subacat, setSubacat] = useState("");
  const [Cat, setCat] = useState("");
  const [check, setCheck] = useState(0);
  const [Click, setClick] = useState(0);
  const [Cat2, setCat2] = useState("");
  const [List, setList] = useState([]);
  const [List2, setList2] = useState([]);
  const [Data, setData] = useState([]);
  const [Id, setId] = useState();
  const [SubCategories, setSubCategories] = useState([]);
  const [dataArray, setdataArray] = useState([]);
  const [Img1, setImg1] = useState(pro);
  const [Img2, setImg2] = useState(pro);
  const [dataArray1, setdataArray1] = useState([]);
  const [timerCount, setTimer] = useState(15);
  const [BackTo, setBackTo] = useState([]);
  const [search, setSearch] = useState("");
  const [Timer, setTimers] = useState(15);
  const [Timer1, setTimers1] = useState(15);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [filterData, setFilterData] = useState();
  const [loader, setLoader] = useState(initLoader);
  const [isImage, setIsImage] = useState(false);
  const [loader1, setLoader1] = useState(initLoader1);

  const [subcatname, setsubcatname] = useState([])
  const [catname, setcatname] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState("")

  const [categoryName, setcategoryName] = useState([]);
  const [subcategory, setsubcategory] = useState([]);


  //required destructuring from the above state objects
  const { isLoading } = loader;
  const { isLoading1 } = loader1;
  const Navigate = useNavigate();
  const { token } = state;
  const [inputvalues, setinputvalues] = useState('');
  const [input_val, setInputVal] = useState([]);
  const [checkedValues, setCheckedValues] = useState([]);
  const [checkLoading, setCheckLoading] = useState(false); // Local loading state


  const myfunc = (checking) => {
    if (input_val.includes(checking)) {
      toast.error('subcategory already exists');
      return;
    } else {
      setInputVal((prevInputVal) => [...prevInputVal, checking]);
      setinputvalues("")
    }
  };

  const handleCheckboxChange = (e) => {
    const value = e.target.value;
    if (e.target.checked) {
      setCheckedValues((prevCheckedValues) => [...prevCheckedValues, value]);
    } else {
      setCheckedValues((prevCheckedValues) =>
        prevCheckedValues.filter((item) => item !== value)
      );
    }
  };



  const handleChangePagi = (event, value) => {
    setCurrentPage(value);
  };

  //functions for handle the on/off for offcanvases
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);



  //function for to select and set to the category
  const Categories2 = (e) => {
    setCat2(e.target.value) ;
  };

  //function for handle chnages for category image
  const ImageFun = (event) => {
    setImg({
      picturePreview: URL.createObjectURL(event.target.files[0]),
      pictureAsFile: event.target.files[0],
    });
    setImg1(event.target.files[0]);
    setImg2(URL.createObjectURL(event.target.files[0]));
    setIsImage(true);
  };





  //function for hnadle changes
  const handleChange1 = (e) => {
    var updatedList1 = [...dataArray1];

    if (e.target.checked) {
      updatedList1 = [...dataArray1, e.target.value];
    } else {
      updatedList1.splice(dataArray1.indexOf(e.target.value), 1);
    }
    setdataArray1(updatedList1);
  };



  //function for move to the next step in offcanvas
  const myFunction = () => {
    setfirst(first + 1);
  };

  //function for adding new category
  const Api = async () => {
    var myHeaders = new Headers();
    myHeaders.append("location", "");
    var formdata = new FormData();
    formdata.append("category_name", Change);
    formdata.append("category_image", Img.pictureAsFile);
    formdata.append("subcategory", dataArray1);
    formdata.append("token", token);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch("/quiz/category/view/category?page=1&limit=10&search=s", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.status == 1) {
          setBackTo(result.status);
          setImg1(pro);
          setdataArray1([]);
          setList([]);
          setIsImage(false);
          setChange("");
          setImg("");
          setfirst(first + 1);
          let interval = setInterval(() => {
            setTimers((lastTimerCount) => {
              lastTimerCount <= 1 && clearInterval(interval);
              return lastTimerCount - 1;
            });
          }, 1000);
          return () => {
            clearInterval(interval);
          };
        }
      })
      .catch((error) => console.log("error", error));
  };

  const New = async (ids) => {
    setLoader1({
      ...loader1,
      isLoading1: true,
    });
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow"
      };
      const war = await fetch(
        `${QUIZ_BASE_URL}/category/view/subcategory/?cat_id=${ids}`
        , requestOptions);
      const response1 = await war.json();

      // console.log(response1);
      if (response1.status == 1) {
        setLoader1({
          ...loader1,
          isLoading1: false,
        });
        console.log("checking here", response1.data)
        setSubCategories(response1.data);
      } else {
        // alert(response1.message);
        setLoader1({
          ...loader1,
          isLoading1: false,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };




  const [totalCategories, setTotalCategories] = useState(0)
  const Categories12 = async () => {
    setLoader({
      ...loader,
      isLoading: true,
    });
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow"
      };

      const response = await fetch(`${QUIZ_BASE_URL}/category/view/category?page=${currentPage}&limit=${12}&search=${search}`, requestOptions)
      const result = await response.json()
      if (result.status == 1) {
        setData(result.categories);
        setFilterData(result.categories);
        setTotalPages(result.totalPages);
        setLoader({
          ...loader,
          isLoading: false,
        });
        setTotalCategories(result.totalCategories)
      } else {
        console.log(response);
      }
    } catch (e) {
      console.log(e)
    }
    finally {
      setLoader({
        ...loader,
        isLoading: false,
      });
    }
    setfirst(0);
    setChange("");
    setImg("");
  }

  const handleSearch = () => {
    // setSearch(e.target.value);
    setCurrentPage(1)
    Categories12();
  }

  useEffect(() => {
    if (search == "") {
      Categories12()
    }
  }, [search])


  // const MutiSub = async () => {
  //   var myHeaders = new Headers();
  //   myHeaders.append("location", "");
  //   var formdata = new FormData();
  //   formdata.append("subcategory", dataArray);
  //   formdata.append("token", token);
  //   var requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: formdata,
  //     redirect: "follow",
  //   };

  //   fetch(
  //     "https://brainbucks.co.in/api/admin/addsubcat?parent_id=" + Id,
  //     requestOptions
  //   )
  //     .then((response) => response.json())
  //     .then((result) => {
  //       if (result.status === 1) {
  //         setdataArray([]);
  //         setClick(Click + 1);
  //         let interval = setInterval(() => {
  //           setTimers1((lastTimerCount) => {
  //             lastTimerCount <= 1 && clearInterval(interval);
  //             return lastTimerCount - 1;
  //           });
  //         }, 1000);
  //         return () => {
  //           clearInterval(interval);
  //         };
  //       } else {
  //         // alert(result.message);
  //       }
  //     })
  //     .catch((error) => console.log("error", error));
  // };

  const MutiSub = async (sub_cat) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

      const raw = JSON.stringify({
        "sub_category_name": Cat2,
        "category_name": sub_cat
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      const response = await fetch(`${QUIZ_BASE_URL}/category/add/subcategory`, requestOptions)
      const result = await response.json();
      if (result.status == 1) {
        toast.success(result.message);
        New(Id)
        setCat2("");
        setClick(0)
      } else if (result.status == "VAL_ERR") {
        toast.error(result.Backend_Error)
      } else if (result.status == "CUSTOM_ERR") {
        toast.error(result.Backend_Error)
      }
    } catch (e) {
      console.log(e);
    }
  }

  const mmultsub = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        "sub_category_name": subcatname,
        "category_name": catname
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      const response = await fetch(`${QUIZ_BASE_URL}/category/add/subcategory`, requestOptions)
      const result = response.json();
      if (result.status == 1) {
        toast.success(result.message)
        setsubcatname("")
        New(Id)
        setcatname("")
        setClick(0)
      } else if (result.status == "CUSTOM_ERR") {
        toast.error(result.Backend_Error)
      } else if (result.status == "VAL_ERR") {
        toast.error(result.Backend_Error)
      }
    } catch (error) {
      console.log(error);
    }
  }

  //function for update subcategory under the category
  const SETUPFDATE = async (id) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      id: id,
      parent_id: Id,
      token: token,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(`${BB_URl}/api/admin/editsubcat`, requestOptions)
      .then((response) => response.json())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
  };

  //timer conditions here
  if (Timer == 0) {
    setShow(false);
    setTimers(15);
    Categories12();
    setfirst(0);
  }
  if (Timer1 == 0) {
    setShow1(false);
    setTimers1(15);
    Categories12();
    setClick(0);
  }



  // add category

  const add_categories = async () => {
    setCheckLoading(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

      const formdata = new FormData();
      formdata.append("category_image", Img1);
      formdata.append("category_name", categoryName);
      let final_array = '[' + checkedValues.map(value => `"${value}"`).join(', ') + ']';
      formdata.append("sub_categories", final_array);


      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow"
      };

      const response = await fetch(`${QUIZ_BASE_URL}/category/add/category`, requestOptions)
      const result = await response.json()
      if (result.status == 1) {
        Categories12();
        setcategoryName(result.message)
        toast.success(result.message)
        setcategoryName('')
        setImg1('')
        setCheckedValues('')
        setInputVal([])
        handleClose();
        setImg2('')
      }
      else if (result.status == "VAL_ERR") {
        toast.error(result.Backend_Error)
      }
      else if (result.status == 0) {
        toast.error(result.message);
      }

    } catch (error) {
      console.log(error)
    }
    finally {
      setCheckLoading(false); // Stop loading spinner after request completes
    }
  }

 

  useEffect(() => {
    Categories12();
  }, [currentPage]);

  useEffect(() => {
    if (
      search == ""
    ) {
      Categories12();
    }
  }, [search]);
  const handleAddNewCategoryClick = () => {
    setcategoryName('');
    setImg1('');
    setCheckedValues([]);
    setInputVal([]);
    setImg2('');
    setCat2('')

    handleShow();
  };
  const handleAddSubCategoryClick = () => {
    setCat2('')
  };
  return (
    <>
      <ToastContainer />
      <div>

      </div>

      <div className="row"
        style={{
          height: "6vh",
          overflow: "hidden"
        }}>


        <div className="col-6 d-flex">
          <div className="col-8">
            <h5 className="mt-1">
              Showing total <span style={{ color: "red" }}>{totalCategories}</span>{" "}
              Exam Categories
            </h5>
          </div>
          <div className="col-4">
            <button
              onClick={handleAddNewCategoryClick}
              style={{
                backgroundColor: "#EDF7FF",
                border: "1px solid #2188E7",
                height: "40px",
                borderRadius: "10px",
                color: "#2188E7",
              }}
            >
              <span className="p-3" > +Add Category</span>
            </button>
          </div>
        </div>


        <div className="col-6">
          <div className="row">
            <div className="col-4"></div>
            <div className="col-6">
              <div
                className="row"
                style={{
                  border: "1px solid #D9D9D9",
                  height: "40px",
                  borderRadius: "7px",
                }}
              >
                <div className="col-2 mt-1">
                  <img alt="SearchIcon" src={SearchIcon} />
                </div>
                <div className="col-10 mt-1 ">
                  <input
                    className="border-0 w-100"
                    placeholder="Search for Exam Category"
                    value={search}
                    style={{
                      textDecoration: "none",
                      outline: "none",
                      color: "#272727",
                    }}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="col-2">
              <button
                className="border-0 bg-white"
                style={{
                  background:
                    "linear-gradient(180deg, rgba(35,176,101,1) 0%, rgba(46,135,96,1) 100%)",
                  color: "#fff",
                  height: "40px",
                  borderTopRightRadius: 10,
                  borderBottomRightRadius: 10,
                  marginLeft: "-20px",
                }}
                onClick={handleSearch}
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>






      <section
        className="mt-3"
        style={{ height: "57vh", overflowY: "scroll", overflowX: "hidden" }}
      >
        <div className="row">
          {isLoading ? (
            <LoaderComponent />
          ) : (
            filterData?.map((res1, key1) => {
              return (
                <>
                  <div key={key1} className="col-4 mt-3">
                    <span
                      className="card"
                      onClick={async () => {
                        setId(res1._id);
                        setSubacat(res1);
                        New(res1._id);
                        setAllSub(res1.sub_category);
                        handleShow1();
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="d-flex ps-2">
                        <span className="col-2">
                          <div
                            className="mt-2"
                            style={{
                              width: "40px",
                              height: "40px",
                              border: "1px solid #D9D9D9",
                              borderRadius: "50px",
                              justifyContent: "center",
                              objectFit: "cover",
                              overflow: "hidden",
                            }}
                          >
                            <img
                              style={{ width: "100%", height: "100%", objectFit: "cover" }}
                              src={`${IMAGE_URL}${res1.image_url}`}
                              alt="#"
                            />
                          </div>
                        </span>
                        <div className="col-8 mt-2">
                          <span className="ml-5">
                            <p
                              style={{
                                marginBottom: "1px",
                                fontStyle: "normal",
                                fontWeight: "600",
                                fontSize: "20px",
                                color: "#000000",
                              }}
                            >
                              {res1.category_name}
                            </p>
                            <p
                              style={{
                                marginTop: "-10px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "16px",
                                color: "#8A8A8A",
                              }}
                            >
                              sub-categories-
                              <span
                                style={{
                                  color: "red",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  color: "#CC1313",
                                }}
                              >
                                {res1.subcategory_count}
                              </span>
                            </p>
                          </span>
                        </div>
                        <span
                          className="col-2 mt-3"
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <FaArrowRight />
                        </span>
                      </div>
                    </span>
                  </div>
                </>
              );
            })
          )}
        </div>

      </section>

      <div>
        <Stack spacing={2} className="d-flex justify-content-center mt-3 align-items-center" >
          <Pagination count={totalPages} page={currentPage} onChange={handleChangePagi} />
        </Stack>
      </div>

      {/* for addcategory offcanvas */}

      <Offcanvas
        show={show}
        // onHide={handleClose}
        placement="end"
        style={{ borderTopLeftRadius: 30, width: "50%" }}
      >
        <Offcanvas.Header>
          <div>
            <span
              style={{ color: "#000", fontWeight: "600", cursor: "pointer" }}
              onClick={() => {
                setfirst(0);
                handleClose();
                setImg1(pro);
                setdataArray1([]);
                setList([]);
                setIsImage(false);
                setChange("");
                setImg("");
              }}
            >
              {first > 3 ? (
                ""
              ) : (
                <svg
                  width="35"
                  height="35"
                  viewBox="0 0 35 35"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M27.4913 9.40793C27.616 9.28337 27.7149 9.13547 27.7825 8.97267C27.85 8.80987 27.8849 8.63535 27.885 8.45909C27.8851 8.28283 27.8505 8.10827 27.7831 7.94539C27.7158 7.7825 27.617 7.63448 27.4924 7.50977C27.3679 7.38505 27.22 7.2861 27.0572 7.21854C26.8944 7.15099 26.7199 7.11617 26.5436 7.11606C26.3673 7.11595 26.1928 7.15056 26.0299 7.21791C25.867 7.28527 25.719 7.38404 25.5943 7.5086L17.4999 15.6029L9.40793 7.5086C9.15607 7.25673 8.81446 7.11523 8.45827 7.11523C8.10207 7.11523 7.76047 7.25673 7.5086 7.5086C7.25673 7.76047 7.11523 8.10207 7.11523 8.45827C7.11523 8.81446 7.25673 9.15607 7.5086 9.40793L15.6029 17.4999L7.5086 25.5919C7.38389 25.7166 7.28496 25.8647 7.21747 26.0276C7.14997 26.1906 7.11523 26.3652 7.11523 26.5416C7.11523 26.718 7.14997 26.8926 7.21747 27.0556C7.28496 27.2185 7.38389 27.3666 7.5086 27.4913C7.76047 27.7431 8.10207 27.8846 8.45827 27.8846C8.63464 27.8846 8.80928 27.8499 8.97222 27.7824C9.13517 27.7149 9.28322 27.616 9.40793 27.4913L17.4999 19.3969L25.5943 27.4913C25.8461 27.7428 26.1876 27.884 26.5436 27.8838C26.8996 27.8836 27.2409 27.742 27.4924 27.4901C27.744 27.2382 27.8852 26.8967 27.885 26.5408C27.8848 26.1848 27.7431 25.8435 27.4913 25.5919L19.3969 17.4999L27.4913 9.40793Z"
                    fill="black"
                  />
                </svg>
              )}
            </span>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body style={{ marginTop: "-20px" }}>
          <h6 style={{ fontSize: 20, fontWeight: "600" }}>
            {first >= 3 ? "" : "Add New Exam Category"}
          </h6>
          <div style={{ display: first === 0 ? "block" : "none" }}>
            <div className="mb-5">
              <div className="progress" style={{ height: "10px" }}>
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{
                    width: "25%",
                    background:
                      "linear-gradient(to right, #f44cc5, #ec40cc, #e335d3, #d72adb, #c922e4)",
                  }}
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <p style={{ color: "#8A8A8A" }}>
                1/3 <span sytle={{ color: "red" }}>Steps</span>
              </p>

              <div className="row">
                <div style={{ justifyConetnt: "center", textAlign: "center" }}>
                  <div>
                    <label
                      className="col-5 mb-2"
                      style={{
                        textAlign: "start",
                        color: "#303030",
                        fontWeight: "600",
                      }}
                    >
                      Enter Name of Exam Category
                    </label>
                    <br />
                    <input
                      className="col-5 border-0"
                      style={{
                        textAlign: "start",
                        height: "50px",
                        backgroundColor: "#EFEFEF",
                        borderRadius: 7,
                        outline: 1,
                      }}
                      placeholder="Start typing the name of Exam"
                      value={categoryName}
                      onChange={(e) => setcategoryName(e.target.value)}
                      type="text"
                      maxLength={50}
                    />
                    <br />
                    <span style={{ marginLeft: "200px" }}>
                      {Change.length}/50
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                justifyContent: "center",
                textAlign: "center",
                backgroundColor: "",
              }}
            >
              <button
                className="col-5 border-0 text-white"
                onClick={myFunction}
                disabled={categoryName.length >= 1 ? false : true}
                style={{
                  backgroundColor: "#2188E7",
                  height: "40px",
                  borderRadius: 7,
                }}
              >
                process
              </button>
            </div>
          </div>
          <div style={{ display: first === 1 ? "block" : "none" }}>
            <div className="mb-5">
              <div className="progress" style={{ height: "10px" }}>
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{
                    width: "50%",
                    background:
                      "linear-gradient(to right, #f44cc5, #ec40cc, #e335d3, #d72adb, #c922e4)",
                  }}
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <p>steps 2/3</p>

              <div className="row">
                <div style={{ justifyConetnt: "center", textAlign: "center" }}>
                  <div>
                    <label
                      className="col-5 mb-2"
                      style={{
                        color: "#303030",
                        fontWeight: "600",
                        textAlign: "center",
                      }}
                    >
                      Upload Category Image
                    </label>
                    <br />
                    <label
                      className="filelabel21"
                      style={{
                        overflow: "hidden",
                        borderRadius: "50%",
                        marginLeft: "70px",
                      }}
                    >
                      <span className="title">
                        {/* to add image to the categoty */}

                        <img
                          src={Img2}
                          style={{
                            width: "20%",
                            height: "100px",
                            borderRadius: "50%",
                          }}
                        />
                      </span>
                      <input
                        className="FileUpload21"
                        id="FileInput"
                        type="file" accept="image/*"
                        onChange={ImageFun}
                      />
                    </label>
                    {/* <input
                      type="file"
                      className="col-5 border-0"
                      style={{
                        textAlign: "start",
                        height: "100px",
                        width: "100px",
                        borderRadius: "50%",
                        backgroundColor: "#EFEFEF",
                        outline: 1,
                      }}
                      onChange={ImageFun}
                    /> */}
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                justifyContent: "center",
                textAlign: "center",
                backgroundColor: "",
              }}
            >
              {/* button to add image to the category */}
              <button
                className="col-5 border-0 text-white"
                disabled={!isImage}
                onClick={myFunction}
                style={{
                  backgroundColor: "#2188E7",
                  height: "40px",
                  borderRadius: 7,
                }}
              >
                process
              </button>
            </div>
          </div>
          <div style={{ display: first === 2 ? "block" : "none" }}>
            <div className="mb-5">
              <div className="progress" style={{ height: "10px" }}>
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{
                    width: "75%",
                    background:
                      "linear-gradient(to right, #f44cc5, #ec40cc, #e335d3, #d72adb, #c922e4)",
                  }}
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <p>steps 3/3</p>

              <div className="row">
                <div style={{ justifyConetnt: "center", textAlign: "center" }}>
                  <div>
                    <label
                      className="col-5 mb-2"
                      style={{
                        textAlign: "start",
                        color: "#303030",
                        fontWeight: "600",
                      }}
                    >
                      Add Sub Categories
                    </label>
                    <br />
                    <input
                      className="col-3 border-0"
                      style={{
                        textAlign: "start",
                        height: "50px",
                        backgroundColor: "#EFEFEF",
                        borderRadius: 7,
                        outline: 1,
                      }}
                      placeholder="  Enter name of Sub Categories"
                      value={inputvalues}
                      onChange={(e) => { setinputvalues(e.target.value) }}
                    />
                    <button
                      className="col-2 ms-2 border-0"
                      style={{
                        backgroundColor: "#2188E7",
                        color: "#fff",
                        textAlign: "center",
                        height: "50px",
                        borderRadius: 7,
                      }}
                      onClick={() => {
                        if (inputvalues <= 0) {
                          toast.error("pls enter value")
                        } else {
                          myfunc(inputvalues)
                        }
                      }}
                    >
                      +Add
                    </button>
                  </div>

                  <div className="pt-3" >

                    {
                      input_val.map((data, value) => {
                        return (
                          <div key={value} className="d-flex justify-content-center  align-items-center gap-2" >
                            <input
                              type="checkbox"
                              value={data}
                              checked={checkedValues.includes(data)}
                              onChange={handleCheckboxChange}
                            />
                            <p className="mb-0" >{data}</p>
                          </div>
                        );
                      })
                    }
                  </div>

                  {/* {AddFunS=="add"?(
              
              Categories.map((res,key)=>{
                return(
                  <>
                  <div key={key} className="h-50">
              <input type="checkbox" />
              <p>{Categories[0]}</p>
            </div>
            </>
          )
              })
            )   
              
              :(<></>)} */}
                  {List.map((res, key) => {
                    return (
                      <>
                        <div key={key} className="row">
                          <div className="col-3"></div>
                          <div className="col-9 d-flex">
                            <input
                              className="ms-4"
                              type="checkbox"
                              value={res.Cat}
                              onClick={(e) => {
                                handleChange1(e);
                              }}
                            />
                            <p className="mt-3 ms-4">{res.Cat}</p>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
            <div
              style={{
                justifyContent: "center",
                textAlign: "center",
                backgroundColor: "",
              }}
            >
              <button
                className="col-5 border-0 text-white"

                disabled={checkedValues.length <= 0 ? true : false}
                onClick={add_categories}

                style={{

                  backgroundColor: "#2188E7",
                  height: "40px",
                  borderRadius: 7,
                }}
              >
                 {checkLoading ? <Spinner /> : "Proceed"}
              </button>
            </div>
          </div>
          <div style={{ display: first === 3 ? "block" : "none" }}>
            <div className="mb-5">
              <div className="progress" style={{ height: "10px" }}>
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{
                    width: "100%",
                    background:
                      "linear-gradient(to right, #f44cc5, #ec40cc, #e335d3, #d72adb, #c922e4)",
                  }}
                  aria-valuenow="100"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <p>steps 4/4</p>

              <div className="row">
                <div style={{ justifyConetnt: "center", textAlign: "center" }}>
                  <div>
                    <label
                      className="col-5 mb-2"
                      style={{
                        textAlign: "center",
                      }}
                    >
                      New Exam Category &nbsp;
                      <span style={{ fontWeight: "600", fontSize: "18px" }}>
                        {Change}
                      </span>
                      &nbsp; will be created with following
                    </label>
                    <br />
                    <p>
                      {List.map((res) => {
                        return (
                          <>
                            <p>{res.Cat}</p>
                          </>
                        );
                      })}
                    </p>
                    <br />
                    <img
                      src={Img1}
                      style={{
                        width: "100px",
                        height: "100px",
                        borderRadius: "50%",
                      }}
                      alt="Cat img"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                justifyContent: "center",
                textAlign: "center",
                backgroundColor: "",
              }}
            >
              <button
                className="col-5 border-0 text-white"
                disabled={check == 0 ? false : true}
                onClick={() => {
                  Api();
                  setCheck(check + 1);
                }}
                style={{
                  backgroundColor: "#2188E7",
                  height: "40px",
                  borderRadius: 7,
                }}
              >
                Confirm
              </button>
            </div>
          </div>
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: first > 3 ? "block" : "none",
            }}
          >
            <div style={{ textAlign: "center", alignItems: "center" }}>
              <p style={{ fontSize: "20px" }}>
                New Exam Categories "<span>{Change}</span>"<br /> added
                Succesfully
              </p>
            </div>
            <div
              className="d-flex"
              style={{ textAlign: "center", alignItems: "center" }}
            >
              <div className="col-3 ms-5"></div>
              <Lottie
                animationData={Clebrate}
                loop={true}
                style={{ width: "40%" }}
              />
            </div>
            <div style={{ textAlign: "center", alignItems: "center" }}>
              {/* <NavLink to="/Home"> */}
              <button
                className="border-0 col-4 mt-4"
                style={{
                  backgroundColor: "#0CBC8B",
                  color: "#fff",
                  height: "40px",
                  borderRadius: 7,
                }}
                onClick={() => {
                  Categories12();
                  handleClose();
                }}
              >
                Back to Dashboard
              </button>
              {/* </NavLink> */}
            </div>
            <div
              className="mt-5"
              style={{ textAlign: "center", alignItems: "center" }}
            >
              <p style={{ color: "#8A8A8A" }}>
                This tab will automatically close in
                <span style={{ color: "#CC1313", fontSize: 20 }}>
                  {" "}
                  {Timer}{" "}
                </span>
                Seconds
              </p>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      {/* offcanvavs for  */}
      <Offcanvas
        show={show1}
        onHide={handleClose1}
        placement="end"
        style={{ borderTopLeftRadius: 30, width: "50%" }}
      >
        <Offcanvas.Header>
          <div>
            <span
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={() => {
                setClick(0);
                Categories12("");
                setClick(0);
                setList2([]);
                handleClose1();
              }}
              style={{
                color: "#000",
                fontWeight: "600",
                display: Click === 2 ? "none" : "block",
              }}
            >
              <svg
                width="35"
                height="35"
                viewBox="0 0 35 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M27.4913 9.40793C27.616 9.28337 27.7149 9.13547 27.7825 8.97267C27.85 8.80987 27.8849 8.63535 27.885 8.45909C27.8851 8.28283 27.8505 8.10827 27.7831 7.94539C27.7158 7.7825 27.617 7.63448 27.4924 7.50977C27.3679 7.38505 27.22 7.2861 27.0572 7.21854C26.8944 7.15099 26.7199 7.11617 26.5436 7.11606C26.3673 7.11595 26.1928 7.15056 26.0299 7.21791C25.867 7.28527 25.719 7.38404 25.5943 7.5086L17.4999 15.6029L9.40793 7.5086C9.15607 7.25673 8.81446 7.11523 8.45827 7.11523C8.10207 7.11523 7.76047 7.25673 7.5086 7.5086C7.25673 7.76047 7.11523 8.10207 7.11523 8.45827C7.11523 8.81446 7.25673 9.15607 7.5086 9.40793L15.6029 17.4999L7.5086 25.5919C7.38389 25.7166 7.28496 25.8647 7.21747 26.0276C7.14997 26.1906 7.11523 26.3652 7.11523 26.5416C7.11523 26.718 7.14997 26.8926 7.21747 27.0556C7.28496 27.2185 7.38389 27.3666 7.5086 27.4913C7.76047 27.7431 8.10207 27.8846 8.45827 27.8846C8.63464 27.8846 8.80928 27.8499 8.97222 27.7824C9.13517 27.7149 9.28322 27.616 9.40793 27.4913L17.4999 19.3969L25.5943 27.4913C25.8461 27.7428 26.1876 27.884 26.5436 27.8838C26.8996 27.8836 27.2409 27.742 27.4924 27.4901C27.744 27.2382 27.8852 26.8967 27.885 26.5408C27.8848 26.1848 27.7431 25.8435 27.4913 25.5919L19.3969 17.4999L27.4913 9.40793Z"
                  fill="black"
                />
              </svg>
            </span>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div style={{ display: Click === 0 ? "block" : "none" }}>
            <div className="row">
              <div className="col-2">
                <div
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    justifyContent: "center",
                    overflow: "hidden",
                  }}
                >
                  <img alt="#" src={Subacat.image_url} width="100%" />
                </div>
              </div>
              <div className="col-8">
                <h2>{Subacat.category_name}</h2>
              </div>
              <div className="col-2">
                <button
                  onClick={() => {
                    handleClose1();
                    // New(Id);
                    Categories12();
                  }}
                  className="border-0 w-100"
                  style={{
                    color: "#2188E7",
                    backgroundColor: "#EAF5FF",
                    borderRadius: 10,
                    height: "35px",
                  }}
                >
                  Save
                </button>
              </div>
            </div>
            <div>
              <div className="col-6 d-flex ms-5">
                <p className="mt-2">
                  Sub Categories <span style={{ color: "red" }}>{AllSub}</span>
                </p>
                <button
                  className="border-0 ms-5 text-nowrap"
                  style={{
                    color: "#2188E7",
                    backgroundColor: "#EAF5FF",
                    borderRadius: 10,
                    height: "35px",
                  }}
                  onClick={() => {
                    setClick(1);
                  }}
                >
                  +Add Subcategory
                </button>
              </div>
            </div>
            {/* <p
              className="ms-5"
              style={{ color: "#000000", fontWeight: 600, fontSize: 16 }}
            >
              Sub Categories
            </p> */}
            <hr />
            {isLoading1 ? (
              <LoaderComponent />
            ) : (
              SubCategories?.map((res, key) => {
                return (
                  <div key={res.id} className="ms-5 mt-2 d-flex">
                    {/* come back here */}
                    <input
                      className="mt-1"
                      type="checkbox"
                      defaultChecked={res.status == 0 ? false : true}
                      onChange={() => {
                        SETUPFDATE(res.id);
                      }}
                      style={{ height: "20px", width: "20px" }}
                    />
                    <p
                      className="ms-4"
                      style={{
                        color: "#000000",
                        fontWeight: "600",
                        fontSize: 20,
                      }}
                    >
                      {res.name}
                    </p>
                  </div>
                );
              })
            )}
          </div>
          <div style={{ display: Click === 1 ? "block" : "none" }}>
            <div className="row">
              <div className="col-2">
                <div
                  className=""
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    justifyContent: "center",
                    overflow: "hidden",
                  }}
                >
                  <img alt="#" src={Subacat.image_url} width="100%" />
                </div>
              </div>
              <div className="col-8">
                <h2>{Subacat.category_name}</h2>
              </div>
              <div className="col-2">
                {/* <button
                  className="border-0 w-100"
                  style={{
                    color: "#2188E7",
                    backgroundColor: "#EAF5FF",
                    borderRadius: 10,
                    height: "35px",
                  }}
                >
                  Save
                </button> */}
              </div>
            </div>
            <div className="row">
              <div
                className="mt-5"
                style={{ justifyConetnt: "center", textAlign: "center" }}
              >
                <div>
                  <label
                    className="col-5 mb-2"
                    style={{
                      textAlign: "start",
                      color: "#303030",
                      fontWeight: "600",
                    }}
                  >
                    Add Sub Categories
                  </label>
                  <br />
                  <input
                    className="col-5 border-0"
                    style={{
                      textAlign: "start",
                      height: "50px",
                      backgroundColor: "#EFEFEF",
                      borderRadius: 7,
                      outline: 1,
                    }}
                    placeholder="  Enter name of Sub Categories"
                    value={Cat2}
                    onChange={Categories2}
                    onClick={handleAddSubCategoryClick}
                  />

                </div>
              </div>
            </div>
            <div
              className="mt-5"
              style={{
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <button
                className="col-5 border-0 text-white"
                disabled={Cat2.length >= 1 ? false : true}
                onClick={() => {
                  setClick(Click + 1);
                  MutiSub(Subacat.category_name);
                }}
                style={{
                  backgroundColor: "#2188E7",
                  height: "40px",
                  borderRadius: 7,
                }}
              >
                Proceed
              </button>
            </div>
          </div>
          <div style={{ display: Click === 2 ? "block" : "none" }}>
            <div style={{ textAlign: "center", alignItems: "center" }}>
              <p style={{ fontSize: "20px" }}>
                New Exam Categories "<span>{Subacat.category_name}</span>"<br />{" "}
                added Succesfully
              </p>
            </div>
            <div
              className="d-flex"
              style={{ textAlign: "center", alignItems: "center" }}
            >
              <div className="col-3 ms-5"></div>
              <Lottie
                animationData={Clebrate}
                loop={true}
                style={{ width: "40%" }}
              />
            </div>
            <div style={{ textAlign: "center", alignItems: "center" }}>
              <button
                className="border-0 col-4 mt-4"
                style={{
                  backgroundColor: "#0CBC8B",
                  color: "#fff",
                  height: "40px",
                  borderRadius: 7,
                }}
                onClick={() => {
                  Categories12("");
                  handleClose1();
                  setClick(0);
                  setList2([]);
                }}
              >
                Back to Dashboard
              </button>
            </div>
            <div
              className="mt-5"
              style={{ textAlign: "center", alignItems: "center" }}
            >
              <p style={{ color: "#8A8A8A" }}>
                This tab will automatically close in
                <span style={{ color: "#CC1313", fontSize: 20 }}>
                  {" "}
                  {Timer1}{" "}
                </span>
                Seconds
              </p>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>


    </>
  );
};

export default Categories;

