import { useState, useEffect, useContext } from "react";
import SearchIcon from "../../Img/ri_search-line.svg";
import NotificationIcon from "../../Img/pajamas_notifications.svg";
import Images from "../../Img/blank-profile-picture-973460__340.webp";

import {
  AuthContext,
  IMAGE_URL,
  PREREC_BASE_URL,
} from "../Utils/AuthContextProvider";

import { useNavigate, useParams } from "react-router-dom";
import SideBar from "../SideBar/SideBar";

const CoursesTab = () => {
  const Navigate = useNavigate();
  const { client_id } = useParams();
  console.log(client_id, "adsdsd");
  const { logoutFun } = useContext(AuthContext);
  let notification = 40;
  let Dates = new Date().toLocaleString();

  const [freeCourses, setFreeCourses] = useState([]);
  const [paidCourses, setPaidCourses] = useState([]);
  const [paidTransectionData, setPaidTransectionData] = useState([]);
  const [allData, setAllData] = useState({});

  const [showTable, setShowTable] = useState(false);
  const [activeTab, setActiveTab] = useState("free"); // State to track active tab

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setShowTable(false);
  };

  const handleViewDetails = () => {
    setShowTable(true); // Show table when "View Details" is clicked
  };

  const handleViewDetails2 = () => {
    setShowTable(false); // Show table when "View Details" is clicked
  };

  // Api to get free courses details for individual clients
  const freeCoursesData = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    const raw = JSON.stringify({
      client_id: client_id,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${PREREC_BASE_URL}/admin/course/get/free/courses`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status == 1) {
          console.log(result);
          setFreeCourses(result?.free_courses);
        } else {
          console.error(result);
        }
      })
      .catch((error) => console.error(error));
  };

  // Api to get paid courses details for individual clients
  const paidCoursesData = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    const raw = JSON.stringify({
      client_id: client_id,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${PREREC_BASE_URL}/admin/course/get/paid/courses`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status == 1) {
          setPaidCourses(result?.paid_courses);
        } else {
          console.error(result);
        }
      })
      .catch((error) => console.error(error));
  };

  // Api to get paid courses transection details for individual clients
  const TransectionData = (course_id) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    const raw = JSON.stringify({
      course_id: course_id,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${PREREC_BASE_URL}/admin/course/get/transations/of/courses`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status == 1) {
          setAllData(result);
          setPaidTransectionData(result?.transactions);
        } else {
          console.error(result);
        }
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    freeCoursesData();
    paidCoursesData();
  }, []);

  return (
    <>
      <div style={{ overflow: "hidden" }}>
        <div className="row">
          <div className="col-2">
            <SideBar />
          </div>
          <div className="col-10">
            <div className="container">
              <div>
                <div
                  className="row mt-3 mb-2 ms-1"
                  style={{ overflowY: "none", postition: "fixed" }}
                >
                  <div className="col-6">
                    <div className="row">
                      <div
                        className="col-8"
                        style={{ backgroundColor: "#fff" }}
                      >
                        <div
                          className="row"
                          style={{
                            border: "1px solid #D9D9D9",
                            height: "40px",
                            borderRadius: "7px",
                          }}
                        >
                          <div className="col-2 mt-1">
                            <img alt="SearchIcon" src={SearchIcon} />
                          </div>
                          <div
                            className="col-10 mt-1"
                            style={{ marginLeft: "-20px" }}
                          >
                            <input
                              className="border-0 w-100"
                              placeholder="Search within Dashboard"
                              style={{
                                textDecoration: "none",
                                outline: "none",
                                color: "#272727",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-4"></div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div
                      className="d-flex"
                      style={{
                        justifyContent: "end",
                        textAlign: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className="d-flex"
                        style={{
                          width: "80px",
                          height: "40px",
                          borderRadius: 20,
                          border: "1px solid #D9D9D9",
                          justifyContent: "center",
                          backgroundColor: "#F5F5F5",
                        }}
                      >
                        <div className="mt-1">
                          <img alt="#" src={NotificationIcon} />
                        </div>
                        <div
                          className="ms-3"
                          style={{
                            width: "35px",
                            marginTop: "1px",
                            height: "35px",
                            borderRadius: "50%",
                            border: "1px solid #D9D9D9",
                            justifyContent: "center",
                            backgroundColor: "#CC1313",
                          }}
                        >
                          <p
                            style={{
                              justifyContent: "center",
                              marginTop: "5px",
                              justifyItems: "center",
                              textAlign: "center",
                              color: "#fff",
                            }}
                          >
                            {notification} +{" "}
                          </p>
                        </div>
                      </div>
                      <div
                        className="d-flex ms-4"
                        style={{
                          justifyContent: "space-between",
                          marginRight: "30px",
                        }}
                      >
                        <div
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                            justifyContent: "center",
                            overflow: "hidden",
                          }}
                        >
                          <img alt="" src={Images} width="100%" />
                        </div>
                        <div className="nav-item dropdown ms-2 mt-2 ">
                          <span
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          ></span>
                          <ul
                            className="dropdown-menu"
                            style={{ backgroundColor: "#000" }}
                          >
                            <li className="dropdown-item text-white">
                              View Profile
                            </li>
                            <hr style={{ color: "#fff" }} />
                            <li className="dropdown-item text-white">
                              View Task
                            </li>
                            <hr style={{ color: "#fff" }} />
                            <li className="dropdown-item text-white">
                              <span onClick={() => logoutFun()}>Log-Out</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="container">
                  <div className="row mt-4">
                    <div className="col-9 mb-4">
                      <div className="row">
                        {/* Free Courese tab */}
                        <div className="col-2">
                          <span
                            style={{
                              fontWeight: activeTab === "free" ? "600" : "400",
                              color:
                                activeTab === "free" ? "#000000" : "#8A8A8A",
                              fontSize: 17,
                              cursor: "pointer",
                            }}
                            onClick={() => handleTabChange("free")}
                          >
                            Free Courses
                          </span>
                        </div>

                        {/* Paid Courses Tab */}
                        <div className="col-2">
                          <span
                            style={{
                              fontWeight: activeTab === "paid" ? "600" : "400",
                              color:
                                activeTab === "paid" ? "#000000" : "#8A8A8A",
                              fontSize: 17,
                              cursor: "pointer",
                            }}
                            onClick={() => handleTabChange("paid")}
                          >
                            Paid Courses
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="row" style={{ marginRight: "20px" }}>
                        <div className="col-2"></div>

                        <div className="col-6">
                          <button
                            style={{
                              backgroundColor: "#EDF7FF",
                              border: "1px solid #2188E7",
                              height: "40px",
                              borderRadius: "10px",
                              color: "#2188E7",
                            }}
                            onClick={() => {
                              Navigate("/Courses");
                            }}
                          >
                            <span className="pe-2 ps-2"> Back</span>
                          </button>
                        </div>
                      </div>
                    </div>

                    {/* start here */}

                    <div className="row">
                      <div className="col-9">
                        <div className="row">
                          <div
                            className="col-2"
                            style={{
                              borderBottom:
                                activeTab === "free" ? "2px solid #000000" : "",
                            }}
                          ></div>
                          <div
                            className="col-2"
                            style={{
                              borderBottom:
                                activeTab === "paid" ? "2px solid #000000" : "",
                            }}
                          ></div>
                        </div>
                      </div>
                      <div className="col-4"></div>
                    </div>
                    <hr />

                    {/* end here */}

                    <>
                      {activeTab === "free" && (
                        <div
                          className="container"
                          style={{ height: "74vh", overflow: "auto" }}
                        >
                          <div class="row row-cols-1">
                            {freeCourses.length === 0 ? (
                              <h5>No Data Found</h5>
                            ) : (
                              <div class="card-group">
                                {freeCourses?.map((res, key) => (
                                  <div className="col-lg-3 col-md-4 col-sm-6 mb-4" key={key}>
                                    <div class="card me-3">
                                      <img
                                        src={`${IMAGE_URL}${res?.banner}`}
                                        class="card-img-top"
                                        alt="..."
                                        width="100"
                                        height="150"
                                      />
                                      <div class="card-body">
                                        <h5 class="card-title">
                                          {res?.cou_name}
                                        </h5>
                                        <p
                                          class="card-text"
                                          style={{
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                            fontSize: "16px",
                                            color: "#8A8A8A",
                                          }}
                                        >
                                          No of Videos:
                                          <span
                                            style={{
                                              color: "red",
                                              fontStyle: "normal",
                                              fontWeight: "400",
                                              fontSize: "16px",
                                            }}
                                          >
                                            {res?.video_count}
                                          </span>
                                        </p>
                                        <p
                                          class="card-text"
                                          style={{
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                            fontSize: "16px",
                                            color: "#8A8A8A",
                                          }}
                                        >
                                          No of Study Material:
                                          <span
                                            style={{
                                              color: "red",
                                              fontStyle: "normal",
                                              fontWeight: "400",
                                              fontSize: "16px",
                                            }}
                                          >
                                            {res?.study_materials_count}
                                          </span>
                                        </p>
                                        <p
                                          class="card-text"
                                          style={{
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                            fontSize: "16px",
                                            color: "#8A8A8A",
                                          }}
                                        >
                                          Duration:
                                          <span
                                            style={{
                                              color: "red",
                                              fontStyle: "normal",
                                              fontWeight: "400",
                                              fontSize: "16px",
                                            }}
                                          >
                                            {res?.Duration}
                                          </span>
                                        </p>
                                        <p
                                          class="card-text"
                                          style={{
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                            fontSize: "16px",
                                            color: "#8A8A8A",
                                          }}
                                        >
                                          Category Name:
                                          <span
                                            style={{
                                              color: "red",
                                              fontStyle: "normal",
                                              fontWeight: "400",
                                              fontSize: "16px",
                                            }}
                                          >
                                            {res?.category_name}
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                      {activeTab === "paid" && !showTable && (
                        <>
                          <div>
                            <div
                              className="container"
                              style={{ height: "74vh", overflow: "auto" }}
                            >
                              <div class="row row-cols-1">
                                {paidCourses.length === 0 ? (
                                  <h5>No data found</h5>
                                ) : (
                                  <div class="card-group">
                                    {paidCourses?.map((res, key) => (
                                      <div className="col-lg-3 col-md-4 col-sm-6 mb-4" key={key}>
                                        <div class="card me-3" key={key}>
                                          <img
                                            src={`${IMAGE_URL}${res?.banner}`}
                                            class="card-img-top"
                                            alt="..."
                                            width="200"
                                            height="130"
                                          />
                                          <div class="card-body">
                                            <h5 class="card-title">
                                              {res?.cou_name}
                                            </h5>
                                            <p
                                              class="card-text"
                                              style={{
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                                fontSize: "16px",
                                                color: "#8A8A8A",
                                              }}
                                            >
                                              No of Videos:
                                              <span
                                                style={{
                                                  color: "red",
                                                  fontStyle: "normal",
                                                  fontWeight: "400",
                                                  fontSize: "16px",
                                                }}
                                              >
                                                {res?.video_count}
                                              </span>
                                            </p>
                                            <p
                                              class="card-text"
                                              style={{
                                                marginTop: "-10px",
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                                fontSize: "16px",
                                                color: "#8A8A8A",
                                              }}
                                            >
                                              No of Study Material:
                                              <span
                                                style={{
                                                  color: "red",
                                                  fontStyle: "normal",
                                                  fontWeight: "400",
                                                  fontSize: "16px",
                                                }}
                                              >
                                                {res?.study_materials_count}{" "}
                                              </span>
                                            </p>
                                            <p
                                              class="card-text"
                                              style={{
                                                marginTop: "-10px",
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                                fontSize: "16px",
                                                color: "#8A8A8A",
                                              }}
                                            >
                                              Duration:
                                              <span
                                                style={{
                                                  color: "red",
                                                  fontStyle: "normal",
                                                  fontWeight: "400",
                                                  fontSize: "16px",
                                                  color: "#CC1313",
                                                }}
                                              >
                                                {res?.Duration}
                                              </span>
                                            </p>
                                            <p
                                              class="card-text"
                                              style={{
                                                marginTop: "-10px",
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                                fontSize: "16px",
                                                color: "#8A8A8A",
                                              }}
                                            >
                                              Category Name:
                                              <span
                                                style={{
                                                  color: "red",
                                                  fontStyle: "normal",
                                                  fontWeight: "400",
                                                  fontSize: "16px",
                                                  color: "#CC1313",
                                                }}
                                              >
                                                {res?.category_name}
                                              </span>
                                            </p>
                                            <p
                                              class="card-text"
                                              style={{
                                                marginTop: "-10px",
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                                fontSize: "16px",
                                                color: "#8A8A8A",
                                              }}
                                            >
                                              Final Amount:
                                              <span
                                                style={{
                                                  color: "red",
                                                  fontStyle: "normal",
                                                  fontWeight: "400",
                                                  fontSize: "16px",
                                                  color: "#CC1313",
                                                }}
                                              >
                                                {res?.final_amount}
                                              </span>
                                            </p>
                                            <p
                                              class="card-text"
                                              style={{
                                                marginTop: "-10px",
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                                fontSize: "16px",
                                                color: "#8A8A8A",
                                              }}
                                            >
                                              Amount Discount:
                                              <span
                                                style={{
                                                  color: "red",
                                                  fontStyle: "normal",
                                                  fontWeight: "400",
                                                  fontSize: "16px",
                                                  color: "#CC1313",
                                                }}
                                              >
                                                {res?.discount}
                                              </span>
                                            </p>
                                            {/* <p class="card-text">
                                            No. of Users: not in api
                                          </p> */}
                                            <p
                                              class="card-text"
                                              style={{
                                                marginTop: "-10px",
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                                fontSize: "16px",
                                                color: "#8A8A8A",
                                              }}
                                            >
                                              Total Collection:
                                              <span
                                                style={{
                                                  color: "red",
                                                  fontStyle: "normal",
                                                  fontWeight: "400",
                                                  fontSize: "16px",
                                                  color: "#CC1313",
                                                }}
                                              >
                                                {res?.revenue_amount}
                                              </span>
                                            </p>
                                            <span
                                              class="btn btn-primary"
                                              onClick={() => {
                                                handleViewDetails();
                                                TransectionData(res?._id);
                                              }}
                                            >
                                              View Details
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      {activeTab === "paid" && showTable && (
                        <section
                          className="container"
                          style={{ height: "74vh", overflow: "auto" }}
                        >
                          <div className="container">
                          <div class="row">
                          <div class="col-6 d-flex">
                           </div>
                           
                           <div class="col-6 d-flex" >
                           <p
                                              class="card-text"
                                              style={{
                                                
                                                fontStyle: "normal",
                                                fontWeight: "600",
                                                fontSize: "16px",
                                                color: "#432525",
                                              }}
                                            >
                                              Total Amount:
                                              <span className="me-3"
                                                style={{
                                                  color: "red",
                                                  fontStyle: "normal",
                                                  fontWeight: "600",
                                                  fontSize: "16px",
                                                  color: "#CC1313",
                                                }}
                                              >
                                                 {allData?.total_amount}
                                              </span>
                                            </p>
                                            <p
                                              class="card-text"
                                              style={{
                                                
                                                fontStyle: "normal",
                                                fontWeight: "600",
                                                fontSize: "16px",
                                                color: "#432525",
                                              }}
                                            >
                                              Total Enrollment:
                                              <span
                                                style={{
                                                  color: "red",
                                                  fontStyle: "normal",
                                                  fontWeight: "600",
                                                  fontSize: "16px",
                                                  color: "#CC1313",
                                                }}
                                              >
                                                 {allData?.total_enrollments}
                                              </span>
                                            </p>
                           </div>
                            
                          </div>
                          </div>
                          <table className="table table table-striped mt-4">
                            <thead>
                              <tr>
                                <th scope="col">S.No.</th>
                                <th scope="col">User Name</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Payment Date</th>
                                <th scope="col">Expired Date</th>
                              </tr>
                            </thead>
                            <tbody
                              style={{ maxHeight: "50vh", overflowY: "auto" }}
                            >
                              {paidTransectionData?.map((res, key) => (
                                <tr>
                                  <th scope="row">{key + 1}</th>
                                  <td>{res?.participant_name}</td>
                                  <td>{res?.amount}</td>
                                  <td>{res?.payment_datetime}</td>
                                  <td>{res?.expire_time}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          <button
                            type="button"
                            class="btn btn-primary"
                            onClick={handleViewDetails2}
                          >
                            Back
                          </button>
                        </section>
                      )}
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CoursesTab;
