import React, { useContext, useEffect, useState } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../Header/Header";
import DepartmentSubheader from "./DepartmentSubheader";
import DepartmentCard from "./DepartmentCard";

import LoaderComponent from "../Utils/LoaderComponent";
import { AUTH_BASE_URL, AuthContext } from "../Utils/AuthContextProvider";

import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';



const initLoader = {
  isLoading: false,
  isError: false,
};
const Department = () => {
  const [departments, setDeparments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  
  
  const [loader, setLoader] = useState(initLoader);
  const [totalCategories, setTotalCategories] = useState(0)
  const { isLoading, isError } = loader;
  const { getDeptRefresh, setGetDeptRefresh } = useContext(AuthContext)

  

 

  const get_department = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")} `);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

      const response = await fetch(`${AUTH_BASE_URL}/departmentpanel/view/department?page=${currentPage}`, requestOptions)
      const result = await response.json();
      if (result.status == 1) {
        // toast.success(result.message)
        setDeparments(result?.data)
        setTotalPages(result.total_pages);
        setLoader({
          ...loader,
          isLoading: false,
        });
        setTotalCategories(result.total_results)
        setGetDeptRefresh(false);
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleChangePagi = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    get_department()
  }, [currentPage, getDeptRefresh]);

  return (
    <div style={{ overflow: "hidden", backgroundColor: "#f5f5f5 " }}>
      <div className="row">
        <div className="col-2">
          <SideBar />
        </div>
        <div className="col-10">
          <div className="container">
            <Header />
            <DepartmentSubheader
              no_of_departments={totalCategories}
              
              
            />
            {isLoading ? (
              <LoaderComponent />
            ) : (
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(3,1fr)",
                  gap: "2rem",
                  maxHeight: "74vh",
                  overflowY: "scroll",
                }}
              >
                {departments?.map((el) => (

                  <>
                    <DepartmentCard key={el._id} data={el} />
                    {/* {console.log("data",el)} */}
                  </>
                ))}
              </div>
              
            )}
            <Stack spacing={2} className="d-flex justify-content-center mt-3 align-items-center" >
            <Pagination count={totalPages} page={currentPage} onChange={handleChangePagi} />
          </Stack>
          </div>
          
          <div>

          </div>
        </div>


      </div>
    </div>
  );
};

export default Department;
