import SearchIcon from "../../Img/ri_search-line.svg";
// import Chat7 from "../../Img/delete.png";
// import Chat from "../../Img/pen-2-fill.png";
// import Thaks from "../../Img/DeleteSucess.json";
// import Lottie from "lottie-react";
import { useContext, useEffect, useState } from "react";
import { AuthContext, BB_URl, PREREC_BASE_URL, QUIZ_BASE_URL } from "../Utils/AuthContextProvider";
import { Button, InputGroup, Modal, Spinner, Form } from "react-bootstrap";
import BlogCard from "./BlogCard";
import { toast } from "react-toastify";
import LoaderComponent from "../Utils/LoaderComponent";
import EmptyContainer from "../Utils/EmptyContainer";
import ImgFileInput from "react-imgfile";
import Editor from "../Utils/RichTextEditor";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Dates from "../../Img/Date.svg";
import DatePicker from "react-datepicker";
import moment from "moment";

const initContent = {
  title: "",
  banner: "",
  category_id: "",
  is_published: 0,
};


const initAddStatus = {
  isPending: false,
  isSucess: false,
};

const initLoader = {
  isLoading: false,
  isError: false,
};
const Drafts = () => {
  const Navigate = useNavigate();
  const [tagsData, setTagsData] = useState([]);
  const { state } = useContext(AuthContext);
  const [show, setShow] = useState(false);
  const [blogCategoreis, setBlogCategoreis] = useState([]);
  const [content, setContent] = useState(initContent);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [addStatus, setAddStatus] = useState(initAddStatus);
  const [blogs, setBlogs] = useState([]);
  const [searchedBlogs, setSearchedBlogs] = useState([]);
  const [loader, setLoader] = useState(initLoader);
  const [toSearch, setToSearch] = useState();
  const [content1, setContent1] = useState("");
  const [exams, setExams] = useState([]);
  const [toShow, setToShow] = useState([])
  const [date, setDate] = useState('')
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState("")
  const [search, setSearch] = useState("");
  const [isBlogLoading, setIsLoading] = useState(false);
  const [Details, setDetails] = useState("");



  // For update time
  const [selectedDateTime, setSelectedDateTime] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [startDate1, setStartDate1] = useState(null);
  const formattedDate = moment(startDate1).format("DD-MM-YYYY");
  const formattedTime = moment(startTime).format("HH:mm:ss");


  //requireed destucture from state object
  const { title, category_id, status, tags_Id } = content;
  const { isPending } = addStatus;
  const { isLoading } = loader;
  const { token } = state;

  //on/off functions for modal(Add blog)
  const handleClose = () => {
    setShow(false);
    setContent("");
    setStartTime(null)
    setStartDate1(null)
    setSelectedOptions([]);
    setDetails("");
    handleSelectChange();
    

  }
  const handleShow = () => {
    setShow(true);
    viewcat();
  };


  const handleChangePagi = (event, value) => {
    setCurrentPage(value);
  };

  const handleSelectChange = (selectedOptions) => {
    setToShow(selectedOptions)
    const selectedValues = selectedOptions.map(option => option.value);
    setSelectedOptions(selectedValues)
  };

  //function for handel changes for blog content
  const handleChangeContnet = (e) => {
    const { value, name } = e.target;

    setContent({
      ...content,
      [name]: value,
    });
  };

  // function for upload file as change
  const handleChangeFile = (files) => {
    setContent({
      ...content,
      banner: files[0],
    });
  };

  //function for get all the category in for blogs
  // const getBlogCategories = () => {
  //   var myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");

  //   var raw = JSON.stringify({
  //     token: token,
  //   });

  //   var requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: raw,
  //     redirect: "follow",
  //   };

  //   fetch(
  //     "https://brainbucks.co.in/api/admin/get-blog-category",
  //     requestOptions
  //   )
  //     .then((response) => response.json())
  //     .then((res) => {
  //       console.log(res);                                             
  //       if (res.status === 1) {
  //         setBlogCategoreis(res.data);
  //       }
  //     })
  //     .catch((error) => console.log("error", error));
  // };

  const getTags = async () => {
    setLoader({
      ...loader,
      isLoading: true,
    });
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${PREREC_BASE_URL}/blogs/view/tag`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {

        if (result?.status == 1) {
          setTagsData(result?.data);
          setLoader({
            ...loader,
            isLoading: false,
          });
        } else if (result.status == "TOKEN_ERR") {
          localStorage.removeItem("api_url");
          Navigate("/");
          toast("Logged in other device! Please login again");
        } else {
          console.log(result?.Backend_Error);
        }
      })
      .catch((error) => console.log("error", error));

  }

  const [categories, setCategories] = useState([])
  const [categorySearch, setCategorySearch] = useState("")


  const viewcat = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow"
      };

      const response = await fetch(`${QUIZ_BASE_URL}/formfill/get/all/category?search=${categorySearch}`, requestOptions)
      const result = await response.json()
      if (result.status == 1) {
        setCategories(result.categories)
      } else if (result.status == "CUSTOM_ERR") {
        toast.error(result.Backend_Error)
      } else if (result.status == "VAL_ERR") {
        toast.error(result.Backend_Error)
      }
    } catch (e) {
      console.log(e);
    }
  }

  //function for add blog
  // const handleAddBlog = () => {
  //   console.log(content1);
  //   setAddStatus({
  //     ...addStatus,
  //     isPending: true,
  //   });
  //   var formdata = new FormData();
  //   Object.entries(content).forEach(([key, value]) =>
  //     formdata.append(key, value)
  //   );
  //   formdata.append("token", token);
  //   formdata.append("body", content1);
  //   var requestOptions = {
  //     method: "POST",
  //     body: formdata,
  //     redirect: "follow",
  //   };

  //   fetch("https://brainbucks.co.in/api/admin/create-blog", requestOptions)
  //     .then((response) => response.json())
  //     .then((result) => {
  //       // if(result.status == 1){
  //       toast(result.messaage);
  //       setAddStatus({
  //         ...addStatus,
  //         isPending: false,
  //       });
  //       // }else{

  //       // }
  //     })
  //     .catch((error) => {
  //       console.log("error", error);
  //       setAddStatus({
  //         isPending: false,
  //       });
  //     });
  // };

  const CreateBlog = async () => {
    try {
      setIsLoading(true); // Start loading
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

      var formdata = new FormData();
      Object.entries(content).forEach(([key, value]) => {
        if (key !== "status") { // Exclude status from FormData
          formdata.append(key, value);
        }
      });
      formdata.append("tags_Id", JSON.stringify(selectedOptions));
      formdata.append("scheduleDateTime", `${formattedDate} ${formattedTime}`);
      formdata.append("body", Details);
      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      }
      const response = await fetch(`${PREREC_BASE_URL}/blogs/create/blogs`, requestOptions)
      const result = await response.json()
      if (result.status == 1) {
        toast.success(result.message)
        console.log('message')
        setContent("");
        setStartTime(null)
        setStartDate1(null)
        setSelectedOptions([]);
        setToShow([])
        setDetails("");
        getDraftBlogs();
        handleClose();
        setAddStatus({
          ...addStatus,
          isPending: false,
        });
      } else {
        toast(result.Backend_Error)
      }
    } catch (error) {
      console.log("error", error);
      setAddStatus({
        ...addStatus,
        isPending: false,
      });

    }
    finally {
      setIsLoading(false); // End loading
    }
  }

  //function for get blogs


  const getDraftBlogs = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

      const response = await fetch(`${PREREC_BASE_URL}/blogs/view/draft/blogs?limit=12&page=${currentPage}&search=${search}`, requestOptions)
      const result = await response.json()
      if (result.status == 1) {
        console.log(result.blog);
        setBlogs(result.blog);
        setSearchedBlogs(result.blog);
        setTotalPages(result.totalPages)
        setLoader({
          ...loader,
          isLoading: false,
        });
      } else {

      }
    } catch (error) {
      console.log("error", error);
      setLoader({
        ...loader,
        isLoading: false,
      });
    }
  }



  const handleSearch = () => {
    // setSearch(e.target.value);
    setCurrentPage(1)
    getDraftBlogs();
  }


  //function for delete blog
  const handleDeleteBlog = (id) => {

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${PREREC_BASE_URL}/blogs/delete/particular/blog?blog_id=${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status == 1) {
          toast(result.message);
          getDraftBlogs();
        } else {
          toast(result.Backend_Error);
        }
      })
      .catch((error) => console.log("error", error));
  };

  //function for get searched blogs
  const handleSearchFunctionality = (tosearch) => {
    setLoader({
      ...loader,
      isLoading: true,
    });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      token: token,
      search: tosearch,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${BB_URl}/api/admin/search-published-blog`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.status === 1) {
          setSearchedBlogs(result.data);
          setLoader({
            ...loader,
            isLoading: false,
          });
        } else {
          setLoader({
            ...loader,
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        setLoader({
          ...loader,
          isLoading: false,
        });
      });
  };

  // funcion for key press for the search functionality
  const handleKeyPressSearch = (e) => {
    const { key, target } = e;
    const { value } = target;
    //  console.log(key,value);
    setToSearch(value);
    if (key === "Enter") {
      // console.log(value);
      handleSearchFunctionality(value);
    }
  };

  //function for mapping all the blogs from empty container page
  const getBackAgain = () => {
    setSearchedBlogs(blogs);
  };

  // to send date
  const handleDateChange = (date) => {
    setStartDate1(date);
    // Here you can update your API request or other logic with the new date value
  };
  // to send time
  const handleTimeChange = (e) => {
    const [hours, minutes] = e.target.value.split(":").map(Number);
    const updatedTime = new Date();
    updatedTime.setHours(hours);
    updatedTime.setMinutes(minutes);
    setStartTime(updatedTime);
    // Here you can update your API request or other logic with the new time value
  };

  useEffect(() => {
    if (
      search == ""
    ) {
      getDraftBlogs();
    }
  }, [search]);


  useEffect(() => {
    getDraftBlogs();
  }, [currentPage]);

  useEffect(() => {
    getTags();
  }, []);

  return (
    <>
      <div>
        <div className="row">
          <div className="col-6 d-flex">
            <div className="col-6">
              <h5 className="mt-1">
                Showing total{" "}
                <span style={{ color: "red" }}>{searchedBlogs?.length}</span>{" "}
                Drafts
              </h5>
            </div>
            <div className="col-4">
              <button
                onClick={() => handleShow()}
                style={{
                  backgroundColor: "#EDF7FF",
                  border: "1px solid #2188E7",
                  height: "40px",
                  borderRadius: "10px",
                  color: "#2188E7",
                }}
              >
                <span className="p-3"> +Add Drafts</span>
              </button>
            </div>
          </div>
          <div className="col-6">
            <div className="row">
              <div className="col-4"></div>
              <div className="col-6">
                <div
                  className="row"
                  style={{
                    border: "1px solid #D9D9D9",
                    height: "40px",
                    borderRadius: "7px",
                  }}
                >
                  <div className="col-2 mt-1">
                    <img alt="SearchIcon" src={SearchIcon} />
                  </div>
                  <div className="col-10 mt-1 ">
                    <input
                      // onKeyPress={handleKeyPressSearch}
                      className="border-0 w-100"
                      placeholder="Search for Drafts"
                      value={search}
                      style={{
                        textDecoration: "none",
                        outline: "none",
                        color: "#272727",
                      }}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-2">
                <button
                  onClick={handleSearch}
                  className="border-0 bg-white"
                  style={{
                    background:
                      "linear-gradient(180deg, rgba(35,176,101,1) 0%, rgba(46,135,96,1) 100%)",
                    color: "#fff",
                    height: "40px",
                    borderTopRightRadius: 10,
                    borderBottomRightRadius: 10,
                    marginLeft: "-20px",
                  }}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-4"
          style={{
            maxHeight: "50vh",
            overflowY: "scroll",
          }}

        >
          <div className="row">
            {isLoading ? (
              <LoaderComponent />
            ) : searchedBlogs.length === 0 ? (
              <EmptyContainer
                message="Data Not Fount"
                action={getBackAgain}
                actionName="Get All Blogs"
              />
            ) : (
              searchedBlogs?.map((el) => (
                <BlogCard
                  getDraftBlogs={getDraftBlogs}
                  key={el.id}
                  handleDeleteBlog={handleDeleteBlog}
                  {...el}
                />
              ))
            )}

            <div>

            </div>


            {/* <div className="col-sm-12 col-sm-4 col-lg-4">
              <div className="card">
                <div className="container">
                  <p
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "20px",
                      color: "#000000",
                    }}
                  >
                    How to Crack UPSC in one first Attempt ?
                  </p>
                  <div className="row">
                    <div className="col-6"></div>
                    <div className="col-6"></div>
                  </div>

                  <div
              style={{
                width: "100%",
                height: "200px",
                margin: "auto",
                marginBottom: "1rem",
              }}
            >
              <img
                src={`${IMAGE_URL}${banner}`}
                style={{
                  width: "100%",
                  height: "100%",
                  marginBottom: "1rem",
                }}
                alt="Sorry to say"
              />
            </div>
                   
                   
                   
                
                  <div className="row mb-3">
                    <div className="col-sm-12 col-md-4 col-lg-4">
                      <button
                        type="button"
                        class="btn btn-primary"
                        style={{
                          background:
                            "linear-gradient(180deg, #23B065 0%, #2E8760 100%)",
                          borderRadius: "8px",
                          fontSize: "12px",
                        }}
                      >
                        View Details
                      </button>
                    </div>
                    <div className="col-sm-12 col-md-5 col-lg-5">
                      <button
                        type="button"
                        class="btn btn-primary"
                        style={{
                          background: "#FFFFFF",
                          border: "1px solid #2E8760",
                          borderRadius: "8px",
                          color: "#2E8760",
                          fontSize: "12px",
                        }}
                      >
                        <span>
                          <img
                            src={Chat}
                            alt="hello"
                            style={{ width: "25%" }}
                          />
                        </span>
                        Edit Blog
                      </button>
                    </div>
                    <div className="col-sm-12 col-md-3 col-lg-3">
                      <button
                        type="button"
                        class="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        style={{ background: "#CC1313", padding: "3px 18px" }}
                      >
                        <span>
                          <img
                            src={Chat7}
                            alt="hello"
                            style={{ width: "90%", margin: "0px 0px 0px 0px" }}
                          />
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <Stack spacing={2} className="d-flex justify-content-center mt-3 align-items-center" >
          <Pagination count={totalPages} page={currentPage} onChange={handleChangePagi} />
        </Stack>
      </div>

      {/* modal for Add blogs starts here*/}
      <Modal
        show={show}
        onHide={handleClose}
        // style={{
        //   width:"50%"
        // }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Drafts</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            maxHeight: "70vh",
            overflowY: "scroll",
          }}
        >
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Title</Form.Label>
              <Form.Control
                onChange={handleChangeContnet}
                name="title"
                value={title}
                type="text"
                placeholder="Enter Title here"
                autoFocus
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Category</Form.Label>
              <Form.Select
                onChange={handleChangeContnet}
                name="category_id"
                value={category_id}
                autoFocus
              >
                <option>select Category</option>
                {categories?.map((el) => (
                  <option value={el._id}>{el.category_name}</option>
                ))}
              </Form.Select>
            </Form.Group>

            <div>
              <Form.Label>Select Tags</Form.Label>

              <div>
                <Select
                  isMulti
                  options={tagsData?.map((key, value) => {
                    return (
                      { value: key._id, label: key.name }
                    )
                  })}
                  onChange={handleSelectChange}
                  value={toShow}
                  styles={{
                    menu: (provided, state) => ({
                      ...provided,
                      maxHeight: 'fit-content', // Adjust the max height as needed
                      overflowY: "scroll",
                    }),
                  }}
                />
              </div>
            </div>
            <br />
            {/* select date and time  */}
            <div style={{ display: "flex", gap: "2rem", marginTop: "1rem" }}>
              <div className="d-flex">
                <img src={Dates} alt="" />


                <DatePicker
                  className="Main"
                  selected={startDate1}
                  onChange={handleDateChange}
                />
              </div>
              <div>

                <input
                  className="border-0 rounded mt-1"
                  type="time"
                  style={{
                    backgroundColor: "#EFEFEF",
                    color: "#8A8A8A",
                  }}
                  value={
                    startTime
                      ? startTime.toTimeString().substring(0, 5)
                      : ""
                  }
                  onChange={handleTimeChange}
                />

              </div>

            </div>
            <br />
            <Form.Label>Banner</Form.Label>

            <ImgFileInput
              buttonText="Choose image"
              onChange={handleChangeFile}
              maxFileSize={5242880}
              singleImage={true}
            />


            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Body</Form.Label>
              {/* <Form.Control
                name="body"
                onChange={handleChangeContnet}
                as="textarea"
                rows={7}
                placeholder="Enter your blog content here..."
              /> */}

              <Editor content1={Details} setContent1={setDetails} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <InputGroup>


            <div>
              <Button
                variant={status ? "success" : "primary"}
                onClick={CreateBlog}
                disabled={isBlogLoading} // Disable button when loading
              >
                {isBlogLoading ? <Spinner /> : "Save"}
              </Button>

            </div>
          </InputGroup>
        </Modal.Footer>
      </Modal>
      {/* modal for Add blogs ends here*/}
    </>
  );
};

export default Drafts;
