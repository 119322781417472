import SearchIcon from "../../Img/ri_search-line.svg";
import { BsFilter } from "react-icons/bs";
import { AiOutlinePlus } from "react-icons/ai";
import mahakal from "../../Img/Group 10.png";
import Ongoing from "./Ongoing";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { BASE_URL, IMAGE_URL } from "../Utils/AuthContextProvider";
import { FaUser } from "react-icons/fa";

const Upcoming = () => {
  const Navigate = useNavigate();
  const [upComingChat, setUpcomingChat] = useState([]);
  const [resId, setResId] = useState('');
  console.log(resId)
  const { id } = useParams()
  const getNewTickets = async () => {
    try {
      const token = localStorage.getItem("token"); 
      const response = await axios.get(
        `${BASE_URL}/adminchat/new/tickets`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setUpcomingChat(response?.data.new_tickets);
      setResId(response?.data.new_tickets[0]?._id)
    } catch (err) {
      console.log(err);
    }
  };
  const handelEnrollChat = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${BASE_URL}/adminchat/enroll/in/tickets`,
        { chat_id: resId },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      const data = response.data;
      const result = response.data;
      setResId(data?._id);
      if (result?.status == 1) {
        // toast.success(result?.msg) 
            Navigate(`/chatroompanel/${resId}`)
        
      } else {
        toast.error(result.msg || 'Failed to unroll');
      }
    
    } catch (error) {
      console.error('Error fetching chat history:', error);
    }
  };

  useEffect(() => {
    getNewTickets();
  }, []);

  return (
    <>   <ToastContainer />
      <div className="container-fluid" style={{
        flex: 1, margin: "10PX",
        padding: "20px"
      }}>
        <div className="row">
          <div className="col-12" style={{ backgroundColor: "#2c2c2c", }}>
            {/* Search Bar */}
            <h5 className="text-white text-center p-2 " style={{
              backgroundColor: " #5e5e5e",
              borderRadius: "20px",
              margin: "10px"
            }}>Upcoming</h5>
            <div className="row p-2">
              <div className="col-12">
                <div className="row" style={{ backgroundColor: "#333", borderRadius: "8px" }}>
                  <div className="col-1 mt-2">
                    <img alt="SearchIcon" src={SearchIcon} />
                  </div>
                  <div className="col-9 mt-2">
                    <input
                      className="border-0 w-100"
                      placeholder="Search for Participant"
                      style={{
                        textDecoration: "none",
                        outline: "none",
                        color: "#ccc",
                        backgroundColor: "#333",
                        border: "none",
                        padding: "5px",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                 
                </div>
              </div>
            </div>


            {/* Participants List */}
            <div className="row">
              <div className="col-12">
                <div style={{ overflowY: "scroll", height: "60vh", cursor: "pointer" }}>
                  {upComingChat?.map((res, key) => (
                    <div
                      className="mb-2 px-2 py-2"
                      style={{
                        backgroundColor: "#333",
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "8px",
                        cursor: "pointer", 
                        transition: "background-color 0.3s", 
                      }}
                      key={key}
                      onClick={handelEnrollChat}
                
                    >
                     {res.image != null ? (
  <img
    src={`${IMAGE_URL}${res.image}`}
    alt="#"
    style={{
      width: "45px",
      height: "45px",
      borderRadius: "50%",
      marginRight: "10px",
      
    }}
  />
) : (
  <FaUser
    style={{
      width: "45px",
      height: "45px",
      borderRadius: "50%",
      marginRight: "10px",
      color: "#fff",
      border: "1px solid"
    }}
  />
)}
                      <div className="chat-item">
                        <h6 className="text-white mb-0 font-weight-bold">{res.participant_name}</h6> 
                        <p className="chat-title mb-0">{res.title}</p> 
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default Upcoming;
