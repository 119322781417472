import React, { useState, useEffect, useRef, useContext } from "react";
import Image1 from "../../Img/ic_round-category.svg";
import Image2 from "../../Img/mdi_subtasks.svg";
import Offcanvas from "react-bootstrap/Offcanvas";
import total from "../../Img/total.svg";
import { MdWatchLater } from "react-icons/md";
import Lottie from "lottie-react";
import Any from "../../Img/Any.json";
import Trans1 from "../../Img/Trans.json";
import File from "../../Img/file.json";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import TranslateIm from "../../Img/icons8.svg";
import Dlf10 from "../../Img/dlf10_g9gACcXlja.json";
import "./AddQuestion.css";
import QuestionComp from "./QuestionComp";
import { NavLink, useNavigate } from "react-router-dom";
import Indu from "../../Img/Group 65.png";
import Bulk from "../../Img/Group 66.png";
import Translate from "../../Img/Translate.png";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import EmptyContainer from "../Utils/EmptyContainer";
import { AUTH_BASE_URL, BASE_URL, QUIZ_BASE_URL, IMAGE_URL, AuthContext, BB_URl } from "../Utils/AuthContextProvider";
import { IoSettingsOutline } from "react-icons/io5";
import { HiCheck } from "react-icons/hi";
import Switch from '@mui/material/Switch';
import "./QuestionCard.css"
import { Spinner } from "react-bootstrap";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';



const QuestionCard = ({
  _id,
  update_time,
  sub_name,
  category,
  subcategories,
  total_questions,
  questionCount
}) => {


  const { queRefresh, setQueRefresh } = useContext(AuthContext)
  const [show1, setShow1] = useState();
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [subjectId, setSubjectId] = useState("");
  const [Second, setSecond] = useState(_id);
  const [subjectName, setSubjectName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [Token, setToken] = useState("");
  const [TotalData, setTotalData] = useState([]);
  const [questionsArr, setQuestionsArr] = useState([]);

  const [first, setfirst] = useState(1);
  const [Hadding, setHadding] = useState("Please Wait While We");
  const [Hadding1, setHadding1] = useState("Analyse Your Questions");
  const [Pra, setPra] = useState("Do not referesh or Close this Screen");
  const [Lotties, setLotties] = useState(Any);
  const [click, setclick] = useState(false);
  const [Da, setDa] = useState(0);
  const [Question, setQuestion] = useState({});
  const checkboxRef = useRef(true);
  const [Opa, setOpa] = useState([]);
  const [file, setFile] = useState();
  const [timer, setTimer] = useState(15);
  const [XlInput, setXlInput] = useState("");
  const [aOpt, setAOpt] = useState([]);
  const [total_question, setTotalQuestions] = useState("");
  const [repeated_questions, setRepaetedQuestions] = useState("");
  const [editque, seteditque] = useState()
  const [subcatid, setcatsubid] = useState("")
  const [check, setCheck] = useState("")
  const [currentCatName, setCurrentCatName] = useState("")
  const [checkLoading, setCheckLoading] = useState(false)
  const [Que, setQue] = useState([]);
  const [lan, setLan] = useState("en");
  var OpaData = new Array(aOpt);
  const newOpt = [];
  const fileName = XlInput.name;
  const [cardId, setCardId] = useState("")
  // const imgBaseUrl = "https://dvuser.brainbucks.in/quizmicro/stream/get/public?blobname=";

  // for add single questions
  const [Select, setSelect] = useState(null);
  const [singleQuestion, setSingleQuestion] = useState('');
  const [option1, setOption1] = useState(null);
  const [option2, setOption2] = useState(null);
  const [option3, setOption3] = useState(null);
  const [option4, setOption4] = useState(null);
  const [isImageOption, setIsImageOption] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [questionImage, setQuestionImage] = useState(null);
  const [correctAnswerSelected, setCorrectAnswerSelected] = useState(false);
  const [uniqueQuestionsCount, setUniqueQuestionsCount] = useState(0);
  const [repeatQuestionsCount, setRepeatQuestionsCount] = useState(0);
  const [totalQuestionsCount, setTotalQuestionsCount] = useState(0);
  const [changeCat, setchangeCat] = useState("")
  const navigate = useNavigate();
  const [editCondition, setEditCondition] = useState(1);


  //  for selected subjects
  const [dataId, setdataId] = useState([]);
  const [dataArray1, setdataArray1] = useState([]);

  // for add single questions
  const handleAddTextOption = () => {
    setIsImageOption(false);
  };

  const handleAddImageOption = () => {
    setIsImageOption(true);
  };

  // api to add single questions

  const handleSubmit = () => {
    setCheckLoading(true)
    const formdata = new FormData();
    formdata.append("question", singleQuestion);
    formdata.append("is_opt_img", isImageOption ? "1" : "0");
    formdata.append("ans", parseInt(selectedOption));
    formdata.append("sub_id", (_id));
    // Append question image if available
    if (questionImage) {
      formdata.append("question_url", questionImage);
    }


    // Append option files only if isImageOption is true
    if (isImageOption) {
      formdata.append("option1", option1);
      formdata.append("option2", option2);
      formdata.append("option3", option3);
      formdata.append("option4", option4);
    } else {
      // Append text options
      formdata.append("option1", option1);
      formdata.append("option2", option2);
      formdata.append("option3", option3);
      formdata.append("option4", option4);
    }


    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow"
    };

    fetch(`${QUIZ_BASE_URL}/questionbank/add/single/question`, requestOptions)
      .then((response) => response.json())
      .then(result => {
        // Check if the result contains a success message
        if (result && result.status === 1) {
          toast.success(result?.msg);
          setSelectedOption(null)
        } else if (result.status == "TokenError") {
          navigate("/")
          localStorage.removeItem('token');
        }
        else {
          toast.error(result?.Backend_Error);
        }
      })
      .catch(error => {
        console.error(error);
        toast.error("An error occurred. Please try again later.");
      })
      .finally(() => {
        setCheckLoading(false);
      });
  };


  // console.log(subcategories)
  click ? (
    setTimeout(() => {
      if (Da === 0) {
        setPra("Do not referesh or Close this Screen");
        setHadding("Please wait, while we ");
        setHadding1("Translate Questions");
        setLotties(Trans1);
        setDa(1);
      } else if (Da === 1) {
        setPra("This can take a while, do not refresh or close this tab.");
        setHadding("Questions Analysed ");
        setHadding1("Successfully, Preparing Report");
        setLotties(File);
        setDa(2);
      } else {
        setfirst(4);
        setclick(false);
      }
    }, 3000)
  ) : (
    <></>
  );

  function handleChange(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
  }


  // console.log(SelectSub);

  const handleDownloadClick = () => {
    // This function will handle the download logic
    const link = document.createElement('a');
    link.href = `${BB_URl}/public/bbweb/sample_questions_format.xlsx`;
    link.download = 'sample_questions_format.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleClose = () => setShow(false);
  const [currentCard, setCurrentCard] = useState("")
  const handleShow = (id) => {
    setShow(true);
    setCurrentCard(id)

  }

  const [categories, setCategories] = useState([])
  const [categorySearch, setCategorySearch] = useState("")

  const [ValCat, setValCat] = useState("");

  //function for handle search for category while adding subjects
  const handleSearchCategory = (e) => {
    const { value } = e.target;
    setCategorySearch(value);
  }

  const viewcat = async () => {
    setIsLoading(true)
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow"
      };

      const response = await fetch(`${QUIZ_BASE_URL}/formfill/get/all/category?search=${categorySearch}`, requestOptions)
      const result = await response.json()
      if (result.status == 1) {
        setCategories(result.categories)
      } else if (result.status == "CUSTOM_ERR") {
        toast.error(result.Backend_Error)
      } else if (result.status == "VAL_ERR") {
        toast.error(result.Backend_Error)
      }
    } catch (e) {
      console.log(e);
    }
  }

  const [subcatdata, setsubcatdata] = useState([])
  const [SubCat, setSubCat] = useState("");
  const [SelectSub, setSelectSub] = useState("");

  const subCategory = async (search) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")} `);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow"
      };


      const response = await fetch(`${QUIZ_BASE_URL}/formfill/get/all/subcategory?search=${search}&cat_id=${Select}`, requestOptions)
      const result = await response.json();
      if (result.status == 1) {
        setsubcatdata(result.sub_categories)
      } else if (result.status == "VAL_ERR") {
        toast.error(result.Backend_Error)
      } else if (result.status == "CUSTOM_ERR") {
        toast.error(result.Backend_Error)
      }
    } catch (error) {
      console.log(error);
    }
  }


  const handleSubCategorySearch = (e) => {
    const { value } = e.target;
    subCategory(value)
  }




  const update_Category = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

      const raw = JSON.stringify({
        "sub_id": _id,
        "cat_id": Select,
        "sub_cat_id": dataId
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      const response = await fetch(`${QUIZ_BASE_URL}/questionbank/update/category/for/subject`, requestOptions)
      const result = await response.json()

      console.log("Dfdsfdsf", result)
      if (result?.status == 1) {

        toast.success("Updated Successfully")
        setEditCondition(1)
        setQueRefresh(!queRefresh)
        // edit(_id)
        setSelect(null)
        handleClose()
      } else {

      }
    } catch (error) {
      console.log(error);
    }
  }

  const edit = async (id) => {
    setCardId(id)
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

      const raw = JSON.stringify({
        "sub_id": id
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };


      const response = await fetch(`${QUIZ_BASE_URL}/questionbank/view/edit/page/subject`, requestOptions)
      const result = await response.json();
      if (result.status == 1) {
        setCurrentCatName(result.category_name)
        seteditque(result.newategory)

      } else if (result.status == "VAL_ERR") {
        toast.error(result.Backend_Error)
      } else if (result.status == "CUSTOM_ERR") {
        toast.error(result.Backend_Error)
      }

    } catch (error) {
      console.log(error);
    }
  }

  const delete_subcat = async (id) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

      const raw = JSON.stringify({
        "sub_id": cardId,
        "delete_sub_cat_id": id
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      const response = await fetch(`${QUIZ_BASE_URL}/questionbank/delete/subcategory/for/subject`, requestOptions)
      const result = await response.json();
      if (result.status == 1) {
        setQueRefresh(!queRefresh)
        toast.success(result.msg)
        edit(cardId)
      } else if (result.status == "VAL_ERR") {
        toast.error(result.Backend_Error)
      } else if (result.status == "CUSTOM_ERR") {
        toast.error(result.Backend_Error)
      }

    } catch (error) {
      console.log(error);
    }
  }

  const insert_subcat = async (id) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

      const raw = JSON.stringify({
        "sub_id": cardId,
        "add_sub_cat_id": id
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      const response = await fetch(`${QUIZ_BASE_URL}/questionbank/insert/subcategory/for/subject`, requestOptions)
      const result = await response.json();
      if (result.status == 1) {
        toast.success(result.msg)
        setQueRefresh(!queRefresh)
        edit(cardId)
      } else if (result.status == "VAL_ERR") {
        toast.error(result.Backend_Error)
      } else if (result.status == "CUSTOM_ERR") {
        toast.error(result.Backend_Error)
      }

    } catch (error) {
      console.log(error);
    }
  }

  const handleCheck = async (event, is) => {
    const checkboxValue = event.target.value;
    setCheck(checkboxValue);
    if (is == 1) {
      delete_subcat(checkboxValue)

    } else {
      insert_subcat(checkboxValue)

    }

  }

  // function for starttimer for the last offcanvas of add role
  const startTimer = () => {
    let interval = setInterval(() => {
      setTimer((lastTimerCount) => {
        lastTimerCount <= 1 && clearInterval(interval);
        return lastTimerCount - 1;
      });
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  };


  //function for delete particuler question
  const functionDelete = async (id) => {
    // console.log(id);
    try {
      let res = await axios.post(
        `${BB_URl}/api/admin/delete-questions`,
        {
          token: localStorage.getItem("token"),
          question_id: id,
        }
      );
      if (res.data.status === "001") {
        const newData = Que.filter((el) => {
          return el.id !== id;
        });
        setQue([...newData]);
        toast(res.data.message);
      } else {
        toast(res.data.message);
      }
    } catch (err) {
      console.log(err);
    }
  };


  const functionDelete2 = (que_id) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);


    //function for delete particuler question
    const functionDelete1 = async (id) => {
      console.log(id);
      try {
        let res = await axios.post(
          `${BB_URl}/api/admin/delete-questions`,
          {
            token: localStorage.getItem("token"),
            question_id: id,
          }
        );
        if (res.data.status === "001") {
          console.log("totaldata", TotalData);
          const newData = TotalData.filter((el) => {
            return el.id !== id;
          });
          setTotalData([...newData]);
          console.log("newdata", newData);
          console.log(res);
          toast(res.data.message);
        } else {
          toast(res.data.message);
        }
      } catch (err) {
        console.log(err);
      }
    };


    var requestOptions = {
      method: "POST",
      body: JSON.stringify({
        "que_id": que_id
      }),
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${QUIZ_BASE_URL}/questionbank/delete/question/for/subject`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result?.status == 1) {
          toast("Question deleted succesfully");
          Api()

        }
        else {
          toast("Error");
        }
      })
      .catch((error) => console.error(error));
  };

  //delete api for delete particuler question ends here

  const Api1 = () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
    var formdata = new FormData();
    formdata.append("sub_id", (_id));
    formdata.append("excel", XlInput);

    var requestOptions = {
      method: "POST",
      body: formdata,
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${QUIZ_BASE_URL}/questionbank/add/excel/questions`, requestOptions)
      .then(response => response.json())
      .then(data => {
        // Set the data
        setUniqueQuestionsCount(data.unique);
        setRepeatQuestionsCount(data.repeat);

        // Display toast notifications based on the response status
        if (data.status === 1) {
          toast.success(data.msg);
        } else if (data.status === "FILE_ERR") {
          toast.error(data.Backend_Error);
        } else if (data.status === "CUSTOM_ERR") {
          toast.error(data.Backend_Error);
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error("An error occurred while uploading the Excel file");
      });
  };



  const hori = {
    borderLeft: "1px solid #D9D9D9",
    height: "300px",
    marginLeft: "40px",
  };

  const myFunction = () => {
    if (fileName) {
      setfirst(2);
    } else {
      toast.error('Please upload a file first.');
    }
  };

  const myFunction2 = () => {
    setfirst(9);
  };

  const MySecond = () => {
    setfirst(first - 1);
  };

  if (timer === 0) {
    handleClose1();
    setTimer(15);
  }




  const Api = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);


    var requestOptions = {
      method: "POST",
      body: JSON.stringify({
        "sub_id": _id
      }),
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${QUIZ_BASE_URL}/questionbank/get/question/for/subject`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result?.status == 1) {
          setQuestionsArr(result?.questions)
          setTotalQuestionsCount(result?.total_questions)
        }
      })
      .catch((error) => console.error(error));
  };

  console.log("data45", questionsArr);
  const handleChangeNew = (e) => {

    var updatedList1 = [...dataArray1];
    var idUpdate = [...dataId]

    if (e.target.checked) {
      updatedList1 = [...dataArray1, e.target.value];
      idUpdate = [...dataId, e.target.id]
    } else {
      updatedList1.splice(dataArray1.indexOf(e.target.value), 1);
      idUpdate.splice(dataId.indexOf(e.target.id), 1);
    }
    setdataArray1(updatedList1);
    setdataId(idUpdate);
  }



  console.log(first, "heli")

  useEffect(() => {
    if (first === 2) {
      const timer = setTimeout(() => {
        setfirst(3);
      }, 2000); // 10 seconds
      return () => clearTimeout(timer);

    }
  }, [first]);

  useEffect(() => {
    if (first === 2) {
      Api1();
    }
  }, [first]);
  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, [categorySearch]);
  const handleDownloadExcel = () => {
    // Prepare data for download (dummy example)
    const data = [
      { question: 'What is React?', option1: 'library', option2: 'Framework', option3: 'library&framwork', option4: 'None of these', ans: '1' },
      { question: 'What is Node.js?', option1: 'library', option2: 'Framework', option3: 'library&framwork', option4: 'None of these', ans: '1'  },
    ];

    // Create a workbook with a worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Questions');

    // Generate Excel file and save using FileSaver.js
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, 'bulk_questions.xlsx');
  };
  return (
    <>
      <div className="col-4">
        <div className="card mt-3">
          <div className="d-flex">
            <span className="col-12">
              <p
                className="mt-3 ms-3"
                style={{ color: "#000000", fontSize: 20, fontWeight: "bold" }}
              >
                {sub_name}
              </p>
            </span>
          </div>

          <div>
            <div className="container" style={{ marginTop: "-20px" }}>
              <div className="d-flex">
                <img style={{}} alt="#" src={Image1} />
                <p
                  className="ms-2"
                  style={{
                    color: "#000000",
                    fontSize: 16,
                    fontWeight: "bold",
                    marginTop: "11PX",
                  }}
                >
                  {category}
                </p>
              </div>


              <div className="ms-5 d-flex">
                <img style={{ marginTop: "-50px" }} alt="#" src={Image2} />
                {subcategories.map((subcategory, index) => (
                  <p
                    key={index}
                    style={{
                      color: "#000000",
                      fontSize: 12,
                      fontWeight: "bold",
                      marginTop: "-10px",
                    }}
                  >
                    {index === subcategories.length - 1 ? (
                      subcategory
                    ) : (
                      `${subcategory},`
                    )}
                  </p>
                ))}
              </div>






              <div className="d-flex mt-2">
                <img
                  alt="#"
                  src={total}
                  style={{ width: "15px", marginTop: "-12px" }}
                />
                <p className="mt-1 ms-2" style={{ color: "#8A8A8A" }}>
                  Total Question
                </p>
                <p className="ms-2 mt-1" style={{ color: "#FF8E09" }}>
                  {questionCount}
                </p>
              </div>
              <div className="d-flex" style={{ marginTop: "-15px" }}>
                <MdWatchLater style={{ marginTop: "15px" }} />
                <p className="mt-2 ms-2" style={{ color: "#8A8A8A" }}>
                  Last Update
                </p>
                <p className="mt-2 ms-2" style={{ color: "#CC1313" }}>
                  {update_time.trim().split(" ")[0]}
                </p>
              </div>

              <div className="row" style={{ justifyContent: "space-around" }}>
                <button
                  className="col-5 mb-3 border-0"
                  style={{
                    color: "#fff",
                    backgroundColor: "#333333",
                    height: "30px",
                    borderRadius: "5px",
                  }}
                  onClick={() => {
                    setSubjectId(_id);
                    setSubjectName(sub_name);
                    handleShow1();
                    setfirst(1)
                  }}
                >
                  +Add
                </button>

                <button
                  className="col-5 mb-3 border-0"
                  style={{
                    color: "#000000",
                    backgroundColor: "#EFEFEF",
                    height: "30px",
                    fontWeight: 500,
                    borderRadius: "5px",
                  }}
                  onClick={() => {
                    handleShow(_id);
                    edit(_id)
                    Api();
                  }}
                >
                  Edit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* offcanvas implimentaion for particular subject to add questions */}

      <Offcanvas
        show={show1}
        style={{ borderTopLeftRadius: 30, width: "50%", padding: "1rem" }}
        placement="end"
      >
        <Offcanvas.Header></Offcanvas.Header>
        <Offcanvas.Body>
          <div>
            <div
              style={{
                display:
                  first === 2 ||
                    first === 2 ||
                    first === 3 ||
                    first === 4 ||
                    first === 6
                    ? "none"
                    : "",
              }}
            >
              <span
                style={{ color: "#000", fontWeight: "600" }}
                onClick={() => {
                  handleClose1();
                  setfirst();
                }}
              >
                <svg
                  width="35"
                  height="35"
                  viewBox="0 0 35 35"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M27.4913 9.40793C27.616 9.28337 27.7149 9.13547 27.7825 8.97267C27.85 8.80987 27.8849 8.63535 27.885 8.45909C27.8851 8.28283 27.8505 8.10827 27.7831 7.94539C27.7158 7.7825 27.617 7.63448 27.4924 7.50977C27.3679 7.38505 27.22 7.2861 27.0572 7.21854C26.8944 7.15099 26.7199 7.11617 26.5436 7.11606C26.3673 7.11595 26.1928 7.15056 26.0299 7.21791C25.867 7.28527 25.719 7.38404 25.5943 7.5086L17.4999 15.6029L9.40793 7.5086C9.15607 7.25673 8.81446 7.11523 8.45827 7.11523C8.10207 7.11523 7.76047 7.25673 7.5086 7.5086C7.25673 7.76047 7.11523 8.10207 7.11523 8.45827C7.11523 8.81446 7.25673 9.15607 7.5086 9.40793L15.6029 17.4999L7.5086 25.5919C7.38389 25.7166 7.28496 25.8647 7.21747 26.0276C7.14997 26.1906 7.11523 26.3652 7.11523 26.5416C7.11523 26.718 7.14997 26.8926 7.21747 27.0556C7.28496 27.2185 7.38389 27.3666 7.5086 27.4913C7.76047 27.7431 8.10207 27.8846 8.45827 27.8846C8.63464 27.8846 8.80928 27.8499 8.97222 27.7824C9.13517 27.7149 9.28322 27.616 9.40793 27.4913L17.4999 19.3969L25.5943 27.4913C25.8461 27.7428 26.1876 27.884 26.5436 27.8838C26.8996 27.8836 27.2409 27.742 27.4924 27.4901C27.744 27.2382 27.8852 26.8967 27.885 26.5408C27.8848 26.1848 27.7431 25.8435 27.4913 25.5919L19.3969 17.4999L27.4913 9.40793Z"
                    fill="black"
                  />
                </svg>
              </span>
              <div
                className="d-flex mb-2"
                style={{ justifyContent: "space-between" }}
              >
                <h3
                  className="mt-2"
                  style={{
                    marginLeft: "0px",
                    fontWeight: "600",
                    color: "#303030",
                    cursor: "pointer"
                  }}
                >
                  Add Questions
                </h3>

                <button
                  className="border-0"
                  style={{
                    display: first === 5 ? "block" : "none",
                    color: "#2188E7",
                    borderRadius: 10,
                    width: "160px",
                    backgroundColor: "#EDF7FF",
                  }}
                  onClick={() => {
                    setfirst(first + 2);

                    startTimer(timer);
                  }}
                >
                  Save & Publish
                </button>
              </div>
            </div>

            <div style={{ display: first === 1 ? "block" : "none" }}>
              <div className="mb-5">
                <div className="progress" style={{ height: "10px" }}>
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                      width: "25%",
                      background:
                        "linear-gradient(to right, #f44cc5, #ec40cc, #e335d3, #d72adb, #c922e4)",
                    }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <p style={{ color: "#8A8A8A" }}>
                  1/2 <span sytle={{ color: "red" }}>Steps Completed</span>
                </p>

                <div className="row">
                  <div className="col-2"></div>
                  <div className="col-4" >
                    <img
                      src={Indu}
                      alt="#"
                      style={{ width: "80%", height: "140px" }}
                    />
                    <h3 className="col-12" style={{ padding: "10px" }}>Add Individual Questions</h3>
                    {/* <p>View Sample Format</p> */}
                    <button
                      className="col-12 border-0"
                      style={{
                        backgroundColor: "#E79821",
                        color: "#fff",
                        height: "40px",
                        borderRadius: 7,
                      }}
                      onClick={() => {
                        myFunction2();
                      }}
                    >
                      +Add Questions
                    </button>
                  </div>
                  <span className="col-1" style={hori}></span>
                  <div className="col-4" style={{ marginTop: "-30px" }}>
                    <label
                      className="filelabel10"
                      style={{ overflow: "hidden", borderRadius: "50%" }}
                    >
                      <img src={Bulk} style={{ width: "100%", height: "140px" }} />
                      <span className="title">
                        300px <span sytle={{}}>×</span>150px
                      </span>

                    </label>

                    <h3 className="col-6 mb-2">Upload Bulk Questions</h3>

                    <p>
                      {fileName ? (
                        fileName
                      ) : (
                        <button className="btn btn-primary mb-2" onClick={handleDownloadExcel}>
                          Download Sample .xlsx
                        </button>
                      )}
                    </p>

                    {/* radio btns added here */}

                    <div
                      className="mt-3"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: ".5rem",
                        textAlign: "center",
                      }}
                    >
                      <div style={{ position: "relative", display: "inline-block" }}>
                        <label
                          htmlFor="FileInput"
                          className="col-12 border-0"
                          style={{
                            backgroundColor: "#576c89",
                            color: "#fff",
                            height: "40px",
                            borderRadius: 7,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                          }}
                        >
                          +Add Bulk Questions
                        </label>
                        <input
                          className="FileUpload10"
                          id="FileInput"
                          type="file"
                          onChange={(e) => {
                            setXlInput(e.target.files[0]);
                          }}
                          accept=".xlsx"
                          style={{
                            position: "absolute",
                            left: 0,
                            top: 0,
                            width: "100%",
                            height: "100%",
                            opacity: 0,
                            cursor: "pointer",
                          }}
                        />
                      </div>
                      {/* <div
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                  >
                    <div className="form-check">
                      <input
                        value="en"
                        checked={lan === "en"}
                        onChange={(e) => setLan(e.target.value)}
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault1"
                      >
                        English
                      </label>
                    </div>
                    <div className="form-check ">
                      <input
                        value="hi"
                        checked={lan === "hi"}
                        onChange={(e) => setLan(e.target.value)}
                        class="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault2"
                      >
                        Hindi
                      </label>
                    </div>
                  </div> */}
                    </div>

                    {/* come here */}
                    {/* btn for add bulk questions */}
                    <button
                      // disabled={fileName ? false : true}
                      onClick={() => {
                        myFunction();
                        console.log("yes");
                      }}
                      className="col-12 border-0 mt-4"
                      style={{
                        backgroundColor: "#2188E7",
                        color: "#fff",
                        height: "40px",
                        borderRadius: 7,
                        cursor: "pointer",
                      
                      }}
                    >
                      Upload
                    </button>
                  </div>
                  <div className="col-1"></div>
                </div>
              </div>
            </div>
          </div>

          {/* <div style={{ display: first === 2 ? "block" : "none" }}>
            <div style={{ justifyContent: "center", textAlign: "center" }}>
              <img alt="#" src={Translate} width="50%" />
              <p style={{ fontSize: "26px", fontWeight: 600 }}>
                Do You want to Translate
                <br />
                the Questions to Hindi ?
              </p>
              <div>
                <button
                  className="border-0 text-white"
                  onClick={() => {
                    myFunction();
                    setclick(true);
                    Api1(1);
                  }}
                  style={{
                    backgroundColor: "#2188E7",
                    borderRadius: 10,
                    width: "100px",
                    height: "40px",
                  }}
                >
                  Yes
                </button>
                <button
                  className="border-0 ms-5"
                  onClick={() => {
                    myFunction();
                    setclick(true);
                    Api1(0);
                  }}
                  style={{
                    backgroundColor: "#D9D9D9",
                    color: "#8A8A8A",
                    borderRadius: 10,
                    width: "100px",
                    height: "40px",
                  }}
                >
                  No
                </button>
              </div>
            </div>
          </div> */}

          <div
            className="mt-3"
            style={{ display: first === 2 ? "block" : "none" }}
          >
            <div
              style={{
                justifyContent: "center",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              <Lottie
                animationData={Lotties}
                style={{ width: "50%", marginLeft: "160px" }}
              />
              <p className="mt-3" style={{ fontSize: "26px", fontWeight: 600 }}>
                {Hadding}
                <br />
                {Hadding1}
              </p>
              <p>{Pra}</p>
            </div>
          </div>

          <div style={{ display: first === 3 ? "block" : "none" }}>
            <div
              className="mt-5"
              style={{
                justifyContent: "center",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              <p style={{ fontSize: "26px", fontWeight: 600 }}>
                Report Generated
              </p>

              <div
                style={{
                  width: "20%",
                  alignItems: "center",
                  marginLeft: "40%",
                }}
              >
                <CircularProgressbarWithChildren
                  value={total_question - repeated_questions}
                  styles={{

                    root: {},

                    path: {

                      stroke: `#367CFF`,


                      strokeWidth: "10px",

                      transition: "stroke-dashoffset 0.5s ease 0s",

                      transform: "rotate(0.50turn)",
                      transformOrigin: "center center",
                    },

                    trail: {

                      stroke: "#FF8E09",
                      strokeWidth: "10px",

                      strokeLinecap: "round",

                      transform: "rotate(0.25turn)",
                      transformOrigin: "center center",
                    },

                    background: {
                      fill: "rgb(16, 227, 163)",
                      backgroundColor: "rgb(16, 227, 163)",
                      strokeWidth: "10px",
                    },
                  }}
                ></CircularProgressbarWithChildren>
              </div>
              <p
                className="mt-5 fs-500"
                style={{ color: "#303030", fontSize: 16 }}
              >
                Total Questions Uploaded
                <span
                  style={{
                    color: "#000000",
                    fontSize: 20,
                    fontWeight: "bolder",
                  }}
                >
                  {uniqueQuestionsCount + repeatQuestionsCount}
                </span>
              </p>
              <div className="row">
                <div className="col-4"></div>
                <div className="col-6 d-flex">
                  <span
                    className="col-1 mt-1"
                    style={{
                      width: "15px",
                      height: "15px",
                      backgroundColor: "#367CFF",
                    }}
                  ></span>
                  <p className="col-11" style={{ marginLeft: "-40px" }}>
                    Unique Questions
                    <span style={{ color: "#367CFF" }}>
                      {uniqueQuestionsCount}
                    </span>
                  </p>
                </div>
                <div className="col-4"></div>
                <div className="col-6 d-flex">
                  <span
                    className="col-1 mt-1"
                    style={{
                      width: "15px",
                      height: "15px",
                      backgroundColor: "#FF8E09",
                    }}
                  ></span>
                  <p className="col-11" style={{ marginLeft: "-40px" }}>
                    Repeated Questions
                    <span style={{ color: "#FF8E09" }}>
                      {" "}
                      {repeatQuestionsCount}
                    </span>
                  </p>
                </div>
              </div>
              <div className="mt-5">
                <button
                  className="border-0"
                  style={{
                    color: "#fff",
                    backgroundColor: "#2188E7",
                    borderRadius: 7,
                    width: "250px",
                    height: "40px",
                  }}
                  onClick={() => {
                    handleClose1();
                    setfirst(1);
                  }}

                >
                  Back To Dashboard
                </button>
              </div>
              {/* <p className="mt-3">
                This tab will automatically close in
                <span style={{ color: "red" }}> 15 </span>Seconds
              </p> */}
            </div>
          </div>

          <div style={{ display: first === 5 ? "block" : "none" }}>
            <div className="progress" style={{ height: "10px" }}>
              <div
                className="progress-bar"
                role="progressbar"
                style={{
                  width: "50%",
                  background:
                    "linear-gradient(to right, #f44cc5, #ec40cc, #e335d3, #d72adb, #c922e4)",
                }}
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <p style={{ color: "#8A8A8A" }}>
              2/4 <span sytle={{ color: "red" }}>Steps Compleated</span>
            </p>
            <div style={{ justifyContent: "center", textAlign: "center" }}>
              <h3 style={{ fontWeight: "600" }}>Preview Questions</h3>
            </div>

            {Que.map((res, key) => (
              //quetion component for particular question
              <QuestionComp res={res} ind={key} deleteFun={functionDelete} />
            ))}
          </div>

          <div style={{ display: first === 6 ? "block" : "none" }}>
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <span
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                style={{ color: "#000", fontWeight: "600" }}
              >
                <svg
                  width="35"
                  height="35"
                  viewBox="0 0 35 35"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M27.4913 9.40793C27.616 9.28337 27.7149 9.13547 27.7825 8.97267C27.85 8.80987 27.8849 8.63535 27.885 8.45909C27.8851 8.28283 27.8505 8.10827 27.7831 7.94539C27.7158 7.7825 27.617 7.63448 27.4924 7.50977C27.3679 7.38505 27.22 7.2861 27.0572 7.21854C26.8944 7.15099 26.7199 7.11617 26.5436 7.11606C26.3673 7.11595 26.1928 7.15056 26.0299 7.21791C25.867 7.28527 25.719 7.38404 25.5943 7.5086L17.4999 15.6029L9.40793 7.5086C9.15607 7.25673 8.81446 7.11523 8.45827 7.11523C8.10207 7.11523 7.76047 7.25673 7.5086 7.5086C7.25673 7.76047 7.11523 8.10207 7.11523 8.45827C7.11523 8.81446 7.25673 9.15607 7.5086 9.40793L15.6029 17.4999L7.5086 25.5919C7.38389 25.7166 7.28496 25.8647 7.21747 26.0276C7.14997 26.1906 7.11523 26.3652 7.11523 26.5416C7.11523 26.718 7.14997 26.8926 7.21747 27.0556C7.28496 27.2185 7.38389 27.3666 7.5086 27.4913C7.76047 27.7431 8.10207 27.8846 8.45827 27.8846C8.63464 27.8846 8.80928 27.8499 8.97222 27.7824C9.13517 27.7149 9.28322 27.616 9.40793 27.4913L17.4999 19.3969L25.5943 27.4913C25.8461 27.7428 26.1876 27.884 26.5436 27.8838C26.8996 27.8836 27.2409 27.742 27.4924 27.4901C27.744 27.2382 27.8852 26.8967 27.885 26.5408C27.8848 26.1848 27.7431 25.8435 27.4913 25.5919L19.3969 17.4999L27.4913 9.40793Z"
                    fill="black"
                  />
                </svg>
              </span>
              <span>
                <button
                  className="border-0"
                  style={{
                    backgroundColor: "#EDF7FF",
                    color: "#2188E7",
                    width: "120px",
                    borderRadius: 7,
                    height: "30px",
                  }}
                  onClick={() => {
                    MySecond();
                    OpaData();
                  }}
                >
                  Save
                </button>
              </span>
            </div>
            <div className="row mt-3">
              <span
                className="col-1"
                style={{ fontSize: 20, fontWeight: "bolder" }}
              >
                Q1
              </span>
              <span className="col-4"></span>
              <span className="col-7">
                <span
                  className="row"
                  style={{ justifyContent: "space-between" }}
                >
                  <button
                    className="col-6 border-0"
                    style={{
                      backgroundColor: "#E3FFF3",
                      color: "#2E8760",
                      height: "30px",
                      borderRadius: 4,
                    }}
                  >
                    Translation Enabled
                  </button>
                  {/* <button className='col-4 border-0' style={{backgroundColor:"#EFEFEF",color:"#8A8A8A",height:"30px",borderRadius:4}}>Id #curaff785236</button> */}
                  <button
                    className="col-1 border-0"
                    style={{
                      backgroundColor: "#1F1F1F",
                      height: "30px",
                      borderRadius: 4,
                    }}
                  >
                    <img alt="translate" src={TranslateIm} />
                  </button>
                </span>
              </span>
            </div>

            <div className="mt-3">
              <input
                className="w-100 border-0 fw-bold mt-3"
                style={{ border: "1px solid blue" }}
                value={Question}
                onChange={(e) => {
                  setQuestion(e.target.value);
                }}
                placeholder={Question}
              />
            </div>
            <div
              className="mt-3 "
              style={{
                justifyContent: "center",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              <img src={file} width="20%" />
            </div>
            <div
              className="mt-3 "
              style={{
                justifyContent: "center",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              <input
                type="file"
                onChange={handleChange}
                accept="*/image"
                multiple
              />
            </div>
          </div>

          <div style={{ display: first === 7 ? "block" : "none" }}>
            <div className="progress" style={{ height: "10px" }}>
              <div
                className="progress-bar"
                role="progressbar"
                style={{
                  width: "75%",
                  background:
                    "linear-gradient(to right, #f44cc5, #ec40cc, #e335d3, #d72adb, #c922e4)",
                }}
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <p style={{ color: "#8A8A8A" }}>
              3/4 <span sytle={{ color: "red" }}>Steps Completed</span>
            </p>
            <div style={{ justifyContent: "center", textAlign: "center" }}>
              <p style={{ fontWeight: "300", fontSize: "20px" }}>
                <span style={{ color: "#121212", fontWeight: "600" }}>
                  {total_question}{" "}
                </span>
                Questions will be uploaded to <br />
                <span style={{ fontWeight: "600", color: "#121212" }}>
                  {" "}
                  {subjectName}
                </span>
              </p>
            </div>
            <div
              className="d-flex mt-5"
              style={{
                justifyContent: "center",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              <button
                className="border-0"
                style={{
                  backgroundColor: "#0CBC8B",
                  color: "#fff",
                  width: "120px",
                  height: "35px",
                  borderRadius: 5,
                }}
                onClick={myFunction}
              >
                Confirm
              </button>
              <button
                className="border-0 ms-5"
                style={{
                  color: "#8A8A8A",
                  width: "120px",
                  backgroundColor: "#EFEFEF",
                  height: "35px",
                  borderRadius: 5,
                }}
              >
                Cancel
              </button>
            </div>
          </div>

          <div style={{ display: first === 8 ? "block" : "none" }}>
            <div className="progress" style={{ height: "10px" }}>
              <div
                className="progress-bar"
                role="progressbar"
                style={{
                  width: "100%",
                  background:
                    "linear-gradient(to right, #f44cc5, #ec40cc, #e335d3, #d72adb, #c922e4)",
                }}
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>

            <p style={{ color: "#8A8A8A" }}>
              4/4
              <span sytle={{ color: "red" }}>Steps Completed</span>
            </p>

            <div
              className="d-flex"
              style={{
                justifyContent: "center",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              <p style={{ fontWeight: "300", fontSize: "20px" }}>
                <span style={{ color: "#121212", fontWeight: "600" }}>
                  {total_question}
                </span>
                Questions uploaded to <br />
                <span style={{ color: "#121212", fontWeight: "600" }}>
                  {" "}
                  {subjectName}
                </span>
                succesfully
              </p>
            </div>


            <div
              className="d-flex"
              style={{
                justifyContent: "center",
                textAlign: "center",
                alignItems: "center",
                marginTop: "-30px",
              }}
            >
              <Lottie animationData={Dlf10} style={{ width: "50%" }}></Lottie>
            </div>
            <span
              className="d-flex"
              style={{
                justifyContent: "center",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              <NavLink to="/Exam">
                <button
                  className="border-0 col-12 mt-4"
                  style={{
                    backgroundColor: "#0CBC8B",
                    color: "#fff",
                    height: "40px",
                    borderRadius: 7,
                  }}
                  onClick={() => handleClose1()}

                >
                  Back to Dashboard
                </button>
              </NavLink>
            </span>
            <span
              className="mt-5"
              style={{ textAlign: "center", alignItems: "center" }}
            >
              <p style={{ color: "#8A8A8A" }}>
                This tab will automatically close in
                <span style={{ color: "#CC1313", fontSize: 20 }}>
                  {" "}
                  {timer}{" "}
                </span>
                Seconds
              </p>
            </span>
          </div>

          {/* add single questions on button click starts */}

          <div style={{ display: first === 9 ? "block" : "none" }}>
            <div className="mb-5">
              <div className="container mt-5">
                <div className="card">
                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="question">Question:</label>
                      <input
                        type="text"
                        className="form-control"
                        id="question"
                        name="question"
                        placeholder="Enter your question"
                        value={singleQuestion}
                        onChange={(e) => setSingleQuestion(e.target.value)}
                      />
                      <input
                        type="file"
                        className="form-control-file my-3"
                        id="questionImage"
                        accept="image/*"
                        onChange={(e) => {
                          const file = e.target.files[0];
                          setQuestionImage(file);
                        }}
                      />
                    </div>
                    <div className="form-group py-2">

                      <label htmlFor="option1">Option A</label>
                      <input
                        type="radio"
                        className="form-check-input me-1 ms-2"
                        name="option"
                        id="option1_radio"
                        value={1}
                        checked={selectedOption === "option1"}
                        onChange={(e) => setSelectedOption(e.target.value)}
                      />

                      {
                        isImageOption ? <>
                          <input
                            type="file"
                            className="form-control"
                            id="option1_image"
                            accept="image/*"
                            name="option1"
                            placeholder='Upload image'

                            onChange={(e) => {
                              setOption1(e.target.files[0])
                            }}
                          />

                        </>
                          :
                          <>
                            <input
                              type='text'
                              className="form-control"
                              id="option1_text"
                              name="option1"
                              placeholder='Enter option A'
                              value={option1}
                              onChange={(e) => setOption1(e.target.value)}
                            />
                          </>
                      }

                    </div>
                    <div className="form-group">

                      <label htmlFor="option2">Option B</label>
                      <input
                        type="radio"
                        className="form-check-input me-1 ms-2"
                        name="option"
                        id="option2_radio"
                        value={2}
                        checked={selectedOption === "option2"}
                        onChange={(e) => setSelectedOption(e.target.value)}
                      />
                      {
                        isImageOption ? <>
                          <input
                            type="file"
                            className="form-control"
                            id="option2_image"
                            accept="image/*"
                            name="option2"
                            placeholder='Upload image'

                            onChange={(e) => {
                              setOption2(e.target.files[0])
                            }}
                          />

                        </>
                          :
                          <>
                            <input
                              type='text'
                              className="form-control"
                              id="option2_text"
                              name="option2"
                              placeholder='Enter option B'
                              value={option2}
                              onChange={(e) => setOption2(e.target.value)}
                            />
                          </>
                      }
                    </div>
                    <div className="form-group py-2">

                      <label htmlFor="option3">Option C</label>
                      <input
                        type="radio"
                        className="form-check-input me-1 ms-2"
                        name="option"
                        id="option3_radio"
                        value={3}
                        checked={selectedOption === "option3"}
                        onChange={(e) => setSelectedOption(e.target.value)}
                      />
                      {
                        isImageOption ? <>
                          <input
                            type="file"
                            className="form-control"
                            id="option3_image"
                            accept="image/*"
                            name="option3"
                            placeholder='Upload image'
                            onChange={(e) => {
                              setOption3(e.target.files[0])
                            }}
                          />

                        </>
                          :
                          <>
                            <input
                              type='text'
                              className="form-control"
                              id="option3_text"
                              name="option3"
                              placeholder='Enter option A'
                              value={option3}
                              onChange={(e) => setOption3(e.target.value)}
                            />
                          </>
                      }
                    </div>
                    <div className="form-group">

                      <label htmlFor="option4">Option D</label>
                      <input
                        type="radio"
                        className="form-check-input me-1 ms-2"
                        name="option"
                        id="option4_radio"
                        value={4}
                        checked={selectedOption === "option4"}
                        onChange={(e) => setSelectedOption(e.target.value)}
                      />
                      {
                        isImageOption ? <>
                          <input
                            type="file"
                            className="form-control"
                            id="option4_image"
                            accept="image/*"
                            name="option4"
                            placeholder='Upload image'

                            onChange={(e) => {
                              setOption4(e.target.files[0])
                            }}
                          />

                        </>
                          :
                          <>
                            <input
                              type='text'
                              className="form-control"
                              id="option4_text"
                              name="option4"
                              placeholder='Enter option A'
                              value={option4}
                              onChange={(e) => setOption4(e.target.value)}
                            />
                          </>
                      }
                    </div>
                    <div className="d-grid gap-2 d-md-block mt-3">
                      <button className="btn btn-success me-2" type="button" onClick={handleAddTextOption}>
                        Add Text Option
                      </button>
                      <button className="btn btn-success ms-2" type="button" onClick={handleAddImageOption}>Add Image Option</button>
                    </div>
                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                      <button className="btn btn-success me-md-2" type="button" onClick={handleSubmit}   >
                        {checkLoading ? <Spinner /> : "Proceed"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>



            </div>
          </div>

          {/* add single questions on button click ends */}




        </Offcanvas.Body>
        <ToastContainer />
      </Offcanvas>


      {/* offcanvas implimentaion for Edit questions */}

      <Offcanvas
        className="w-50"
        show={show}
        onHide={handleClose}
        placement="end"
        style={{ borderTopLeftRadius: 30, width: "50%" }}
      >

        <Offcanvas.Header style={{ display: "flex", flexDirection: "column", alignItems: "start" }} >
          <div>
            <span
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={() => {
                setShow(false);
              }}
              style={{ color: "#000", fontWeight: "600" }}
            >
              <svg
                width="35"
                height="35"
                viewBox="0 0 35 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M27.4913 9.40793C27.616 9.28337 27.7149 9.13547 27.7825 8.97267C27.85 8.80987 27.8849 8.63535 27.885 8.45909C27.8851 8.28283 27.8505 8.10827 27.7831 7.94539C27.7158 7.7825 27.617 7.63448 27.4924 7.50977C27.3679 7.38505 27.22 7.2861 27.0572 7.21854C26.8944 7.15099 26.7199 7.11617 26.5436 7.11606C26.3673 7.11595 26.1928 7.15056 26.0299 7.21791C25.867 7.28527 25.719 7.38404 25.5943 7.5086L17.4999 15.6029L9.40793 7.5086C9.15607 7.25673 8.81446 7.11523 8.45827 7.11523C8.10207 7.11523 7.76047 7.25673 7.5086 7.5086C7.25673 7.76047 7.11523 8.10207 7.11523 8.45827C7.11523 8.81446 7.25673 9.15607 7.5086 9.40793L15.6029 17.4999L7.5086 25.5919C7.38389 25.7166 7.28496 25.8647 7.21747 26.0276C7.14997 26.1906 7.11523 26.3652 7.11523 26.5416C7.11523 26.718 7.14997 26.8926 7.21747 27.0556C7.28496 27.2185 7.38389 27.3666 7.5086 27.4913C7.76047 27.7431 8.10207 27.8846 8.45827 27.8846C8.63464 27.8846 8.80928 27.8499 8.97222 27.7824C9.13517 27.7149 9.28322 27.616 9.40793 27.4913L17.4999 19.3969L25.5943 27.4913C25.8461 27.7428 26.1876 27.884 26.5436 27.8838C26.8996 27.8836 27.2409 27.742 27.4924 27.4901C27.744 27.2382 27.8852 26.8967 27.885 26.5408C27.8848 26.1848 27.7431 25.8435 27.4913 25.5919L19.3969 17.4999L27.4913 9.40793Z"
                  fill="black"
                />
              </svg>
            </span>
          </div>
          <h3 className="mb-0 mt-3" >{currentCatName}</h3>
          <div className="d-flex gap-3  my-3" style={{ alignItems: "center" }}>
            <div className="d-flex align-items-center gap-1 px-2 py-1" style={{ background: "#F6F6F6", borderRadius: "6px" }}>
              <img
                alt="#"
                src={total}
                style={{ width: "15px", }}
              />
              <p className="mb-0" style={{ color: "#8A8A8A" }}>
                Total Question <span style={{ color: "#FF8E09" }}>{questionCount}</span>
              </p>
            </div>


            <div className="d-flex align-items-center gap-1 px-2 py-1" style={{ background: "#F6F6F6", borderRadius: "6px" }}>
              <MdWatchLater style={{ width: "20px" }} />
              <p className="mb-0" style={{ color: "#8A8A8A" }}>
                Last Update <span style={{ color: "#cc1313" }} >
                  {update_time.trim().split(" ")[0]}
                </span>
              </p>
            </div>

            {/* <div className="d-flex align-items-center px-2 py-1">

              <button
                className=" px-2 py-1 border-0"
                style={{
                  backgroundColor: "#2E8760"
                }}
              >
                <img alt="translate" src={TranslateIm} />
              </button>
              <button
                className="border-0 px-2 py-1"
                style={{
                  backgroundColor: "#E3FFF3",
                  color: "#2E8760"

                }}
              >
                Translation Enabled
              </button>
            </div>

            <button
              className="border-0 px-2 py-1"
              style={{
                backgroundColor: "#EDF7FF",
                color: "#2188E7",
                borderRadius: 6,
              }}

            >
              Save
            </button> */}


          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>


          <div className="d-flex align-items-center gap-3">

            <div onClick={() => setEditCondition(2)}>
              <h6>Questions</h6>
            </div>


            <div onClick={() => setEditCondition(1)} className="d-flex align-items-center gap-2 ">
              <IoSettingsOutline style={{ fontWeight: "bold" }} />
              <h6 className="edit_tab">Other Settings</h6>
            </div>
          </div>
          <hr />



          {
            editCondition === 1 &&
            <>
              <div className="d-flex align-items-center">
                <h6 className="mb-0"> Translation</h6>
                <Switch defaultChecked />
              </div>
              <button
                className="border-0 px-2 py-1"
                style={{
                  backgroundColor: "#E3FFF3",
                  color: "#2E8760"

                }}
              >
                Enabled
              </button>
              <div className="d-flex gap-3 mt-3">
                <img style={{}} alt="#" src={Image1} />
                <p
                  className="ms-2"
                  style={{
                    color: "#000000",
                    fontSize: 16,
                    fontWeight: "bold",
                    marginTop: "11PX",
                  }}
                >
                  {category}
                </p>
                <button
                  className="border-0 px-3 "
                  style={{
                    backgroundColor: "#EDF7FF",
                    color: "#2188E7"

                  }}
                  onClick={() => {
                    viewcat();
                    setEditCondition(3)
                  }}
                >
                  Change Category
                </button>
              </div>


              {editque?.map((data, key) => (
                <>
                  <div key={key} className="d-flex align-items-center gap-2" >
                    <input type="checkbox" defaultChecked={data.is_alot == 1} onChange={(event) => { handleCheck(event, data.is_alot) }} value={data._id} />
                    <p className="mb-0">{data.sub_category_name}</p>
                  </div>
                </>
              ))}


            </>
          }


          {
            editCondition == 2 &&
            <>
              {total_questions && total_questions.length === 0 ? (
                <EmptyContainer
                  actionName="Close"
                  action={() => setShow(false)}
                  message={"Question bank is Empty!"}
                />
              ) : (
                total_questions?.map((res, key) => (
                  <QuestionComp res={res} ind={key} />
                ))
              )}

              {questionsArr?.map((res, key) => {
                return (
                  <div className="container_questionbank">
                    <div className="container">
                      <div className="row">
                        <div className="col-7">
                          <h6>Q{key + 1}</h6>
                        </div>
                        <div className="col-5">
                          <div className="d-flex justify-content-between align-items-center">
                            <button type="button" className="btn btn-secondary btn-sm text-center" onClick={() => functionDelete2(res._id)}>Delete</button>
                            <p className="idnocolor mb-0">{res._id}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="container mb-3">
                      <div class="row">
                        <div class="col">
                          <h6>{res?.question}</h6>
                          <div className="ques_img">
                            {/*  render image of question if available */}
                            {res.is_ques_img === 1 ? (
                              <img src={`${IMAGE_URL}${res.question_url}`} alt="question_img" />
                            ) : null}
                          </div>
                          {/* Conditionally render image or text option */}
                          {res.is_opt_img === 1 ? (
                            <>
                              <div className="options_img"><span className="ans_optiontitle">a.</span>
                                <img src={`${IMAGE_URL}${res.option1}`} alt="option1_img" />
                                {
                                  res?.ans == 1 ?
                                    <>

                                      <span className="idnocolor2"><HiCheck className="mt-1" style={{ fontWeight: "bold" }} />Marked as Correct</span>
                                    </> : <></>
                                }
                              </div>
                              <div className="options_img"><span className="ans_optiontitle">b.</span>
                                <img src={`${IMAGE_URL}${res.option2}`} alt="option2_img" />
                                {
                                  res?.ans == 2 ?
                                    <>

                                      <span className="idnocolor2"><HiCheck className="mt-1" style={{ fontWeight: "bold" }} />Marked as Correct</span>
                                    </> : <></>
                                }
                              </div>
                              <div className="options_img"><span className="ans_optiontitle">c.</span>
                                <img src={`${IMAGE_URL}${res.option3}`} alt="option3_img" />
                                {
                                  res?.ans == 3 ?
                                    <>

                                      <span className="idnocolor2"><HiCheck className="mt-1" style={{ fontWeight: "bold" }} />Marked as Correct</span>
                                    </> : <></>
                                }
                              </div>
                              <div className="options_img"><span className="ans_optiontitle">d.</span>
                                <img src={`${IMAGE_URL}${res.option4}`} alt="option4_img" />
                                {
                                  res?.ans == 4 ?
                                    <>

                                      <span className="idnocolor2"><HiCheck className="mt-1" style={{ fontWeight: "bold" }} />Marked as Correct</span>
                                    </> : <></>
                                }
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="d-flex justify-content-start align-items-start" >
                                <p className="option_spac"><span className="ans_optiontitle">a.</span>{res?.option1}</p>
                                {
                                  res?.ans == 1 ?
                                    <>

                                      <span className="idnocolor1"><HiCheck className="mt-1" style={{ fontWeight: "bold" }} />Marked as Correct</span>
                                    </> : <></>
                                }

                              </div>
                              <div className="d-flex justify-content-start align-items-start" >
                                <p className="option_spac"><span className="ans_optiontitle">b.</span>{res?.option2}</p>
                                {
                                  res?.ans == 2 ?
                                    <>
                                      <span className="idnocolor1"><HiCheck className="mt-1" style={{ fontWeight: "bold" }} />Marked as Correct</span>
                                    </> : <></>
                                }

                              </div>
                              <div className="d-flex justify-content-start align-items-start" >
                                <p className="option_spac"><span className="ans_optiontitle">c.</span>{res?.option3}</p>
                                {
                                  res?.ans == 3 ?
                                    <>
                                      <span className="idnocolor1"><HiCheck className="mt-1" style={{ fontWeight: "bold" }} />Marked as Correct</span>
                                    </> : <></>
                                }

                              </div>
                              <div className="d-flex justify-content-start align-items-start" >
                                <p className="option_spac"><span className="ans_optiontitle">d.</span>{res?.option4}</p>
                                {
                                  res?.ans == 4 ?
                                    <>
                                      <span className="idnocolor1"><HiCheck className="mt-1" style={{ fontWeight: "bold" }} />Marked as Correct</span>
                                    </> : <></>
                                }

                              </div>
                            </>
                          )}

                        </div>

                      </div>
                    </div>

                  </div>
                )
              }

              )}
            </>
          }























          <div style={{ display: editCondition === 3 ? "block" : "none" }}>
            <div className="row">
              <div className="col-1"></div>
              <div
                className="card col-9"
                style={{ justifyContent: "center", textAlign: "center" }}
              >
                <div>
                  <label
                    className="col-12 mb-2 mt-3"
                    style={{
                      textAlign: "start",
                      color: "#303030",
                      fontWeight: "600",
                    }}
                  >
                    Select Category
                  </label>
                  <br />
                  <input
                    className="col-12 border-0 mb-3"
                    style={{
                      textAlign: "start",
                      height: "50px",
                      backgroundColor: "#EFEFEF",
                      borderRadius: 7,
                      outline: 1,
                    }}
                    placeholder="  Search for Category"
                    type="categorySearch"
                    value={categorySearch}
                    onChange={(e) => setCategorySearch(e.target.value)}
                  />
                  <br />
                </div>
                <div
                  style={{
                    height: "160px",
                    overflowY: "scroll",
                    justifyContent: "start",
                    alignItems: "start",
                  }}
                >
                  <div>
                    {isLoading ? (
                      <p>Loading...</p>
                    ) : (
                      categories.length <= 0 ? (
                        <p>No results found</p>
                      ) : (
                        categories.map((res, key) => (
                          <div className="d-flex mt-3" key={key}>
                            <input
                              style={{ color: "#F6F6F6" }}
                              type="radio"
                              name="Select"
                              onClick={() => {
                                setSelect(res._id);
                                setValCat(res.category_name);
                                // setCategoryImg(res.image);
                              }}
                            />
                            <img
                              alt="#"
                              style={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "50%",
                              }}
                              src={`${IMAGE_URL}${res.image}`}
                            />
                            <span className="ms-3 fw-bolder">
                              {res.category_name}
                            </span>
                          </div>
                        ))
                      )
                    )}
                  </div>
                </div>


              </div>
              <div className="d-flex" style={{ justifyContent: "center" }}>
                <button
                  className="col-6 mt-4 mx-5 border-0 text-white"
                  onClick={() => {
                    subCategory("")
                    setEditCondition(4);
                  }}
                  style={{
                    backgroundColor: "#2188E7",
                    height: "40px",
                    borderRadius: 7,
                  }}
                  disabled={Select == null ? true : false}
                >
                  Proceed
                </button>
              </div>
            </div>
          </div>


          <div style={{ display: editCondition === 4 ? "block" : "none" }}>
            <div className="row">
              <div className="col-3"></div>
              <div
                className="card col-6"
                style={{ justifyConetnt: "center", textAlign: "center" }}
              >
                <div>
                  <label
                    className="col-12 mb-2 mt-3"
                    style={{
                      textAlign: "start",
                      color: "#303030",
                      fontWeight: "600",
                    }}
                  >
                    Select Sub Category
                  </label>
                  <br />
                  <input
                    className="col-12 border-0 mb-3"
                    style={{
                      textAlign: "start",
                      height: "50px",
                      backgroundColor: "#EFEFEF",
                      borderRadius: 7,
                      outline: 1,
                    }}
                    placeholder="   for search sub Category"
                    type="text"
                    onKeyUp={handleSubCategorySearch}
                  />
                  <br />
                </div>
                <div
                  style={{
                    height: "160px",
                    overflowY: "scroll",
                    justifyContent: "start",
                    alignItems: "start",
                  }}
                >
                  {
                    subcatdata <= 0 ?
                      <>
                        <p>No results found</p>
                      </> :
                      <>

                        {subcatdata?.map((res, key) => {
                          // return (
                          //   <>
                          //     <div className="d-flex mt-3" key={key}>
                          //       <input
                          //         style={{ color: "#F6F6F6" }}
                          //         type="checkbox"
                          //         name="Select"
                          //         onClick={() => {
                          //           setSelectSub(res._id);
                          //           setSubCat(res.sub_category_name);
                          //         }}
                          //       />
                          //       <span className="ms-3 fw-bolder">
                          //        {res.sub_category_name}
                          //       </span>
                          //     </div>
                          //   </>
                          // );

                          return (
                            <>
                              <div className="form-check" key={key}  >
                                <input className="form-check-input  mx-2"
                                  type="checkbox" id={res._id} value={res.sub_category_name} onClick={handleChangeNew}
                                  ref={checkboxRef}
                                />


                                <label className="form-check-label " for="flexCheckDefault"  >
                                  {res.sub_category_name}
                                </label>
                              </div><br></br>
                            </>
                          )
                        })}
                      </>
                  }
                </div>
              </div>
              <div className="d-flex" style={{ justifyContent: "center" }}>
                <button
                  className="col-6 mt-4 mx-5 border-0 text-white"
                  onClick={() => {

                    if (dataId.length === 0) {
                      toast.error("Select at least one Sub")
                    } else {
                      update_Category()
                    }
                  }}
                  style={{
                    backgroundColor: "#2188E7",
                    height: "40px",
                    borderRadius: 7,
                  }}
                // disabled={Select==null?true:false}
                >
                  Save
                </button>
              </div>
            </div>
          </div>



        </Offcanvas.Body>
        <ToastContainer />
      </Offcanvas>
    </>
  );
};

export default QuestionCard;
