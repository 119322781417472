import SearchIcon from "../../Img/ri_search-line.svg";
import { BsFilter } from "react-icons/bs";
import { AiOutlinePlus } from "react-icons/ai";
import mahakal from "../../Img/Group 10.png";
import "../Complaints/complaints.css";
import NotificationIcon from "../../Img/pajamas_notifications.svg";
import Images from "../../Img/blank-profile-picture-973460__340.webp";
import { useContext, useEffect, useRef, useState } from "react";
import {
  AuthContext,
  BASE_URL,
  IMAGE_URL,
  SOCKET_URL,
} from "../Utils/AuthContextProvider";
import { MdSend } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import io from "socket.io-client";
import { FaUser } from "react-icons/fa";

const ChatRoom = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const userId = localStorage.getItem("user_id");
  const { handleShowChangePass } = useContext(AuthContext);

  const [chatData, setChatData] = useState([]);

  const [message, setMessage] = useState("");
  const [isChatClosed, setIsChatClosed] = useState(false);
  const token = localStorage.getItem("token");

  const socketRef = useRef();
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const [userData, setUserData] = useState("");


  function formatDate(dateString) {
    if (!dateString || isNaN(new Date(dateString))) return '';
    const date = new Date(dateString);
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(yesterday?.getDate() - 1);

    const options = { hour: 'numeric', minute: 'numeric', hour12: true };

    const day = ('0' + date?.getDate())?.slice(-2);
    const month = ('0' + (date.getMonth() + 1))?.slice(-2);
    const year = date?.getFullYear();
    const formattedDate = month + '/' + day + '/' + year;
    if (date?.toLocaleDateString() === today?.toLocaleDateString()) {
      
      return "Today " + date?.toLocaleTimeString('en-US', options);
    } else if (date?.toLocaleDateString() === yesterday?.toLocaleDateString()) {
      console.log('Yesterday')
      return "Yesterday " + date.toLocaleTimeString('en-US', options);
    } else {
     
      return formattedDate + " " + date.toLocaleTimeString('en-US', options);

    }
  }
  useEffect(() => {
    // Initialize socket connection without the sid parameter
    socketRef.current = io(SOCKET_URL, {
      query: {
        token: token,
        user_type: "User",
      },
      transports: ["websocket"],
    });

    socketRef.current.on("connect", () => {
      console.log("Socket connected:", socketRef.current.id);
    });

    socketRef.current.on("connect_error", (error) => {
      console.error("Socket connection error:", error);
    });

    socketRef.current.on("error", (error) => {
      console.error("Socket error:", error);
    });

    socketRef.current.on("ticket_response", (newMessage) => {
      console.log("newMessage", newMessage);
      let msg = JSON.parse(newMessage);
      if (msg?.chat_id == id) {
        setChatData((prev) => [...prev, { ...msg, align: "left", send_time: msg.time  }]);
      }
    });

    socketRef.current.on("disconnect", () => {
      console.log("Socket disconnected");
    });

    return () => {
      socketRef.current.disconnect();
    };
  }, []);
  const getParticularChat = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/adminchat/chats/of/room`,
        { chat_id: id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response?.data;
      setChatData(data?.messages);
      setUserData(data)
    } catch (error) {
      console.error("Error fetching chat history:", error);
    }
  };

  const handelClosedChat = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${BASE_URL}/adminchat/mark/chat/closed`,
        { chat_id: id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const result = response.data;
      if (result?.status == 1) {
        setIsChatClosed(true);
        toast.success(result?.msg, {
          onClose: () => navigate(`/chatroompanel/${id}`),
        });
      } else {
        toast.error(result.msg || "Failed to close chat");
      }
    } catch (error) {
      console.error("Error closing chat:", error);
      const errorMessage =
        error.response?.data?.message ||
        "An error occurred while closing the chat";
      toast.error(errorMessage);
    }
  };
  const { logoutFun } = useContext(AuthContext);
  const notification = 40;

  const handleSendMessage = () => {
    if (message.trim()) {
      // Emit message through socket
      const newMessage = {
        chat_id: id,
        user_id: userId,
        message: message,
      };
      try {
        socketRef.current.emit(
          "admin_msg_to_ticket",
          JSON.stringify(newMessage)
        );
        let msg = {
          chat_id: id,
          content: message,
          sender_id: userId,
          send_time: new Date().toISOString(), // Change: Use ISO string for send_time
          _id: new Date().toISOString(), // Change: Use ISO string for _id
          align: "right",
        };
        setChatData((prev) => [...prev, msg]);
      } catch (err) {
        console.log("error in send", err);
      }

      console.log("Sent message:", newMessage); // Log the sent message
      // setSentMessages(prevMessages => [...prevMessages, message]); // Add the sent message to the array
      setMessage("");
      getParticularChat()
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSendMessage();
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatData]);

  useEffect(() => {
    getParticularChat();
  }, []);
  
  
  return (
    <>
      <ToastContainer />
      <div className="flex-grow-0">
        <div className="row mt-3 mb-2">
          <div className="col-6">
            <div className="row ">
              <div className="col-12">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <button
                    className="ms-4"
                    style={{
                      backgroundColor: "#EDF3FF",
                      border: "1px solid #5f90be",
                      color: "#2188E7",
                      padding: "8px 15px",
                      borderRadius: "8px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate("/Complaints");
                    }}
                  >
                    Back
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div
              className="d-flex"
              style={{
                justifyContent: "end",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              {/* <div
                className="d-flex"
                style={{
                  width: "80px",
                  height: "40px",
                  borderRadius: 20,
                  border: "1px solid #D9D9D9",
                  justifyContent: "center",
                  backgroundColor: "#F5F5F5",
                }}
              >
                <div className="mt-1">
                  <img alt="#" src={NotificationIcon} />
                </div>
                <div
                  className="ms-3"
                  style={{
                    width: "35px",
                    marginTop: "1px",
                    height: "35px",
                    borderRadius: "50%",
                    border: "1px solid #D9D9D9",
                    justifyContent: "center",
                    backgroundColor: "#CC1313",
                  }}
                >
                  <p
                    style={{
                      justifyContent: "center",
                      marginTop: "5px",
                      justifyItems: "center",
                      textAlign: "center",
                      color: "#fff",
                    }}
                  >
                    {notification} +{" "}
                  </p>
                </div>
              </div> */}
              <div
                className="d-flex ms-4"
                style={{ justifyContent: "space-between", marginRight: "30px" }}
              >
                <div
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    justifyContent: "center",
                    overflow: "hidden",
                  }}
                >
                  <img alt="" src={Images} width="100%" />
                </div>
                <div class="nav-item dropdown ms-2 mt-2 ">
                  <span
                    class="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  ></span>
                  <ul class="dropdown-menu" style={{ backgroundColor: "#000" }}>
                  <li class="dropdown-item text-white" style={{cursor:"pointer"}} onClick={handleShowChangePass}>Change Password</li>

                    <hr style={{ color: "#fff" }} />
                    <li class="dropdown-item text-white">
                      <span onClick={() => logoutFun()}>Log-Out</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="row py-2"
          style={{
            backgroundColor: "#ececec",
            margin: "0",
            border: "1px solid #ffc6c6",
          }}
        >
          
          <div className="col-8 d-flex align-items-center">
            <h6 className="mb-0">
            {userData.image != null ? (
  <img
    src={`${IMAGE_URL}${userData.image}`}
    alt="#"
    style={{
      width: "45px",
      height: "45px",
      borderRadius: "50%",
      marginRight: "10px",
      
    }}
  />
) : (
  <FaUser
    style={{
      width: "45px",
      height: "45px",
      borderRadius: "50%",
      marginRight: "10px",
      
      border: "1px solid"
    }}
  />
)}{" "}
              {userData?.name}
            </h6>
          </div>
          <div className="col-4 d-flex justify-content-end align-items-center">
            <button
              style={{
                color: "#2188E7",
                borderRadius: "8px",
                padding: "8px 15px",
                backgroundColor: "#EDF3FF",
                borderColor: "5f90be",
                cursor: "pointer",
              }}
              onClick={handelClosedChat}
            >
              Mark as Closed
            </button>
          </div>
        </div>
      </div>

      <div
        className="flex-grow-1 rounded px-2"
        style={{
          overflowY: "auto",
          backgroundColor: "#ececec",
          border: "1px solid #dab8b8",
        }}
      >
        {chatData?.map((message, index) => (
          <div
            className="row"
            key={message._id}
            style={{
              justifyContent:
                message.align === "left" ? "flex-start" : "flex-end",
            }}
          >
            <div
              className="col-8 mt-3"
              style={{
                display: "flex",
                justifyContent:
                  message.align === "left" ? "flex-start" : "flex-end",
              }}
            >
              <div
                style={{
                  backgroundColor: message.align == "left" ? "#fff" : "#367CFF",
                  borderRadius: "8px",
                  marginLeft: message.align == "left" ? "0" : "auto", // Align sent messages to the right
                  padding: "5px",
                  color: message.align == "left" ? "#8a8a8a" : "#fff",
                  maxWidth: "80%", // Ensure messages don't take up the whole width
                  display: "flex", justifyContent: "space-between", alignItems: "center"
                }}
              >
                <p style={{ padding: "10px",  flex: "1" }}>{message?.content}</p>
                <span style={{ fontSize: "14px", fontWeight: "bold",  marginLeft: "20px", padding: "30px  5px 0 0" }}>
                {formatDate(message?.send_time)}
                </span>
              </div>
            </div>
          </div>
        ))}

        <div ref={messagesEndRef} />
      </div>

      {!isChatClosed && (
        <div className="flex-grow-0 pb-3">
          <div className="row" style={{ position: "relative" }}>
            <div className="col-12 d-flex">
              <div className="input-group">
                <input
                  type="email"
                  className="form-control py-2"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Type your message here"
                  style={{ width: "100%", zIndex: 1 }}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyDown={handleKeyDown}
                />

                <div className="input-group-append">
                  <MdSend
                    style={{
                      backgroundColor: "#8a8a8a",
                      padding: "8px",
                      borderRadius: "8px",
                      fontSize: "34px",
                      color: "#fff",
                      fontWeight: "600",
                      cursor: "pointer",
                    }}
                    onClick={handleSendMessage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ChatRoom;
