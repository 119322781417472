import { useState, useEffect, useContext } from "react";
import LoaderComponent from "../Utils/LoaderComponent";
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination'
import { AUTH_BASE_URL, AuthContext } from "../Utils/AuthContextProvider";
import { useNavigate } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";


const initLoader = {
  isLoading: false,
  isError: false,
};

const DebitSucessTransection = ({phone}) => {
  const [Data, setData] = useState([]);
  const [loader, setLoader] = useState(initLoader);
  const [currentPage,setCurrentPage] = useState(1);
  const [totalPages,setTotalPages] = useState("")
  const { isLoading } = loader;
  const {salesSearch, setSalesSearch} =  useContext(AuthContext);
  const Navigate = useNavigate();

  const handleChangePagi = (event, value) => {
    setCurrentPage(value);
  };



  const Api = async () => {
    setLoader({
      ...loader,
      isLoading: true,
    });
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };
      const response = await fetch(`${AUTH_BASE_URL}/account/get/debit/success?searchTerm=${salesSearch}&page=${currentPage}`, requestOptions)
      const result = await response.json();
      if (result?.status == 1) {
        setTotalPages(result?.latest_transactions?.totalPages);
        setData(result?.latest_transactions?.results);
        setLoader({
          ...loader,
          isLoading: false,
        });
      }

    } catch (error) {

    }
  }



  useEffect(() => {
    Api();
  }, [currentPage]);

  useEffect(() => {
    Api();
    setCurrentPage(1);
  }, [salesSearch])

  return (
    <>
      <div>
    
      <div className="">
          <div className="row">
            <div
              className="row"
              style={{ color: "#434343", fontWeight: "600", fontSize: "14px" }}
            >
              <div className="col-1" >
                <p>S.No.</p>
              </div>
              <div className="col-1" >
                <p>Name</p>
              </div>
              <div className="col-2" >
                <p>Date&Time</p> 
              </div>
              <div className="col-3" >
                <p>Order ID</p> 
              </div>
              <div className="col-1">                                                     
                <p>Amount</p>
              </div>
              <div className="col-2" >   
                <p>Phone Number</p>
              </div>
              <div className="col-1" >  
                <p>Payment Type</p>
              </div>
              <div className="col-1" >   
                <p>Status</p> 
              </div>
            </div>

            <div style={{ overflowY: "scroll", height: "32vh" }}>
              {isLoading ? (
                <LoaderComponent />
              ) :(
                Data?.map((res, key) => {
                  return (
                    <>                                                
                      <div
                        className="row align-items-center  mt-3"
                        style={{
                          border: "1px solid #DDDDDD",
                          width: "98.5%",
                          borderRadius: "5px",
                          height: "45px",
                          backgroundColor: " #FFFFFF",
                          cursor: "pointer"
                        }}
                        onClick={() => Navigate(`/viewdetails/${res?._id}`)}
                      >
                        <div className="col-1" key={key}>
                          <p
                            style={{
                              color: "#434343",
                              fontWeight: "400",
                              fontSize: "14px",
                            }}
                          >
                            {key + 1}
                          </p>
                        </div>
                        <div className="col-1" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                          <p style={{ fontWeight: "400", fontSize: "14px" }}>
                            {res.participant_name}
                          </p>
                        </div>
                        <div className="col-2 text-nowrap">
                          <p
                            style={{
                              fontSize: "14px",
                              color: "#434343",
                              fontWeight: "400",
                            }}
                          >
                          {res.order_datetime.split(' ')[0]} <br /> {res.order_datetime.split(' ')[1]}
                          </p>  
                        </div>
                        <div className="col-3">
                          <p
                            style={{
                              fontSize: "14px",
                              color: "#434343",
                              fontWeight: "400",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                          >
                            
                            {res.order_id ? res.order_id : "Not Initiated" }
                          </p>
                        </div>
                        <div className="col-1">
                          <p
                            style={{
                              fontSize: "14px",
                              color: " #40997E",
                              fontWeight: "500",
                            }}
                          >
                            {res?.amount}
                          </p>
                        </div>
                        <div className="col-2">
                          <p
                            style={{
                              fontSize: "14px",
                              color: "#434343",
                              fontWeight: "400",
                            }}
                          >
                            {res.participant_phone}
                          </p>
                        </div>
                        <div className="col-1">
                          <p
                            style={{
                              fontSize: "14px",
                              color: "#434343",
                              fontWeight: "400",
                            }}
                          >
                            {res.type}
                          </p>
                        </div>
                        <div className="col-1">
                          <p
                            style={{
                              fontSize: "14px",
                              color: " #2E8760",
                              fontWeight: "600",
                            }}
                          >
                            {res.success == "1" ? (
                              <>
                                <span style={{ color: "green" }}>SUCCESS</span>
                              </>
                            ) : (
                              <>
                                <span style={{ color: "red" }}>FAILED</span>
                              </>
                            )}
                             <span
                          className="col-2 mt-3"
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <FaArrowRight />
                        </span>
                          </p>
                        </div>
                      </div>
                    </>
                  );
                })
              )}
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >

            </div>
          </div>
        </div>

        <div>
       <Stack spacing={2} className="d-flex justify-content-center mt-3 align-items-center" >
            <Pagination count={totalPages} page={currentPage} onChange={handleChangePagi}  />
          </Stack>
       </div>
      </div>
    </>
  );
};

export default DebitSucessTransection;
