import React, { useState } from "react";
import { MdOutlineWatchLater } from "react-icons/md";
import { AiFillEye } from "react-icons/ai";
import howw from "../../Img/Group 13160.png";
import horr from "../../Img/Group 13161.png";
import { useNavigate } from "react-router-dom";
// import cardimg from "../../Img/Group 10.png";
import { toast } from "react-toastify";
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import DatePicker from "react-datepicker";
import Dates from "../../Img/Date.svg";
import { MdFileUpload } from "react-icons/md";
import { AUTH_BASE_URL } from "../Utils/AuthContextProvider";
import moment from "moment";
const UserCard = ({ res, key }) => {
  console.log(res);
  const Navigate = useNavigate();

  const funForNavigation = (destination) => {
    Navigate("");
  };
  const [show, setShow] = useState(false);
  const [userID, setUserId] = useState("");
  const [user_resume, setuser_resume] = useState("")
  const [startDate, setStartDate] = useState(null);
  const [startTime, setStartTime] = useState("");
  const [due_date,setdue_date]=useState("");
  const [due_time,setdue_time]=useState("");
  const [status,setstatus]=useState(0);
  const [issue,setissue]=useState("")
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);



  const assign_task = async()=>{

    let formattedDateTime = moment(due_date).format("DD-MM-YYYY")
    formattedDateTime = `${formattedDateTime} ${due_time}:00`

    try {
      const myHeaders = new Headers();
myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

const formdata = new FormData();
formdata.append("user_id", userID);
formdata.append("issue", issue);
formdata.append("status", status);
formdata.append("due_date", formattedDateTime);
formdata.append("attachment", user_resume);

const requestOptions = {
  method: "POST",
  headers: myHeaders,
  body: formdata,
  redirect: "follow"
};

const response = await fetch(`${AUTH_BASE_URL}/taskpanel/assigntasks`, requestOptions)
const result = await response.json();
if(result.status==1){
  toast.success(result.message)
  
}else if(result.status=="VAL_ERR"){
  toast.error(result.Backend_Error)
}
    } catch (error) {
      console.log(error)
    }
  }


  return (
    <div className="col-lg-4 col-sm-12 mt-5">
      <div
        class="card"
        style={{
          width: "100%",
          boxShadow: "0px 4px 4px rgba(57,80,126, 0.14);",
        }}
        key={key}
      >
        <div className="row">
          <div className="col-lg-3 col-sm-4 border">
            <img
              src="https://images-ext-2.discordapp.net/external/sdLmKpayR0kOd9rEB7eIC7wYFcz7B04aT3ZKa1lqp44/https/e7.pngegg.com/pngimages/85/114/png-clipart-avatar-user-profile-male-logo-profile-icon-hand-monochrome.png?width=585&height=586"
              alt="#"
              style={{
                width: "100%",
                padding: 5,
                borderRadius: "50%",
              }}
            />
          </div>

          <div className="col-lg-8 col-sm-6">
            <h4 className="text-nowrap mt-2">{res.name} </h4>
            <div className="row">
              <div className="col-7  ">
                <p style={{ color: "#8a8a8a" }}> {res.role} </p>
              </div>
              {/* <span
                className="col-5"
                style={{
                  textAlign: "left",
                  backgroundColor: "#E3FFF3",
                  height: "30px",
                  borderRadius: "5px",
                }}
              >
                <strong
                  className="mt-2"
                  style={{
                    position: "absolute",
                    width: "10px",
                    height: "10px",
                    backgroundColor: "#2e8760",
                    borderRadius: "50%",
                  }}
                ></strong>
                <p className="ms-3"> online </p>
              </span> */}

              {/* <p style={{ fontSize: "14px", color: "#2E8760" }}>
                {`${res.created_at.split(" ")[0]} | ${
                  res.created_at.split(" ")[1]
                }`}
              </p> */}
            </div>
          </div>
        </div>

        <div className="card-body">
          {/* <p className="card-title" style={{ color: "#8a8a8a" }}>
            <MdOutlineWatchLater
              className="me-2"
              style={{
                backgroundColor: "#EFEFEF",
                color: "#8a8a8a",
                padding: "3px",
                borderRadius: "50%",
                fontSize: "28px",
              }}
            />
            {res.join_date}
    
          </p> */}

          <div className="row">
            <div className="col-lg-6 col-sm-6">
              <div>
                <p style={{ color: "#8a8a8a" }}>
                  <img src={howw} alt="#" style={{ width: "35px" }} />
                  {res.cont3}
                  <span style={{ color: "#cc1313" }} className="mx-1">
                    {res.totalTasks}
                  </span>
                </p>
              </div>
            </div>

            <div className="col-lg-6 col-sm-6">
              <div>
                <p style={{ color: "#8a8a8a" }}>
                  <img src={horr} alt="#" style={{ width: "35px" }} />
                  {res.cont6}
                  <span style={{ color: "#2E8760  " }} className="mx-1">
                    {res.completedTasks}
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-sm-6">
              <div>
                <button
                  type="button"
                  style={{
                    backgroundColor: "#21B867",
                    color: "#fff",
                    border: "none",
                    padding: "7px 10px",
                    width: "100%",
                    borderRadius: "8px",
                    fontSize: "18px",
                  }}
                  onClick={()=>Navigate(`/profile/${res._id}`)}
                >
                  <AiFillEye className="mb-1" style={{ fontSize: "22px" }} />
                  View Profile
                </button>
              </div>
            </div>

            <div className="col-lg-6 col-sm-6">
              <button
                type="button"
                style={{
                  backgroundColor: "#fff",
                  color: "#000",
                  border: "1px solid #000",
                  borderRadius: "7px",
                  padding: "7px 10px",
                  width: "100%",
                }}
                onClick={()=>{
                  handleShow()
                  setUserId(res._id)
                  
                }}
              >
                Assign Task
              </button>
            </div>
          </div>
        </div>
      </div>
      
      
  

    <Offcanvas show={show} onHide={handleShow} placement="end" className="w-50"  style={{ borderTopLeftRadius: 30 }}>
      <Offcanvas.Header closeButton onClick={handleClose} >
        <Offcanvas.Title > 
          <div className="mt-2">
          Assign Task
          </div>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
       <div>
        <h6 className="mx-2">Issue</h6>
        <input value={issue} onChange={(e) => setissue(e.target.value)}></input>
       </div>
       <div>
        <h6 className="mx-2 mt-5">Status : Not Started</h6>
     
       </div>
       <div className="row mt-5">
        <h6>Due_Date</h6>
                  <span
                    className="col-2 ms-2 d-flex rounded"
                    style={{
                      backgroundColor: "#EFEFEF",
                      overflow: "hidden",
                      height: "35px",
                    }}
                  >
                    <img className="mt-1" src={Dates} alt="#" />
                    <DatePicker
                      className="Main"
                      selected={due_date}
                       value={due_date}
                      onChange={(date) => setdue_date(date)}
                    />
                  </span>
                  <span
                    className="col-2 ms-4 rounded mt-1"
                    style={{
                      backgroundColor: "#EFEFEF",
                      border: "1px solid #252525",
                      overflow: "hidden",
                      height: "35px",
                    }}
                  >
                    <input
                      className="border-0 rounded mt-1"
                      type="time"
                      id="timeCodeInput"
                      value={due_time}
                      onChange={(e)=>{
                        setdue_time(e.target.value)
                      }}
                      placeholder="HH:MM:SS"

                    />
                  </span>

               
                </div>
             
                <div
                  style={{
                    // width: "60%",
                    margin: "auto",
                    height: "40px",
                  }}
                  className="border mt-5"
                >
                   
                  <label className="filelabel10">
                    {user_resume ? (
                      <img src={user_resume} alt="" style={{ marginRight: '5px' }} />
                    ) : (
                      <MdFileUpload color="#808080" />
                    )}
                    <span className="title" style={{ color: "#808080" }}>
                      {user_resume.name ? user_resume.name : " Add Attachment"}
                    </span>
                    <input
                      name="resume"
                      onChange={(e) => { setuser_resume(e.target.files[0]) }}
                      type="file"
                      className="FileUpload10"
                      id="FileInput"
                      accept=".pdf"
                    />
                  </label>
                </div>

              <div className="d-flex justify-content-center mt-3">
              <button
                    className="border-0 mt-5"
                    style={{
                      width: "200px",
                      height: "40px",
                      backgroundColor: "#2188E7",
                      color: "#fff",
                      borderRadius: 10,
                    }}
                    onClick={()=>{assign_task()}}
                  
                  >
                    Assign Task
                  </button>
              </div>
      </Offcanvas.Body>
    </Offcanvas>
    </div>
    
  );
};

export default UserCard;
