import Header from "../Header/Header";
import Content from "./Content";
import SubHeader from "./SubHeader";

const Main = () => {
  return (
    <div>
      <Header />
      <div>
        <SubHeader/>
        {/* <Content /> */}
      </div>
    </div>
  );
};

export default Main;
