import { useEffect, useState } from "react";
import {
  AUTH_BASE_URL,
  AuthContext,
  IMAGE_URL,
  PREREC_BASE_URL,

} from "../Utils/AuthContextProvider";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import LoaderComponent from "../Utils/LoaderComponent";
import Thaks from "../../Img/DeleteSucess.json";
import EmptyContainer from "../Utils/EmptyContainer";
import { toast } from "react-toastify";

import { useNavigate } from "react-router-dom";
import { RiDeleteBin6Line } from "react-icons/ri";
import Lottie from "lottie-react";

const Banner = () => {
  const Navigate = useNavigate();
  const [tagsData, setTagsData] = useState([]);
  const [bannerImage, setBannerImage] = useState(null);
  const [previewImage, setPreviewImage] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  const [hasShownAnimation, setHasShownAnimation] = useState(false);
  const [show1, setShow1] = useState(false);
  const handleShow1 = () => setShow1(true);
  const handleClose1 = () => setShow1(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  // Api to get banner image
  const getBanners = async () => {
    setIsLoading(false);
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${AUTH_BASE_URL}/banner/get/banner`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setIsLoading(false);

        if (result?.status == 1) {
          setTagsData(result?.bannerss);
          console.log(result)
        } else if (result.status == "TOKEN_ERR") {
          localStorage.removeItem("api_url");
          Navigate("/");
          toast("Logged in other device! Please login again");
        } else {
          console.log(result?.Backend_Error);
        }
      })
      .catch((error) => console.log("error", error));
  };

  // api to delete banner

  const Deletebanner = () => {
    setIsLoading(true); // Set loading to true when the request is initiated

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

    const raw = JSON.stringify({
      "banner_id": selectedId
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${AUTH_BASE_URL}/banner/delete/banner`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setIsLoading(false); // Set loading to false after receiving response
        if (result?.status === 1) {
          getBanners();
          setSelectedId("");
          setIsSuccessModalVisible(true);
          setHasShownAnimation(false);
        } else {
          toast(result?.Backend_Error);
        }
      })
      .catch((error) => {
        setIsLoading(false); // Set loading to false in case of error
        console.log("error", error);
      });
  };

  // api to Add Banner

  const AddBanner = () => {
    setIsLoading(true); // Start loading

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

    const formdata = new FormData();
    formdata.append("banner", previewImage);

    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(`${AUTH_BASE_URL}/banner/add/banner`, requestOptions )
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === 1) {
          toast.success(result?.msg);
          handleClose();
          getBanners();
          setBannerImage(null);
          setPreviewImage("");
        } else {
          toast.error(result?.Backend_Error);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setIsLoading(false); // End loading
      });
  };

  const handleDeleteClick = (id) => {
    setSelectedId(id);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const img = new Image();
        img.src = reader.result;
        img.onload = () => {
          const { width, height } = img;
          if (width === 804 && height === 177) {
            setBannerImage(reader.result);
            setPreviewImage(file);
          } else {
            toast.error('Please upload an image of size 804x177');
            setBannerImage(null);
          setPreviewImage("");
          e.target.value = null;
          }
        };
      };
      reader.readAsDataURL(file);
    }
  };


  useEffect(() => {
    getBanners();
  }, []);
  return (
    <>
      <div>
        {/* modal for Add category */}
        <Modal
          show={show}
          onHide={() => {
            handleClose();
            setBannerImage(null);
            setPreviewImage("");
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Banner</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <label
                className="filelabel1 w-100"
                style={{
                  height: "115px",
                  marginLeft: "0px",
                  backgroundColor: "#F5F5F5",
                }}
              >
                {bannerImage ? (
                  <span className="title1">
                    <img
                      className="d-flex"
                      src={bannerImage}
                      alt="banner"
                      style={{ width: "100%", height: "100px" }}
                    />
                  </span>
                ) : (
                  <span className="title1" style={{ fontSize: 20, fontWeight: 600 }}>
                    Please upload an image of size 804x177
                  </span>
                )}
                <input
                  className="FileUpload1"
                  id="FileInput1"
                  name="booking_attachment1"
                  type="file"
                  accept=".png,.jpg"
                  onChange={handleImageChange}
                />
              </label>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={isLoading} // Disable button when loading
              variant="primary"
              onClick={AddBanner}
            >
              {isLoading ? <Spinner/> : "Add"}
            </Button>
          </Modal.Footer>
        </Modal>




        <div className="row">
          <div className="col-6 d-flex">
            <div className="col-7">
              <h5 className="mt-1">
                Showing total{" "}
                <span style={{ color: "red" }}> {tagsData?.length} </span>
                Banners
              </h5>
            </div>
            <div className="col-5">
              <button
                onClick={handleShow}
                style={{
                  backgroundColor: "#EDF7FF",
                  border: "1px solid #2188E7",
                  height: "40px",
                  borderRadius: "10px",
                  color: "#2188E7",
                }}
              >
                <span className="p-3"> +Add Banner</span>
              </button>
            </div>
          </div>
          <div className="col-6">
            <div className="row">
              <div className="col-4"></div>
            </div>
          </div>
        </div>

        <div
          className="container"
          style={{
            padding: "10px 73px 10px 73px",
            overflowY: "scroll",
            height: "65vh",
          }}
        >
          <div className="row">
            {isLoading ? (
              <LoaderComponent />
            ) : tagsData?.length === 0 ? (
              <EmptyContainer message="Data Not Found" />
            ) : (
              
              tagsData?.map((res, key) => {
                return (
                  <>
                       {isLoading && <p>Please wait...</p>}
                    <div
                      className="col-4"
                      key={key}
                      style={{ textAlign: "center" }}
                    >
                      <div
                        className="card mt-3 d-flex "
                        style={{ width: "90%" }}
                      >
                        <div className="card-body row">
                          <div
                            style={{
                              width: "100%",
                              height: "200px",
                              margin: "auto",
                              marginBottom: "1rem",
                            }}
                          >
                            <img
                              src={`${IMAGE_URL}${res?.banner}`}
                              style={{
                                width: "100%",
                                height: "100%",
                                marginBottom: "1rem",
                              }}
                              alt="Banner Pic"
                            />
                          </div>

                          <div className="col-2 d-flex">
                            <button
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal"
                              type="button"
                              class="btn btn-primary d-flex align-items-center"
                              // data-bs-toggle="modal"
                              // data-bs-target="#exampleModal"
                              style={{ background: "#CC1313", padding: "3px 18px" }}
                              onClick={() => handleDeleteClick(res?._id)}
                            >
                              <span className="d-flex align-items-center">
                                Delete <RiDeleteBin6Line style={{ marginLeft: "5px" }} />

                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })
            )}
          </div>
        </div>
      </div>
 
      {/* modal for delete  */}
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ backdropFilter: "blur(10px)" }}
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
              <p
                className="text-center"
                style={{
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "24px",
                  color: "#000000",
                }}
              >
                Are you sure?
              </p>
              <p
                className="text-center"
                style={{
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "18px",
                  color: "#8A8A8A",
                }}
              >
                Do you really want to delete this Blog?
              </p>
            </div>
            <div className="container mb-3">
              <div className="row">
                <div className="col-6 text-center">
                  <button
                    type="button"
                    class="btn btn-secondary text-center"
                    data-bs-dismiss="modal"
                    style={{
                      background: "#dadada",
                      border: "none",
                      padding: "7px 40px",
                    }}
                    onClick={() => {
                      setSelectedId("");
                    }}
                  >
                    Cancel
                  </button>
                </div>
                <div className="col-6 text-center">
                  <button
                    onClick={() => {
                      Deletebanner();
                    }}
                    type="button"
                    class="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal1"
                    data-bs-dismiss="modal"
                    style={{
                      background: "#CC1313",
                      borderRadius: "7px",
                      border: "none",
                      padding: "7px 40px",
                    }}
                  >
                    Yes Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
 
      {isSuccessModalVisible && !hasShownAnimation && (
        <div
          className="modal fade show"
          style={{ display: "block" }}
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <div
                  className="d-flex"
                  style={{ justifyContent: "center", alignItems: "center" }}
                >
                  <Lottie animationData={Thaks} style={{ width: "40%" }} />
                </div>
                <p
                  className="text-center"
                  style={{
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "24px",
                    color: "#000000",
                  }}
                >
                  {/* Are you sure? */}
                </p>
                <p
                  className="text-center"
                  style={{
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "18px",
                    color: "#8A8A8A",
                  }}
                >
                  “ Banner ” is successfully deleted
                </p>
              </div>
              <div className="container text-center mb-3">
                <button
                  type="button"
                  className="btn btn-primary text-center"
                  data-bs-dismiss="modal"
                  style={{ border: "none", padding: "7px 40px" }}
                  onClick={() => {
                    setIsSuccessModalVisible(false);
                    setHasShownAnimation(true);
                  }}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      )}


      {/* delete modal ends here */}

    </>
  );
};

export default Banner;
