import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DepartmentSubheader from "./DepartmentSubheader";
import Header from "../Header/Header";
import SideBar from "../SideBar/SideBar";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import RolesCard from "./RolesCard";
import LoaderComponent from "../Utils/LoaderComponent";
import "./userdata.css";
import { AUTH_BASE_URL, BB_URl } from "../Utils/AuthContextProvider";
//function for get departments
const getDepartments = async (id) => {
  let res = await axios.post(
    `${BB_URl}/api/admin/get-departments-roles`,
    {
      token: localStorage.getItem("token"),
      id: id,
    }
  );

  return res.data;
};

const initLoader = {
  isloading: false,
  isError: false,
};

const ParticularDeparment = () => {
  const { id } = useParams();
  const [department, setDepartment] = useState("");
  const [departmentName, setDepartmentName] = useState("");
  const [roles, setRoles] = useState();
  const [access, setaccess] = useState([])
  const [loader, setLoader] = useState(initLoader);
  const [granted, setgranted] = useState([])
  const [available, setavailable] = useState([])
  const navigate = useNavigate();
  const [panel_name, setpanel_name] = useState("")
  const { isLoading, isError } = loader;
  const Navigate = useNavigate();

  // function for getting the data of particular department
  // const filterRequireDepartment = () => {
  //   setLoader({
  //     ...loader,
  //     isLoading: true,
  //   });

  //   getDepartments(id)
  //     .then((res) => {
  //       if (res.status === 1) {
  //         setRoles(res.data);
  //         setDepartmentName(res.department_name);
  //         setLoader({
  //           ...loader,
  //           isLoading: false,
  //         });
  //       } else {
  //         setLoader({
  //           ...loader,
  //           isLoading: false,
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setLoader({
  //         ...loader,
  //         isLoading: false,
  //       });
  //     });
  // };

  useEffect(() => {
    // filterRequireDepartment();
  }, []);

  //function for creating new role
  const createRole = async (role) => {
    try {
      let res = await axios.post(
        `${BB_URl}/api/admin/create-role`,
        role
      );
      // filterRequireDepartment();
      //    console.log(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const view_role = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

      const raw = JSON.stringify({
        "department_id": id
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      const response = await fetch(`${AUTH_BASE_URL}/departmentpanel/read/access/department`, requestOptions)
      const result = await response.json();
      if (result.status == 1) {
        setgranted(result?.access_controls)
        setavailable(result?.available_controls)
      }
    } catch (error) {
      console.log(error)
    }
  }


  const add_access = async (res) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

      const raw = JSON.stringify({
        "departmentId": id,
        "panel_name": res
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      const response = await fetch(`${AUTH_BASE_URL}/departmentpanel/add/access/department`, requestOptions)
      const result = await response.json();
      if (result.status == 1) {
        view_role()
      } else if (result.status == "VAL_ERR") {
        toast.error(result.Backend_Error)
      }
    } catch (error) {
      console.log(error)
    }
  }


  const delete_access = async (res) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

      const raw = JSON.stringify({
        "departmentId": id,
        "panel_name": res
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      const response = await fetch(`${AUTH_BASE_URL}/departmentpanel/delete/access/department`, requestOptions)
      const result = await response.json();
      if (result.status == 1) {
        view_role()
      } else if (result.status == "VAL_ERR") {
        toast.error(result.Backend_Error)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    view_role()
  }, [])

  return (
    <div style={{ overflow: "hidden", backgroundColor: "#f5f5f5 " }}>
      <div className="row">
        <div className="col-2">
          <SideBar />
        </div>
        <div className="col-10">
          <div className="container">  <Header />





            <div className="mt-5">

              <div className="d-flex justify-content-between" style={{marginLeft: "25px", marginRight: "30px"}}>
              <h5>Showing Roles</h5>
              <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                Navigate(-1)
              }}
              style={{
                fontSize: 18,
                backgroundColor: "#EDF7FF",
                padding: "8px 17px",
                border: "1px solid #2188E7",
                borderRadius: 10,
                color: "#2188E7",
              }}
            >
              Back
            </button>
            </div>

              <div style={{ borderRadius: '10px', padding: "10px" }}>
                <div className='row px-3 mt-4'>
                  <div className='col-lg-6'>
                    <div class="shadow-lg mb-3 p-3 text-center border">
                      <h5 className="color-white"> Granted Access </h5>
                      <hr style={{ color: "black" }} />
                      <div className="row">
                        {
                          granted?.map((res) => {

                            return (
                              <>
                                <div className="col-6">
                                  <p className="pt-1 " >{res}</p>
                                </div>
                                <div className="col-6">
                                  <div className="pt-2">
                                    <button
                                      type="button"
                                      className="btn btn-danger"
                                      style={{
                                        color: "black",
                                        backgroundColor: "#FFF1F1",
                                        borderRadius: "5px",
                                      }}
                                      onClick={() => delete_access(res)}
                                      value={panel_name}

                                    >

                                      Delete
                                    </button>
                                  </div>


                                </div>
                              </>
                            )
                          })
                        }
                      </div>
                    </div>

                  </div>

                  <div className='col-lg-6'>
                    <div class="shadow-lg mb-3 p-3 text-center border ">
                      <h5 className="color-white"> Available Access </h5>
                      <hr style={{ color: "black" }} />
                      <div className="row">
                        {
                          available?.map((res) => {
                            return (
                              <>
                                <div className="col-6">
                                  <p className="pt-1 " >{res}</p>

                                </div>
                                <div className="col-6">
                                  <div className="pt-2">
                                    <button
                                      type="button"
                                      className="btn btn-dark"
                                      style={{
                                        color: "white",
                                        backgroundColor: "black",
                                        borderRadius: "5px",
                                      }}
                                      onClick={() => add_access(res)}
                                      value={panel_name}
                                    >
                                      Add
                                    </button>
                                  </div>


                                </div>

                              </>
                            )
                          })
                        }

                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  );
};

export default ParticularDeparment;
