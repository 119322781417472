import React, { useState , useEffect, useContext} from "react";
import TaskCard from "./TaskCard";
import TaskTableHead from "./TaskTableHead";
import { AUTH_BASE_URL, AuthContext } from "../Utils/AuthContextProvider";
import { toast } from "react-toastify";
const InProgress = () => {
  const [inprogress, setinprogress] = useState([]);
  const {taskCount, setTaskCount} = useContext(AuthContext)
  const handleSelectChange = (value,id,date) => {
    update(value,id,date);
  }
  const progress = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

   const response = await   fetch(` ${AUTH_BASE_URL}/taskpanel/inprogress/tasks?searchQuery=&page=1&limit=4`, requestOptions)
      const result = await response.json();
      if(result.status==1){
        setinprogress(result.tasks)
        setTaskCount({
          ...taskCount , inProgress : result?.TotalNumberOfTask
        })
      }
      else{
        toast.error("failed")
      }
    } catch (error) {
      console.log(error)
    }
  }
  const update = async (value,id,date) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow"
      };

   const response = await fetch(` ${AUTH_BASE_URL}/taskpanel/change/status?id=${id}&status=${value}&due_date=${date}`, requestOptions)
    const result = await response.json();
    if(result.status==1){
      progress()
      toast.success("updated")
    }else if(result.status=="INT_ERR"){
      toast.error(result.Backend_Error)
    }
    } catch (error) {
      console.log(error)
    }
  }

  

  useEffect(() => {
   progress()
  }, [])
  
  return (
    <div>
      <TaskTableHead />
      <TaskCard type={inprogress} handleSelectChange={handleSelectChange} need={1} />

    </div>
  );
};

export default InProgress;
