import React, { useContext, useState } from 'react';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';
import { AuthContext, BASE_URL } from '../Utils/AuthContextProvider';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';


const AddReelModal = ({ show, handleClose, Id }) => {
  const [reel, setReel] = useState('');
  const [videoReel, setVideoReel] = useState(null);
  const [previewVideoReel, setPreviewVideoReel] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  // const { isUploaded, setIsUploaded } = useContext(AuthContext);

  const handelUpload = async () => {
    setLoading(true);
    setError('');
    try {
      const token = localStorage.getItem("token");
      const formData = new FormData();
      formData.append('Reel_id', Id);
      formData.append('video', previewVideoReel);

      const response = await fetch(`${BASE_URL}/admin/reels/add/video/to/reel`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to upload reel');
      }

      const data = await response.json();

      if (data?.status === 1) {
        handleClose();
        toast.success(data.msg);
        setReel('');
        setVideoReel(null);
        setPreviewVideoReel(null);
        setError('');
        setLoading(false);
      } else if (data.status === "TokenError") {
        localStorage.removeItem("token");
      } else {
        toast.error(data?.Backend_Error);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setVideoReel(URL.createObjectURL(file));
    setPreviewVideoReel(file);
  };
  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static"
        keyboard={false}
        style={{ backdropFilter: "blur(10px)" }}>
        <Modal.Header closeButton>
          <Modal.Title>Add Video</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? ( // Conditionally render loader if loading is true
            <div className="text-center">
              <Spinner animation="border" variant="primary" />
              <p>Uploading...</p>
            </div>
          ) : (
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
              <Form.Label style={{ fontWeight: "500" }}>Brain Booster Video</Form.Label>
              <label
                className="w-100"
                style={{
                  height: '150px',
                  marginLeft: '0px',
                  backgroundColor: '#F5F5F5',
                  color: '#8A8A8A',
                  textAlign: 'center'
                }}
              >
                {videoReel ? (
                  <span className="" style={{ color: '#8A8A8A' }}>
                    <video className="d-flex" src={videoReel} controls style={{ width: '100%', height: '150px' }} />
                  </span>
                ) : (
                  <span className="title1" style={{ fontSize: 20, fontWeight: 600 }}>Click here to upload video</span>
                )}
                <input
                  className="FileUpload1"
                  id="FileInput2"
                  name="video_attachment"
                  type="file"
                  accept="video/*"
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                />
              </label>
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Close</Button>
          <Button variant="primary" onClick={handelUpload}>Upload</Button>
        </Modal.Footer>
      </Modal>
    </>
    
  );
};

export default AddReelModal;