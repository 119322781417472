import React, { useEffect, useState } from 'react';
import SideBar from '../SideBar/SideBar';
import { AUTH_BASE_URL } from '../Utils/AuthContextProvider';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { IoArrowBack } from "react-icons/io5";
import { BsCoin } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";
import { MdDateRange } from "react-icons/md";
import { MdOutlineDoneOutline } from "react-icons/md";
import { CgSandClock } from "react-icons/cg";
import Images from "../../Img/blank-profile-picture-973460__340.webp";


const ViewDetails = () => {
    const Navigate = useNavigate();
    const [allDetails, setAllDetails] = useState("");
    const {id }= useParams();




    const viewParticularDetail = async () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
          "Authorization",
          `Bearer ${localStorage.getItem("token")}`
        );
         
        const raw = JSON.stringify({
          "transaction_id": id
        });
    
        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow"
        };
        
    
        fetch(
          `${AUTH_BASE_URL}/account/view/payment/detail`,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
             if (result?.status === 1) {
              setAllDetails(result?.sendata)
            } else if (result.status === "TOKEN_ERR") {
                localStorage.removeItem("api_url");
              Navigate("/");
              toast("Logged in other device! Please login again");
            } else {
              console.log(result?.Backend_Error);
            }
          })
          .catch((error) => console.error(error));
      };
    
      useEffect(() => {
        viewParticularDetail();
      }, [])

  return (
    <>
     <div style={{ overflow: "hidden", backgroundColor: "#fafdff" }}>
      <div className="row">
        <div className="col-2">
          <SideBar />
        </div>
        <div className="col-10">
          <div className="container">
           {/* view details start */}
           <div>
      <div className='red'>
        <div className='text-center rounded red  py-3 text-white '  style={{ 
          background: allDetails?.success == 0 ? "red" : allDetails?.success == 1 ? "green" : "orange"
           }}>


          <div className='d-flex justify-content-between'>
            <button className='text-start fs-2 btn' onClick={() => { Navigate(-1) }}><IoArrowBack color='white'/></button>
            <h2 className='text-center mx-auto' style={{color:"white"}}>Transaction Details</h2>
          </div>

        
         
             <p className='fs-2'>
            <span><BsCoin className='bg-warning rounded mb-2 ms-2 me-2' /></span>
            {allDetails?.amount} <span className="rupee-symbol">₹</span>
          </p>
     
        {allDetails?.success == 0 ? (
              <p style={{fontSize: "20px" , fontWeight: "600"}}>
              <span ><RxCross2 className='mb-1'  style={{fontSize: "25px"}}/></span>
               Failed</p>
        ) : allDetails?.success == 1 ? (
          <p style={{fontSize: "20px" , fontWeight: "600"}}>
              <span style={{fontSize: "25px"}}><MdOutlineDoneOutline className='mb-1' /></span>
               Success</p>
        ): (
          <p style={{fontSize: "20px" , fontWeight: "600"}}>
          <span style={{fontSize: "25px"}}><CgSandClock  className='mb-1' /></span>
           Pending</p>
        )}
         
        </div>
        <div className="transaction-wrapper ">

          <div className=' text-start text-secondary '>
            <p className='code0'>Transaction ID</p>
            <p className='code'>{allDetails?.payment_id ? allDetails?.payment_id : "Transaction not Initiated"}</p>
            <p className='code1'> Method:</p>
            <p>
              {/* <img src={img} className='img-fluid' alt='icon' /> */}
               {allDetails?.type}</p>
            <p >
              <img src={<MdDateRange />} className='img-fluid' alt='' />{<MdDateRange style={{fontSize: "20px", marginRight:"5px"}} />}
              {allDetails?.order_datetime}</p>
          </div>
          {/* <p className='mt-3'>Reason for Failure </p>
          <div className='bg-body-secondary p-2 mt-3 fs-3 text-secondary code'>Declined by Client</div> */}

          <section>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div
                    className="row pt-3 example"
                    style={{
                      //  height: "30vh",
                        overflowY: "auto" }}
                  >
                    <section>
                      <div className="container">
                        <div className="row">
                          <div className="col-12">
                            <div className="row">
                              <div className="col-12 pb-3">
                                {allDetails?.type == "credit" && (
                                  <div
                                    className="border p-2"
                                    style={{ borderRadius: "5px" }}
                                  >
                                    <div className="d-flex justify-content-start align-items-center">
                                      <img
                                        src={Images}
                                        alt="bank logo"
                                        style={{
                                          width: "40px",
                                          height: "40px",
                                          backgroundColor: "blue",
                                          borderRadius: "50%",
                                        }}
                                      />
                                      <h6
                                        className="pt-1"
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "25px",
                                          marginLeft: "10px",
                                        }}
                                      >
                                        {allDetails?.bankdetails?.bank_name}
                                      </h6>
                                    </div>
                                    <div
                                      style={{
                                        textAlign: "left",
                                        marginTop: "10px",
                                      }}
                                    >
                                      <h6
                                        style={{
                                          fontSize: "17px",
                                          fontWeight: "500",
                                        }}
                                      >
                                        <span style={{ color: "#7E7E7E" }}>
                                          Acc. Holder:{" "}
                                        </span>
                                        {
                                          allDetails?.bankdetails
                                            ?.acc_holder_name
                                        }
                                      </h6>
                                      <h6
                                        style={{
                                          fontSize: "17px",
                                          fontWeight: "500",
                                          marginTop: "10px",
                                        }}
                                      >
                                        <span style={{ color: "#7E7E7E" }}>
                                          Account Number:{" "}
                                        </span>
                                        {allDetails?.bankdetails?.bank_acc_no}
                                      </h6>
                                      <h6
                                        style={{
                                          fontSize: "17px",
                                          fontWeight: "500",
                                          marginTop: "10px",
                                        }}
                                      >
                                        <span style={{ color: "#7E7E7E" }}>
                                          Verification Status:{" "}
                                        </span>
                                        Failed
                                      </h6>
                                    </div>
                                  </div>
                                )}

                               
                                
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </section>


        </div>



      </div>
    </div>

                 {/* view details end */}

          </div>
        </div>
      </div>
    </div>
    </>
    
  )
}

export default ViewDetails;