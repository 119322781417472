import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../Img/logo.png";
import { AUTH_BASE_URL, AuthContext } from "../Utils/AuthContextProvider";
import { Spinner } from "react-bootstrap";
import { getToken } from "firebase/messaging";
import { messaging } from "../Firebase";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const initUser = {
  email: "",
  password: ""
};
function Login() {
  const { state, loginFun } = useContext(AuthContext);
  const navigate = useNavigate();
  const [user, setUser] = useState(initUser);
  const { email, password } = user;
  // const { isLoading, isError } = state;
  const [isLoading, setIsLoading] = useState(false); // Local loading state
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const CheckToken = async (Token) => {
    var formdata = new FormData();
    formdata.append("token", Token);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(`${AUTH_BASE_URL}/api/admin/check-token`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.status == 1) {
          navigate(`/${result.default_panel}`);
        } else {
          navigate("/");
        }
      })
      .catch((error) => console.log("error", error));
  };

  async function requestPermission() {

    const permission = await Notification.requestPermission();


    const token = await getToken(messaging, { vapidKey: 'BFMRfGtpFh6PFQgR0jErExOhjXSzv_vjAsu3NkeWROhzXPLh_h8WCL1siE3Ub9lp_nGet09MKORj3lyNRt6smv4' })
    localStorage.setItem("firebase_token", token);
    // if(permission==='granted'){
    // } else if(permission==='denied'){
    //  alert("You denied for the notification");
    // }
  }
  // const handleFocus = () => {
  //   setIsFocused(true);
  // };

  // const handleBlur = () => {
  //   setIsFocused(false);
  // };

  useEffect(() => {
    requestPermission()
    if (!localStorage.getItem("token")) {
      navigate("/");
    } else {
      CheckToken(localStorage.getItem("token"));
    }
  }, []);

  const handleLogin = async () => {
    setIsLoading(true); // Start loading spinner

    try {
      await loginFun(user);
      // Navigation logic after successful login is handled in loginFun itself
    } catch (error) {
      // Error handling if needed
      console.error("Login error:", error);
    } finally {
      setIsLoading(false); // Stop loading spinner after request completes
    }
  };



  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-3"></div>
          <div className="col-md-6">
            <div
              className="card"
              style={{ marginTop: "8rem", background: "rgb(249 249 249)" }}
            >
              <div className="Auth-form-container p-5">
                <div className="Auth-form-content">
                  <div className="text-center mb-3">
                    <img src={Logo} alt="#" width={"50%"} />
                  </div>

                  <div className="text-center" style={{ fontSize: "20px" }}>
                    Login
                  </div>
                  <div className="form-group mt-4">
                    <label>Email address</label>
                    <input
                      type="email"
                      className="form-control mt-1"
                      placeholder="Enter Your Email"
                      name="email"
                      onChange={handleChange}
                      value={email}
                    />
                  </div>
                  <div className="form-group mt-4">
                    <label>Password</label>
                    <div style={{ position: 'relative' }}>
                      <input
                        type={showPassword ? 'text' : 'password'}
                        name="password"
                        className="form-control mt-1"
                        placeholder="Enter Password"
                        onChange={handleChange}
                        value={password}
                        // onFocus={handleFocus}
                        // onBlur={handleBlur}
                        style={{ flex: 1 }}
                      />
                        <span
                          onClick={togglePasswordVisibility}
                          style={{
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: '0.5rem',
                            position: 'absolute',
                            right: '10px',
                            bottom: '13px'
                          }}
                        >
                          {showPassword ? <FaEye /> : <FaEyeSlash />}
                        </span>
                     
                    </div>
                  </div>


                  <div className="d-grid gap-2 mt-4">
                    <button
                      disabled={isLoading}
                      type="submit"
                      className="btn btn-primary"
                      onClick={handleLogin}
                    >
                      {isLoading ? <Spinner /> : "Login"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
