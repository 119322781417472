import React from "react";
import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const DepartmentCard = ({res, data}) => {
  const { totalUsers, _id, name } = data
  const navigate = useNavigate();

  const navigatetoParticulrarDepartment = (destination) => {
    navigate(`/department/${destination}`);
  };
  const navigatetoViewUsers = (destination) => {
    navigate(`/department/view/user/${destination}`);
  };
// const view_role = async () => {
//     try {
//       const myHeaders = new Headers();
//       myHeaders.append("Content-Type", "application/json");
//       myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

//       const raw = JSON.stringify({
//         "department_id": "66068006dc37744ae2d88dd6"
//       });

//       const requestOptions = {
//         method: "POST",
//         headers: myHeaders,
//         body: raw,
//         redirect: "follow"
//       };

//       fetch("https://auth.brainbucks.in/departmentpanel/read/access/department", requestOptions)
//         .then((response) => response.text())
//         .then((result) => console.log(result))
//         .catch((error) => console.error(error));
//     } catch (error) {
//       console.log(error)
//     }
//   }
  return (
    <div
      style={{
        backgroundColor: "white",
        padding: "1rem",
        borderRadius: "5px",
        height: "150px",
      }}
    >
      
      <h3
        style={{
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: "500",
          fontSize: "24px",
          lineHeight: "29px",
        }}
      >
        {name}
      </h3>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h5
          style={{
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "19px",
          }}
        >
          <span
            style={{
              color: "#2E8760",
            }}
          >
            {totalUsers}{" "}
          </span>
          users
        </h5>
        <h5
          style={{
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "19px",
          }}
        >
          <span
            style={{
              color: "#d33d3d",
            }}
          >
            {/* {total_roles}{" "} */}
          </span>
          roles
        </h5>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-dark"
          style={{
            borderRadius: "5px",
          }}
          onClick={() => navigatetoParticulrarDepartment(_id)}
        >
          View Roles
        </button>

        <button
          type="button"
          className="btn btn-light"
          style={{
            color: "black",
            // backgroundColor: "#FFF1F1",
            borderRadius: "5px",
          }}
          onClick={() => navigatetoViewUsers(_id)}
        >
          View Users
        </button>
      </div>
    </div>
  );
};

export default DepartmentCard;
