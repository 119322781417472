import { useState, useEffect, useContext } from "react";
import Image1 from "../../Img/ic_round-category.svg";
import Image2 from "../../Img/mdi_subtasks.svg";
import Image3 from "../../Img/bb coin 1.svg";
import Image4 from "../../Img/carbon_trophy-filled.svg";
import Image5 from "../../Img/mdi_clock-outline.svg";
import DatePicker from "react-datepicker";
import Dates from "../../Img/Date.svg";
import SearchIcon from "../../Img/ri_search-line.svg";
import ProgressBar from "react-bootstrap/ProgressBar";
import Wallet from "../../Img/Wallet.svg";
import LoaderComponent from "../Utils/LoaderComponent";
import QuizHistory from "./QuizHistory";
import { AuthContext, IMAGE_URL, QUIZ_BASE_URL } from "../Utils/AuthContextProvider";
import { toast } from "react-toastify";
import EmptyContainer from "../Utils/EmptyContainer";
import Slider from "./Slider";
import Offcanvas from "react-bootstrap/Offcanvas";
import { NavLink, useNavigate } from "react-router-dom";
import moment from "moment/moment";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { HiArrowNarrowLeft } from "react-icons/hi";
import UpcomingQuiz from "./UpcomingQuiz";




const initLoader = {
  isLoading: false,
  isError: false,
};

const ActiveQuizeEdit = (props) => {
  const getCurrentDate1 = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  const { fromDate, setFromDate, toDate, setToDate } = useContext(AuthContext)
  const [fromDate1, setFromDate1] = useState(getCurrentDate1());
  const [toDate1, setToDate1] = useState(getCurrentDate1());
  const [Data, setData] = useState([]);
  const Navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const {
    currentPageHistory,
    setCurrentPageHistory,
    
  } = useContext(AuthContext);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchFeild, setSearchField] = useState("");


  const [Overview, setOverview] = useState("");
  const [Token, setToken] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [Datas, setDatas] = useState([]);
  const [Data1, setData1] = useState([]);
  const [Edit3, setEdit3] = useState([]);
  const [Edit4, setEdit4] = useState("");
  const [Edit5, setEdit5] = useState("");
  const [Edit6, setEdit6] = useState("");
  const [Edit7, setEdit7] = useState("");
  const [bannerImage, setbannerImage] = useState("");
  const [BannerShow, setBannerShow] = useState("");
  const [TotalData, setTotalData] = useState([]);
  const [search, setSearch] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [Fire1, setFire1] = useState("");
  const [Fire2, setFire2] = useState("");
  const [Fire3, setFire3] = useState("");
  const [Fire4, setFire4] = useState("");
  const [Fire5, setFire5] = useState({});
  const [Fire6, setFire6] = useState("");
  const [title, setTitle] = useState("")
  const [schduleTime, setSchduleTime] = useState("");

  const [filled, setFilled] = useState();
  const [ChangesNo, setChangesNo] = useState("");
  const [HistryData, setHistryData] = useState([]);
  const [last_page, setLastPage] = useState();
  const [loader, setLoader] = useState(initLoader);
  const { isLoading } = loader;
  const currentDate = new Date();
  const [Click, setClick] = useState(0);
  const [filterCategory1, setFilterCategory1] = useState([]);
  const formattedDate = `${currentDate.getDate().toString().padStart(2, '0')}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getFullYear()}`;

  // for change in edit categories
  const [selectedCategoryName1, setSelectedCategoryName1] = useState("");

  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [selectedCategoryName, setSelectedCategoryName] = useState("");
  const [compositionSub, setCompositionSub] = useState([]);
  const [selectedSubId, setSelectedSubId] = useState("");
  const [searchSubCat, setSearchSubCat] = useState("");
  const [subjectCount, setSubjectCount] = useState([]);
  const [selectedSubjectIds, setSelectedSubjectIds] = useState([]);
  const [defaultCatId, setDefaultCatId] = useState("");
  const [QuizId, setQuizId] = useState("");
  const [totalQuizz, setTotalQuizz] = useState("");
  const [selectedQuestionComp, setSelectedQuestionComp] = useState({});
  const [filterSubCatrgory, setFilterSubCatrgory] = useState([]);
  const [subjectData, setsubjectData] = useState("");
  const [SubCatName, setSubCatName] = useState("");
  const [isSave, setIsSave] = useState(true);
  const [IsLoading, setIsLoading] = useState(false);
  const [cat_search, set_cat_search] = useState("")
  const [sub_cat_search, set_sub_cat_search] = useState("")

  const [rules, setRules] = useState([""]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [viewQuizId, setViewQuizId] = useState("");
  const [hasMore, setHasMore] = useState(true)
  const [upcomingCat_Name, setUpcomingCat_Name] = useState("");

  const { quizzRefresh, setQuizzRefresh, idNotify } = useContext(AuthContext)
  const initRes_Add = {
    isPending: false,
    isRejected: false,
  };
  const [res_add, setRes_Add] = useState(initRes_Add);
  const { isPending } = res_add;
  const [catIdNotify, setCatIdNotify] = useState("");
  const [buzerLoader, setBuzerLoader] = useState(false);
  const [buzerTitle, setBuzerTitle] = useState("");
  const [buzerBody, setBuzerBody] = useState("");
  const [showNotify, setShowNotify] = useState(false);
  const handleShowNotify = () => setShowNotify(true);
  
  const handleCloseNotify = () => {
    setShowNotify(false);
    setBuzerTitle("");
    setBuzerBody("");
  };

  //function for getting the history of particular quiz
  const Histry = async (id) => {
    setIsLoading(true);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")} `);

    const raw = JSON.stringify({
      "quiz_id": Fire4,
      "fromDate": fromDate1,
      "toDate": toDate1,
      "page": currentPageHistory,
      "limit": 10
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    try {
      const response = await fetch(`${QUIZ_BASE_URL}/active/quiz/view/history/of/activequiz`, requestOptions);
      const result = await response.json();
      if (result.status === 1) {
        setHistryData(result.data2);
        setHasMore(result.data2.length === 10);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };


  // const handleNextPage = () => {
  //   if (hasMore) {
  //     setCurrentPage((prevPage) => prevPage + 1);
  //   }
  // };

  // const handlePreviousPage = () => {
  //   if (currentPage > 1) {
  //     setCurrentPage((prevPage) => prevPage - 1);
  //   }
  // };


  //function for
  function handleChangeName(key, newValue) {
    const updatedFire5 = { ...Fire5 };
    updatedFire5[key] = newValue;
    setFire5(updatedFire5);
  }



  const handleChangeCheck = (e, res) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedSubjects((prev) => [...prev, value]);
      setCompositionSub((prev) => [...prev, { ...res, value: 0 }])
    } else {
      setSelectedSubjects((prev) => prev.filter((item) => item !== value));
      setCompositionSub((prev) => prev.filter((item) => item._id !== value));
    }
  };



  const handleChange11 = (event, value) => {
    setCurrentPage(value);
  };



  function handleChangeName1(id, value) {
    const newtag = compositionSub?.map((tag) => {
      if (tag._id == id) {
        return { ...tag, value };
      } else {
        return tag;
      }
    });

    setCompositionSub(newtag);
  }

  const [ArrForm, setArrForm] = useState();
  const OnClickfun = () => {
    const outputObject = {};
    compositionSub.forEach((item) => {
      outputObject[item._id] = Number(item.value);
    });

    setArrForm(outputObject);
    setIsSave(false)

    console.log("after click", ArrForm)
  };




  //function for validate  the sum of all the subject is 100 or not
  const funForLastvalidation = () => {
    if (compositionSub.length === 0) {
      toast("Please select at least one subject.");
    }
    else {
      let sum = compositionSub.reduce((total, Value) => total + Value.value, 0);
      if (sum !== 100) {
        toast("Sum of Question should be 100%");
      }
      else if (compositionSub.some(subject => subject.value === 0)) {
        toast("All subjects should have a value greater than 0%");
      }
      else {

        // Store the IDs of selected subjects
        const selectedIds = compositionSub?.map(subject => subject._id);
        // setSelectedSubjectIds(selectedIds);
        setSelectedSubjectIds(selectedIds)
        OnClickfun();
        // handleClose();
        // setClick(0);      
      }
    }
  };

  // Finction to get rules 
  const handleRuleChange = (index, value) => {
    const newEdit3 = [...Edit3];
    newEdit3[index] = value;
    setEdit3(newEdit3);
  };

  const handleAddRule = () => {
    setEdit3([...Edit3, ""]);
  };

  const handleRemoveRule = (index) => {
    const newEdit3 = [...Edit3];
    newEdit3.splice(index, 1);
    setEdit3(newEdit3);
  };



  //function for hit the api for getting the quizs
  // const Api = async () => {
  //   setLoader({
  //     ...loader,
  //     isLoading: true,
  //   });
  //   try {
  //     const Responce = await fetch(
  //       `${QUIZ_BASE_URL}/active/quiz/get/quiz?page=1&searchQuery=&fromDate=01-03-2024&toDate=04-04-2024`
  //     );
  //     const Data = await Responce.json();
  //     if (Data.status === 1) {
  //       //  console.log(Data);
  //       setDatas(Data.data2);
  //       setFilterData(Data.data2);
  //       setLoader({
  //         ...loader,
  //         isLoading: false,
  //       });
  //     } else {
  //       // alert(Data.message);
  //       setLoader({
  //         ...loader,
  //         isLoading: false,
  //       });
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setLoader({
  //       ...loader,
  //       isLoading: false,
  //     });
  //   }
  // };

  const getData = async () => {
    setLoader({
      ...loader,
      isLoading: true,
    });


    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${QUIZ_BASE_URL}/active/quiz/get/quiz?searchQuery=${searchFeild}&page=${currentPage}&limit=${limit}&fromDate=${moment(fromDate).format("DD-MM-YYYY")}&toDate=${moment(toDate).format("DD-MM-YYYY")}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setLoader({
          ...loader,
          isLoading: false,
        })

        if (result?.status == 1) {
          setData(result?.data2);
          setTotalQuizz(result?.totalData)
          setTotalPages(result?.totalPages);
        } else if (result.status == "TOKEN_ERR") {
          localStorage.removeItem("api_url");
          Navigate("/");
          toast("Logged in other device! Please login again");
        } else {
          console.log(result.Backend_Error);
        }
      })
      .catch((error) => console.log("error", error));
  };



  //function for getting the data of darticular quiz
  const Api1 = async (ID) => {
    try {
      const Responce = await fetch(
        `${QUIZ_BASE_URL}/active/quiz/view/details?id=${ID}`
      );
      const Data = await Responce.json();
      if (Data.status === 1) {
        setData1(Data?.data);
        setsubjectData(Data?.subjects);
        setEdit3(Data?.data?.rules);
        setEdit4(Data?.data?.slots);
        setChangesNo(Data?.data?.repeat);
        // setEdit7(Data?.data[0].question_time);
        setEdit5(Data?.data?.entryFees);
        setEdit6(Data?.data?.total_num_of_quest);
        setEdit7(Data?.data?.time_per_question);
        setBannerShow(Data.data?.banner);
        // setFire1(Data?.data[0].quizze_image);qa
        setTitle(Data?.data?.quiz_name)
        setFire2(Data?.data?.category_name);
        setFire3(Data?.data?.subcategory_name);
        setFire4(Data?.data?._id);
        // console.log('Fire4', Data?.data?._id)
        setFire6(Data1?.image);
        setSchduleTime(Data?.data?.sch_time);
        setSelectedCategoryId(Data?.data?.category_id);
        setSelectedSubId(Data?.data?.sub_cat_id);
        setFire5(Data?.data?.question_composition);
        setCatIdNotify(Data?.data?.category_id);
      }
      else {
        toast(Data.message);
      }
    } catch (error) {
      console.log(error);
    }

  };



  const APi3 = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("token")}`
      );

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `${QUIZ_BASE_URL}/formfill/get/all/category?search=${cat_search}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status == 1) {
            setFilterCategory1(result.categories);

          }
        })
        .catch((error) => console.log("error", error));
    } catch (e) { }
  };


  // Finction to get sub category name and id
  const Api4 = async () => {

    console.log('Cat Id : ', selectedCategoryId);
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    const raw = "";

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${QUIZ_BASE_URL}/formfill/get/all/subcategory?search=${sub_cat_search}&cat_id=${selectedCategoryId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status == 1) {
          setFilterSubCatrgory(result?.sub_categories);
          // setSelectedCategoryName(result.data.category_name);
        }
      })
      .catch((error) => console.error(error));
  };

  // Api to get subject
  const Api5 = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

    const raw = JSON.stringify({
      "sub_ids": selectedSubId
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${QUIZ_BASE_URL}/formfill/get/all/subjects/from/subcategories?search=${searchSubCat}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setSubjectCount(result?.data);
      })
      .catch((error) => console.error(error));
  };

  // select Radio button of category 
  const handleRadioClick = (categoryId, category_name) => {
    setSelectedCategoryId(categoryId);
    setSelectedCategoryName1(category_name);
  };

  // Function to get sub_category id
  const handleSubCatId = (subId, subName) => {
    setSelectedSubId(subId);
  };

  //all the validation for form and functionality of edit quiz cat,sub_cat, subject come under this function
  const moveOnToLast = () => {

    if (Click === 0) {
      filterCategory1?.length === 0
        ? toast("Please select the category")
        : setClick(Click + 1);
    } else if (Click === 1) {
      selectedSubId?.length === 0
        ? toast("Please select the sub-category")
        : setClick(Click + 1);
    }
    else if (Click === 2) {
      compositionSub?.length === 0
        ? toast("Please select the subjects")
        : setClick(Click + 1);
    }

  };

  // Api to change the category in edit quizz
  const Api6 = () => {

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")} `);

    const raw = JSON.stringify({
      "quiz_id": Fire4,
      "category_id": selectedCategoryId,
      "subjects_id": selectedSubjectIds,
      "sub_cat_id": selectedSubId,
      "question_composition": ArrForm,
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${QUIZ_BASE_URL}/active/quiz/changes/category`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // Check the status code and show appropriate toast message
        if (result.status === 1) {
          toast.success(result.message);
          setArrForm()
          setClick(0)
          setIsSave(true)
          setSelectedCategoryId("")
          setSelectedSubjects([])
          setSelectedSubjectIds([])
          setSubjectCount([])
          handleClose()


        } else {
          toast.error(result.Backend_Error);
        }
      })
      .catch((error) => console.error(error));
  }




  // Api to change repeat in edit quizz
  const repeatApi = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")} `);

    const raw = JSON.stringify({
      "id": Fire4,
      "repeat": ChangesNo,
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${QUIZ_BASE_URL}/active/quiz/changes/repeat`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // Check the status code and show appropriate toast message
        if (result.status === 1) {
          toast.success(result.message);
        } else {
          toast.error(result.Backend_Error);
        }
      })
      .catch((error) => console.error(error));
  }

  // Api to change total no of questions
  const EditToalQuesApi = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")} `);

    setRes_Add({
      ...res_add,
      isPending: true,
    });

    const raw = JSON.stringify({
      "quiz_id": Fire4,
      "numberofquestion": Edit5,
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${QUIZ_BASE_URL}/active/quiz/changes/numberof/quiz`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // Check the status code and show appropriate toast message
        if (result.status === 1) {
          setRes_Add({
            ...res_add,
            isPending: false,
          });
          toast.success(result.message);
          Api1(QuizId)
        } else {
          toast.error(result.Backend_Error);
          setRes_Add({
            ...res_add,
            isPending: false,
          });
        }
      })

      .catch((error) => {
        setRes_Add({
          ...res_add,
          isPending: false,
        });
        console.log("error", error);
      });
  };

  // Api to change time per question in edit quizz
  const EditTimePerQuesApi = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")} `);

    const raw = JSON.stringify({
      "id": Fire4,
      "time_per_question": Edit7,
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${QUIZ_BASE_URL}/active/quiz/changes/time/perquestion`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // Check the status code and show appropriate toast message
        if (result.status === 1) {
          toast.success(result.message);
          Api1(QuizId)
        } else {
          toast.error(result.Backend_Error);
        }
      })
      .catch((error) => console.error(error));
  }

  // Api to change banner image in edit quizz
  const EditBannerImgApi = () => {
    const formdata = new FormData();
    formdata.append("id", Fire4);
    formdata.append("banner", bannerImage);



    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow"
    };

    fetch(`${QUIZ_BASE_URL}/active/quiz/change/banner`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // Check the status code and show appropriate toast message
        if (result.status === 1) {
          toast.success(result.message);
          Api1(QuizId)
        } else {
          toast.error(result.Backend_Error);
        }
      })
      .catch((error) => console.error(error));
  }

  // Api to change rule in edit quizz
  const EditRulesApi = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")} `);

    const raw = JSON.stringify({
      "id": Fire4,
      "rules": Edit3,
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${QUIZ_BASE_URL}/active/quiz/change/rules`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // Check the status code and show appropriate toast message
        if (result.status === 1) {
          toast.success(result.message);
          Api1(QuizId)
        } else {
          toast.error(result.Backend_Error);
        }
      })
      .catch((error) => console.error(error));
  }

  // Api to change Total Slots in edit quizz
  const EditTotalSlotsApi = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")} `);

    const raw = JSON.stringify({
      "id": Fire4,
      "slots": Edit4,
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${QUIZ_BASE_URL}/active/quiz/update/total/slots`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // Check the status code and show appropriate toast message
        if (result.status === 1) {
          toast.success(result.message);
          Api1(QuizId)
        } else {
          toast.error(result.Backend_Error);
        }
      })
      .catch((error) => console.error(error));
  }

  // Api to change Entery Fee in edit quizz
  const EditEnteryFeetsApi = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")} `);

    const raw = JSON.stringify({
      "id": Fire4,
      "entryFees": Edit5,
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${QUIZ_BASE_URL}/active/quiz/update/entry/fees`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // Check the status code and show appropriate toast message
        if (result.status === 1) {
          toast.success(result.message);
          Api1(QuizId)
        } else {
          toast.error(result.Backend_Error);
        }
      })
      .catch((error) => console.error(error));
  }


  // api to send buzer
  const sendBuzerMsg = async () => {
    setBuzerLoader(true);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")} `
    );

    const raw = JSON.stringify({
      quiz_id: idNotify,
      header: buzerTitle,
      body: buzerBody,
      category_name: catIdNotify,
      
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${QUIZ_BASE_URL}/active/quiz/active/quiz/buzzer`,
        requestOptions
      );
      const result = await response.json();
      if (result?.status === 1) {
        toast.success(result?.msg);
        handleCloseNotify();
      } else {
        toast.error(result.Backend_Error);
      }
    } catch (error) {
      console.log("error", error);
    }
    finally {
      setBuzerLoader(false);
    }
  };

  useEffect(() => {
    Api5()
  }, [searchSubCat])

  // useEffect(() => {
  //   setCompositionSub(subjectCount);
  // }, [subjectCount]);




  useEffect(() => {
    if (schduleTime) {
      const [dateString, timeString] = schduleTime.split(" ");
      const [month, day, year] = dateString.split('-').map(Number);
      const [hours, minutes] = timeString.split(':').map(Number);

      const presetDate = new Date(year, month - 1, day);
      setStartDate(presetDate);

      const presetTime = new Date();
      presetTime.setHours(hours);
      presetTime.setMinutes(minutes);
      setStartTime(presetTime);
    }
  }, [schduleTime]);



  //function for action passing in empty container;
  const actionToFetchBack = () => {
    setFilterData(Datas);
  };





  useEffect(() => {
    setToken(localStorage.getItem("token"));
    setOverview("Overview");
    Api1();
    APi3();
  }, []);

  useEffect(() => {
    if (fromDate && toDate) {
      getData();
    }
  }, [currentPage, limit, fromDate, toDate]);

  useEffect(() => {
    if (
      searchFeild == ""
    ) {
      getData();
    }
  }, [searchFeild, quizzRefresh]);

  useEffect(() => {
    APi3()
  }, [cat_search]);

  useEffect(() => {
    Api4()
  }, [sub_cat_search]);

  useEffect(() => {
    Histry();
  }, [fromDate1, toDate1, currentPageHistory]);

  console.log("check id", catIdNotify);

  return (
    <div>
      <div className="row" style={{
        height: "6vh",
        overflow: "hidden"
      }}>
        <div className="col-6 d-flex">
          <div className="col-8">
            <h5 className="mt-1">
              Showing total
              <span style={{ color: "red" }}> {totalQuizz} </span>
              Active Quizzes
            </h5>
          </div>
          <div className="col-4">
            <button
              onClick={props.Handel}
              style={{
                backgroundColor: "#EDF7FF",
                border: "1px solid #2188E7",
                height: "40px",
                borderRadius: "10px",
                color: "#2188E7",
              }}
            >
              <span className="p-3"> + Add Quiz</span>
            </button>
          </div>
        </div>
        <div className="col-6">
          <div className="row">
            <div className="col-4"></div>
            <div className="col-6">
              <div
                className="row"
                style={{
                  border: "1px solid #D9D9D9",
                  height: "40px",
                  borderRadius: "7px",
                }}
              >
                <div className="col-2 mt-1">
                  <img alt="SearchIcon" src={SearchIcon} />
                </div>
                <div className="col-10 mt-1 ">
                  <input
                    className="border-0 w-100"
                    placeholder="Search for Exam Category"
                    value={searchFeild}
                    style={{
                      textDecoration: "none",
                      outline: "none",
                      color: "#272727",
                    }}
                    onChange={(e) => setSearchField(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="col-2">
              <button
                className="border-0 bg-white"
                style={{
                  background:
                    "linear-gradient(180deg, rgba(35,176,101,1) 0%, rgba(46,135,96,1) 100%)",
                  color: "#fff",
                  height: "40px",
                  borderTopRightRadius: 10,
                  borderBottomRightRadius: 10,
                  marginLeft: "-20px",
                }}
                onClick={() => {
                  setCurrentPage(1);
                  getData();
                }}
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>




      <section
        className="row"
        style={{
          height: "60vh",
          overflowY: "scroll",
        }}
      >
        {isLoading ? (
          <LoaderComponent />
        ) : Data.length === 0 ? (
          <EmptyContainer
            actionName="Fetch All Data"
            action={actionToFetchBack}
            message="No Data Found"
          />
        ) : (
          Data?.map((res, key) => {
            return (
              <>
                <div className="col-md-4 col-sm-12 col-xl-4 mt-3" key={key}>
                  <div className="card">
                    <div className="d-flex">
                      <div className="col-2">
                        <img
                          className="ms-3 mt-3"
                          alt="tittle"
                          src={`${IMAGE_URL}${res.image}`}
                          style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                          }}
                        />
                      </div>
                      <span className="col-10 ">
                        <p
                          className="mt-3"
                          style={{
                            color: "#000000",
                            fontSize: 18,
                            fontWeight: "bold",
                          }}
                        >
                          {res?.quiz_name}
                        </p>
                      </span>
                    </div>
                    <div
                      className="d-flex ms-5 mb-1"
                      style={{ marginTop: "-10px" }}
                    >
                      <p className="mt-1">Repetition</p>
                      <button
                        className="border-0 ms-3  rounded"
                        style={{
                          width: "90px",
                          height: "30px",
                          backgroundColor: "#E9FFF5",
                          color: "#188241",
                        }}
                      >
                        {/* {res?.repeat == 0
                          ? "No Repeat"
                          : res.repeat + " mins"} */}
                        {res.repeat}
                      </button>
                    </div>

                    <hr style={{ marginTop: "-10px" }} />

                    <div>
                      <div className="d-flex">
                        <img className="ms-3 mb-2" src={Image5} alt="" />
                        <p className="mt-1 ms-3">{res.sch_time}</p>
                      </div>
                      <div className="container" style={{ marginTop: "-20px" }}>
                        <div className="d-flex">
                          <img style={{}} alt="#" src={Image1} />
                          <p
                            className="ms-2"
                            style={{
                              color: "#000000",
                              fontSize: 16,
                              fontWeight: "bold",
                              marginTop: "11PX",
                            }}
                          >
                            {res.category_name}
                            
                          </p>
                        </div>
                        <div className="ms-5 d-flex">
                          <img
                            style={{ marginTop: "-50px" }}
                            alt="#"
                            src={Image2}
                          />
                          <p
                            style={{
                              color: "#000000",
                              fontSize: 12,
                              fontWeight: "bold",
                              marginTop: "-10px",
                            }}
                          >
                            {res.subcategory_name}
                          </p>
                        </div>
                        <div className="d-flex">
                          <img alt="#" src={Image3} />
                          <p className="mt-2 ms-2">{res.entryFees}</p>
                        </div>
                        {/* <div className="d-flex">
                          <img alt="#" src={Image4} />
                          <p className="mt-2 ms-2">{res.rewards}</p>
                        </div> */}
                        <div className="d-flex">
                          <img src={Wallet} alt="" />
                          <div
                            className="ms-2"
                            style={{
                              fontSize: 16,
                              fontWeight: 600,
                              color: "#333333",
                            }}
                          >
                            <span style={{ color: "#2188E7" }}>
                              {res.slots}
                            </span>
                            {/* /{res.total_slot} */}
                          </div>
                        </div>
                        <div className="mt-2 mb-3">
                          <ProgressBar
                            now={res.filled}
                            max={res.total_slot}
                            style={{ height: "12px" }}
                          />
                        </div>
                        <div
                          className="row"
                          style={{ justifyContent: "space-around" }}
                        >
                          <button
                            className="col-5 mb-3 border-0 rounded"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasRight7"
                            aria-controls="offcanvasRight7"
                            style={{
                              color: "#fff",
                              backgroundColor: "#333333",
                              height: "35px",
                            }}
                            onClick={() => {
                              Api1(res._id);
                              setViewQuizId(res._id);
                              setUpcomingCat_Name(res.category_name);
                              

                              // Histry(res._id);
                              // setFilled(res.filled);
                            }}
                          >
                            View Details
                          </button>
                          {/* come here */}
                          <button
                            className="col-5 mb-3 border-0 rounded"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasRight8"
                            aria-controls="offcanvasRight8"
                            style={{
                              color: "#000000",
                              backgroundColor: "#EFEFEF",
                              height: "35px",
                              fontWeight: 500,
                            }}
                            onClick={() => {
                              Api1(res._id);
                              setQuizId(res._id)
                              Histry(res._id);
                              setFilled(res.filled);
                            }}
                          >
                            Edit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })
        )}
      </section>


      <div className="mt-2">
        <Stack spacing={2} className="d-flex justify-content-center mt-3 align-items-center" >
          <Pagination count={totalPages} page={currentPage} onChange={handleChange11} />
        </Stack>
      </div>






      {/* offcanvas7 for viewdwtails of quiz */}
      <div
        class="offcanvas offcanvas-end w-50"
        // tabindex="-1"
        id="offcanvasRight7"
        aria-labelledby="offcanvasRightLabel"
        style={{ borderTopLeftRadius: 30 }}
      >
        <div class="offcanvas-header">
          <span
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            style={{ color: "#000", fontWeight: "600" }}
            onClick={() => setOverview("Overview")}
          >
            <svg
              width="35"
              height="35"
              viewBox="0 0 35 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M27.4913 9.40793C27.616 9.28337 27.7149 9.13547 27.7825 8.97267C27.85 8.80987 27.8849 8.63535 27.885 8.45909C27.8851 8.28283 27.8505 8.10827 27.7831 7.94539C27.7158 7.7825 27.617 7.63448 27.4924 7.50977C27.3679 7.38505 27.22 7.2861 27.0572 7.21854C26.8944 7.15099 26.7199 7.11617 26.5436 7.11606C26.3673 7.11595 26.1928 7.15056 26.0299 7.21791C25.867 7.28527 25.719 7.38404 25.5943 7.5086L17.4999 15.6029L9.40793 7.5086C9.15607 7.25673 8.81446 7.11523 8.45827 7.11523C8.10207 7.11523 7.76047 7.25673 7.5086 7.5086C7.25673 7.76047 7.11523 8.10207 7.11523 8.45827C7.11523 8.81446 7.25673 9.15607 7.5086 9.40793L15.6029 17.4999L7.5086 25.5919C7.38389 25.7166 7.28496 25.8647 7.21747 26.0276C7.14997 26.1906 7.11523 26.3652 7.11523 26.5416C7.11523 26.718 7.14997 26.8926 7.21747 27.0556C7.28496 27.2185 7.38389 27.3666 7.5086 27.4913C7.76047 27.7431 8.10207 27.8846 8.45827 27.8846C8.63464 27.8846 8.80928 27.8499 8.97222 27.7824C9.13517 27.7149 9.28322 27.616 9.40793 27.4913L17.4999 19.3969L25.5943 27.4913C25.8461 27.7428 26.1876 27.884 26.5436 27.8838C26.8996 27.8836 27.2409 27.742 27.4924 27.4901C27.744 27.2382 27.8852 26.8967 27.885 26.5408C27.8848 26.1848 27.7431 25.8435 27.4913 25.5919L19.3969 17.4999L27.4913 9.40793Z"
                fill="black"
              />
            </svg>
          </span>
          {Overview == "History" &&
            <div
              className="col-7 ms-1 "

              style={{
                display: "flex",
                background:
                  "linear-gradient(180deg, rgba(33,184,103,1) 0%, rgba(47,132,96,1))",
                height: "40px",
                borderRadius: "7px",
                color: "#fff",
                width: "auto",
                alignItems: "center",
                gap: 5
              }}
            >


              <input
                value={fromDate1}
                onChange={(e) => {
                  setFromDate1(e.target.value)
                }}
                className="border-0"
                type="date"
                style={{
                  background:
                    "linear-gradient(180deg, rgba(33,184,103,1) 0%, rgba(47,132,96,1))",
                  color: "white"
                }}

              />
              -
              <input
                value={toDate1}
                onChange={(e) => {
                  setToDate1(e.target.value)
                }}
                className="border-0"
                type="date"
                style={{
                  background:
                    "linear-gradient(180deg, rgba(33,184,103,1) 0%, rgba(47,132,96,1))",
                  color: "white"
                }}
              />
            </div>
          }
        </div>

        <div class="offcanvas-body m-2">
          {/* //mapping for particular Quiz */}

          <div className="">
            <div className="row ">
              <div className="col-1">
                <img
                  alt="#"
                  src={`${IMAGE_URL}${Fire6}`}
                  style={{
                    width: "40px",
                    height: "40px",
                    objectFit: "cover",
                    borderRadius: "50%",
                  }}
                />
              </div>
              <div className="col-6">
                <p style={{ fontSize: 20, fontWeight: 600 }}>
                  {title}
                </p>
              </div>

              {/* <button
                className="col-2 border-0 rounded"
                style={{ backgroundColor: "#E9FFF5", color: "#188241" }}
                onClick={() => functionSave(Fire4)}
              >
                Save
              </button> */}
            </div>
            <div className="row mt-2">
              <span className="col-1"></span>
              <span className="col-1">
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 600,
                    color: "#8A8A8A",
                  }}
                >
                  Repetition
                </p>
              </span>
              <button
                className="col-2 ms-5 rounded border-0"
                style={{ backgroundColor: "#E9FFF5", color: "#188241" }}
              >
                {Data1?.repeat === 0
                  ? "No Repeat"
                  : Data1?.repeat}
              </button>
              <button
                className="col-2 rounded border-0 ms-1"
                style={{ color: "#FFBD00" }}

              >
                <img alt="entery fees" src={Image3} />
                {Data1?.entryFees}
              </button>
              {/* <button
                className="col-2 rounded border-0 ms-1"
                style={{ color: "#C922E4" }}
              >
                {Data1?.rewards}
              </button> */}
              <button
                className="col-2 rounded border-0 ms-1"
                style={{ color: "#2188E7" }}
              >
                <img src={Wallet} alt="slots" />
                {/* {filled}/ */}
                {Data1?.slots}
              </button>
            </div>

            <div className="row">
              <span
                className="col-2"
                onClick={() => {
                  setOverview("Overview");
                }}
                style={{
                  fontSize: 16,
                  fontWeight: Overview === "Overview" ? "600" : "",
                  cursor: "pointer",
                }}
              >
                Overview
              </span>
              <span
                className="col-2"
                onClick={() => {
                  setOverview("History");
                  Histry();
                }}
                style={{
                  fontSize: 16,
                  fontWeight: Overview == "History" ? "600" : "",
                  cursor: "pointer",
                }}
              >
                History
              </span>
              <span
                className="col-3"
                onClick={() => {
                  setOverview("Upcoming");
                  
                }}
                style={{
                  fontSize: 16,
                  fontWeight: Overview == "Upcoming" ? "600" : "",
                  cursor: "pointer",
                }}
              >
                Upcoming Quiz
              </span>
            </div>
            <div className="row">
              <div
                className="col-2 mt-2"
                style={{
                  borderBottom:
                    Overview == "Overview" ? "1px solid #000000" : "",
                }}
              ></div>
              <div
                className="col-2"
                style={{
                  borderBottom:
                    Overview === "History" ? "1px solid #000000" : "",
                }}
              ></div>
              <div
                className="col-3"
                style={{
                  borderBottom:
                    Overview === "Upcoming" ? "1px solid #000000" : "",
                }}
              ></div>
            </div>
            <hr style={{ marginTop: "0px" }} />

            {/* overview for view details */}
            {Overview == "Overview" ? (
              <>
                <div className="row ">
                  <p
                    className="col-3 mt-1"
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: "#303030",
                    }}
                  >
                    Exam Category
                  </p>



                </div>
                <div className="row">
                  <img
                    className="col-2"
                    alt="#"
                    src={`${IMAGE_URL}${Fire6}`}
                    style={{
                      width: "auto",
                      height: "40px",
                      objectFit: "cover",
                      borderRadius: "50%",
                    }}
                  />

                  <input
                    disabled={true}
                    className="col-8 border-0"
                    style={{ fontSize: 20, fontWeight: "600" }}
                    // placeholder={Fire2}
                    value={Fire2}
                    onChange={(e) => {
                      setFire2(e.target.value);
                    }}
                  />
                </div>
                <div className="row mt-2">
                  <p
                    className="col-4 mt-1"
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: "#303030",
                    }}
                  >
                    Exam Sub Category
                  </p>

                </div>
                <div className="row">
                  <input
                    disabled={true}
                    className="col-8 border-0"
                    style={{ fontSize: 20, fontWeight: "600" }}
                    value={Fire3}
                    onChange={(e) => {
                      setFire3(e.target.value);
                    }}
                  />
                </div>
                <div className="row mt-4">
                  <p
                    className="col-4 mt-1"
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: "#303030",
                    }}
                  >
                    Question Composition
                  </p>

                </div>
                <div className="row mt-2">
                  {subjectData && subjectData?.map((res, key) => {
                    return (
                      <>
                        <div className="d-flex">
                          <p
                            className="col-5"
                            style={{
                              fontSize: 16,
                              fontWeight: 600,
                              color: "#303030",
                            }}
                          >
                            {res.name}
                          </p>
                          <p className="col-1">{res.persentage}%</p>
                        </div>

                        <Slider
                          key={key}
                          id={key}

                          value={res?.persentage}

                        // total={Object.values(Fire5).reduce((total, value) => total + value, 0)}

                        // onChange={handleChangeName}
                        />
                      </>
                    );
                  })}
                </div>

                <div className="row mt-4 mb-2">
                  <p
                    className="col-2 mt-1"
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: "#303030",
                    }}
                  >
                    Next Quiz
                  </p>
                </div>
                <div className="row ">
                  <span
                    className="col-2 ms-2 d-flex rounded"
                    style={{
                      backgroundColor: "#EFEFEF",
                      overflow: "hidden",
                      height: "35px",
                    }}
                  >
                    <img className="mt-1" src={Dates} alt="#" />
                    <DatePicker
                      className="Main"
                      selected={startDate}
                      value={schduleTime}
                    // onChange={(date) => setStartDate(date)}
                    />
                  </span>
                  <span
                    className="col-2 ms-4 rounded"
                    style={{
                      backgroundColor: "#EFEFEF",
                      height: "35px",
                      border: "1px solid #252525",
                    }}
                  >
                    <input
                      className="border-0 rounded mt-1"
                      type="time"
                      style={{
                        backgroundColor: "#EFEFEF",
                        color: "#8A8A8A",
                      }}
                      value={startTime ? startTime.toTimeString().substring(0, 5) : ''}
                    // onChange={(e) => {
                    //   const [hours, minutes] = e.target.value.split(':').map(Number);
                    //   const updatedTime = new Date();
                    //   updatedTime.setHours(hours);
                    //   updatedTime.setMinutes(minutes);
                    //   setStartTime(updatedTime);
                    // }}
                    />
                  </span>
                </div>
                <div>
                  <div className="row mt-4">
                    <p className="col-2 mt-1" style={{ fontSize: 16, fontWeight: 600, color: "#303030" }}>Rules</p>
                  </div>
                  {Edit3?.map((rule, index) => (
                    <div key={index} className="mb-2">
                      <textarea
                        className="col-6 rounded"
                        style={{ height: "100px" }}
                        value={rule}
                        disabled={true}
                      // onChange={(event) => handleRuleChange(index, event.target.value)}
                      />

                    </div>
                  ))}
                </div>
                {/* <textarea
                  className="col-6 rounded"
                  style={{ height: "200px" }}
                  value={Edit3}
                  onChange={(e) => {
                    setEdit3(e.target.value);
                  }}
                /> */}
                <div className="row mt-4">
                  <p
                    className="col-3 mt-1"
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: "#303030",
                    }}
                  >
                    Banner Image
                  </p>
                  {/* <label
                    className="filelabel2 col-2"
                    style={{ backgroundColor: "#F5F5F5" }}
                    
                  >
                    <span className="title2">upload</span>
                    <input
                      className="FileUpload2"
                      id="FileInput2"
                      name="booking_attachment2"
                      type="file"
                      src={`${IMAGE_URL}${BannerShow}`}
                      alt="banner image"
                      accept=".png,.jpg"
                      onChange={(e) => {
                        setbannerImage(e.target.files[0]);
                        setBannerShow(URL.createObjectURL(e.target.files[0]));
                      }}
                    />
                    
                  </label> */}

                </div>
                <div className="col-6 rounded">
                  {/* <img
                    className="w-100 rounded"
                    alt=""
                    src={`${IMAGE_URL}${BannerShow}`}
                    style={{ height: "200px" }}
                  /> */}
                </div>
                <div className="row mt-4">
                  <p
                    className="col-3 mt-1"
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: "#303030",
                    }}
                  >
                    Total Slots
                  </p>

                </div>
                <input
                  className="col-6 "
                  style={{
                    fontSize: 20,
                    fontWeight: "600",
                    backgroundColor: "#",
                  }}
                  value={Edit4}
                  // onChange={(e) => {
                  //   setEdit4(e.target.value);
                  // }}
                  disabled={true}
                />
                <div className="row mt-4">
                  <p
                    className="col-3 mt-1"
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: "#303030",
                    }}
                  >
                    Entry Fee
                  </p>

                </div>
                <input
                  className="col-6 "
                  style={{
                    fontSize: 20,
                    fontWeight: "600",
                    backgroundColor: "#",
                  }}
                  value={Edit5}
                  // onChange={(e) => {
                  //   setEdit5(e.target.value);
                  // }}
                  disabled={true}
                />
                <div className="row mt-4">
                  <p
                    className="col-4 mt-1"
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: "#303030",
                    }}
                  >
                    Total Number of Questions
                  </p>

                </div>
                <input
                  className="col-6 "
                  style={{
                    fontSize: 20,
                    fontWeight: "600",
                    backgroundColor: "#",
                  }}
                  value={Edit6}
                  disabled={true}
                />
                <div className="row mt-4">
                  <p
                    className="col-4 mt-1"
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: "#303030",
                    }}
                  >
                    Time Per Question
                  </p>

                  {Edit7}
                </div>
                <input
                  className="col-6 "
                  type="range"
                  min={10}
                  max={60}
                  value={Edit7}
                />

                <div className="row mt-4">
                  <p
                    className="col-4 mt-1"
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: "#303030",
                    }}
                  >
                    Repeat Quiz After
                  </p>

                  <select
                    className="col-2 rounded"
                    value={ChangesNo}
                    style={{
                      border: "1px solid #000000",
                      height: "30px",
                    }}
                    disabled={true}

                  >
                    <option value="never">never</option>
                    <option value="5 mins">5 mins</option>
                    <option value="15 mins">15 mins</option>
                    <option value="30 mins">30 mins</option>
                    <option value="45 mins">45 mins</option>
                    <option value="1 hrs">1 hrs</option>
                    <option value="2 hrs">2 hrs</option>
                    <option value="3 hrs">3 hrs</option>
                    <option value="6 hrs">6 hrs</option>
                    <option value="1 days">1 days</option>
                    <option value="2 days">2 days</option>
                    <option value="3 days">3 days</option>
                    <option value="1 week">1 week</option>
                    <option value="2 week">2 week</option>
                    <option value="1 month">1 month</option>
                    <option value="2 month">2 month</option>
                    <option value="3 month">3 month</option>
                    <option value="6 month">6 month</option>
                    <option value="1 Year">1 Year</option>

                  </select>

                </div>
              </>
            ) : (
              <></>
            )}
            <>
              {isLoading ? (
                <div className="loader">Loading...</div> // Replace with your actual loader component
              ) : (
                <>
                  {Overview === "History" ? (
                    <QuizHistory
                      last_page={last_page}
                      Data={HistryData}
                      setIsLoading={setIsLoading}
                      quiz_id={QuizId}
                      view_quizId={viewQuizId}
                      fromDate1={fromDate1}
                      toDate1={toDate1}
                      // handleNextPage={handleNextPage}
                      // handlePreviousPage={handlePreviousPage}
                      currentPage={currentPageHistory}
                      hasMore={hasMore}
                    />
                  ) : (
                    <></>
                  )}
                </>
              )}
            </>
            <>
              {isLoading ? (
                <div className="loader">Loading...</div> // Replace with your actual loader component
              ) : (
                <>
                  {Overview === "Upcoming" ? (
                   <UpcomingQuiz
                   view_quizId={viewQuizId}
                   handleShowNotify={handleShowNotify}
                   />
                  ) : (
                    <></>
                  )}
                </>
              )}
            </>
          </div>
        </div>
      </div>

      {/* offcanvas8 here for edit the particular active quiz */}

      <div
        class="offcanvas offcanvas-end w-50"
        tabindex="-1"
        id="offcanvasRight8"
        aria-labelledby="offcanvasRightLabel"
        style={{ borderTopLeftRadius: 30 }}
      >
        <div class="offcanvas-header">
          <span
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            style={{ color: "#000", fontWeight: "600", cursor: "pointer" }}
            onClick={() => setOverview("Overview")}
          >
            <svg
              width="35"
              height="35"
              viewBox="0 0 35 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M27.4913 9.40793C27.616 9.28337 27.7149 9.13547 27.7825 8.97267C27.85 8.80987 27.8849 8.63535 27.885 8.45909C27.8851 8.28283 27.8505 8.10827 27.7831 7.94539C27.7158 7.7825 27.617 7.63448 27.4924 7.50977C27.3679 7.38505 27.22 7.2861 27.0572 7.21854C26.8944 7.15099 26.7199 7.11617 26.5436 7.11606C26.3673 7.11595 26.1928 7.15056 26.0299 7.21791C25.867 7.28527 25.719 7.38404 25.5943 7.5086L17.4999 15.6029L9.40793 7.5086C9.15607 7.25673 8.81446 7.11523 8.45827 7.11523C8.10207 7.11523 7.76047 7.25673 7.5086 7.5086C7.25673 7.76047 7.11523 8.10207 7.11523 8.45827C7.11523 8.81446 7.25673 9.15607 7.5086 9.40793L15.6029 17.4999L7.5086 25.5919C7.38389 25.7166 7.28496 25.8647 7.21747 26.0276C7.14997 26.1906 7.11523 26.3652 7.11523 26.5416C7.11523 26.718 7.14997 26.8926 7.21747 27.0556C7.28496 27.2185 7.38389 27.3666 7.5086 27.4913C7.76047 27.7431 8.10207 27.8846 8.45827 27.8846C8.63464 27.8846 8.80928 27.8499 8.97222 27.7824C9.13517 27.7149 9.28322 27.616 9.40793 27.4913L17.4999 19.3969L25.5943 27.4913C25.8461 27.7428 26.1876 27.884 26.5436 27.8838C26.8996 27.8836 27.2409 27.742 27.4924 27.4901C27.744 27.2382 27.8852 26.8967 27.885 26.5408C27.8848 26.1848 27.7431 25.8435 27.4913 25.5919L19.3969 17.4999L27.4913 9.40793Z"
                fill="black"
              />
            </svg>
          </span>

          {/* {Overview == "History" &&
            <div
              className="col-7 ms-1 "

              style={{
                display: "flex",
                background:
                  "linear-gradient(180deg, rgba(33,184,103,1) 0%, rgba(47,132,96,1))",
                height: "40px",
                borderRadius: "7px",
                color: "#fff",
                width: "auto",
                alignItems: "center",
                gap: 5
              }}
            >


              <input
                value={fromDate1}
                onChange={(e) => {
                  setFromDate1(e.target.value)
                }}
                className="border-0"
                type="date"
                style={{
                  background:
                    "linear-gradient(180deg, rgba(33,184,103,1) 0%, rgba(47,132,96,1))",
                  color: "white"
                }}

              />
              -
              <input
                value={toDate1}
                onChange={(e) => {
                  setToDate1(e.target.value)
                }}
                className="border-0"
                type="date"
                style={{
                  background:
                    "linear-gradient(180deg, rgba(33,184,103,1) 0%, rgba(47,132,96,1))",
                  color: "white"
                }}

              />



            </div>
          } */}

          {/* <p style={{ fontSize: 20 }}>The changes will reflected on 12:01 AM</p> */}
        </div>
        <div class="offcanvas-body m-2">
          {/* //mapping for particular Quiz */}

          <div className="">
            <div className="row ">
              <div className="col-1">
                <img
                  alt="#"
                  src={`${IMAGE_URL}${Fire6}`}
                  style={{
                    width: "40px",
                    height: "40px",
                    objectFit: "cover",
                    borderRadius: "50%",
                  }}
                />
              </div>
              <div className="col-6">
                <p style={{ fontSize: 20, fontWeight: 600 }}>
                  {title}
                </p>
              </div>

              {/* <button
                className="col-2 border-0 rounded"
                style={{ backgroundColor: "#E9FFF5", color: "#188241" }}
                onClick={() => functionSave(Fire4)}
              >
                Save
              </button> */}

            </div>
            <div className="row mt-2">
              <span className="col-1"></span>
              <span className="col-1">
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 600,
                    color: "#8A8A8A",
                  }}
                >
                  Repetition
                </p>
              </span>
              <button
                className="col-2 ms-5 rounded border-0"
                style={{ backgroundColor: "#E9FFF5", color: "#188241" }}
              >
                {Data1?.repeat === 0
                  ? "No Repeat"
                  : Data1?.repeat}
              </button>
              <button
                className="col-2 rounded border-0 ms-1"
                style={{ color: "#FFBD00" }}

              >
                <img alt="entery fees" src={Image3} />
                {Data1?.entryFees}
              </button>
              {/* <button
                className="col-2 rounded border-0 ms-1"
                style={{ color: "#C922E4" }}
              >
                {Data1?.rewards}
              </button> */}
              <button
                className="col-2 rounded border-0 ms-1"
                style={{ color: "#2188E7" }}
              >
                <img src={Wallet} alt="slots" />
                {/* {filled}/ */}
                {Data1?.slots}
              </button>
            </div>

            <div className="row">
              <span
                className="col-2"
                onClick={() => {
                  setOverview("Overview");
                }}
                style={{
                  fontSize: 16,
                  fontWeight: Overview === "Overview" ? "600" : "",
                  cursor: "pointer",
                }}
              >
                Overview
              </span>
              {/* <span
                className="col-2"
                onClick={() => {
                  setOverview("History");
                }}
                style={{
                  fontSize: 16,
                  fontWeight: Overview === "History" ? "600" : "",
                  cursor: "pointer",
                }}
              >
                History
              </span> */}
            </div>
            <div className="row">
              <div
                className="col-2 mt-2"
                style={{
                  borderBottom:
                    Overview === "Overview" ? "1px solid #000000" : "",
                }}
              ></div>
              <div
                className="col-2"
                style={{
                  borderBottom:
                    Overview === "History" ? "1px solid #000000" : "",
                }}
              ></div>
            </div>
            <hr style={{ marginTop: "0px" }} />





            {/* this overview for edit section */}
            {Overview === "Overview" ? (
              <>
                <div className="row mb-2">
                  <p
                    className="col-3 mt-1"
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: "#303030",
                    }}
                  >
                    Exam Category
                  </p>
                  <button
                    className="col-2 border-0 rounded"
                    style={{ backgroundColor: "#efefef", color: "#188241" }}
                    onClick={() => {
                      APi3();
                      handleShow();
                    }}
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    Change
                  </button>


                </div>


                <div className="row">
                  <img
                    className="col-2"
                    alt="#"
                    src={`${IMAGE_URL}${Fire6}`}
                    style={{
                      width: "auto",
                      height: "40px",
                      objectFit: "cover",
                      borderRadius: "50%",
                    }}
                  />

                  <input
                    disabled={true}
                    className="col-8 border-0"
                    style={{ fontSize: 20, fontWeight: "600" }}
                    // placeholder={Fire2}
                    value={Fire2}
                    onChange={(e) => {
                      setFire2(e.target.value);
                    }}
                  />
                </div>
                <div className="row mt-2 mb-2">
                  <p
                    className="col-4 mt-1"
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: "#303030",
                    }}
                  >
                    Exam Sub Category
                  </p>
                  <button
                    className="col-2 border-0 rounded"
                    style={{ backgroundColor: "#efefef", color: "#188241" }}
                    onClick={() => {
                      setClick(1);
                      Api4();
                      handleShow();
                    }}
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    Change
                  </button>
                </div>
                <div className="row">
                  <input
                    disabled={true}
                    className="col-8 border-0"
                    style={{ fontSize: 20, fontWeight: "600" }}
                    value={Fire3}
                    onChange={(e) => {
                      setFire3(e.target.value);
                    }}
                  />
                </div>
                <div className="row mt-4">
                  <p
                    className="col-4 mt-1"
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: "#303030",
                    }}
                  >
                    Question Composition
                  </p>
                  <button
                    className="col-2 border-0 rounded"
                    style={{ backgroundColor: "#efefef", color: "#188241" }}
                    onClick={() => {
                      setClick(2);
                      Api5();
                      handleShow();
                    }}
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    Change
                  </button>
                </div>
                <div className="row mt-2">
                  {subjectData && subjectData?.map((res, key) => {
                    return (
                      <>
                        <div className="d-flex">
                          <p
                            className="col-5"
                            style={{
                              fontSize: 16,
                              fontWeight: 600,
                              color: "#303030",
                            }}
                          >
                            {res?.name}
                          </p>
                          <p className="col-1">{res.persentage}%</p>
                        </div>

                        <Slider
                          key={key}
                          id={key}

                          value={res.persentage}
                          total={Object.values(Fire5).reduce((total, value) => total + value, 0)}
                          onChange={handleChangeName}
                        />
                      </>
                    );
                  })}
                </div>

                {/* <div className="row mt-4 mb-2">
                  <p
                    className="col-2 mt-1"
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: "#303030",
                    }}
                  >
                    Next Quiz
                  </p>
                  <button
                className="col-2 border-0 rounded"
                style={{ backgroundColor: "#efefef", color: "#188241" }}
                >
                Change
              </button>
                </div> */}
                {/* <div className="row ">
                  <span
                    className="col-2 ms-2 d-flex rounded"
                    style={{
                      backgroundColor: "#EFEFEF",
                      overflow: "hidden",
                      height: "35px",
                    }}
                  >
                    <img className="mt-1" src={Dates} alt="#" />
                    <DatePicker
                      className="Main"
                       selected={startDate}
                      onChange={(date) => setStartDate(date)}
                    />
                  </span>
                  <span
                    className="col-2 ms-4 rounded"
                    style={{
                      backgroundColor: "#EFEFEF",
                      height: "35px",
                      border: "1px solid #252525",
                    }}
                  >
                    <input
                      className="border-0 rounded mt-1"
                     type="time"
                      style={{
                        backgroundColor: "#EFEFEF",
                        color: "#8A8A8A",
                      }}
                      value={startTime ? startTime.toTimeString().substring(0, 5) : ''}
                      onChange={(e) => {
                        const [hours, minutes] = e.target.value.split(':').map(Number);
                        const updatedTime = new Date();
                        updatedTime.setHours(hours);
                        updatedTime.setMinutes(minutes);
                        setStartTime(updatedTime);
                      }}
                    />
                  </span>
                </div> */}
                <div>
                  <div className="row mt-4">
                    <p className="col-2 mt-1" style={{ fontSize: 16, fontWeight: 600, color: "#303030" }}>Rules</p>
                    <button className="col-2 border-0 rounded" style={{ backgroundColor: "#efefef", color: "#188241" }} onClick={EditRulesApi}>Change</button>
                  </div>
                  {Edit3?.map((rule, index) => (
                    <div key={index} className="mb-2">
                      <textarea
                        className="col-6 rounded"
                        style={{ height: "100px" }}
                        value={rule}
                        onChange={(event) => handleRuleChange(index, event.target.value)}
                      />
                      {index === Edit3?.length - 1 && (
                        <button className="btn btn-secondary btn-md ms-2" onClick={handleAddRule}>Add More Rule</button>
                      )}
                      {Edit3?.length > 1 && (
                        <button className="btn btn-secondary btn-md ms-2" onClick={() => handleRemoveRule(index)}>Remove Rule</button>
                      )}
                    </div>
                  ))}
                </div>
                {/* <textarea
                  className="col-6 rounded"
                  style={{ height: "200px" }}
                  value={Edit3}
                  onChange={(e) => {
                    setEdit3(e.target.value);
                  }}
                /> */}
                <div className="row mt-4">
                  <p
                    className="col-3 mt-1"
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: "#303030",
                    }}
                  >
                    Banner Image
                  </p>
                  <label
                    className="filelabel2 col-2"
                    style={{ backgroundColor: "#F5F5F5" }}
                    onClick={() => { }}
                  >
                    <span className="title2">upload</span>
                    <input
                      className="FileUpload2"
                      id="FileInput2"
                      name="booking_attachment2"
                      type="file"
                      src={`${IMAGE_URL}${BannerShow}`}
                      alt="banner image"
                      accept=".png,.jpg"
                      onChange={(e) => {
                        setbannerImage(e.target.files[0]);
                        setBannerShow(URL.createObjectURL(e.target.files[0]));
                      }}
                    />

                  </label>
                  <button
                    className="col-2 border-0 rounded ms-3"
                    style={{ backgroundColor: "#efefef", color: "#188241" }}
                    onClick={EditBannerImgApi}
                  >
                    Change
                  </button>
                </div>
                <div className="col-6 rounded">
                  {bannerImage ? <>
                    <img
                      className="w-100 rounded"
                      alt=""
                      src={BannerShow}
                      style={{ height: "200px" }}
                    />

                  </>
                    :
                    <>
                      <img
                        className="w-100 rounded"
                        alt=""
                        src={`${IMAGE_URL}${BannerShow}`}
                        style={{ height: "200px" }}
                      />
                    </>
                  }
                </div>
                <div className="row mt-4">
                  <p
                    className="col-3 mt-1"
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: "#303030",
                    }}
                  >
                    Total Slots
                  </p>
                  <button
                    className="col-2 border-0 rounded"
                    style={{ backgroundColor: "#efefef", color: "#188241" }}
                    onClick={EditTotalSlotsApi}
                  >
                    Change
                  </button>
                </div>
                <input
                  className="col-6 "
                  style={{
                    fontSize: 20,
                    fontWeight: "600",
                    backgroundColor: "#",
                  }}
                  value={Edit4}
                  onChange={(e) => {
                    setEdit4(e.target.value);
                  }}
                />
                <div className="row mt-4">
                  <p
                    className="col-3 mt-1"
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: "#303030",
                    }}
                  >
                    Entry Fee
                  </p>
                  <button
                    className="col-2 border-0 rounded"
                    style={{ backgroundColor: "#efefef", color: "#188241" }}
                    onClick={EditEnteryFeetsApi}
                  >
                    Change
                  </button>
                </div>
                <input
                  className="col-6 "
                  style={{
                    fontSize: 20,
                    fontWeight: "600",
                    backgroundColor: "#",
                  }}
                  value={Edit5}
                  onChange={(e) => {
                    setEdit5(e.target.value);
                  }}
                />
                <div className="row mt-4">
                  <p
                    className="col-4 mt-1"
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: "#303030",
                    }}
                  >
                    Total Number of Questions
                  </p>
                  <button
                    className="col-2 border-0 rounded"
                    style={{ backgroundColor: "#efefef", color: "#188241" }}
                    onClick={EditToalQuesApi}
                  >
                    {isPending ? <Spinner /> : " Change"}
                  </button>
                </div>
                <input
                  className="col-6 "
                  style={{
                    fontSize: 20,
                    fontWeight: "600",
                    backgroundColor: "#",
                  }}
                  value={Edit6}
                  onChange={(e) => {
                    setEdit6(e.target.value);
                  }}
                />
                <div className="row mt-4">
                  <p
                    className="col-4 mt-1"
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: "#303030",
                    }}
                  >
                    Time Per Question
                  </p>

                  {Edit7} sec
                </div>
                <input
                  className="col-6 "
                  type="range"
                  min={10}
                  max={60}
                  value={Edit7}
                  onChange={(e) => {
                    setEdit7(e.target.value);
                  }}
                />
                <button
                  className="col-2 border-0 rounded"
                  style={{ backgroundColor: "#efefef", color: "#188241" }}
                  onClick={EditTimePerQuesApi}
                >
                  Change
                </button>
                <div className="row mt-4">
                  <p
                    className="col-4 mt-1"
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: "#303030",
                    }}
                  >
                    Repeat Quiz After
                  </p>

                  <select
                    className="col-2 rounded"
                    value={ChangesNo}
                    style={{
                      border: "1px solid #000000",
                      height: "30px",
                    }}
                    onChange={(e) => {
                      setChangesNo(e.target.value);
                    }}
                  >
                    <option value="never">never</option>
                    <option value="5 mins">5 mins</option>
                    <option value="15 mins">15 mins</option>
                    <option value="30 mins">30 mins</option>
                    <option value="45 mins">45 mins</option>
                    <option value="1 hrs">1 hrs</option>
                    <option value="2 hrs">2 hrs</option>
                    <option value="3 hrs">3 hrs</option>
                    <option value="6 hrs">6 hrs</option>
                    <option value="1 days">1 days</option>
                    <option value="2 days">2 days</option>
                    <option value="3 days">3 days</option>
                    <option value="1 week">1 week</option>
                    <option value="2 week">2 week</option>
                    <option value="1 month">1 month</option>
                    <option value="2 month">2 month</option>
                    <option value="3 month">3 month</option>
                    <option value="6 month">6 month</option>
                    <option value="1 Year">1 Year</option>

                  </select>
                  <button
                    className="col-2 border-0 rounded"
                    style={{ backgroundColor: "#efefef", color: "#188241" }}
                    onClick={repeatApi}
                  >
                    Change
                  </button>
                </div>
              </>
            ) : (
              <></>
            )}

            {/* {Overview === "History" ? (
              <>
              hello
                <QuizHistory last_page={last_page} Data={HistryData} quiz_id={QuizId} fromDate={fromDate1} toDate={toDate1} view_quizId={viewQuizId} />
              </>
            ) : (
              <></>
            )} */}
          </div>
        </div>
      </div>

      {/* edit category canvas start */}
      <Offcanvas
        show={show}
        className="w-50"
        onHide={() => {
          handleClose();
          setArrForm()
          setClick(0)
          setSelectedSubjects([])
          setIsSave(true)
          setSelectedCategoryId("")
          setSelectedSubjectIds([])
          setSubjectCount([])
          handleClose()
        }}
        placement="end"
        style={{ borderTopLeftRadius: 30, width: "50%" }}
      >
        <Offcanvas.Header>

          <div style={{ width: "100%", flex: 1 }}>

            <div className="d-flex" style={{ justifyContent: "space-between" }}>

              <div>
                <span
                  style={{
                    color: "#000",
                    fontWeight: "600",
                    display: Click === 8 ? "none" : "block",
                    cursor: "pointer",

                  }}
                  onClick={() => {
                    handleClose();
                    setArrForm()
                    setClick(0)
                    setSelectedSubjects([])
                    setIsSave(true)
                    setSelectedCategoryId("")
                    setSelectedSubjectIds([])
                    setSubjectCount([])
                    handleClose()
                  }}
                >
                  <svg
                    width="35"
                    height="35"
                    viewBox="0 0 35 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M27.4913 9.40793C27.616 9.28337 27.7149 9.13547 27.7825 8.97267C27.85 8.80987 27.8849 8.63535 27.885 8.45909C27.8851 8.28283 27.8505 8.10827 27.7831 7.94539C27.7158 7.7825 27.617 7.63448 27.4924 7.50977C27.3679 7.38505 27.22 7.2861 27.0572 7.21854C26.8944 7.15099 26.7199 7.11617 26.5436 7.11606C26.3673 7.11595 26.1928 7.15056 26.0299 7.21791C25.867 7.28527 25.719 7.38404 25.5943 7.5086L17.4999 15.6029L9.40793 7.5086C9.15607 7.25673 8.81446 7.11523 8.45827 7.11523C8.10207 7.11523 7.76047 7.25673 7.5086 7.5086C7.25673 7.76047 7.11523 8.10207 7.11523 8.45827C7.11523 8.81446 7.25673 9.15607 7.5086 9.40793L15.6029 17.4999L7.5086 25.5919C7.38389 25.7166 7.28496 25.8647 7.21747 26.0276C7.14997 26.1906 7.11523 26.3652 7.11523 26.5416C7.11523 26.718 7.14997 26.8926 7.21747 27.0556C7.28496 27.2185 7.38389 27.3666 7.5086 27.4913C7.76047 27.7431 8.10207 27.8846 8.45827 27.8846C8.63464 27.8846 8.80928 27.8499 8.97222 27.7824C9.13517 27.7149 9.28322 27.616 9.40793 27.4913L17.4999 19.3969L25.5943 27.4913C25.8461 27.7428 26.1876 27.884 26.5436 27.8838C26.8996 27.8836 27.2409 27.742 27.4924 27.4901C27.744 27.2382 27.8852 26.8967 27.885 26.5408C27.8848 26.1848 27.7431 25.8435 27.4913 25.5919L19.3969 17.4999L27.4913 9.40793Z"
                      fill="black"
                    />
                  </svg>

                </span>
              </div>


              <div>
                <span className="bach_step"
                  style={{
                    display: Click <= 1,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setClick(Click - 1)
                  }}
                >
                  <HiArrowNarrowLeft size="30px" style={{ display: Click === 8 ? "none" : "block" }} />
                </span>
              </div>


            </div>

            <h3
              style={{
                marginLeft: "5px",
                fontWeight: "600",
                display: Click >= 7 ? "none" : "block",
                marginTop: 20,
              }}
            >
              Edit Quiz
            </h3>
          </div>
        </Offcanvas.Header>
        {/* <Offcanvas.Header>
          <div>
            <span
              style={{
                color: "#000",
                fontWeight: "600",
                display: Click === 8 ? "none" : "block",
                cursor: "pointer",
              }}
              onClick={() => {
                handleClose();
                setArrForm()
                setClick(0)
                setSelectedSubjects([])
                setIsSave(true)
                setSelectedCategoryId("")
                setSelectedSubjectIds([])
                setSubjectCount([])
                handleClose()
              }}

            >
              <svg
                width="35"
                height="35"
                viewBox="0 0 35 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M27.4913 9.40793C27.616 9.28337 27.7149 9.13547 27.7825 8.97267C27.85 8.80987 27.8849 8.63535 27.885 8.45909C27.8851 8.28283 27.8505 8.10827 27.7831 7.94539C27.7158 7.7825 27.617 7.63448 27.4924 7.50977C27.3679 7.38505 27.22 7.2861 27.0572 7.21854C26.8944 7.15099 26.7199 7.11617 26.5436 7.11606C26.3673 7.11595 26.1928 7.15056 26.0299 7.21791C25.867 7.28527 25.719 7.38404 25.5943 7.5086L17.4999 15.6029L9.40793 7.5086C9.15607 7.25673 8.81446 7.11523 8.45827 7.11523C8.10207 7.11523 7.76047 7.25673 7.5086 7.5086C7.25673 7.76047 7.11523 8.10207 7.11523 8.45827C7.11523 8.81446 7.25673 9.15607 7.5086 9.40793L15.6029 17.4999L7.5086 25.5919C7.38389 25.7166 7.28496 25.8647 7.21747 26.0276C7.14997 26.1906 7.11523 26.3652 7.11523 26.5416C7.11523 26.718 7.14997 26.8926 7.21747 27.0556C7.28496 27.2185 7.38389 27.3666 7.5086 27.4913C7.76047 27.7431 8.10207 27.8846 8.45827 27.8846C8.63464 27.8846 8.80928 27.8499 8.97222 27.7824C9.13517 27.7149 9.28322 27.616 9.40793 27.4913L17.4999 19.3969L25.5943 27.4913C25.8461 27.7428 26.1876 27.884 26.5436 27.8838C26.8996 27.8836 27.2409 27.742 27.4924 27.4901C27.744 27.2382 27.8852 26.8967 27.885 26.5408C27.8848 26.1848 27.7431 25.8435 27.4913 25.5919L19.3969 17.4999L27.4913 9.40793Z"
                  fill="black"
                />
              </svg>
            </span>
            <h3
              style={{
                marginLeft: "5px",
                fontWeight: "600",
                display: Click >= 7 ? "none" : "block",
                marginTop: 20,
              }}
            >
              Edit Quiz
            </h3>
          </div>
        </Offcanvas.Header> */}
        <Offcanvas.Body style={{ marginTop: "-20px" }}>
          <div style={{ display: Click === 0 ? "block" : "none" }}>
            <div className="mb-5">
              <div className="progress" style={{ height: "10px" }}>
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{
                    width: "25%",
                    background:
                      "linear-gradient(to right, #f44cc5, #ec40cc, #e335d3, #d72adb, #c922e4)",
                  }}
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <div style={{ color: "#8A8A8A" }}>
                1/4 <span sytle={{ color: "#000" }}>Steps Completed</span>
              </div>

              <div className="row">
                <div className="col-3"></div>
                <div
                  className="card col-6"
                  style={{ justifyConetnt: "center", textAlign: "center" }}
                >
                  <div>
                    <label
                      className="col-12 mb-2 mt-3"
                      style={{
                        textAlign: "start",
                        color: "#303030",
                        fontWeight: "600",
                      }}
                    >
                      Select Category
                    </label>
                    <br />

                    <input
                      className="col-12 border-0 mb-3"
                      style={{
                        textAlign: "start",
                        height: "50px",
                        backgroundColor: "#EFEFEF",
                        borderRadius: 7,
                        outline: 1,
                      }}
                      placeholder="  Search for Category"
                      type="search"
                      value={cat_search}
                      onChange={(e) => set_cat_search(e.target.value)}

                    />
                    <br />
                  </div>
                  <div
                    style={{
                      height: "160px",
                      overflowY: "scroll",
                      justifyContent: "start",
                      alignItems: "start",
                    }}
                  >
                    {filterCategory1?.map((res, key) => {
                      return (
                        <>
                          <div className="d-flex mt-3" key={key}>
                            <input
                              style={{ color: "#F6F6F6" }}
                              type="radio"
                              name="Select"
                              onChange={() => handleRadioClick(res._id, res.category_name)}
                            />
                            <img
                              alt="#"
                              src={`${IMAGE_URL}${res?.image}`}
                              style={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "50%",
                              }}
                            />
                            <span className="ms-3 fw-bolder">
                              {res.category_name}
                            </span>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                justifyContent: "center",
                textAlign: "center",
                backgroundColor: "",
              }}
            >
              <button
                disabled={filterCategory1?.length >= 1 ? false : true}
                className="col-5 border-0 text-white"
                onClick={() => {
                  // setClick(Click + 1);
                  moveOnToLast();
                  Api4();
                  // Api1(selectedCategoryId );
                }}
                style={{
                  backgroundColor: "#2188E7",
                  height: "40px",
                  borderRadius: 7,
                }}
              >
                Proceed
              </button>
            </div>
          </div>


          {/* Select Sub Category Start */}

          <div style={{ display: Click === 1 ? "block" : "none" }}>
            <div className="mb-5">
              <div className="progress" style={{ height: "10px" }}>
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{
                    width: "50%",
                    background:
                      "linear-gradient(to right, #f44cc5, #ec40cc, #e335d3, #d72adb, #c922e4)",
                  }}
                  aria-valuenow="50"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <p style={{ color: "#8A8A8A" }}>
                2/4 <span sytle={{ color: "red" }}>Steps Completed</span>
              </p>
              <div>
                <p style={{ fontSize: 16, fontWeight: 600 }}>
                  Exam Category : <span>{selectedCategoryName1}</span>
                </p>
              </div>
              <div className="row">
                <div className="col-3"></div>
                <div
                  className="card col-6"
                  style={{ justifyConetnt: "center", textAlign: "center" }}
                >
                  <div>
                    <label
                      className="col-12 mb-2 mt-3"
                      style={{
                        textAlign: "start",
                        color: "#303030",
                        fontWeight: "600",
                      }}
                    >
                      Select Sub Category
                    </label>
                    <br />
                    <input
                      className="col-12 border-0 mb-3"
                      style={{
                        textAlign: "start",
                        height: "50px",
                        backgroundColor: "#EFEFEF",
                        borderRadius: 7,
                        outline: 1,
                      }}
                      placeholder="  Search for Sub Category"
                      type="search"
                      value={sub_cat_search}
                      onChange={(e) => set_sub_cat_search(e.target.value)}
                    />
                    <br />
                  </div>
                  <div
                    style={{
                      height: "160px",
                      overflowY: "scroll",
                      justifyContent: "start",
                      alignItems: "start",
                    }}
                  >
                    {filterSubCatrgory?.map((res, key) => {
                      return (
                        <>
                          <div className="d-flex mt-3" key={key}>
                            <input
                              style={{ color: "#F6F6F6" }}
                              type="radio"
                              name="Select"
                              onClick={() => {
                                handleSubCatId(res._id)
                                setSubCatName(res?.sub_category_name)
                              }}
                            />
                            {/* <img
                              alt="#"
                              src={Image2}
                              style={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "50%",
                              }}
                            /> */}
                            <span className="ms-3 fw-bolder">
                              {res?.sub_category_name}
                            </span>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                justifyContent: "center",
                textAlign: "center",
                backgroundColor: "",
              }}
            >
              <button
                disabled={selectedSubId.length >= 1 ? false : true}
                className="col-5 border-0 text-white"
                onClick={() => {
                  moveOnToLast();
                  Api5();
                  // setClick(Click + 1);

                }}
                style={{
                  backgroundColor: "#2188E7",
                  height: "40px",
                  borderRadius: 7,
                }}
              >
                Proceed
              </button>
            </div>
          </div>

          {/* Select Subject */}

          <div style={{ display: Click === 2 ? "block" : "none" }}>
            <div className="mb-5">
              <div className="progress" style={{ height: "10px" }}>
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{
                    width: "75%",
                    background:
                      "linear-gradient(to right, #f44cc5, #ec40cc, #e335d3, #d72adb, #c922e4)",
                  }}
                  aria-valuenow="75"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <p style={{ color: "#8A8A8A" }}>
                3/4 <span sytle={{ color: "red" }}>Steps Completed</span>
              </p>
              <div className="row" style={{ justifyContent: "space-evenly" }}>
                <p
                  className="col-6"
                  style={{ fontSize: 16, fontWeight: 600, color: "#303030" }}
                >
                  Exam Category :
                  <span style={{ coor: "#000000" }}>{selectedCategoryName1}</span>
                </p>
                <p
                  className="col-6"
                  style={{ fontSize: 16, fontWeight: 600, color: "#303030" }}
                >
                  Exam Sub Category :
                  <span style={{ coor: "#000000" }}> {SubCatName}</span>
                </p>
              </div>
              <div className="row">
                <div className="col-3"></div>
                <div
                  className="card col-6"
                  style={{ justifyConetnt: "center", textAlign: "center" }}
                >
                  <div>
                    <label
                      className="col-12 mb-2 mt-3"
                      style={{
                        textAlign: "start",
                        color: "#303030",
                        fontWeight: "600",
                      }}
                    >
                      Select Subjects
                    </label>
                    <br />
                    <input
                      className="col-12 border-0 mb-3"
                      style={{
                        textAlign: "start",
                        height: "50px",
                        backgroundColor: "#EFEFEF",
                        borderRadius: 7,
                        outline: 1,
                      }}
                      placeholder="  Search for Subject"
                      type="search"
                      value={searchSubCat}
                      onChange={(e) => setSearchSubCat(e.target.value)}
                    />
                    <br />
                  </div>


                  <div
                    style={{
                      height: "160px",
                      overflowY: "scroll",
                      justifyContent: "start",
                      alignItems: "start",
                      marginBottom: ".5rem",
                    }}
                  >


                    {/* {subjectCount?.map((res) => (
        <div className="d-flex mt-3" key={res._id}>
          <input
            style={{ color: "#F6F6F6" }}
            type="checkbox"
            name="Select"
            onChange={() => handleCheckboxChange(res)} 
            checked={compositionSub && compositionSub.some((subject) => subject._id === res._id)}
          />
          <span className="ms-3 fw-bolder">{res.sub_name}</span>
          
        </div>
      ))} */}


                    {subjectCount?.map((res) => (
                      <div className="d-flex mt-3" key={res._id}>
                        <input
                          style={{ color: "#F6F6F6" }}
                          type="checkbox"
                          id={res._id}
                          value={res._id}
                          checked={selectedSubjects?.includes(res._id)}
                          onChange={(e) => handleChangeCheck(e, res)}
                        />
                        <span className="ms-3 fw-bolder">{res.sub_name}</span>

                      </div>
                    ))}


                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                justifyContent: "center",
                textAlign: "center",
                backgroundColor: "",
              }}
            >
              <button
                disabled={subjectCount?.length >= 1 ? false : true}
                className="col-5 border-0 text-white"
                onClick={() => {
                  moveOnToLast();
                  // addvalueToArray();

                }}
                style={{
                  backgroundColor: "#2188E7",
                  height: "40px",
                  borderRadius: 7,
                }}
              >
                Proceed
              </button>
            </div>
          </div>

          {/* Select Question Composition */}

          <div style={{ display: Click === 3 ? "block" : "none" }}>
            <div className="mb-5">
              <div className="progress" style={{ height: "10px" }}>
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{
                    width: "100%",
                    background:
                      "linear-gradient(to right, #f44cc5, #ec40cc, #e335d3, #d72adb, #c922e4)",
                  }}
                  aria-valuenow="100"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <p style={{ color: "#8A8A8A" }}>
                4/4 <span sytle={{ color: "red" }}>Steps Completed</span>
              </p>
              <div className="row" style={{ justifyContent: "space-evenly" }}>
                <p
                  className="col-6"
                  style={{ fontSize: 16, fontWeight: 600, color: "#303030" }}
                >
                  Exam Category :
                  <span style={{ coor: "#000000" }}>{selectedCategoryName1}</span>
                </p>
                <p
                  className="col-6"
                  style={{ fontSize: 16, fontWeight: 600, color: "#303030" }}
                >
                  Exam Sub Category :

                  <span style={{ coor: "#000000" }}>
                    {SubCatName}
                  </span>
                </p>
              </div>
              <div className="row">
                <div className="col-3"></div>
                <div
                  className="card col-6"
                  style={{ justifyConetnt: "center", textAlign: "center" }}
                >
                  <div>
                    <label
                      className="col-12 mb-2 mt-3"
                      style={{
                        textAlign: "start",
                        color: "#303030",
                        fontWeight: "600",
                      }}
                    >
                      Select Question Composition
                    </label>
                    <br />
                  </div>

                  <div className="p-2"
                    style={{ overflowY: "scroll", height: "300px" }}
                  >
                    {compositionSub?.map((Value, index) => {
                      return (
                        <>
                          <div
                            className="mt-3"
                            style={{ textAlign: "start" }}
                          >
                            <div
                              className="d-flex"
                              style={{
                                justifyContent: "space-between",
                              }}
                            >
                              <span
                                className="ms-3 fw-bolder"
                                style={{ fontSize: 14 }}
                              >
                                {Value.sub_name}
                              </span>
                              <span style={{ marginLeft: "190px" }}>
                                {parseInt(Value.value)}%
                              </span>
                              <br />
                            </div>

                            <Slider
                              style={{
                                // accentColor: colors[index % 4],
                                cursor: "pointer",
                              }}
                              key={Value._id}
                              id={Value._id}
                              name={Value.sub_name}
                              value={Value.value}
                              total={compositionSub.reduce(
                                (total, Value) => total + Value.value,
                                0
                              )}
                              onChange={handleChangeName1}
                            />
                          </div>
                        </>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                justifyContent: "center",
                textAlign: "center",
                backgroundColor: "",
              }}
            >

              {

                isSave ? <>
                  <button 
                    className="col-5 border-0 text-white"
                    onClick={() => {
                      funForLastvalidation();

                    }}
                    style={{
                      backgroundColor: "#2188E7",
                      height: "40px",
                      borderRadius: 7,
                    }}
                  >
                    Save Changes
                  </button>

                </> :
                  <>

                    <button
                      className="col-5 border-0 text-white"
                      onClick={() => {
                        Api6()
                      }}
                      style={{
                        backgroundColor: "#2188E7",
                        height: "40px",
                        borderRadius: 7,
                      }}
                    >
                      Publish Changes
                    </button>

                  </>

              }
            </div>
          </div>



        </Offcanvas.Body>
      </Offcanvas>


      <Modal  show={showNotify} onHide={handleCloseNotify} backdrop="static">
  <Modal.Header closeButton>
    <Modal.Title>Send Notification</Modal.Title>
  </Modal.Header>
  <Modal.Body>

    <Form>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>
          <span className="" style={{ fontSize: "20px" }}>
            Title
          </span>
        </Form.Label>
        <Form.Control
          as="textarea"
          rows={1}
          value={buzerTitle}
          onChange={(e) => setBuzerTitle(e.target.value)}
        />
        <Form.Label>
          <span className="" style={{ fontSize: "20px" }}>
            Message
          </span>
        </Form.Label>
        <Form.Control
          as="textarea"
          rows={2}
          value={buzerBody}
          onChange={(e) => setBuzerBody(e.target.value)}
        />
      </Form.Group>
    </Form>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={handleCloseNotify}>
      Close
    </Button>
    <Button variant="primary" onClick={sendBuzerMsg} disabled={buzerLoader}>
      {buzerLoader ? <Spinner /> : "Send"}
    </Button>
  </Modal.Footer>
</Modal>




    </div>
  );
};

export default ActiveQuizeEdit;
