import { useRef, useMemo } from "react";
import JoditEditor from "jodit-react";

const Editor = ({ content1, setContent1 }) => {
  const placeholder = "Type your blog here...";
  const editor = useRef(null);

  const config = useMemo(() => {
    return {
      readonly: false,
      placeholder: placeholder || "Start typing...",
    };
  }, [placeholder]);

  // Check if content1 is a string, if not convert it to a string
  const value = typeof content1 === "string" ? content1 : "";

  return (
    <JoditEditor
      ref={editor}
      value={value}
      config={config}
      tabIndex={1}
      onChange={(newContent) => setContent1(newContent)}
      defaultValue=""
    />
  );
};

export default Editor;
