import { useState, useEffect, useContext } from "react";
import Image1 from "../../Img/ic_round-category.svg";
import Image2 from "../../Img/mdi_subtasks.svg";
import Image3 from "../../Img/bb coin 1.svg";
import Image4 from "../../Img/carbon_trophy-filled.svg";
import Image5 from "../../Img/mdi_clock-outline.svg";
import SearchIcon from "../../Img/ri_search-line.svg";
import LoaderComponent from "../Utils/LoaderComponent";
import TriviaHistory from "./TriviaHistory";
import Offcanvas from "react-bootstrap/Offcanvas";
import { toast } from "react-toastify";
import Wallet from "../../Img/Wallet.svg";
import EmptyContainer from "../Utils/EmptyContainer";
import { AuthContext, BASE_URL, IMAGE_URL, QUIZ_BASE_URL } from "../Utils/AuthContextProvider";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import moment from "moment/moment";
import { NavLink, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import Dates from "../../Img/Date.svg";
import Slider from "./Slider";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { Button } from "react-bootstrap";


const initLoading = {
  isLoading: false,
  isError: false,
};
const initHeader = {
  name: "",
  icon: "",
};






const FreeTrivaEdit = (props) => {
  const getCurrentDate1 = () => {
    const now = new Date();
    now.setDate(now.getDate() - 1); // Decrease one day

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  const [fromDate1, setFromDate1] = useState(getCurrentDate1());
  const [toDate1, setToDate1] = useState(getCurrentDate1());
  const [Overview, setOverview] = useState("");   // setOverview("overview")

  const [startDate, setStartDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [Rule, setRule] = useState("");
  const [Reward, setReward] = useState("");
  const [Question, setQuestion] = useState("");
  const [QuestionTime, setQuestionTime] = useState("");
  const [Chage2, setChage2] = useState(0);
  const [Chage3, setChage3] = useState(0);
  const [Chage4, setChage4] = useState(0);
  const [Chage5, setChage5] = useState(0);
  const [ChangesNo, setChangesNo] = useState("");
  const [loader, setLoader] = useState(initLoading);
  const { isLoading, isError } = loader;
  const [HistryData, setHistryData] = useState([]);
  const [last_page, setLastPage] = useState(null);
  const [header, setHeader] = useState(initHeader);
  const [Datas, setDatas] = useState([]);
  const [Data1, setData1] = useState([]);
  const [Edit31, setEdit31] = useState([]);
  const [Edit4, setEdit4] = useState("");
  const [Edit5, setEdit5] = useState("");
  const [Edit6, setEdit6] = useState("");
  const [Edit7, setEdit7] = useState("");
  const [BannerShow1, setBannerShow1] = useState("");
  const [Fire2, setFire2] = useState("");
  const [Quizname, setQuizName] = useState("")
  const [Fire3, setFire3] = useState("");
  const [Fire4, setFire4] = useState("");
  const [Fire5, setFire5] = useState({});
  const [Fire6, setFire6] = useState("");
  const [schduleTime, setSchduleTime] = useState("");
  const [Token, setToken] = useState("");


  // NEW STATES
  const { fromDate, toDate } = useContext(AuthContext)
  const [Data, setData] = useState([]);
  const Navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchFeild, setSearchField] = useState("");
  const [Click, setClick] = useState(0);
  const [editData, setEditData] = useState({})
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [QuizId, setQuizId] = useState("");
  const handleShow = () => setShow(true);
  const [Edit3, setEdit3] = useState([]);
  const [BannerShow, setBannerShow] = useState("");
  const [bannerImage, setbannerImage] = useState("");
  const [compData, setCompData] = useState([]);
  const [showSubjects, setShowSubs] = useState([])
  const [filterCategory1, setFilterCategory1] = useState([]);
  const [cat_search, set_cat_search] = useState("")
  const [sub_cat_search, set_sub_cat_search] = useState("")
  const [selectedCategoryName1, setSelectedCategoryName1] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [filterSubCatrgory, setFilterSubCatrgory] = useState([]);
  const [selectedSubId, setSelectedSubId] = useState("");
  const [selectedSubName, setSelectedSubName] = useState("");


  const [subject_search, set_sub_search] = useState("");
  const [subjectsData, setSubjectsData] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [isSave, setIsSave] = useState(true);

  const [subjectsComposition, setSubjectsComposition] = useState([]);
  const [ArrForm, setArrForm] = useState();
  const [lastPage, setLast_Page] = useState(null);
  const {
    currentPageHistory,
    setCurrentPageHistory,
    
  } = useContext(AuthContext);
  const { triviaRefresh, settriviaRefresh } = useContext(AuthContext);

  const OnClickfun = () => {
    const outputObject = {};
    subjectsComposition.forEach((item) => {
      outputObject[item._id] = Number(item.value);
    });

    setArrForm(outputObject);
    setIsSave(false)
  };



  const Histry = async (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")} `);

    const raw = JSON.stringify({
      "quiz_id": Fire4,
      "fromDate": fromDate1,
      "toDate": toDate1,
      "page": currentPageHistory,
      "limit": 10
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    try {
      const response = await fetch(`${QUIZ_BASE_URL}/trivia/view/history/of/triviaquiz`, requestOptions);
      const result = await response.json();
      if (result.status === 1) {
        setHistryData(result.data2);
        setLastPage(result.last_page);
        console.log(result.data2)
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      // setIsLoading(false);
    }
  };

  

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };


  const getDetails = async (quiz_id) => {
    setLoader({
      ...loader,
      isLoading: true,
    });


    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${QUIZ_BASE_URL}/trivia/view/details?id=${quiz_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setLoader({
          ...loader,
          isLoading: false,
        })

        if (result?.status == 1) {
          setEditData(result?.data)
          setShowSubs(result?.data?.subjects)
          setEdit3(result?.data?.rules);
          setBannerShow(result?.data?.banner)
          setOverview("Overview")
          setQuizId(quiz_id)
          setSelectedCategoryId(result?.data?.category_id)
          setSelectedSubId(result?.data?.sub_cat_id)

        } else if (result.status == "TOKEN_ERR") {
          localStorage.removeItem("api_url");
          Navigate("/");
          toast("Logged in other device! Please login again");
        } else {
          console.log(result.Backend_Error);
        }
      })
      .catch((error) => console.log("error", error));

  }

  const getDetails1 = async (quiz_id) => {

    setLoader({
      ...loader,
      isLoading: true,
    });


    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${QUIZ_BASE_URL}/trivia/view/details?id=${quiz_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setLoader({
          ...loader,
          isLoading: false,
        })

        if (result?.status === 1) {


          setData1(result?.data);
          setEdit31(result?.data?.rules);
          setEdit4(result?.data?.slots);
          // setStartDate(result?.data?.sch_time)
          setChangesNo(result?.data?.repeat);
          // setEdit7(Data?.data[0].question_time);
          setEdit5(result?.data?.reward);
          setEdit6(result?.data?.total_num_of_quest);
          setEdit7(result?.data?.time_per_question);
          setBannerShow1(result.data?.banner);
          // setFire1(Data?.data[0].quizze_image);qa
          setFire2(result?.data?.category_name);
          setFire3(result?.data?.subcategory_name);
          setQuizName(result?.data?.quiz_name)
          setFire4(result?.data?._id);
          setFire6(result?.data?.image);
          console.log(setFire6)
          setSchduleTime(result?.data?.sch_time);
          setFire5(result?.data?.question_composition);
        } else if (result.status == "TOKEN_ERR") {
          localStorage.removeItem("api_url");
          Navigate("/");
          toast("Logged in other device! Please login again");
        } else {
          console.log(result.Backend_Error);
        }
      })
      .catch((error) => console.log("error", error));

  }



  console.log("data1", Data1);

  const getData = async () => {
    setLoader({
      ...loader,
      isLoading: true,
    });


    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${QUIZ_BASE_URL}/trivia/get/quizz?searchQuery=${searchFeild}&page=${currentPage}&limit=${limit}&fromDate=${moment(fromDate).format("DD-MM-YYYY")}&toDate=${moment(toDate).format("DD-MM-YYYY")}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setLoader({
          ...loader,
          isLoading: false,
        })

        if (result?.status == 1) {
          setData(result?.data2);
          setTotalPages(result?.totalPages);
        } else if (result.status == "TOKEN_ERR") {
          localStorage.removeItem("api_url");
          Navigate("/");
          toast("Logged in other device! Please login again");
        } else {
          console.log(result.Backend_Error);
        }
      })
      .catch((error) => console.log("error", error));
  };




  // GET APIS

  const getCategories = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("token")}`
      );

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `${QUIZ_BASE_URL}/formfill/get/all/category?search=${cat_search}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status == 1) {
            setFilterCategory1(result.categories);
          }
        })
        .catch((error) => console.log("error", error));
    } catch (e) { }
  };


  const getSubCategories = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("token")}`
      );

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `${QUIZ_BASE_URL}/formfill/get/all/subcategory?search=${sub_cat_search}&cat_id=${selectedCategoryId}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result?.status == 1) {
            setFilterSubCatrgory(result?.sub_categories);
          }
        })
        .catch((error) => console.log("error", error));
    } catch (e) { }
  };



  const getSubjects = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("token")}`
      );
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        "sub_ids": selectedSubId
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: raw
      };

      fetch(
        `${QUIZ_BASE_URL}/formfill/get/all/subjects/from/subcategories?search=${subject_search}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result?.status == 1) {
            setSubjectsData(result?.data);
          }
        })
        .catch((error) => console.log("error", error));
    } catch (e) { }
  };



  const editTriviaApi = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("token")}`
      );
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        "quiz_id": QuizId,
        "category_id": selectedCategoryId,
        "subjects_id": selectedSubjects,
        "sub_cat_id": selectedSubId,
        "question_composition": ArrForm
      });


      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch(`${QUIZ_BASE_URL}/trivia/changes/category`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result?.status == 1) {
            toast.success("Trivia Updated Successfully")
            getDetails(QuizId)
            setSelectedSubjects([])
            setSubjectsComposition([])
            setArrForm()
            setClick(0)
            setIsSave(true)
          }
        })
        .catch((error) => console.log("error", error));
    } catch (e) { }
  };


  // EDIT APIS


  const EditRulesApi = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")} `);

    const raw = JSON.stringify({
      "id": QuizId,
      "rules": Edit3,
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${QUIZ_BASE_URL}/trivia/change/rules`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 1) {
          toast.success(result.message);
          getDetails(QuizId)
        } else {
          toast.error(result.Backend_Error);
        }
      })
      .catch((error) => console.error(error));
  }


  // Finction to get rules 
  const handleRuleChange = (index, value) => {
    const newEdit3 = [...Edit3];
    newEdit3[index] = value;
    setEdit3(newEdit3);
  };

  const handleAddRule = () => {
    setEdit3([...Edit3, ""]);
  };

  const handleRemoveRule = (index) => {
    const newEdit3 = [...Edit3];
    newEdit3.splice(index, 1);
    setEdit3(newEdit3);
  };



  const EditBannerImgApi = () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);


    const formdata = new FormData();
    formdata.append("id", QuizId);
    formdata.append("banner", bannerImage);

    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
      headers: myHeaders,
    };

    fetch(`${QUIZ_BASE_URL}/trivia/change/banner`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // Check the status code and show appropriate toast message
        if (result.status === 1) {
          toast.success(result.message);
          getDetails(QuizId)

        } else {
          toast.error(result.Backend_Error);
        }
      })
      .catch((error) => console.error(error));
  }



  const editRewardApi = () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "id": QuizId,
      "reward": editData?.reward
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${QUIZ_BASE_URL}/trivia/update/reward`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // Check the status code and show appropriate toast message
        if (result.status === 1) {
          toast.success(result.message);
          getDetails(QuizId)

        } else {
          toast.error(result.Backend_Error);
        }
      })
      .catch((error) => console.error(error));
  }


  const EditToalQuesApi = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")} `);

    const raw = JSON.stringify({
      "quiz_id": QuizId,
      "numberofquestion": editData?.total_num_of_quest,
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };
    fetch(`${QUIZ_BASE_URL}/trivia/changes/numberof/quiz`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // Check the status code and show appropriate toast message
        if (result.status === 1) {
          toast.success(result.message);
          getDetails(QuizId)
        } else {
          toast.error(result.Backend_Error);
        }
      })
      .catch((error) => console.error(error));
  }


  const EditTimePerQuesApi = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")} `);

    const raw = JSON.stringify({
      "id": QuizId,
      "time_per_question": editData?.time_per_question,
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${QUIZ_BASE_URL}/trivia/changes/time/perquestion`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // Check the status code and show appropriate toast message
        if (result.status === 1) {
          toast.success(result.message);
          getDetails(QuizId)
        } else {
          toast.error(result.Backend_Error);
        }
      })
      .catch((error) => console.error(error));
  }

  const repeatApi = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")} `);

    const raw = JSON.stringify({
      "id": QuizId,
      "repeat": editData?.repeat,
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${QUIZ_BASE_URL}/trivia/changes/repeat`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 1) {
          toast.success(result.message);
          getDetails(QuizId)
        } else {
          toast.error(result.Backend_Error);
        }
      })
      .catch((error) => console.error(error));
  }


  const minRewardPer = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")} `);

    const raw = JSON.stringify({
      "id": QuizId,
      "min_reward_per": editData?.min_reward_per,
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${QUIZ_BASE_URL}/trivia/change/min/reward`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 1) {
          toast.success(result.message);
          getDetails(QuizId)
        } else {
          toast.error(result.Backend_Error);
        }
      })
      .catch((error) => console.error(error));
  }


  //  Edit Handlers

  const handleRadioClick = (categoryId, category_name) => {
    setSelectedCategoryId(categoryId);
    setSelectedCategoryName1(category_name);
  };

  const handleSubCatId = (subId, subName) => {
    setSelectedSubId(subId);
    setSelectedSubName(subName);
  };

  const handleChangeCheck = (e, res) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedSubjects((prev) => [...prev, value]);
      setSubjectsComposition((prev) => [...prev, { ...res, value: 0 }])
    } else {
      setSelectedSubjects((prev) => prev.filter((item) => item !== value));
      setSubjectsComposition((prev) => prev.filter((item) => item._id !== value));
    }
  };

  function compositionHandler(id, value) {
    const newtag = subjectsComposition.map((tag) => {
      if (tag._id == id) {
        return { ...tag, value };
      } else {
        return tag;
      }
    });

    setSubjectsComposition(newtag);
  }

  const validationComposition = () => {

    let sum = subjectsComposition.reduce((total, Value) => total + Value.value, 0);

    if (sum !== 100) {
      toast("Sum of % must be 100%");
    }
    else if (subjectsComposition.some(subject => subject.value === 0)) {
      toast("All subjects should have a value greater than 0%");
    }
    else {
      OnClickfun()
    }
  };




  useEffect(() => {
    if (fromDate && toDate) {
      getData();
    }
  }, [currentPage, limit, fromDate, toDate]);

  useEffect(() => {
    if (
      searchFeild == ""
    ) {
      getData();
    }
  }, [searchFeild, triviaRefresh]);

  // Serach UseEffects
  useEffect(() => {
    getCategories()
  }, [cat_search]);

  useEffect(() => {
    getSubCategories()
  }, [sub_cat_search]);

  useEffect(() => {
    getSubjects()
  }, [subject_search]);


  useEffect(() => {
    if (schduleTime) {
      const [dateString, timeString] = schduleTime.split(" ");
      const [month, day, year] = dateString.split('-').map(Number);
      const [hours, minutes] = timeString.split(':').map(Number);

      const presetDate = new Date(year, month - 1, day);
      setStartDate(presetDate);

      const presetTime = new Date();
      presetTime.setHours(hours);
      presetTime.setMinutes(minutes);
      setStartTime(presetTime);
    }
  }, [schduleTime]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    setOverview("Overview");
    getDetails1();
  }, []);


  useEffect(() => {
    if (Overview === "History") {
      Histry(QuizId, currentPage);
    }
  }, [Overview, fromDate1, toDate1, currentPage]);
  return (
    <div>
      <div className="row"
        style={{
          height: "6vh",
          overflow: "hidden"
        }}>

        <div className="col-6 d-flex">
          <div className="col-8">
            <h5 className="mt-1">
              Showing total <span style={{ color: "red" }}>{Data?.length} </span>
              Free Trivia
            </h5>
          </div>
          <div className="col-4">
            <button
              onClick={props.Handel}
              style={{
                backgroundColor: "#EDF7FF",
                border: "1px solid #2188E7",
                height: "40px",
                borderRadius: "10px",
                color: "#2188E7",
              }}
            >
              <span className="p-3"> + Add Trivia</span>
            </button>
          </div>
        </div>
        <div className="col-6">
          <div className="row">
            <div className="col-4"></div>
            <div className="col-6">
              <div
                className="row"
                style={{
                  border: "1px solx #D9D9D9",
                  height: "40px",
                  borderRadius: "7px",
                }}
              >
                <div className="col-2 mt-1">
                  <img alt="SearchIcon" src={SearchIcon} />
                </div>
                <div className="col-10 mt-1 ">
                  <input
                    className="border-0 w-100"
                    placeholder="Search for Trivia"
                    value={searchFeild}
                    style={{
                      textDecoration: "none",
                      outline: "none",
                      color: "#272727",
                    }}
                    onChange={(e) => setSearchField(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="col-2">
              <button
                className="border-0 bg-white"
                style={{
                  background:
                    "linear-gradient(180deg, rgba(35,176,101,1) 0%, rgba(46,135,96,1) 100%)",
                  color: "#fff",
                  height: "40px",
                  borderTopRightRadius: 10,
                  borderBottomRightRadius: 10,
                  marginLeft: "-20px",
                }}
                onClick={() => {
                  setCurrentPage(1);
                  getData();
                }}
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>

      <section
        className="row"
        style={{
          height: "60vh",
          overflowY: "scroll",

        }}
      >
        {isLoading ? (
          <LoaderComponent />
        ) : (Data?.length === 0 ? <EmptyContainer message='No Data Found' actionName='Fetch All Data' /> :
          Data?.map((res, key) => {
            return (
              <>
                <div className="col-md-4 col-sm-12 col-xl-4 mt-3" key={key}>
                  <div className="card">
                    <div className="d-flex">
                      <div className="col-2">
                        <img
                          className="ms-3 mt-3"
                          alt="tittle"
                          src={`${IMAGE_URL}${res?.banner}`}
                          style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                          }}
                        />
                      </div>
                      <span className="col-10 ">
                        <p
                          className="mt-3"
                          style={{
                            color: "#000000",
                            fontSize: 18,
                            fontWeight: "bold",
                          }}
                        >
                          {res?.quiz_name}

                        </p>
                      </span>
                    </div>
                    <div
                      className="d-flex ms-5 mb-1"
                      style={{ marginTop: "-10px" }}
                    >
                      <p className="mt-1">Repetition</p>
                      <button
                        className="border-0 ms-3  rounded"
                        style={{
                          width: "90px",
                          height: "30px",
                          backgroundColor: "#E9FFF5",
                          color: "#188241",
                        }}
                      >
                        {/* {res?.repeat == 0
                          ? "No Repeat"
                          : res.repeat + " mins"} */}
                        {res?.repeat}
                      </button>
                    </div>

                    <hr style={{ marginTop: "-10px" }} />

                    <div>
                      <div className="d-flex">
                        <img className="ms-3 mb-2" src={Image5} alt="" />
                        <p className="mt-1 ms-3">{res?.sch_time}</p>
                      </div>
                      <div className="container" style={{ marginTop: "-20px" }}>
                        <div className="d-flex">
                          <img style={{}} alt="#" src={Image1} />
                          <p
                            className="ms-2"
                            style={{
                              color: "#000000",
                              fontSize: 16,
                              fontWeight: "bold",
                              marginTop: "11PX",
                            }}
                          >
                            {res?.category_name}
                          </p>
                        </div>
                        <div className="ms-5 d-flex">
                          <img
                            style={{ marginTop: "-50px" }}
                            alt="#"
                            src={Image2}
                          />
                          <p
                            style={{
                              color: "#000000",
                              fontSize: 12,
                              fontWeight: "bold",
                              marginTop: "-10px",
                            }}
                          >
                            {res?.subcategory_name}
                          </p>
                        </div>
                        <div className="d-flex">
                          <img alt="#" src={Image3} />
                          <p className="mt-2 ms-2">{res?.reward}</p>
                        </div>
                        <div className="d-flex">
                          <img alt="#" src={Image4} />
                          <p className="mt-2 ms-2">{res?.min_reward_per}%</p>
                        </div>


                        <div
                          className="row"
                          style={{ justifyContent: "space-around" }}
                        >
                          <button
                            className="col-5 mb-3 border-0 rounded"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasRight7"
                            aria-controls="offcanvasRight7"
                            style={{
                              color: "#fff",
                              backgroundColor: "#333333",
                              height: "35px",
                            }}
                            onClick={() => {
                              getDetails1(res?._id);
                            }}
                          >
                            View Details
                          </button>

                          <button
                            className="col-5 mb-3 border-0 rounded"
                            // data-bs-toggle="offcanvas"
                            // data-bs-target="#offcanvasRight7"
                            // aria-controls="offcanvasRight7"
                            style={{
                              color: "#000000",
                              backgroundColor: "#EFEFEF",
                              height: "35px",
                              fontWeight: 500,
                            }}
                            onClick={() => {

                              getDetails(res?._id);
                              handleShow()

                            }}
                          >
                            Edit
                          </button>


                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })
        )}
      </section>

      <div className="mt-2">
        <Stack spacing={2} className="d-flex justify-content-center mt-3 align-items-center" >
          <Pagination count={totalPages} page={currentPage} onChange={handleChange} />
        </Stack>
      </div>




      {/* offcanvas for edit particular freetriviA */}
      <div
        className="offcanvas offcanvas-end w-50"
        tabindex="-1"
        id="offcanvasRight8"
        aria-labelledby="offcanvasRightLabe8"
        style={{ borderTopLeftRadius: 30 }}
      >
        <div className="offcanvas-header">
          <span
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            style={{ color: "#000", fontWeight: "600" }}
            onClick={() => {
              setOverview("Overview");
              setHeader(initHeader);
            }}
          >
            <svg
              width="35"
              height="35"
              viewBox="0 0 35 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M27.4913 9.40793C27.616 9.28337 27.7149 9.13547 27.7825 8.97267C27.85 8.80987 27.8849 8.63535 27.885 8.45909C27.8851 8.28283 27.8505 8.10827 27.7831 7.94539C27.7158 7.7825 27.617 7.63448 27.4924 7.50977C27.3679 7.38505 27.22 7.2861 27.0572 7.21854C26.8944 7.15099 26.7199 7.11617 26.5436 7.11606C26.3673 7.11595 26.1928 7.15056 26.0299 7.21791C25.867 7.28527 25.719 7.38404 25.5943 7.5086L17.4999 15.6029L9.40793 7.5086C9.15607 7.25673 8.81446 7.11523 8.45827 7.11523C8.10207 7.11523 7.76047 7.25673 7.5086 7.5086C7.25673 7.76047 7.11523 8.10207 7.11523 8.45827C7.11523 8.81446 7.25673 9.15607 7.5086 9.40793L15.6029 17.4999L7.5086 25.5919C7.38389 25.7166 7.28496 25.8647 7.21747 26.0276C7.14997 26.1906 7.11523 26.3652 7.11523 26.5416C7.11523 26.718 7.14997 26.8926 7.21747 27.0556C7.28496 27.2185 7.38389 27.3666 7.5086 27.4913C7.76047 27.7431 8.10207 27.8846 8.45827 27.8846C8.63464 27.8846 8.80928 27.8499 8.97222 27.7824C9.13517 27.7149 9.28322 27.616 9.40793 27.4913L17.4999 19.3969L25.5943 27.4913C25.8461 27.7428 26.1876 27.884 26.5436 27.8838C26.8996 27.8836 27.2409 27.742 27.4924 27.4901C27.744 27.2382 27.8852 26.8967 27.885 26.5408C27.8848 26.1848 27.7431 25.8435 27.4913 25.5919L19.3969 17.4999L27.4913 9.40793Z"
                fill="black"
              />
            </svg>
          </span>
          <p style={{ fontSize: 20 }}>The changes will reflected on 12:01  AM</p>
        </div>
        <div className="offcanvas-body m-2">

          <h1>sdfghjk</h1>
          {/* {Data1?.map((res, key) => {
            return (
              <>
                <div key={key}>
                  <div className="row">
                    <div className="col-2">
                      <img
                        alt="#"
                        src={res.category.category_image}
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                        }}
                      />
                    </div>
                    <div className="col-8">
                      <p style={{ fontSize: 20, fontWeight: 600 }}>
                        {res.category.category_name}{" "}
                        {res.subcategory.subcategory}
                      </p>
                    </div>
                    <button
                      className="col-2 border-0 rounded"
                      style={{ backgroundColor: "#EAF5FF", color: "#2188E7" }}
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                      onClick={() => functionSave(Fire4)}
                    >
                      Save
                    </button>
                  </div>
                  <div className="row mt-2">
                    <span className="col-1"></span>
                    <span className="col-1">
                      <p
                        style={{
                          fontSize: 16,
                          fontWeight: 600,
                          color: "#8A8A8A",
                        }}
                      >
                        Repetition
                      </p>
                    </span>
                    <button
                      className="col-2 ms-5 rounded border-0"
                      style={{ backgroundColor: "#E9FFF5", color: "#188241" }}
                    >
                      5 Mins
                    </button>
                    <button
                      className="col-2 rounded border-0 ms-1"
                      style={{ color: "#FFBD00" }}
                    >
                      {res.total_question}
                    </button>
                    <button
                      className="col-2 rounded border-0 ms-1"
                      style={{ color: "#C922E4" }}
                    >
                      {res.rewards}
                    </button>
                    <button
                      className="col-2 rounded border-0 ms-1"
                      style={{ color: "#2188E7" }}
                    >
                      2560/12500
                    </button>
                  </div>

                  <div className="row">
                    <span
                      className="col-2"
                      onClick={() => {
                        setOverview("Overview");
                      }}
                      style={
                        Overview === "Overview"
                          ? { fontSize: 16, fontWeight: 600, cursor: "pointer" }
                          : { cursor: "pointer" }
                      }
                    >
                      Overview
                    </span>
                    <span
                      className="col-2"
                      onClick={() => {
                        setOverview("History");
                      }}
                      style={
                        Overview === "History"
                          ? { fontSize: 16, fontWeight: 600, cursor: "pointer" }
                          : { cursor: "pointer" }
                      }
                    >
                      History
                    </span>
                  </div>
                  <div className="row">
                    <div
                      className="col-2 mt-2"
                      style={{
                        borderBottom:
                          Overview === "Overview" ? "1px solid #000000" : "",
                      }}
                    ></div>
                    <div
                      className="col-2"
                      style={{
                        borderBottom:
                          Overview === "History" ? "1px solid #000000" : "",
                      }}
                    ></div>
                  </div>
                  <hr style={{ marginTop: "0px" }} />

                  {Overview === "Overview" ? (
                    <>
                      <div className="row">
                        <p
                          className="col-3 mt-1"
                          style={{
                            fontSize: 16,
                            fontWeight: 600,
                            color: "#303030",
                          }}
                        >
                          Exam Category
                        </p>
                      </div>
                      <div className="row">
                        <img
                          className="col-2"
                          alt="#"
                          src={res.category.category_image}
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                          }}
                        />
                        <input
                          className="col-8 border-0"
                          style={{ fontSize: 20, fontWeight: "600" }}
                          placeholder={res.category.category_name}
                          disabled={true}
                        />
                      </div>
                      <div className="row mt-2">
                        <p
                          className="col-4 mt-1"
                          style={{
                            fontSize: 16,
                            fontWeight: 600,
                            color: "#303030",
                          }}
                        >
                          Exam Sub Category
                        </p>
                      </div>
                      <div className="row">
                        <input
                          className="col-8 border-0"
                          style={{ fontSize: 20, fontWeight: "600" }}
                          placeholder={res.subcategory.subcategory}
                          disabled={true}
                        />
                      </div>
                      <div className="row mt-4">
                        <p
                          className="col-4 mt-1"
                          style={{
                            fontSize: 16,
                            fontWeight: 600,
                            color: "#303030",
                          }}
                        >
                          Question Composition
                        </p>
                      </div>

                      {Fire5?.map((res) => {
                        return (
                          <>
                            <div className="d-flex">
                              <p
                                className="col-5"
                                style={{
                                  fontSize: 16,
                                  fontWeight: 600,
                                  color: "#303030",
                                }}
                              >
                                {res.name}
                              </p>
                              <p className="col-1">{res.value}%</p>
                            </div>

                            <Slider
                              key={res.id}
                              id={res.id}
                              name={res.name}
                              value={res.value}
                              total={Fire5.reduce(
                                (total, Value) => total + Value.value,
                                0
                              )}
                              onChange={handleChangeName}
                            />
                          </>
                        );
                      })}

                 
                      <div className="row mt-4">
                        <p
                          className="col-2 mt-1"
                          style={{
                            fontSize: 16,
                            fontWeight: 600,
                            color: "#303030",
                          }}
                        >
                          Rules
                        </p>
                      </div>
                      <textarea
                        className="col-6 rounded"
                        disabled={false}
                        style={{ height: "200px" }}
                        value={Rule}
                        onChange={(e) => {
                          setRule(e.target.value);
                        }}
                      />
                      <div className="row mt-4">
                        <p
                          className="col-3 mt-1"
                          style={{
                            fontSize: 16,
                            fontWeight: 600,
                            color: "#303030",
                          }}
                        >
                          Banner Image
                        </p>
                        <label
                          className="filelabel2 col-2"
                          style={{ backgroundColor: "#F5F5F5" }}
                          onClick={() => {
                            setbannerImage("");
                          }}
                        >
                          <span className="title2">Change</span>
                          <input
                            className="FileUpload2"
                            id="FileInput2"
                            name="booking_attachment2"
                            type="file"
                            accept=".png,.jpg"
                            onChange={(e) => {
                              setbannerImage(e.target.files[0]);
                              setBannerShow(
                                URL.createObjectURL(e.target.files[0])
                              );
                            }}
                          />
                        </label>
                      </div>
                      <div className="col-6 rounded">
                        <img
                          className="w-100 rounded"
                          alt=""
                          src={BannerShow}
                          style={{ height: "200px" }}
                        />
                      </div>
                      <div className="row mt-4">
                        <p
                          className="col-3 mt-1"
                          style={{
                            fontSize: 16,
                            fontWeight: 600,
                            color: "#303030",
                          }}
                        >
                          Reward
                        </p>
                      </div>
                      <input
                        className="col-6 "
                        type="number"
                        style={{
                          fontSize: 20,
                          fontWeight: "600",
                          backgroundColor: "#",
                        }}
                        disabled={false}
                        value={Reward}
                        onChange={(e) => {
                          setReward(e.target.value);
                        }}
                      />
                      <div className="row mt-4">
                        <p
                          className="col-4 mt-1"
                          style={{
                            fontSize: 16,
                            fontWeight: 600,
                            color: "#303030",
                          }}
                        >
                          Total Number of Question
                        </p>
                      </div>
                      <input
                        className="col-6 "
                        type="number"
                        style={{
                          fontSize: 20,
                          fontWeight: "600",
                          backgroundColor: "#",
                        }}
                        disabled={false}
                        value={Question}
                        onChange={(e) => {
                          setQuestion(e.target.value);
                        }}
                      />
                      <div className="row mt-4">
                        <p
                          className="col-4 mt-1"
                          style={{
                            fontSize: 16,
                            fontWeight: 600,
                            color: "#303030",
                          }}
                        >
                          Time Per Question :- {QuestionTime}
                        </p>
                      </div>
                      <input
                        className="col-6 "
                        type="range"
                        min={10}
                        max={60}
                        disabled={false}
                        value={QuestionTime}
                        onChange={(e) => {
                          setQuestionTime(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  {Overview === "History" ? (
                    <>
                      <TriviaHistory
                        header={header}
                        last_page={last_page}
                        Data={HistryData}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            );
          })} */}
        </div>
      </div>

      {/* for view deatils trivia */}
      <div
        class="offcanvas offcanvas-end w-50"
        tabindex="-1"
        id="offcanvasRight7"
        aria-labelledby="offcanvasRightLabel"
        style={{ borderTopLeftRadius: 30 }}
      >
        <div class="offcanvas-header">
          <span
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            style={{ color: "#000", fontWeight: "600" }}
            onClick={() => setOverview("Overview")}
          >
            <svg
              width="35"
              height="35"
              viewBox="0 0 35 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M27.4913 9.40793C27.616 9.28337 27.7149 9.13547 27.7825 8.97267C27.85 8.80987 27.8849 8.63535 27.885 8.45909C27.8851 8.28283 27.8505 8.10827 27.7831 7.94539C27.7158 7.7825 27.617 7.63448 27.4924 7.50977C27.3679 7.38505 27.22 7.2861 27.0572 7.21854C26.8944 7.15099 26.7199 7.11617 26.5436 7.11606C26.3673 7.11595 26.1928 7.15056 26.0299 7.21791C25.867 7.28527 25.719 7.38404 25.5943 7.5086L17.4999 15.6029L9.40793 7.5086C9.15607 7.25673 8.81446 7.11523 8.45827 7.11523C8.10207 7.11523 7.76047 7.25673 7.5086 7.5086C7.25673 7.76047 7.11523 8.10207 7.11523 8.45827C7.11523 8.81446 7.25673 9.15607 7.5086 9.40793L15.6029 17.4999L7.5086 25.5919C7.38389 25.7166 7.28496 25.8647 7.21747 26.0276C7.14997 26.1906 7.11523 26.3652 7.11523 26.5416C7.11523 26.718 7.14997 26.8926 7.21747 27.0556C7.28496 27.2185 7.38389 27.3666 7.5086 27.4913C7.76047 27.7431 8.10207 27.8846 8.45827 27.8846C8.63464 27.8846 8.80928 27.8499 8.97222 27.7824C9.13517 27.7149 9.28322 27.616 9.40793 27.4913L17.4999 19.3969L25.5943 27.4913C25.8461 27.7428 26.1876 27.884 26.5436 27.8838C26.8996 27.8836 27.2409 27.742 27.4924 27.4901C27.744 27.2382 27.8852 26.8967 27.885 26.5408C27.8848 26.1848 27.7431 25.8435 27.4913 25.5919L19.3969 17.4999L27.4913 9.40793Z"
                fill="black"
              />
            </svg>
          </span>
          {Overview == "History" &&
            <div
              className="col-8 ms-1 "

              style={{
                display: "flex",
                background:
                  "linear-gradient(180deg, rgba(33,184,103,1) 0%, rgba(47,132,96,1))",
                height: "40px",
                borderRadius: "7px",
                color: "#fff",
                width: "auto",
                alignItems: "center",
                gap: 5
              }}
            >


              <input
                value={fromDate1}
                onChange={(e) => {
                  const selectedDate = new Date(e.target.value);
                  const currentDate = new Date();

                  if (
                    selectedDate.getFullYear() < currentDate.getFullYear() ||
                    (selectedDate.getFullYear() === currentDate.getFullYear() &&
                      selectedDate.getMonth() < currentDate.getMonth()) ||
                    (selectedDate.getFullYear() === currentDate.getFullYear() &&
                      selectedDate.getMonth() === currentDate.getMonth() &&
                      selectedDate.getDate() < currentDate.getDate())
                  ) {
                    setFromDate1(e.target.value);
                  } else {
                    toast("Please select a past date");
                  }
                }}
                className="border-0"
                type="date"
                style={{
                  background:
                    "linear-gradient(180deg, rgba(33,184,103,1) 0%, rgba(47,132,96,1))",
                  color: "white"
                }}

              />
              -
              <input
                value={toDate1}
                onChange={(e) => {
                  const selectedDate = new Date(e.target.value);
                  const currentDate = new Date();

                  if (
                    selectedDate.getFullYear() < currentDate.getFullYear() ||
                    (selectedDate.getFullYear() === currentDate.getFullYear() &&
                      selectedDate.getMonth() < currentDate.getMonth()) ||
                    (selectedDate.getFullYear() === currentDate.getFullYear() &&
                      selectedDate.getMonth() === currentDate.getMonth() &&
                      selectedDate.getDate() < currentDate.getDate())
                  ) {
                    setToDate1(e.target.value);
                  } else {
                    toast("Please select a past date");
                  }
                }}
                className="border-0"
                type="date"
                style={{
                  background:
                    "linear-gradient(180deg, rgba(33,184,103,1) 0%, rgba(47,132,96,1))",
                  color: "white"
                }}

              />



            </div>

          }
        </div>
        <div class="offcanvas-body m-2">
          {/* //mapping for particular Quiz */}

          <div className="">
            <div className="row ">
              <div className="col-1">
                <img
                  alt="#"
                  src={`${IMAGE_URL}${Fire6}`}
                  style={{
                    width: "40px",
                    height: "40px",
                    objectFit: "cover",
                    borderRadius: "50%",
                  }}
                />
              </div>
              <div className="col-6">
                <p style={{ fontSize: 20, fontWeight: 600 }}>
                  {Quizname}
                  {/* {Fire2}
                  &nbsp;
                  {Fire3} */}
                </p>
              </div>

              {/* <button
                className="col-2 border-0 rounded"
                style={{ backgroundColor: "#E9FFF5", color: "#188241" }}
                onClick={() => functionSave(Fire4)}
              >
                Save
              </button> */}
            </div>
            <div className="row mt-2">
              <span className="col-1"></span>
              <span className="col-1">
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 600,
                    color: "#8A8A8A",
                  }}
                >
                  Repetition
                </p>
              </span>
              <button
                className="col-2 ms-5 rounded border-0"
                style={{ backgroundColor: "#E9FFF5", color: "#188241" }}
              >
                {Data1?.repeat === 0
                  ? "No Repeat"
                  : Data1?.repeat}
              </button>
              <button
                className="col-2 rounded border-0 ms-1"
                style={{ color: "#FFBD00" }}

              >
                <img alt="entery fees" src={Image3} />
                {Data1?.reward}
              </button>
              {/* <button
                className="col-2 rounded border-0 ms-1"
                style={{ color: "#C922E4" }}
              >
                {Data1?.rewards}
              </button> */}
              <button
                className="col-2 rounded border-0 ms-1"
                style={{ color: "#2188E7" }}
              >
                <img src={Image4} alt="slots" />
                {/* {filled}/ */}
                {Data1?.min_reward_per}
              </button>
            </div>

            <div className="row">
              <span
                className="col-2"
                onClick={() => {
                  setOverview("Overview");
                }}
                style={{
                  fontSize: 16,
                  fontWeight: Overview === "Overview" ? "600" : "",
                  cursor: "pointer",
                }}
              >
                Overview
              </span>
              <span
                className="col-2"
                onClick={() => {
                  setOverview("History");
                  Histry();
                }}
                style={{
                  fontSize: 16,
                  fontWeight: Overview === "History" ? "600" : "",
                  cursor: "pointer",
                }}
              >
                History
              </span>
            </div>
            <div className="row">
              <div
                className="col-2 mt-2"
                style={{
                  borderBottom:
                    Overview === "Overview" ? "1px solid #000000" : "",
                }}
              ></div>
              {/* <div
                className="col-2"
                style={{
                  borderBottom:
                    Overview === "History" ? "1px solid #000000" : "",
                }}
              ></div> */}
            </div>
            <hr style={{ marginTop: "0px" }} />

            {Overview === "Overview" ? (
              <>
                <div className="row ">
                  <p
                    className="col-3 mt-1"
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: "#303030",
                    }}
                  >
                    Exam Category
                  </p>



                </div>
                <div className="row">
                  <img
                    className="col-2"
                    alt="#"
                    src={`${IMAGE_URL}${Fire6}`}
                    style={{
                      width: "auto",
                      height: "40px",
                      objectFit: "cover",
                      borderRadius: "50%",
                    }}
                  />

                  <input
                    disabled={true}
                    className="col-8 border-0"
                    style={{ fontSize: 20, fontWeight: "600" }}
                    // placeholder={Fire2}
                    value={Fire2}
                    onChange={(e) => {
                      setFire2(e.target.value);
                    }}
                  />
                </div>
                <div className="row mt-2">
                  <p
                    className="col-4 mt-1"
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: "#303030",
                    }}
                  >
                    Exam Sub Category
                  </p>

                </div>
                <div className="row">
                  <input
                    disabled={true}
                    className="col-8 border-0"
                    style={{ fontSize: 20, fontWeight: "600" }}
                    value={Fire3}
                    onChange={(e) => {
                      setFire3(e.target.value);
                    }}
                  />
                </div>
                <div className="row mt-4">
                  <p
                    className="col-4 mt-1"
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: "#303030",
                    }}
                  >
                    Question Composition
                  </p>

                </div>
                <div className="row mt-2">
                  {Object.entries(Fire5).map(([key, value]) => {
                    return (
                      <>
                        <div className="d-flex">
                          <p
                            className="col-5"
                            style={{
                              fontSize: 16,
                              fontWeight: 600,
                              color: "#303030",
                            }}
                          >
                            {key}
                          </p>
                          <p className="col-1">{value}%</p>
                        </div>

                        <Slider
                          key={key}
                          id={key}

                          value={value}

                        // total={Object.values(Fire5).reduce((total, value) => total + value, 0)}

                        // onChange={handleChangeName}
                        />
                      </>
                    );
                  })}
                </div>

                <div className="row mt-4 mb-2">
                  <p
                    className="col-2 mt-1"
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: "#303030",
                    }}
                  >
                    Next Quiz
                  </p>
                </div>
                <div className="row ">
                  <span
                    className="col-3 d-flex rounded"
                    style={{
                      backgroundColor: "#EFEFEF",
                      overflow: "hidden",
                      height: "35px",
                      border: "1px solid #252525"
                    }}
                  >

                    <img className="mt-1" src={Dates} alt="#" />

                    <DatePicker
                      disabled={true}
                      className="Main"
                      selected={startDate}
                    />
                  </span>
                  <span
                    className="col-3 ms-4 rounded"
                    style={{
                      backgroundColor: "#EFEFEF",
                      height: "35px",
                      border: "1px solid #252525",
                    }}
                  >
                    <input
                      disabled
                      className="border-0 rounded mt-1"
                      type="time"
                      style={{
                        backgroundColor: "#EFEFEF",
                        color: "#8A8A8A",
                      }}
                      value={startTime ? startTime.toTimeString().substring(0, 5) : ''}

                    />
                  </span>
                </div>
                <div>
                  <div className="row mt-4">
                    <p className="col-2 mt-1" style={{ fontSize: 16, fontWeight: 600, color: "#303030" }}>Rules</p>
                  </div>
                  {Data1?.rules?.map((rule, index) => (
                    <div key={index} className="mb-2">
                      <textarea
                        className="col-6 rounded"
                        style={{ height: "100px" }}
                        value={rule}
                        disabled={true}
                      // onChange={(event) => handleRuleChange(index, event.target.value)}
                      />

                    </div>
                  ))}
                </div>
                {/* <textarea
                  className="col-6 rounded"
                  style={{ height: "200px" }}
                  value={Edit3}
                  onChange={(e) => {
                    setEdit3(e.target.value);
                  }}
                /> */}
                <div className="row mt-4">
                  <p
                    className="col-3 mt-1"
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: "#303030",
                    }}
                  >
                    Banner Image
                  </p>
                  {/* <label
                    className="filelabel2 col-2"
                    style={{ backgroundColor: "#F5F5F5" }}
                    
                  >
                    <span className="title2">upload</span>
                    <input
                      className="FileUpload2"
                      id="FileInput2"
                      name="booking_attachment2"
                      type="file"
                      src={`${IMAGE_URL}${BannerShow}`}
                      alt="banner image"
                      accept=".png,.jpg"
                      onChange={(e) => {
                        setbannerImage(e.target.files[0]);
                        setBannerShow(URL.createObjectURL(e.target.files[0]));
                      }}
                    />
                    
                  </label> */}

                </div>
                <div className="col-6 rounded">
                  <img
                    className="w-100 rounded"
                    alt=""
                    src={`${IMAGE_URL}${BannerShow1}`}
                    style={{ height: "200px" }}
                  />
                </div>
                {/* <div className="row mt-4">
                  <p
                    className="col-3 mt-1"
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: "#303030",
                    }}
                  >
                    Total Slots
                  </p>
                  
                </div>
                <input
                  className="col-6 "
                  style={{
                    fontSize: 20,
                    fontWeight: "600",
                    backgroundColor: "#",
                  }}
                  value={Edit4}
                  // onChange={(e) => {
                  //   setEdit4(e.target.value);
                  // }}
                  disabled={true}
                /> */}
                {/* <div className="row mt-4">
                  <p
                    className="col-3 mt-1"
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: "#303030",
                    }}
                  >
                    Entery Fee
                  </p>
                 
                </div>
                <input
                  className="col-6 "
                  style={{
                    fontSize: 20,
                    fontWeight: "600",
                    backgroundColor: "#",
                  }}
                  value={Edit5}
                  // onChange={(e) => {
                  //   setEdit5(e.target.value);
                  // }}
                  disabled={true}
                /> */}
                <div className="row mt-4">
                  <p
                    className="col-4 mt-1"
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: "#303030",
                    }}
                  >
                    Total Number of Question
                  </p>

                </div>
                <input
                  className="col-6 "
                  style={{
                    fontSize: 20,
                    fontWeight: "600",
                    backgroundColor: "#",
                  }}
                  value={Edit6}
                  disabled={true}
                />
                <div className="row mt-4">
                  <p
                    className="col-4 mt-1"
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: "#303030",
                    }}
                  >
                    Time Per Questions
                  </p>

                  {Edit7}%
                </div>
                <input
                  className="col-6 "
                  type="range"
                  min={10}
                  max={60}
                  value={Edit7}
                />


                <div className="row mt-4">
                  <p
                    className="col-4 mt-1"
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: "#303030",
                    }}
                  >
                    Minimum Reward Percentage
                  </p>

                  {Data1?.min_reward_per}%
                </div>
                <input
                  className="col-6 "
                  type="range"
                  min={0}
                  max={100}
                  value={Edit7}
                />

                <div className="row mt-4">
                  <p
                    className="col-4 mt-1"
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: "#303030",
                    }}
                  >
                    Repeat Quiz After
                  </p>

                  <select
                    className="col-2 rounded"
                    value={ChangesNo}
                    style={{
                      border: "1px solid #000000",
                      height: "30px",
                    }}
                    disabled={true}

                  >
                    <option value="never">never</option>
                    <option value="5 mins">5 mins</option>
                    <option value="15 mins">15 mins</option>
                    <option value="30 mins">30 mins</option>
                    <option value="45 mins">45 mins</option>
                    <option value="1 hrs">1 hrs</option>
                    <option value="2 hrs">2 hrs</option>
                    <option value="3 hrs">3 hrs</option>
                    <option value="6 hrs">6 hrs</option>
                    <option value="1 days">1 days</option>
                    <option value="2 days">2 days</option>
                    <option value="3 days">3 days</option>
                    <option value="1 week">1 week</option>
                    <option value="2 week">2 week</option>
                    <option value="1 month">1 month</option>
                    <option value="2 month">2 month</option>
                    <option value="3 month">3 month</option>
                    <option value="6 month">6 month</option>
                    <option value="1 Year">1 Year</option>

                  </select>

                </div>
              </>
            ) : (
              <></>
            )}

            {Overview === "History" ? (
              <>
                <TriviaHistory currentPage={currentPage} last_page={last_page} Data={HistryData} quiz_id={QuizId} fromDate={moment(fromDate1).format("DD-MM-YYYY")} toDate={moment(toDate1).format("DD-MM-YYYY")} />
                {/* {currentPageHistory  && (
                  <Button onClick={handleLoadMore}>Load MORE</Button>
                )} */}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>





      <Offcanvas
        show={show}
        className="w-50"
        onHide={() => {
          setSelectedSubjects([])
          setSubjectsComposition([])
          setArrForm()
          setClick(0)
          setIsSave(true)
          handleClose()
        }}
        placement="end"
        style={{ borderTopLeftRadius: 30, width: "50%" }}
      >
        <Offcanvas.Header>
          <div style={{ width: "100%", flex: "1" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span
                style={{
                  color: "#000",
                  fontWeight: "600",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setSelectedSubjects([])
                  setSubjectsComposition([])
                  setArrForm()
                  setClick(0)
                  setIsSave(true)
                  handleClose()
                }}

              >
                <svg
                  width="35"
                  height="35"
                  viewBox="0 0 35 35"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M27.4913 9.40793C27.616 9.28337 27.7149 9.13547 27.7825 8.97267C27.85 8.80987 27.8849 8.63535 27.885 8.45909C27.8851 8.28283 27.8505 8.10827 27.7831 7.94539C27.7158 7.7825 27.617 7.63448 27.4924 7.50977C27.3679 7.38505 27.22 7.2861 27.0572 7.21854C26.8944 7.15099 26.7199 7.11617 26.5436 7.11606C26.3673 7.11595 26.1928 7.15056 26.0299 7.21791C25.867 7.28527 25.719 7.38404 25.5943 7.5086L17.4999 15.6029L9.40793 7.5086C9.15607 7.25673 8.81446 7.11523 8.45827 7.11523C8.10207 7.11523 7.76047 7.25673 7.5086 7.5086C7.25673 7.76047 7.11523 8.10207 7.11523 8.45827C7.11523 8.81446 7.25673 9.15607 7.5086 9.40793L15.6029 17.4999L7.5086 25.5919C7.38389 25.7166 7.28496 25.8647 7.21747 26.0276C7.14997 26.1906 7.11523 26.3652 7.11523 26.5416C7.11523 26.718 7.14997 26.8926 7.21747 27.0556C7.28496 27.2185 7.38389 27.3666 7.5086 27.4913C7.76047 27.7431 8.10207 27.8846 8.45827 27.8846C8.63464 27.8846 8.80928 27.8499 8.97222 27.7824C9.13517 27.7149 9.28322 27.616 9.40793 27.4913L17.4999 19.3969L25.5943 27.4913C25.8461 27.7428 26.1876 27.884 26.5436 27.8838C26.8996 27.8836 27.2409 27.742 27.4924 27.4901C27.744 27.2382 27.8852 26.8967 27.885 26.5408C27.8848 26.1848 27.7431 25.8435 27.4913 25.5919L19.3969 17.4999L27.4913 9.40793Z"
                    fill="black"
                  />
                </svg>
              </span>
              <div>
                <span className="bach_step"
                  style={{
                    display: Click <= 0,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setClick(Click - 1)
                  }}
                >
                  <HiArrowNarrowLeft size="30px" style={{ display: Click === 7 ? "block" : "none" }} />
                </span>
              </div>
            </div>

            <div className="">
              {Overview == "History" &&
                <div
                  className="col-8 ms-1 "

                  style={{
                    display: "flex",
                    background:
                      "linear-gradient(180deg, rgba(33,184,103,1) 0%, rgba(47,132,96,1))",
                    height: "40px",
                    borderRadius: "7px",
                    color: "#fff",
                    width: "auto",
                    alignItems: "center",
                    gap: 5
                  }}
                >


                  <input
                    value={fromDate1}
                    onChange={(e) => {
                      const selectedDate = new Date(e.target.value);
                      const currentDate = new Date();

                      if (
                        selectedDate.getFullYear() < currentDate.getFullYear() ||
                        (selectedDate.getFullYear() === currentDate.getFullYear() &&
                          selectedDate.getMonth() < currentDate.getMonth()) ||
                        (selectedDate.getFullYear() === currentDate.getFullYear() &&
                          selectedDate.getMonth() === currentDate.getMonth() &&
                          selectedDate.getDate() < currentDate.getDate())
                      ) {
                        setFromDate1(e.target.value);
                      } else {
                        toast("Please select a past date");
                      }
                    }}
                    className="border-0"
                    type="date"
                    style={{
                      background:
                        "linear-gradient(180deg, rgba(33,184,103,1) 0%, rgba(47,132,96,1))",
                      color: "white"
                    }}

                  />
                  -
                  <input
                    value={toDate1}
                    onChange={(e) => {
                      const selectedDate = new Date(e.target.value);
                      const currentDate = new Date();

                      if (
                        selectedDate.getFullYear() < currentDate.getFullYear() ||
                        (selectedDate.getFullYear() === currentDate.getFullYear() &&
                          selectedDate.getMonth() < currentDate.getMonth()) ||
                        (selectedDate.getFullYear() === currentDate.getFullYear() &&
                          selectedDate.getMonth() === currentDate.getMonth() &&
                          selectedDate.getDate() < currentDate.getDate())
                      ) {
                        setToDate1(e.target.value);
                      } else {
                        toast("Please select a past date");
                      }
                    }}
                    className="border-0"
                    type="date"
                    style={{
                      background:
                        "linear-gradient(180deg, rgba(33,184,103,1) 0%, rgba(47,132,96,1))",
                      color: "white"
                    }}

                  />



                </div>

              }
            </div>


            <h4
              style={{
                marginLeft: "5px",
                fontWeight: "600",
                display: Click >= 7 ? "none" : "block",
                marginTop: 20,
              }}
            >
              Edit Free Trivia
            </h4>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body style={{ marginTop: "-20px" }}>


          <div style={{ display: Click === 0 ? "block" : "none" }}>


            <div className="m-2">
              <div className="row ">
                <div className="col-1">
                  <img
                    alt="#"
                    src={`${IMAGE_URL}${editData?.image}`}
                    style={{
                      width: "40px",
                      height: "40px",
                      objectFit: "cover",
                      borderRadius: "50%",
                    }}
                  />
                </div>
                <div className="col-6">
                  <p style={{ fontSize: 20, fontWeight: 600 }}>
                    {editData?.quiz_name}
                    {/* {editData?.category_name}
                    &nbsp;
                    {editData?.subcategory_name} */}
                  </p>
                </div>


              </div>
              <div className="row mt-2">
                <span className="col-1"></span>
                <span className="col-1">
                  <p
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: "#8A8A8A",
                    }}
                  >
                    Repetition
                  </p>
                </span>
                <button
                  className="col-2 ms-5 rounded border-0"
                  style={{ backgroundColor: "#E9FFF5", color: "#188241" }}
                >
                  {editData?.repeat === 0
                    ? "No Repeat"
                    : editData?.repeat}
                </button>
                <button
                  className="col-2 rounded border-0 ms-1"
                  style={{ color: "#FFBD00" }}

                >
                  <img alt="entery fees" src={Image3} />
                  {editData.reward}
                </button>


                <button
                  className="col-2 rounded border-0 ms-1"
                  style={{ color: "#2188E7" }}
                >
                  <img src={Image4} alt="slots" />
                  {editData?.min_reward_per}
                </button>
              </div>




              <div className="row">
                <span
                  className="col-2"
                  onClick={() => {
                    setOverview("Overview");
                  }}
                  style={{
                    fontSize: 16,
                    fontWeight: Overview === "Overview" ? "600" : "",
                    cursor: "pointer",
                  }}
                >
                  Overview
                </span>
                {/* <span
                  className="col-2"
                  onClick={() => {
                    setOverview("History");
                  }}
                  style={{
                    fontSize: 16,
                    fontWeight: Overview === "History" ? "600" : "",
                    cursor: "pointer",
                  }}
                >
                  History
                </span> */}
              </div>
              <div className="row">
                <div
                  className="col-2 mt-2"
                  style={{
                    borderBottom:
                      Overview === "Overview" ? "1px solid #000000" : "",
                  }}
                ></div>
                <div
                  className="col-2"
                  style={{
                    borderBottom:
                      Overview === "History" ? "1px solid #000000" : "",
                  }}
                ></div>
              </div>
              <hr style={{ marginTop: "0px" }} />



              {Overview === "History" ? (
                <>
                  <TriviaHistory  currentPage={currentPageHistory}
                     last_page={last_page} Data={HistryData} quiz_id={QuizId} fromDate={moment(fromDate1).format("DD-MM-YYYY")} toDate={moment(toDate1).format("DD-MM-YYYY")} />
                  {console.log("Overview value:", Overview)}
                </>
              ) : (
                <></>
              )}



              {Overview === "Overview" ? (
                <>
                  <div className="row mb-2">
                    <p
                      className="col-3 mt-1"
                      style={{
                        fontSize: 16,
                        fontWeight: 600,
                        color: "#303030",
                      }}
                    >
                      Exam Category
                    </p>
                    <button
                      className="col-2 border-0 rounded"
                      style={{ backgroundColor: "#efefef", color: "#188241" }}
                      onClick={() => {

                        getCategories()
                        setClick(1)

                      }}

                    >
                      Change
                    </button>
                  </div>


                  <div className="row">
                    <img
                      className="col-2"
                      alt="#"
                      src={`${IMAGE_URL}${editData?.image}`}
                      style={{
                        width: "auto",
                        height: "40px",
                        objectFit: "cover",
                        borderRadius: "50%",
                      }}
                    />

                    <input
                      disabled={true}
                      className="col-8 border-0"
                      style={{ fontSize: 20, fontWeight: "600" }}
                      value={editData?.category_name}
                    />
                  </div>



                  <div className="row mt-2 mb-2">
                    <p
                      className="col-4 mt-1"
                      style={{
                        fontSize: 16,
                        fontWeight: 600,
                        color: "#303030",
                      }}
                    >
                      Exam Sub Category
                    </p>
                    <button
                      className="col-2 border-0 rounded"
                      style={{ backgroundColor: "#efefef", color: "#188241" }}
                      onClick={() => {

                        getSubCategories()
                        setClick(2)

                      }}
                    >
                      Change
                    </button>
                  </div>
                  <div className="row">
                    <input
                      disabled={true}
                      className="col-8 border-0"
                      style={{ fontSize: 20, fontWeight: "600" }}
                      value={editData?.subcategory_name}

                    />
                  </div>
                  <div className="row mt-4">
                    <p
                      className="col-4 mt-1"
                      style={{
                        fontSize: 16,
                        fontWeight: 600,
                        color: "#303030",
                      }}
                    >
                      Question Composition
                    </p>
                    <button
                      className="col-2 border-0 rounded"
                      style={{ backgroundColor: "#efefef", color: "#188241" }}
                      onClick={() => {
                        getSubjects()
                        setClick(3)
                      }}
                    >
                      Change
                    </button>
                  </div>
                  <div className="row mt-2">
                    {showSubjects?.map((res) => {
                      return (
                        <>
                          <div className="d-flex">
                            <p
                              className="col-5"
                              style={{
                                fontSize: 16,
                                fontWeight: 600,
                                color: "#303030",
                              }}
                            >
                              {res?.name}
                            </p>
                            <p className="col-1">{res?.persentage}%</p>
                          </div>

                          <input
                            disabled={true}
                            type="range"
                            value={res?.persentage}
                            max={100}
                            min={0}
                          />
                        </>
                      );
                    })}
                  </div>


                  <div>


                    <div className="row mt-4">
                      <p className="col-2 mt-1" style={{ fontSize: 16, fontWeight: 600, color: "#303030" }}>Rules</p>
                      <button className="col-2 border-0 rounded" style={{ backgroundColor: "#efefef", color: "#188241" }} onClick={() => {
                        EditRulesApi()
                      }}>Change</button>
                    </div>


                    {Edit3?.map((rule, index) => (
                      <div key={index} className="mb-2">
                        <textarea
                          className="col-6 rounded"
                          style={{ height: "100px" }}
                          value={rule}
                          onChange={(event) => handleRuleChange(index, event.target.value)}
                        />
                        {index === Edit3?.length - 1 && (
                          <button className="btn btn-secondary btn-md ms-2" onClick={handleAddRule}>Add More Rule</button>
                        )}
                        {Edit3?.length > 1 && (
                          <button className="btn btn-secondary btn-md ms-2" onClick={() => handleRemoveRule(index)}>Remove Rule</button>
                        )}
                      </div>
                    ))}

                  </div>



                  <div className="row mt-4">
                    <p
                      className="col-3 mt-1"
                      style={{
                        fontSize: 16,
                        fontWeight: 600,
                        color: "#303030",
                      }}
                    >
                      Banner Image
                    </p>

                    <label
                      className="filelabel2 col-2"
                      style={{ backgroundColor: "#F5F5F5" }}
                      onClick={() => { }}
                    >
                      <span className="title2">upload</span>


                      <input
                        className="FileUpload2"
                        id="FileInput2"
                        name="booking_attachment2"
                        type="file"
                        accept=".png,.jpg"
                        onChange={(e) => {
                          setbannerImage(e.target.files[0]);
                          setBannerShow(URL.createObjectURL(e.target.files[0]));
                        }}
                      />

                    </label>


                    <button
                      className="col-2 border-0 rounded ms-3"
                      style={{ backgroundColor: "#efefef", color: "#188241" }}
                      onClick={EditBannerImgApi}
                    >
                      Change
                    </button>
                  </div>


                  <div className="col-6 rounded">

                    {bannerImage ? <>
                      <img
                        className="w-100 rounded"
                        alt=""
                        src={BannerShow}
                        style={{ height: "200px" }}
                      />

                    </> :
                      <>
                        <img
                          className="w-100 rounded"
                          alt=""
                          src={`${IMAGE_URL}${BannerShow}`}
                          style={{ height: "200px" }}
                        />
                      </>
                    }


                  </div>



                  <div className="row mt-4">
                    <p
                      className="col-3 mt-1"
                      style={{
                        fontSize: 16,
                        fontWeight: 600,
                        color: "#303030",
                      }}
                    >
                      Reward
                    </p>
                    <button
                      className="col-2 border-0 rounded"
                      style={{ backgroundColor: "#efefef", color: "#188241" }}
                      onClick={() => {
                        editRewardApi()
                      }}
                    >
                      Change
                    </button>
                  </div>


                  <input
                    type="number"
                    className="col-6 "
                    style={{
                      fontSize: 20,
                      fontWeight: "600",
                      backgroundColor: "#",
                    }}
                    value={editData?.reward}
                    onChange={(e) => {
                      setEditData({
                        ...editData, reward: e.target.value
                      });
                    }}
                  />





                  <div className="row mt-4">
                    <p
                      className="col-4 mt-1"
                      style={{
                        fontSize: 16,
                        fontWeight: 600,
                        color: "#303030",
                      }}
                    >
                      Total Number of Question
                    </p>
                    <button
                      className="col-2 border-0 rounded"
                      style={{ backgroundColor: "#efefef", color: "#188241" }}
                      onClick={EditToalQuesApi}
                    >
                      Change
                    </button>
                  </div>
                  <input
                    type="number"
                    className="col-6 "
                    style={{
                      fontSize: 20,
                      fontWeight: "600",
                      backgroundColor: "#",
                    }}
                    value={editData?.total_num_of_quest}
                    onChange={(e) => {
                      setEditData({
                        ...editData, total_num_of_quest: Number(e.target.value)
                      });
                    }}
                  />



                  <div className="row mt-4">
                    <p
                      className="col-4 mt-1"
                      style={{
                        fontSize: 16,
                        fontWeight: 600,
                        color: "#303030",
                      }}
                    >
                      Time Per Questions
                    </p>

                    {editData?.time_per_question}
                  </div>
                  <input
                    className="col-6 "
                    type="range"
                    min={10}
                    max={60}
                    value={editData?.time_per_question}
                    onChange={(e) => {
                      setEditData({
                        ...editData, time_per_question: Number(e.target.value)
                      });
                    }}
                  />
                  <button
                    className="col-2 border-0 rounded"
                    style={{ backgroundColor: "#efefef", color: "#188241" }}
                    onClick={EditTimePerQuesApi}
                  >
                    Change
                  </button>



                  <div className="row mt-4">
                    <p
                      className="col-4 mt-1"
                      style={{
                        fontSize: 16,
                        fontWeight: 600,
                        color: "#303030",
                      }}
                    >
                      Min reward Percentage
                    </p>

                    {editData?.min_reward_per}
                  </div>
                  <input
                    className="col-6 "
                    type="range"
                    min={0}
                    max={100}
                    value={editData?.min_reward_per}
                    onChange={(e) => {
                      setEditData({
                        ...editData, min_reward_per: Number(e.target.value)
                      });
                    }}
                  />
                  <button
                    className="col-2 border-0 rounded"
                    style={{ backgroundColor: "#efefef", color: "#188241" }}
                    onClick={() => {
                      minRewardPer()
                    }}
                  >
                    Change
                  </button>




                  <div className="row mt-4">
                    <p
                      className="col-4 mt-1"
                      style={{
                        fontSize: 16,
                        fontWeight: 600,
                        color: "#303030",
                      }}
                    >
                      Repeat Quiz After
                    </p>

                    <select
                      className="col-2 rounded"
                      value={editData?.repeat}
                      style={{
                        border: "1px solid #000000",
                        height: "30px",
                      }}
                      onChange={(e) => {
                        setEditData({
                          ...editData, repeat: e.target.value
                        });
                      }}
                    >
                      <option value="never">never</option>
                      <option value="5 mins">5 mins</option>
                      <option value="15 mins">15 mins</option>
                      <option value="30 mins">30 mins</option>
                      <option value="45 mins">45 mins</option>
                      <option value="1 hrs">1 hrs</option>
                      <option value="2 hrs">2 hrs</option>
                      <option value="3 hrs">3 hrs</option>
                      <option value="6 hrs">6 hrs</option>
                      <option value="1 days">1 days</option>
                      <option value="2 days">2 days</option>
                      <option value="3 days">3 days</option>
                      <option value="1 week">1 week</option>
                      <option value="2 week">2 week</option>
                      <option value="1 month">1 month</option>
                      <option value="2 month">2 month</option>
                      <option value="3 month">3 month</option>
                      <option value="6 month">6 month</option>
                      <option value="1 Year">1 Year</option>

                    </select>
                    <button
                      className="col-2 border-0 rounded m-1 mt-0"

                      style={{ backgroundColor: "#efefef", color: "#188241", height: "30px" }}
                      onClick={repeatApi}
                    >
                      Change
                    </button>
                  </div>
                </>
              ) : (
                <></>
              )}

            </div>


          </div>



          <div style={{ display: Click === 1 ? "block" : "none" }}>
            <div className="mb-5">
              <div className="progress" style={{ height: "10px" }}>
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{
                    width: "14%",
                    background:
                      "linear-gradient(to right, #f44cc5, #ec40cc, #e335d3, #d72adb, #c922e4)",
                  }}
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <div style={{ color: "#8A8A8A" }}>
                1/4 <span sytle={{ color: "#000" }}>Steps Completed</span>
              </div>

              <div className="row">
                <div className="col-3"></div>
                <div
                  className="card col-6"
                  style={{ justifyConetnt: "center", textAlign: "center" }}
                >
                  <div>
                    <label
                      className="col-12 mb-2 mt-3"
                      style={{
                        textAlign: "start",
                        color: "#303030",
                        fontWeight: "600",
                      }}
                    >
                      Select Category
                    </label>
                    <br />

                    <input
                      className="col-12 border-0 mb-3"
                      style={{
                        textAlign: "start",
                        height: "50px",
                        backgroundColor: "#EFEFEF",
                        borderRadius: 7,
                        outline: 1,
                      }}
                      placeholder="  Search for Category"
                      type="search"
                      value={cat_search}
                      onChange={(e) => set_cat_search(e.target.value)}

                    />
                    <br />
                  </div>
                  <div
                    style={{
                      height: "160px",
                      overflowY: "scroll",
                      justifyContent: "start",
                      alignItems: "start",
                    }}
                  >
                    {filterCategory1?.map((res, key) => {
                      return (
                        <>
                          <div className="d-flex mt-3" key={key}>
                            <input
                              style={{ color: "#F6F6F6" }}
                              type="radio"
                              name="Select"
                              onChange={() => handleRadioClick(res?._id, res?.category_name)}
                            />
                            <img
                              alt="#"
                              src={`${IMAGE_URL}${res?.image}`}
                              style={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "50%",
                              }}
                            />
                            <span className="ms-3 fw-bolder">
                              {res?.category_name}
                            </span>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                justifyContent: "center",
                textAlign: "center",
                backgroundColor: "",
              }}
            >
              <button
                disabled={selectedCategoryId ? false : true}
                className="col-5 border-0 text-white"
                onClick={() => {
                  getSubCategories()
                  setClick(Click + 1);
                }}
                style={{
                  backgroundColor: "#2188E7",
                  height: "40px",
                  borderRadius: 7,
                }}
              >
                Proceed
              </button>
            </div>
          </div>


          <div style={{ display: Click === 2 ? "block" : "none" }}>
            <div className="mb-5">
              <div className="progress" style={{ height: "10px" }}>
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{
                    width: "50%",
                    background:
                      "linear-gradient(to right, #f44cc5, #ec40cc, #e335d3, #d72adb, #c922e4)",
                  }}
                  aria-valuenow="50"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <p style={{ color: "#8A8A8A" }}>
                2/4 <span sytle={{ color: "red" }}>Steps Completed</span>
              </p>
              <div>
                <p style={{ fontSize: 16, fontWeight: 600 }}>
                  Exam Category : <span>{selectedCategoryName1}</span>
                </p>
              </div>
              <div className="row">
                <div className="col-3"></div>
                <div
                  className="card col-6"
                  style={{ justifyConetnt: "center", textAlign: "center" }}
                >
                  <div>
                    <label
                      className="col-12 mb-2 mt-3"
                      style={{
                        textAlign: "start",
                        color: "#303030",
                        fontWeight: "600",
                      }}
                    >
                      Select Sub Category
                    </label>
                    <br />
                    <input
                      className="col-12 border-0 mb-3"
                      style={{
                        textAlign: "start",
                        height: "50px",
                        backgroundColor: "#EFEFEF",
                        borderRadius: 7,
                        outline: 1,
                      }}
                      placeholder="  Search for Sub Category"
                      type="search"
                      value={sub_cat_search}
                      onChange={(e) => set_sub_cat_search(e.target.value)}
                    />
                    <br />
                  </div>
                  <div
                    style={{
                      height: "160px",
                      overflowY: "scroll",
                      justifyContent: "start",
                      alignItems: "start",
                    }}
                  >
                    {filterSubCatrgory?.map((res, key) => {
                      return (
                        <>
                          <div className="d-flex mt-3" key={key}>
                            <input
                              style={{ color: "#F6F6F6" }}
                              type="radio"
                              name="Select"
                              onClick={() => handleSubCatId(res?._id, res?.sub_category_name)}
                            />

                            <span className="ms-3 fw-bolder">
                              {res?.sub_category_name}
                            </span>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                justifyContent: "center",
                textAlign: "center",
                backgroundColor: "",
              }}
            >
              <button
                disabled={selectedSubId ? false : true}
                className="col-5 border-0 text-white"
                onClick={() => {
                  getSubjects();
                  setClick(Click + 1)
                }}
                style={{
                  backgroundColor: "#2188E7",
                  height: "40px",
                  borderRadius: 7,
                }}
              >
                Proceed
              </button>
            </div>
          </div>


          <div style={{ display: Click === 3 ? "block" : "none" }}>
            <div className="mb-5">
              <div className="progress" style={{ height: "10px" }}>
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{
                    width: "75%",
                    background:
                      "linear-gradient(to right, #f44cc5, #ec40cc, #e335d3, #d72adb, #c922e4)",
                  }}
                  aria-valuenow="75"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <p style={{ color: "#8A8A8A" }}>
                3/4 <span sytle={{ color: "red" }}>Steps Completed</span>
              </p>
              <div className="row" style={{ justifyContent: "space-evenly" }}>
                <p
                  className="col-6"
                  style={{ fontSize: 16, fontWeight: 600, color: "#303030" }}
                >
                  Exam Category :
                  <span style={{ coor: "#000000" }}>{selectedCategoryName1}</span>
                </p>
                <p
                  className="col-6"
                  style={{ fontSize: 16, fontWeight: 600, color: "#303030" }}
                >
                  Exam Sub Category :
                  <span style={{ coor: "#000000" }}> {selectedSubName}</span>
                </p>
              </div>
              <div className="row">
                <div className="col-3"></div>
                <div
                  className="card col-6"
                  style={{ justifyConetnt: "center", textAlign: "center" }}
                >
                  <div>
                    <label
                      className="col-12 mb-2 mt-3"
                      style={{
                        textAlign: "start",
                        color: "#303030",
                        fontWeight: "600",
                      }}
                    >
                      Select Subjects
                    </label>
                    <br />
                    <input
                      className="col-12 border-0 mb-3"
                      style={{
                        textAlign: "start",
                        height: "50px",
                        backgroundColor: "#EFEFEF",
                        borderRadius: 7,
                        outline: 1,
                      }}
                      placeholder="  Search for Subject"
                      type="search"
                      value={subject_search}
                      onChange={(e) => set_sub_search(e.target.value)}
                    />
                    <br />
                  </div>


                  <div
                    style={{
                      height: "160px",
                      overflowY: "scroll",
                      justifyContent: "start",
                      alignItems: "start",
                      marginBottom: ".5rem",
                    }}
                  >


                    {subjectsData?.map((res) => (
                      <div className="d-flex mt-3" key={res._id}>
                        <input
                          style={{ color: "#F6F6F6" }}
                          type="checkbox"
                          id={res._id}
                          value={res._id}
                          checked={selectedSubjects?.includes(res._id)}
                          onChange={(e) => handleChangeCheck(e, res)}
                        />
                        <span className="ms-3 fw-bolder">{res.sub_name}</span>

                      </div>
                    ))}


                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                justifyContent: "center",
                textAlign: "center",
                backgroundColor: "",
              }}
            >
              <button
                disabled={selectedSubjects?.length >= 1 ? false : true}
                className="col-5 border-0 text-white"
                onClick={() => {
                  setClick(Click + 1)
                }}
                style={{
                  backgroundColor: "#2188E7",
                  height: "40px",
                  borderRadius: 7,
                }}
              >
                Proceed
              </button>
            </div>
          </div>


          <div style={{ display: Click === 4 ? "block" : "none" }}>
            <div className="mb-5">
              <div className="progress" style={{ height: "10px" }}>
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{
                    width: "100%",
                    background:
                      "linear-gradient(to right, #f44cc5, #ec40cc, #e335d3, #d72adb, #c922e4)",
                  }}
                  aria-valuenow="100"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <p style={{ color: "#8A8A8A" }}>
                4/4 <span sytle={{ color: "red" }}>Steps Completed</span>
              </p>
              <div className="row" style={{ justifyContent: "space-evenly" }}>
                <p
                  className="col-6"
                  style={{ fontSize: 16, fontWeight: 600, color: "#303030" }}
                >
                  Exam Category :
                  <span style={{ coor: "#000000" }}>{selectedCategoryName1}</span>
                </p>
                <p
                  className="col-6"
                  style={{ fontSize: 16, fontWeight: 600, color: "#303030" }}
                >
                  Exam Sub Category :

                  <span style={{ coor: "#000000" }}> {selectedSubName}</span>
                </p>
              </div>
              <div className="row">
                <div className="col-3"></div>
                <div
                  className="card col-6"
                  style={{ justifyConetnt: "center", textAlign: "center" }}
                >
                  <div>
                    <label
                      className="col-12 mb-2 mt-3"
                      style={{

                        textAlign: "start",
                        color: "#303030",
                        fontWeight: "600",
                      }}
                    >
                      Select Question Composition
                    </label>
                    <br />
                  </div>

                  <div
                    className="p-2"
                    style={{ overflowY: "scroll", height: "300px" }}
                  >
                    {subjectsComposition?.map((Value, index) => {
                      return (
                        <>
                          <div
                            className="mt-3"
                            style={{ textAlign: "start" }}
                          >
                            <div
                              className="d-flex"
                              style={{
                                justifyContent: "space-between",
                              }}
                            >
                              <span
                                className="ms-3 fw-bolder"
                                style={{ fontSize: 14 }}
                              >
                                {Value.sub_name}
                              </span>
                              <span style={{ marginLeft: "190px" }}>
                                {parseInt(Value.value)}%
                              </span>
                              <br />
                            </div>

                            <Slider
                              style={{
                                // accentColor: colors[index % 4],
                                cursor: "pointer",
                              }}
                              key={Value._id}
                              id={Value._id}
                              name={Value.sub_name}
                              value={Value.value}
                              total={subjectsComposition.reduce(
                                (total, Value) => total + Value.value,
                                0
                              )}
                              onChange={compositionHandler}
                            />
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                justifyContent: "center",
                textAlign: "center",
                backgroundColor: "",
              }}
            >

              {

                isSave ? <>
                  <button
                    className="col-5 border-0 text-white"
                    onClick={() => {
                      validationComposition()
                    }}
                    style={{
                      backgroundColor: "#2188E7",
                      height: "40px",
                      borderRadius: 7,
                    }}
                  >
                    Save Changes
                  </button>

                </> :
                  <>

                    <button
                      className="col-5 border-0 text-white"
                      onClick={() => {
                        editTriviaApi()
                      }}
                      style={{
                        backgroundColor: "#2188E7",
                        height: "40px",
                        borderRadius: 7,
                      }}
                    >
                      Publish Changes
                    </button>

                  </>

              }

            </div>
          </div>


        </Offcanvas.Body>
      </Offcanvas>

    </div>
  );
};

export default FreeTrivaEdit;
