import { useContext, useEffect, useState } from "react";
import Chat7 from "../../Img/delete.png";
import Chat from "../../Img/pen-2-fill.png";
import Thaks from "../../Img/DeleteSucess.json";
import Lottie from "lottie-react";
import {
  AuthContext,
  IMAGE_URL,
  PREREC_BASE_URL,
} from "../Utils/AuthContextProvider";
import { Button, Form, Offcanvas, Spinner } from "react-bootstrap";
import { RxCross2 } from "react-icons/rx";
import LoaderComponent from "../Utils/LoaderComponent";
import clock from "../../Img/clock.svg";
import Dates from "../../Img/Date.svg";
import Select from "react-select";
import Editor from "../Utils/RichTextEditor";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import moment from "moment";
import { RiDeleteBin6Line } from "react-icons/ri";

const initLoader = {
  isLoading: false,
  isError: false,
};

const initRes = {
  isPending: false,
  isRejected: false,
};

const BlogCard = ({
  title,
  banner,
  category_id,
  scheduleDateTime,
  status,
  tags,
  body,
  _id,
  handleDeleteBlog,
  getBlogs,
  tagsData,
  is_published,
  getDraftBlogs,

}) => {
  const { state } = useContext(AuthContext);
  const [showView, setShowView] = useState(false);
  const [loader, setLoader] = useState(initLoader);
  const [updatedContent, setUpdatedContent] = useState({});
  const [content1, setContent1] = useState("");
  const [resEditBlog, setResEditBlog] = useState(initRes);
  const [blog, setBlog] = useState({});
  const [blogId, setBlogID] = useState("");
  const [show, setShow] = useState(false);
  const [user_departmentId, setuser_departmentId] = useState("");
  const [data, setdata] = useState([]);

  // For update time
  const [selectedDateTime, setSelectedDateTime] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [startDate1, setStartDate1] = useState(null);
  const formattedDate = moment(startDate1).format("DD-MM-YYYY");
  const formattedTime = moment(startTime).format("HH:mm:ss");

  // To edit image in blog
  const [NewImages, setNewImages] = useState("");
  const [Imagess, setImagess] = useState(null);

  // to send category id in edit blog api
  const [selectedCategoryId, setSelectedCategoryId] = useState("");

  //required destructuring from state objects
  const { token } = state;
  const { isLoading, isError } = loader;
  const { isPending, isRejected } = resEditBlog;

  // for delete model
  const [Click, setClick] = useState(0);

  const [toShow, setToShow] = useState(tags);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleSelectChange = (selectedOptions) => {
    console.log("selected options in handle select change", selectedOptions);
    setToShow(selectedOptions);
    const selectedValues = selectedOptions.map((option) => option.value);
    setSelectedOptions(selectedValues);
  };

  const setinitialtags = () => {
    const selectedValues = tags.map((option) => option.value);
    setSelectedOptions(selectedValues);
  };




  const handleView = async (_id) => {
    setLoader({
      ...loader,
      isloading: true,
    });
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("token")}`
      );

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        `${PREREC_BASE_URL}/blogs/view/particular/details?blog_id=${_id}`,
        requestOptions
      );
      const result = await response.json();
      if (result?.status == 1) {
        setBlog(result?.data);
        setLoader({
          ...loader,
          isLoading: false,
        });
      } else {
        setLoader({
          ...loader,
          isLoading: false,
        });
      }
    } catch (error) {
      console.log("error", error);
      setLoader({
        ...loader,
        isLoading: false,
      });
    }
  };


  const handleEditBlog = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("token")}`
      );
  
      const formdata = new FormData();
      if (Imagess) {
        formdata.append("banner", Imagess);
      }
      formdata.append("blog_id", updatedContent._id);
      formdata.append("title", updatedContent.title);
      formdata.append("categoryId", selectedCategoryId);
      formdata.append("body", content1);
      formdata.append("tags_Id", JSON.stringify(selectedOptions));
      formdata.append("scheduleDateTime", `${formattedDate} ${formattedTime}`);
  
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };
  
      const response = await fetch(
        `${PREREC_BASE_URL}/blogs/update/particular/blog`,
        requestOptions
      );
      const result = await response.json();
      if (result.status === 1) {
        setShow(false);
        toast(result.message);
       
        if (updatedContent?.is_published == 0) {
          console.log('update', updatedContent)
          await getBlogs();
        } else if (updatedContent?.is_published == 1) {
          await getDraftBlogs();
        }
        setResEditBlog({
          ...resEditBlog,
          isPending: false,
        });
      } else {
        toast(result.message);
        setResEditBlog({
          ...resEditBlog,
          isPending: false,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  // const handleEditDraft = async () => {
  //   try {
  //     const myHeaders = new Headers();
  //     myHeaders.append(
  //       "Authorization",
  //       `Bearer ${localStorage.getItem("token")}`
  //     );

  //     const formdata = new FormData();
  //     if (Imagess) {
  //       formdata.append("banner", Imagess);
  //     }
  //     formdata.append("blog_id", updatedContent._id);
  //     formdata.append("title", updatedContent.title);
  //     formdata.append("categoryId", selectedCategoryId);
  //     formdata.append("body", updatedContent.body);
  //     formdata.append("tags_Id", JSON.stringify(selectedOptions));

  //     formdata.append("scheduleDateTime", `${formattedDate} ${formattedTime}`);

  //     const requestOptions = {
  //       method: "POST",
  //       headers: myHeaders,
  //       body: formdata,
  //       redirect: "follow",
  //     };

  //     const response = await fetch(
  //       `${PREREC_BASE_URL}/blogs/update/particular/blog`,
  //       requestOptions
  //     );
  //     const result = await response.json();
  //     if (result.status == 1) {
  //       setShow(false);
  //       // toast(result.message);
  //       await getDraftBlogs();
  //       setResEditBlog({
  //         ...resEditBlog,
  //         isPending: false,
  //       });
  //     } else {
  //       toast(result.message);
  //       setResEditBlog({
  //         ...resEditBlog,
  //         isPending: false,
  //       });
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };




  //function for handle changes over the blog condition
  const handleChangeBlogContemt = (e) => {
    const { value, name } = e.target;
    setUpdatedContent({
      ...updatedContent,
      [name]: value,
    });
  };

  // to send date
  const handleDateChange = (date) => {
    setStartDate1(date);
    // Here you can update your API request or other logic with the new date value
  };
  // to send time
  const handleTimeChange = (e) => {
    const [hours, minutes] = e.target.value.split(":").map(Number);
    const updatedTime = new Date();
    updatedTime.setHours(hours);
    updatedTime.setMinutes(minutes);
    setStartTime(updatedTime);
    // Here you can update your API request or other logic with the new time value
  };

  // to Publish or draft the blog api in card
  const publishanddraft = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("token")}`
      );

      const formdata = new FormData();

      const requestOptions = {
        method: "GET",
        headers: myHeaders,

        redirect: "follow",
      };

      const response = await fetch(
        `${PREREC_BASE_URL}/blogs/update/status/blog?blog_id=${updatedContent._id}&status=${updatedContent?.is_published}`,
        requestOptions
      );
      const result = await response.json();
      if (result.status == 1) {
        console.log(result);
        // toast(result.message);
      } else {
        // toast(result.Backend_Error);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  // for update time
  useEffect(() => {
    if (selectedDateTime) {
      // Replace non-standard space character with regular space
      const cleanedScheduleTime = selectedDateTime.replace(/\s+/g, " ");

      // console.log(cleanedScheduleTime);
      const [dateString, timeString] = cleanedScheduleTime.split(" ");
      if (dateString && timeString) {
        const [day, month, year] = dateString.split("-").map(Number);
        const [hours, minutes] = timeString.split(":").map(Number);

        const presetDate = new Date(year, month - 1, day); // Month should be 0-indexed
        setStartDate1(presetDate);

        const presetTime = new Date();
        presetTime.setHours(hours);
        presetTime.setMinutes(minutes);
        setStartTime(presetTime);
      }
    }
  }, [selectedDateTime]);

  useEffect(() => {
    setinitialtags();

  }, []);

  return (
    <>
      <div
        style={{
          gap: ".5rem",
          marginTop: ".5rem",
        }}
        className="col-sm-12 col-sm-4 col-lg-4"
      >
        <div className="card">
          <div className="container">
            <p
              style={{
                width: "350px",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "20px",
                color: "#000000",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {title}
            </p>

            <div className="row">
              <div className="col-6"></div>
              <div className="col-6"></div>
            </div>

            {/* <div className="col-sm-12 col-md-2 col-lg-3">
              <p
                className="text-center"
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "12px",
                  color: "#8A8A8A",
                  background: "#EFEFEF",
                  borderRadius: "23px",
                  padding: "8px 4px",
                }}
              >
               
              </p>
            </div> */}

            <div
              style={{
                width: "100%",
                height: "200px",
                margin: "auto",
                marginBottom: "1rem",
              }}
            >
              <img
                src={`${IMAGE_URL}${banner}`}
                style={{
                  width: "100%",
                  height: "100%",
                  marginBottom: "1rem",
                }}
                alt="Sorry to say"
              />
            </div>

            <div className="row mb-3">
              <div className="col-sm-12 col-md-4 col-lg-4">
                <button
                  onClick={() => {
                    setShowView(true);
                    handleView(_id);
                  }}
                  type="button"
                  class="btn btn-primary"
                  style={{
                    background:
                      "linear-gradient(180deg, #23B065 0%, #2E8760 100%)",
                    borderRadius: "8px",
                    fontSize: "12px",
                  }}
                >
                  View Details
                </button>
              </div>
              <div className="col-sm-12 col-md-5 col-lg-5">
                <button
                  onClick={() => {
                    setShow(true);
                    setUpdatedContent({
                      title,
                      banner,
                      scheduleDateTime,
                      category_id,
                      body,
                      status,
                      _id,
                      is_published
                    });
                    setSelectedDateTime(scheduleDateTime);
                    setNewImages(banner);
                    setSelectedCategoryId(category_id);
                  }}
                  type="button"
                  class="btn btn-primary"
                  style={{
                    background: "#FFFFFF",
                    border: "1px solid #2E8760",
                    borderRadius: "8px",
                    color: "#2E8760",
                    fontSize: "12px",
                  }}
                >
                  <span>
                    <img src={Chat} alt="hello" style={{ width: "25%" }} />
                  </span>
                  Edit Blog
                </button>
              </div>

              <div className="col-sm-12 col-md-3 col-lg-3">
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  type="button"
                  class="btn btn-primary"
                  // data-bs-toggle="modal"
                  // data-bs-target="#exampleModal"
                  style={{ background: "#CC1313", padding: "3px 18px" }}
                >
                  <span>
                    <RiDeleteBin6Line />
                    {/* <img
                      src={<RiDeleteBin6Line />}
                      alt="hello"
                      style={{
                        width: "90%",
                        margin: "0px 0px 0px 0px",
                      }}
                    /> */}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* modal for delete  */}
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ backdropFilter: "blur(10px)" }}
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
              <p
                className="text-center"
                style={{
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "24px",
                  color: "#000000",
                }}
              >
                Are you sure?
              </p>
              <p
                className="text-center"
                style={{
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "18px",
                  color: "#8A8A8A",
                }}
              >
                Do you really want to delete this Blog?
              </p>
            </div>
            <div className="container mb-3">
              <div className="row">
                <div className="col-6 text-center">
                  <button
                    type="button"
                    class="btn btn-secondary text-center"
                    data-bs-dismiss="modal"
                    style={{
                      background: "#dadada",
                      border: "none",
                      padding: "7px 40px",
                    }}
                  >
                    Cancel
                  </button>
                </div>
                <div className="col-6 text-center">
                  <button
                    onClick={() => {
                      handleDeleteBlog(_id);
                    }}
                    type="button"
                    class="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal1"
                    data-bs-dismiss="modal"
                    style={{
                      background: "#CC1313",
                      borderRadius: "7px",
                      border: "none",
                      padding: "7px 40px",
                    }}
                  >
                    Yes Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="exampleModal1"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
              <div
                className="d-flex"
                style={{ justifyContent: "center", alignItems: "center" }}
              >
                <Lottie animationData={Thaks} style={{ width: "40%" }} />
              </div>
              <p
                className="text-center"
                style={{
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "24px",
                  color: "#000000",
                }}
              >
                {/* Are you sure? */}
              </p>
              <p
                className="text-center"
                style={{
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "18px",
                  color: "#8A8A8A",
                }}
              >
                “ Blog ” is successfully deleted
              </p>
            </div>
            <div className="container text-center mb-3">
              <button
                type="button"
                class="btn btn-primary text-center"
                data-bs-dismiss="modal"
                style={{ border: "none", padding: "7px 40px" }}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* delete modal ends here */}

      {/* offcanvas for view particular blog */}

      <Offcanvas
        show={showView}
        placement="end"
        style={{
          width: "50%",
          borderRadius: "20px 0 0 0",
        }}
      >
        <Offcanvas.Header>
          <span
            onClick={() => setShowView(false)}
            style={{
              color: "#000",
              fontWeight: "600",
              cursor: "pointer",
            }}
          >
            <RxCross2 size="30px" />
          </span>
        </Offcanvas.Header>

        <Offcanvas.Body
          style={{
            maxHeight: "90vh",
            overflowY: "scroll",
          }}
        >
          {isLoading ? (
            <LoaderComponent />
          ) : (
            <>
              <h2>{blog.title}</h2>

              <div style={{ display: "flex", gap: "2rem" }}>
                <div>
                  <img src={Dates} alt="" />
                  <span>{blog?.scheduleDateTime?.split(" ")[0]}</span>
                </div>
                <div>
                  <img src={clock} alt="" />
                  <span>{blog?.scheduleDateTime?.split(" ")[1]}</span>
                </div>
              </div>

              <div className="col-sm-12 col-md-2 col-lg-3">
                <p
                  className="text-center"
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "20px",
                    color: "#8A8A8A",
                    background: "#EFEFEF",
                    borderRadius: "23px",
                    padding: "8px 4px",
                  }}
                >
                  <span>Published: </span>
                  {blog?.is_published === 1 ? "Yes" : "No"}
                </p>
              </div>

              <hr />
              <img
                style={{ height: "300px", width: "100%" }}
                src={`${IMAGE_URL}${blog?.banner}`}
                alt=""
              />

              <div>
                <Select
                  isDisabled={true}
                  isMulti
                  options={tagsData?.map((key, value) => {
                    return { value: key._id, label: key.name };
                  })}
                  value={tags}
                  styles={{
                    menu: (provided, state) => ({
                      ...provided,
                      maxHeight: "fit-content", // Adjust the max height as needed
                      overflowY: "scroll",
                    }),
                  }}
                />
              </div>
              

                                  <div dangerouslySetInnerHTML={{ __html: `<style> .details-content p { margin: 0; } </style>${blog?.body}` }} className="details-content"></div>

            </>
          )}
        </Offcanvas.Body>
      </Offcanvas>

      {/* offcanvas for Edit particular blog */}

      <Offcanvas
        show={show}
        placement="end"
        style={{
          width: "50%",
          borderRadius: "20px 0 0 0",
          padding: "1rem",
        }}
      >
        <Offcanvas.Header>
          <span
            onClick={() => {
              setShow(false);
            }}
            style={{
              color: "#000",
              fontWeight: "600",
              cursor: "pointer",
            }}
          >
            <RxCross2 size="30px" />
          </span>

          <Button
            disabled={isPending}
            onClick={() => {
              publishanddraft();
              handleEditBlog();
              // handleEditDraft();
            }}
          >
            {isPending ? <Spinner /> : "Save"}
          </Button>
        </Offcanvas.Header>

        <Offcanvas.Body>
          {isLoading ? (
            <LoaderComponent />
          ) : (
            <>
              <input
                style={{
                  border: "1px solid #d9d9d9",
                  borderRadius: ".5rem",
                  fontSize: "25px",
                  width: "100%",
                }}
                value={updatedContent.title}
                name="title"
                onChange={handleChangeBlogContemt}
              />

              <div style={{ display: "flex", gap: "2rem", marginTop: "1rem" }}>
                <div className="d-flex">
                  <img src={Dates} alt="" />


                  <DatePicker
                    className="Main"
                    selected={startDate1}
                    onChange={handleDateChange}
                  />
                </div>
                <div>
                  {/* <img src={clock} alt="" /> */}
                  <input
                    className="border-0 rounded mt-1"
                    type="time"
                    style={{
                      backgroundColor: "#EFEFEF",
                      color: "#8A8A8A",
                    }}
                    value={
                      startTime ? startTime.toTimeString().substring(0, 5) : ""
                    }
                    onChange={handleTimeChange}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    cursor: "pointer",
                  }}
                >
                  <Form.Check // prettier-ignore
                    onChange={() =>
                      setUpdatedContent({
                        ...updatedContent,
                        is_published: updatedContent.is_published == 0 ? 1 : 0,
                      })
                    }

                    type="switch"
                    id="custom-switch"
                  // label="Check this switch"
                  />
                  <h6>{updatedContent.is_published ? "PUBLISHED" : "DRAFT"}</h6>
                </div>
              </div>

              <div className="col-sm-12 col-md-2 col-lg-3 mt-3">
                <p
                  className="text-center"
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "20px",
                    color: "#8A8A8A",
                    background: "#EFEFEF",
                    borderRadius: "23px",
                    padding: "8px 4px",
                  }}
                >
                  <span>Published: </span>
                  {is_published === 1 ? "Yes" : "No"}
                </p>
              </div>

              <hr />
              {/* <img
                style={{
                  width: "100%",
                  marginBottom: "1rem",
                }}
                src={`${IMAGE_URL}${banner}`}
                alt="#"
              /> */}
              <div className="d-flex">
                <p className="mt-2" style={{ fontWeight: "600", fontSize: 16 }}>
                  Upload Banner Image
                </p>
                <label
                  className="filelabel2 ms-4"
                  style={{ backgroundColor: "#F5F5F5", width: "100px" }}
                >
                  <span className="title2">Change</span>
                  <input
                    className="FileUpload2"
                    id="FileInput2"
                    name="booking_attachment2"
                    type="file"
                    onChange={(e) => {
                      setImagess(e.target.files[0]);
                      setNewImages(URL.createObjectURL(e.target.files[0]));
                    }}
                  />
                </label>
              </div>
              <div className="w-100 rounded">
                {Imagess ? (
                  <>
                    <img
                      className="border-0"
                      src={NewImages}
                      alt="banner"
                      style={{ height: "200px", width: "100%" }}
                    />
                  </>
                ) : (
                  <>
                    <img
                      className="border-0"
                      src={`${IMAGE_URL}${NewImages}`}
                      alt="banner"
                      style={{ height: "200px", width: "100%" }}
                    />
                  </>
                )}
              </div>

              {/* for editing tags */}
              <div>
                <Form.Label>Edit Tags</Form.Label>

                <div>
                  <Select
                    isMulti
                    options={tagsData?.map((key, value) => {
                      return { value: key._id, label: key.name };
                    })}
                    onChange={handleSelectChange}
                    value={toShow}
                    styles={{
                      menu: (provided, state) => ({
                        ...provided,
                        maxHeight: "fit-content", // Adjust the max height as needed
                        overflowY: "scroll",
                      }),
                    }}
                  />
                </div>
              </div>

              <div
                style={{
                  maxHeight: "70vh",
                  overflowY: "scroll",
                }}
              >
                {/* <textarea
                  style={{
                    height: "20vh",
                    width: "100%",
                  }}
                  name="body"
                  value={updatedContent?.body}
                  onChange={handleChangeBlogContemt}
                /> */}

                <Editor
                  content1={updatedContent?.body}
                  setContent1={setContent1}
                />
              </div>
            </>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default BlogCard;
