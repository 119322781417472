import { useState, useEffect } from "react";
import SearchIcon from "../../Img/ri_search-line.svg";
import { FaArrowRight } from "react-icons/fa";
import { AiFillEdit } from "react-icons/ai";
import { IoFilterSharp } from "react-icons/io5";
import FreePdfData from "./FreePdfData";
import Lottie from "lottie-react";
import Clebrate from "../../Img/lf20_VuglDs.json";
import PdfImg from "../../Img/pdf.svg";
import "./FreePdf1.css";
import Thaks from "../../Img/lf20_snmbndsh.json";
import Offcanvas from "react-bootstrap/Offcanvas";
import EmptyContainer from "../Utils/EmptyContainer";
import LoaderComponent from "../Utils/LoaderComponent";
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { MdModeEditOutline } from "react-icons/md";
import { BB_URl, IMAGE_URL, PREREC_BASE_URL, QUIZ_BASE_URL } from "../Utils/AuthContextProvider";
import { Spinner } from "react-bootstrap";

//initial conditions for loader;
const initLoader = {
  isLoading: false,
  isError: false,
};

//initial pdf form data;
const initUpdatedSTM = {
  pdf: "",
  pdf1: "",
  pdf_cat: "",
  category_id: "",
  display_name: "",
  pdf_id: "",
};

const PdfType = () => {
  const [ImgCat, setImgCat] = useState("");
  const [CatName, setCatName] = useState("");
  const [NameCat, setNameCat] = useState("");
  const [Token, setToken] = useState("");
  const [Click, setClick] = useState(0);
  const [demo, setDemo] = useState([]);
  const [Categories, setCategories] = useState([]);
  const [File, setFile] = useState({
    name: "",
  });
  const [File1, setFile1] = useState();
  const [Name, setName] = useState("");
  const [CatId, setCatId] = useState("");
  const [Value, setValue] = useState("");
  const [View, setView] = useState(0);
  const [het, setHet] = useState([]);
  const [IdFor, setIdFor] = useState("");
  const [hets, setHets] = useState([]);
  const [Display, setDisplay] = useState(0);
  const [Category, setCategory] = useState(0);
  const [DisplayChag, setDisplayChag] = useState("");
  const [CategoryChag, setCategoryChag] = useState("");
  const [PdfChan1, setPdfChan1] = useState("");
  const [PdfChan, setPdfChan] = useState("");
  const [DisplayChan, setDisplayChan] = useState("");
  const [ApiIds, setApiIds] = useState("");
  const [DeleteId, setDeleteId] = useState("");
  const [show, setShow] = useState(false);
  const [Timers1, setTimers1] = useState(15);
  const [Notes_id, setNotes_id] = useState();
  const [toSearch, setToSearch] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [loader, setLoader] = useState(initLoader);
  const [filterCategories, setFilteerCatories] = useState();
  const [updatedSTM, setUpdatedSTM] = useState([]);
  const [replaced_file, setReplacedFile] = useState();

  // for model to add pdf type
  const [lgShow, setLgShow] = useState(false);
  const [pdftypename, setPdfTypeName] = useState("");
  const [pdfTypeLogo, setPdfTypeLogo] = useState(null);

  // for model to edit pdf type
  const [lgShow1, setLgShow1] = useState(false);

  const [pdfTypeLogo1, setPdfTypeLogo1] = useState(null);
  const [pdfTypeID, setPdfTypeId] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const [isloader, setIsLoading] = useState(false)

  //required destructuring from the above state objects
  const { isLoading } = loader;

  //function for handling the on/off of offcanvas
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //function for handling of moving top next step of offcanvas
  const MyFun = () => {
    setClick(Click + 1);
  };

  //function for delete particular particular study material
  const DeleteApi = async () => {
    try {
      const Res = await fetch(
        `${BB_URl}/api/admin/studymatdelete?id=${DeleteId}`
      );
      const Data = await Res.json();
      if (Data.status == 1) {
      }
    } catch (error) {
      console.log(error);
    }
  };

  //function for Add pdf from card;
  const Api = async () => {
    var formdata = new FormData();
    formdata.append("token", Token);
    formdata.append("file_path_en", File);
    formdata.append("notes_id", Value);
    formdata.append("category_id", CatId);
    formdata.append("file_title", Name);
    formdata.append("file_path_hi", File1);
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    fetch(`${BB_URl}/api/admin/studymat`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status == 1) {
          setClick(Click + 1);
          let interval = setInterval(() => {
            setTimers1((lastTimerCount) => {
              lastTimerCount <= 1 && clearInterval(interval);
              return lastTimerCount - 1;
            });
          }, 1000);
          return () => {
            clearInterval(interval);
          };
        } else {
          //pdf work over the less then 10mb only
          toast(result.message);
        }
      })
      .catch((error) => console.log("error", error));
  };

  //function for getting all the categories
  const Categories12 = async () => {
    try {
      const Dar = await fetch(
        `${BB_URl}/api/admin/categorylist`
      );
      const res = await Dar.json();
      setCategories(res);
      setFilteerCatories(res);
    } catch (error) {
      console.log(error);
    }
  };

  //function for get particular study material by their id;
  const Api3 = async (id) => {
    try {
      const Responce = await fetch(
        `${BB_URl}/api/admin/studymatpdf?id=${id}`
      );

      const Data = await Responce.json();
      if (Data.status == 1) {
        setHets(Data.data);
        setUpdatedSTM(Data.data[0]);
        setUpdatedSTM({
          ...updatedSTM,
          file_path_en: null,
          file_path_hi: null,
        });
        //set state for updated stm
        setIdFor(Data.data[0].notes_id);
      } else {
        alert(Data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //function for update particular study material data
  const ApiSend = async (id) => {
    var myHeaders = new Headers();
    myHeaders.append("location", "");
    var formdata = new FormData();
    Object.entries(updatedSTM).forEach(([key, value]) => {
      if (value !== null) {
        formdata.append(key, value);
      }
    });
    formdata.append("id", id);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    fetch(`${BB_URl}/api/admin/studymatupdate`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 1) {
          toast(result.message);
        } else {
          toast(result.message);
        }
      })
      .catch((error) => console.log("error", error));
  };

  //function for get all the study material
  const Api1 = async () => {
    setLoader({
      ...loader,
      isLoading: true,
    });
    try {
      const Responce = await fetch(
        `${PREREC_BASE_URL}/study/get/pdf/type`
      );
      const Data = await Responce.json();
      if (Data.status === 1) {
        setDemo(Data.data);
        setSearchData(Data.data);
        setLoader({
          ...loader,
          isLoading: false,
        });
      } else {
        alert(Data.message);
        setLoader({
          ...loader,
          isLoading: false,
        });
      }
    } catch (error) {
      console.log(error);
      setLoader({
        ...loader,
        isLoading: false,
      });
    }
  };

  //function for empty or go back to while no data found on searching
  const noDataAction = () => {
    setSearchData(demo);
  };

  //function for filter categories
  const handleFilter = (search) => {
    const filterData = Categories.filter((el) => {
      if (search.length === 0) {
        return el;
      }
      return el.category_name.toLowerCase().startsWith(search.toLowerCase());
    });
    setFilteerCatories([...filterData]);
  };

  //function for getting notes by id
  const Api2 = async (VAL) => {
    try {
      const Responce = await fetch(
        `${BB_URl}/api/admin/studymatlist?notes_id=${VAL}`
      );
      const Data = await Responce.json();
      if (Data.status == 1) {
        setHet(Data.data);
      } else {
        alert(Data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //function for search functionality
  const searchFunction = () => {
    setLoader({
      ...loader,
      isLoading: true,
    });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      token: localStorage.getItem("token"),
      search: toSearch,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${BB_URl}/api/admin/search-pdf`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        const { data } = result;
        if (!data) {
          setSearchData([]);
          setLoader({
            ...loader,
            isLoading: false,
          });
          return;
        }
        setSearchData(result.data);
        setLoader({
          ...loader,
          isLoading: false,
        });
      })
      .catch((error) => {
        console.log("error", error);
        setLoader({
          ...loader,
          isLoading: false,
        });
      });
  };

  // Api to add Pdf Type
  const AddPdfType = () => {
    setIsLoading(true);
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );
    var formdata = new FormData();
    formdata.append("name", pdftypename);
    formdata.append("logo", pdfTypeLogo);

    var requestOptions = {
      method: "POST",
      body: formdata,
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${PREREC_BASE_URL}/study/add/pdf/type`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 1) {
          // Success toast
          toast.success(result.msg);
          setLgShow(false); // close modal on success
          setPdfTypeName("");
          setPdfTypeLogo(null);
          Api1();
        } else {
          // Error toast
          toast.error(result.Backend_Error);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setIsLoading(false); // End loading
      });
  };

  // Api to edit Pdf type
  const EditPdfType = () => {
    setIsLoading(true); // Start loading

    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    const formdata = new FormData();
    formdata.append("id", pdfTypeID);
    formdata.append("name", selectedName);
    formdata.append("logo", pdfTypeLogo1);

    const requestOptions = {
      method: "POST",
      body: formdata,
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${PREREC_BASE_URL}/study/edit/pdf/type`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setIsLoading(false); // End loading

        if (result.status === 1) {
          // Success toast
          toast.success(result.msg);
          setLgShow1(false); // close modal on success
          setPdfTypeLogo1(null);
          setPdfTypeId("");
          setSelectedName("");
          Api1();
        } else {
          // Error toast
          toast.error(result.Backend_Error);
        }
      })
      .catch((error) => {
        setIsLoading(false); // End loading
        console.error(error);
        toast.error("An error occurred. Please try again.");
      });
  };

  //function for handle search functionlity by using enter key on keyboard
  const handleSearchFunctionlity = (e) => {
    const { key, target } = e;
    const { value } = target;
    setToSearch(value);
    if (key === "Enter") {
      searchFunction();
    }
  };

  console.log("slecting file", File?.name.length);

  useEffect(() => {
    Categories12();
    Api1();
    setToken(localStorage.getItem("token"));
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-6 d-flex">
          <div className="col-8">
            <h5 className="mt-1" style={{ fontWeight: 600 }}>
              Showing total
              <span style={{ color: "#CC1313" }}> {searchData?.length} </span>
              PDF Types
            </h5>
          </div>
          <div className="col-4">
            <button
              //   data-bs-toggle="offcanvas"
              //   data-bs-target="#offcanvasRight"
              //   aria-controls="offcanvasRight"
              style={{
                backgroundColor: "#EDF7FF",
                border: "1px solid #2188E7",
                height: "40px",
                borderRadius: "10px",
                color: "#2188E7",
              }}
              onClick={() => setLgShow(true)}
            >
              <span className="p-3"> + Add PDF Type</span>
            </button>
          </div>
        </div>

        {/* Search Option area */}
        {/* <div className="col-6">
          <div className="row">
            <div className="col-4"></div>
            <div className="col-6">
              <div
                className="row"
                style={{
                  border: "1px solid #D9D9D9",
                  height: "40px",
                  borderRadius: "7px",
                }}
              >
                <div className="col-2 mt-2">
                  <img alt="SearchIcon" src={SearchIcon} />
                </div>
                <div className="col-10 mt-2 ">
                  <input
                    onKeyPress={handleSearchFunctionlity}
                    onChange={(e) => setToSearch(e.target.value)}
                    className="border-0 w-100"
                    placeholder="Search for PDF"
                    style={{
                      textDecoration: "none",
                      outline: "none",
                      color: "#272727",
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-2">
              <button
                onClick={searchFunction}
                className="border-0 bg-white"
                style={{
                  background:
                    "linear-gradient(180deg, rgba(35,176,101,1) 0%, rgba(46,135,96,1) 100%)",
                  color: "#fff",
                  height: "40px",
                  borderTopRightRadius: 10,
                  borderBottomRightRadius: 10,
                  marginLeft: "-20px",
                  padding: "10px 10px 10px 10px",
                }}
              >
                Search
              </button>
            </div>
          </div>
        </div> */}

        {/* Model to add Pdf type */}
        <Modal
          size="lg"
          show={lgShow}
          onHide={() => setLgShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Add Pdf Type
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">
                <h5>Pdf Type</h5>
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleFormControlInput1"
                placeholder="Add Pdf Type"
                onChange={(e) => setPdfTypeName(e.target.value)}
              />
            </div>
            <div class="mb-3">
              <label for="formFile" class="form-label">
                <h5>Upload Logo</h5>
              </label>
              <input
                class="form-control"
                type="file"
                id="formFile"
                onChange={(e) => setPdfTypeLogo(e.target.files[0])}
              />
            </div>
            <div className="add_pdftyp">
              <Button variant="primary" onClick={AddPdfType} disabled={isloader} >
                {isloader ? <Spinner /> : "Add"}
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>

      {/* Model to edit PDf type */}
      <Modal
        size="lg"
        show={lgShow1}
        onHide={() => setLgShow1(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Edit Pdf Type
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="mb-3">
            <label class="form-label">
              <h5>Edit Pdf Type Name</h5>
            </label>
            <input
              type="text"
              class="form-control edit_name"
              value={selectedName}
              onChange={(e) => {
                setSelectedName(e.target.value);
              }}
            />
          </div>
          <div class="mb-3">
            <label for="formFile1" class="form-label">
              <h5>Upload Logo</h5>
            </label>
            <input
              class="form-control"
              type="file"
              id="formFile1"
              onChange={(e) => {
                console.log(e.target.files[0]); // Check if file is captured
                setPdfTypeLogo1(e.target.files[0]);
              }}
            />
          </div>
          <div>
            <div className="add_pdftyp">
              <Button variant="primary" onClick={EditPdfType}>
                {isloader ? <Spinner animation="border" size="sm" /> : "Edit"}
              </Button>
            </div>
            {/* Add any additional JSX here */}
          </div>
        </Modal.Body>
      </Modal>

      {/* for add pdf from the top of free pdf page offcanvas start */}
      <div
        className="offcanvas offcanvas-end w-50"
        tabindex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
        style={{ borderTopLeftRadius: 30, backgroundColor: "#F5F5F5" }}
      >
        <div className="offcanvas-body">
          <FreePdfData />
        </div>
      </div>
      {/* ends here */}

      <div className="container mt-4">
        <div className="row">
          {isLoading ? (
            <LoaderComponent />
          ) : searchData.length === 0 ? (
            <EmptyContainer
              message="No data found"
              action={noDataAction}
              actionName="Fetch All Data"
            />
          ) : (
            searchData?.map((res1, key1) => {
              return (
                <div key={key1} className="col-4 mt-3">
                  <span
                    className="card"
                    //   onClick={async () => {
                    //     setId(res1._id);
                    //     setSubacat(res1);
                    //     New(res1._id);
                    //     setAllSub(res1.sub_category);
                    //     handleShow1();
                    //   }}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="d-flex ps-2">
                      <span className="col-2">
                        <div
                          className="mt-2"
                          style={{
                            width: "40px",
                            height: "40px",
                            border: "1px solid #D9D9D9",
                            borderRadius: "50px",
                            justifyContent: "center",
                            objectFit: "cover",
                            overflow: "hidden",
                          }}
                        >
                          <img
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                            src={`${IMAGE_URL}${res1.logo}`}
                            alt="#"
                          />
                        </div>
                      </span>
                      <div className="col-8 mt-2">
                        <span className="ml-5">
                          <p
                            style={{
                              marginBottom: "1px",
                              fontStyle: "normal",
                              fontWeight: "600",
                              fontSize: "20px",
                              color: "#000000",
                            }}
                          >
                            {res1.name}
                          </p>
                        </span>
                      </div>
                      <span
                        className="col-2 mt-3"
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          setPdfTypeId(res1._id);
                          setSelectedName(res1.name)
                          setLgShow1(true);
                        }}
                      >
                        <MdModeEditOutline />
                      </span>
                    </div>
                  </span>
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};
export default PdfType;
