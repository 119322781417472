import React, { useContext, useEffect, useState } from 'react'
import TaskTableHead from './TaskTableHead';
import TaskCard from './TaskCard';
// import { BASE_URL } from '../Utils/AuthContextProvider';
import { ToastContainer, toast } from 'react-toastify';
import { AUTH_BASE_URL, AuthContext } from '../Utils/AuthContextProvider';


const Missed = () => {
    const [missed, setmissed] = useState([])
    const {taskCount, setTaskCount} = useContext(AuthContext)
    const handleSelectChange = (value, id,date) => {
        update(value, id,date);
    }

    const missed_api = async () => {
        try {
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')} `);

            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow"
            };

            const response = await fetch(`${AUTH_BASE_URL}/taskpanel/missedtasks?searchQuery&page=1&limit=6`, requestOptions)
            const result = await response.json()

            if (result?.status == 1) {
                setmissed(result?.tasks)
                setTaskCount({
                    ...taskCount , missed : result?.TotalNumberOfTask
                  })
            }else{
                toast.error("failed")
              }


        }   catch (error) {
            console.log(error)
        }
    }

    const update = async (value,id,date) => {
        try {
          const myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);
    
          const requestOptions = {
            method: "POST",
            headers: myHeaders,
            redirect: "follow"
          };
    
       const response = await fetch(` ${AUTH_BASE_URL}/taskpanel/change/status?id=${id}&status=${value}&due_date=${date}`, requestOptions)
        const result = await response.json();

        if(result.status==1){
        missed_api();
        }else if(result.status=="INT_ERR"){
            toast.error(result.Backend_Error)
          }
        } catch (error) {
          console.log(error)
        }
      }
    useEffect(() => {
     missed_api()
    }, [])
    
    return (

        <div> 
            <ToastContainer/>
            <TaskTableHead />
            <div style={{ overflowY: "scroll", height: "50vh" }}>
                <TaskCard type={missed} handleSelectChange={handleSelectChange} need={0} />
            </div>
        </div>

    )
}

export default Missed

