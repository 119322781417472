import { initializeApp } from "firebase/app";
import {getMessaging} from "firebase/messaging";



const firebaseConfig = {
    apiKey: "AIzaSyAB2qggwL_tGupZBN9dPtiGVhyMG1cx2Xs",
    authDomain: "intellix-c1f9c.firebaseapp.com",
    projectId: "intellix-c1f9c",
    storageBucket: "intellix-c1f9c.appspot.com",
    messagingSenderId: "730661590456",
    appId: "1:730661590456:web:ab7d44ada5bb5b56b1ab4d",
    measurementId: "G-J6FH85XL03"
  };

  export const app = initializeApp(firebaseConfig);
  export const messaging = getMessaging(app)