import { useState, useEffect } from "react";
import LoaderComponent from "../Utils/LoaderComponent";
import Pagination from "../Utils/Pagination";
import { AUTH_BASE_URL } from "../Utils/AuthContextProvider";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import DebitSucessTransection from "./DebitSucessTransection";
import DebitFailedTransection from "./DebitFailedTransection";

const initLoader = {
  isLoading: false,
  isError: false,
};

const DebitTransection = ({phone}) => {
  const [Data, setData] = useState([]);
  const [loader, setLoader] = useState(initLoader);
  const [page, setPage] = useState(1);
  const [last_page, setLastPage] = useState(3);
  const { isLoading } = loader;

  const [value, setValue] = useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };



  const Api = async (x) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };
      const response = await fetch(`${AUTH_BASE_URL}/adsale/latest/transactions?phone=${phone}`, requestOptions)
      const result = await response.json();
      if (result.status == 1) {
        setData(result.latestTransactions);
      }

    } catch (error) {

    }
  }



  useEffect(() => {
    Api();
  }, [phone]);

  return (
    <>
      <div>
    
      <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Sucess Transections" value="1" />
            <Tab label="Failed Transections" value="2" />
            
          </TabList>
        </Box>
        <TabPanel value="1">
        <DebitSucessTransection />
          </TabPanel>
        <TabPanel value="2">
         <DebitFailedTransection /></TabPanel>
        
      </TabContext>
    </Box>

       
      </div>
    </>
  );
};

export default DebitTransection;
