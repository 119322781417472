import { useContext, useEffect, useState } from "react";
import { toast, ToastContainer } from 'react-toastify';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

import QuestionCard from "./QuestionCard";
import LoaderComponent from "../Utils/LoaderComponent";
import { AuthContext, QUIZ_BASE_URL } from "../Utils/AuthContextProvider";

const initLoader = {
  isLoading: false,
  isError: false,
};

const QuestionsData = () => {
  const [data, setData] = useState([]);
  const [subjectId, setSubjectId] = useState();
  const [loader, setLoader] = useState(initLoader);
  const { isLoading, isError } = loader;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState("")
  const { queRefresh, setQueRefresh } = useContext(AuthContext)

  const handleChangePagi = (event, value) => {
    setCurrentPage(value);
  };
  const show_subject = async () => {
    setLoader({ ...loader, isLoading: true });
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", ` Bearer ${localStorage.getItem("token")}`);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };
      const response = await fetch(`${QUIZ_BASE_URL}/questionbank/view/subject?page=${currentPage}&limit=10&search`, requestOptions)
      const result = await response.json();
      if (result.status == 1) {
        setData(result.subjects)
        setLoader({ ...loader, isLoading: false });
        setTotalPages(result.totalPages)
      } else if (result.status == "TOKEN_ERR") {
        toast.error(result.Backend_Error)
      } else if (result.status == "CUSTOM_ERR") {
        toast.error(result.Backend_Error)
      }
    } catch (error) {
      console.log(error);
    }
  }





  // const getData = async (token) => {
  //   setLoader({
  //     ...loader,
  //     isLoading: true,
  //   });
  //   try {
  //     let res = await axios.post(
  //       `https://brainbucks.co.in/api/admin/get-question-bank?token=${token}`
  //     );
  //     if (res.data.status === "001") {
  //       setData(res.data.data);
  //       setLoader({
  //         ...loader,
  //         isLoading: false,
  //       });
  //     } else {
  //       setLoader({
  //         ...loader,
  //         isLoading: false,
  //       });
  //     }
  //   } catch {
  //     setLoader({
  //       ...loader,
  //       isLoading: false,
  //     });
  //   }
  // };

  useEffect(() => {
    show_subject()
  }, [currentPage, queRefresh]);

  return (
    <>
      <div
        className="row"
        style={{
          height: "65vh",
          overflowY: "scroll",

        }}
      >
        {isLoading ? (
          <LoaderComponent />
        ) : (
          data?.map((res) => <QuestionCard key={res.id} {...res} refreshSubjects={show_subject}/>)
        )}
      </div>
      <div>
        <Stack spacing={2} className="d-flex justify-content-center mt-3 align-items-center" >
          <Pagination count={totalPages} page={currentPage} onChange={handleChangePagi} />
        </Stack>
      </div>
    </>
  );
};

export default QuestionsData;
