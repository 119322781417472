import React, { useEffect } from 'react';
import { Route,Routes} from 'react-router-dom';
import Home from './components/Home/Home';
import Exam from "./components/Exam/Exam";
import Study from "./components/Studymaterial/Study";
import Login from './components/Login/Login';
import User from './components/User/User';
import Talentfest from './components/Talentfest/Talentfest';
import Complaints from './components/Complaints/Complaints';
import Blog from './components/Blog/Blog';
import Sales from './components/Sales/Sales';
import Analytics from './components/Analytics/Analytics';
import Department from './components/User/Department';
import ParticularDeparment from './components/User/ParticularDeparment';
import Banner from './components/BannerImage/Banner.jsx';
import Task from './components/Task/Task';
import Room from './components/Room/Room';
import Withdrawal from './components/Withdrawal/Withdrawal';
import { WithdrawalContextProvider } from './components/Utils/WithdrawalContextProvider';
import Profile from './components/User/Profile.jsx';
// import CategoryStudy from './components/Studymaterial/CategoryStudy.jsx';
import "./App.css"
import Courses from './components/Courses/Courses.jsx';
import CoursesTab from './components/Courses/CoursesTab.jsx';
import ViewUsers from './components/User/ViewUsers.jsx';
import ChatRoom from './components/Complaints/ChatRoom.jsx';
import ChatroomPanel from './components/Complaints/ChatroomPanel.jsx';
import ViewDetails from './components/Sales/ViewDetails.jsx';
import Reels from './components/Reels/Reels.jsx';
import ViewReels from './components/Reels/ViewReels.jsx';



const App = () => {

  
  return (
   <>
 
      <Routes>
        <Route exact path='/' element={<Login/>}/>
        <Route exact path="/Home" element={<Home/>}/>
        <Route exact path="/Exam" element={<Exam/>}/>
        <Route exact path="/Study" element={<Study/>}/>
        {/* <Route exact path="/Catstudy" element={<CategoryStudy/>}/> */}
        <Route exact path="/User" element={<User/>}/>
        <Route exact path="/Talentfest" element={<Talentfest/>}/>
        <Route exact path="/Complaints" element={<Complaints/>}/>
        <Route exact path="/Blogs" element={<Blog/>}/>
        <Route exact path="/Sales" element={<Sales/>}/>
        <Route exact path="/Analytics" element={<Analytics/>}/>
        <Route exact path="/Courses" element={<Courses />}/>
        <Route exact path="/CoursesTab/:client_id" element={<CoursesTab />}/>
        <Route exact path='/department' element={<Department/>}/>
        <Route exact path='/department/:id' element={<ParticularDeparment/>}/>
        <Route exact path="/Banner" element={<Banner/>}/>
        <Route exact path="/Task" element={<Task/>}/>
        <Route exact path="/Rooms" element={<Room/>}/>
        <Route exact path="/chatRoom" element={<ChatRoom/>}/>
        <Route exact path="/viewdetails/:id" element={<ViewDetails />}/>
        <Route exact path="/chatroompanel/:id" element={<ChatroomPanel/>}/>
        <Route exact path="/profile/:id" element={<Profile/>}/>
        <Route exact path="/department/view/user/:id" element={<ViewUsers/>}/>
        <Route exact path="/reels" element={<Reels />}/>
        <Route exact path="/viewreels/:id" element={< ViewReels/>}/>
        <Route exact path='/withdrawal' element={
          <WithdrawalContextProvider>
        <Withdrawal/>
         </WithdrawalContextProvider>
        }/>
      </Routes>
     
   </>
  )
}

export default App;
