import { useState, useEffect, useContext } from "react";
import SearchIcon from "../../Img/ri_search-line.svg";
import NotificationIcon from "../../Img/pajamas_notifications.svg";
import Images from "../../Img/blank-profile-picture-973460__340.webp";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  AUTH_BASE_URL,
  AuthContext,
  PREREC_BASE_URL,
} from "../Utils/AuthContextProvider";
import { toast } from "react-toastify";
import { Navigate, useNavigate } from "react-router-dom";
import LoaderComponent from "../Utils/LoaderComponent";
import { Spinner } from "react-bootstrap";

const CoursesData = () => {
  const { logoutFun, handleShowChangePass } = useContext(AuthContext);
  let notification = 40;
  let Dates = new Date().toLocaleString();
  const initLoader = {
    isLoading1: false,
    isError1: false,
  };
  const [loader, setLoader] = useState(initLoader);
  const { isLoading } = loader;
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [clientData, setClientData] = useState([]);
  const [selectedClientId, setSelectedClientId] = useState("");
  const Navigate = useNavigate();

  // to add user model

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [isloader, setIsLoading] = useState(false)
  const handleShow = () => setShow(true);
  const [clientName, setClientName] = useState("");
  const [clientMobile, setClientMobile] = useState("");
  const [clientmail, setClientmail] = useState("");
  const [clientPassword, setClientPassword] = useState("");

  // Api to get all clients user data
  const clientDetail = async () => {
    setLoader({
      ...loader,
      isLoading: true,
    });
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${PREREC_BASE_URL}/admin/course/get/clients`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status == 1) {
          setClientData(result?.clients);
          setLoader({
            ...loader,
            isLoading: false,
          });
        } else if (result?.status == "TOKEN_ERR") {
          localStorage.removeItem("api_url");
          Navigate("/");
          toast("Logged in other device! Please login again");
        } else {
          console.log(result?.Backend_Error);
        }
      })
      .catch((error) => console.log("error", error));
  };

  // Api to add  new client
  const AddClient = () => {
    setIsLoading(true); // Start loading

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")} `);

    const raw = JSON.stringify({
      email: clientmail,
      password: clientPassword,
      name: clientName,
      phn_no: clientMobile,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${AUTH_BASE_URL}/auth/client/register`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === 1) {
          toast.success(result?.msg);
          handleClose();
          clientDetail();
          setClientName("");
          setClientMobile("");
          setClientmail("");
          setClientPassword("");
        } else {
          toast.error(result?.Backend_Error);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setIsLoading(false); // End loading
      });
  };

  useEffect(() => {
    clientDetail();
  }, []);

  return (
    <>
      <div>
        <div
          className="row mt-3 mb-2 ms-1"
          style={{ overflowY: "none", postition: "fixed" }}
        >
          
          <div className="col-6">
            <div className="row">
              <div className="" style={{width: "150px"}}>
                <span className="styled-heading">Courses</span>
              </div>
              {/* <div className="col-8" style={{ backgroundColor: "#fff" }}>
                <div
                  className="row"
                  style={{
                    border: "1px solid #D9D9D9",
                    height: "40px",
                    borderRadius: "7px",
                  }}
                >
                  <div className="col-2 mt-1">
                    <img alt="SearchIcon" src={SearchIcon} />
                  </div>
                  <div className="col-10 mt-1" style={{ marginLeft: "-20px" }}>
                    <input
                      className="border-0 w-100"
                      placeholder="Search within Dashboard"
                      style={{
                        textDecoration: "none",
                        outline: "none",
                        color: "#272727",
                      }}
                    />
                  </div>
                </div>
              </div> */}
              <div className="col-4"></div>
            </div>
          </div>
          <div className="col-6">
            <div
              className="d-flex"
              style={{
                justifyContent: "end",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              {/* <div
                className="d-flex"
                style={{
                  width: "80px",
                  height: "40px",
                  borderRadius: 20,
                  border: "1px solid #D9D9D9",
                  justifyContent: "center",
                  backgroundColor: "#F5F5F5",
                }}
              >
                <div className="mt-1">
                  <img alt="#" src={NotificationIcon} />
                </div>
                <div
                  className="ms-3"
                  style={{
                    width: "35px",
                    marginTop: "1px",
                    height: "35px",
                    borderRadius: "50%",
                    border: "1px solid #D9D9D9",
                    justifyContent: "center",
                    backgroundColor: "#CC1313",
                  }}
                >
                  <p
                    style={{
                      justifyContent: "center",
                      marginTop: "5px",
                      justifyItems: "center",
                      textAlign: "center",
                      color: "#fff",
                    }}
                  >
                    {notification} +{" "}
                  </p>
                </div>
              </div> */}
              <div
                className="d-flex ms-4"
                style={{ justifyContent: "space-between", marginRight: "30px" }}
              >
                <div
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    justifyContent: "center",
                    overflow: "hidden",
                  }}
                >
                  <img alt="" src={Images} width="100%" />
                </div>
                <div className="nav-item dropdown ms-2 mt-2 ">
                  <span
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  ></span>
                  <ul
                    className="dropdown-menu"
                    style={{ backgroundColor: "#000" }}
                  >
                                     <li class="dropdown-item text-white" style={{cursor:"pointer"}} onClick={handleShowChangePass}>Change Password</li> 
                    <hr style={{ color: "#fff" }} />
                    <li className="dropdown-item text-white">
                      <span onClick={() => logoutFun()}>Log-Out</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row mt-4">
            <div className="col-9 mb-4">
              <div className="row">
                <div className="col-2">
                  <span
                    style={{
                      fontWeight: "600",
                      color: "#000000",
                      fontSize: 17,
                      cursor: "pointer",
                    }}
                  >
                    Users
                  </span>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="row" style={{ marginRight: "20px" }}>
                <div className="col-2"></div>

                <div className="col-6">
                  <button
                    onClick={handleShow}
                    style={{
                      backgroundColor: "#EDF7FF",
                      border: "1px solid #2188E7",
                      height: "40px",
                      borderRadius: "10px",
                      color: "#2188E7",
                    }}
                  >
                    <span className="pe-2 ps-2"> +Add User</span>
                  </button>
                </div>
              </div>
            </div>

            {/* start here */}

            <div className="row">
              <div className="col-9">
                <div className="row">
                  <div
                    className="col-2"
                    style={{
                      borderBottom: "2px solid #000000",
                    }}
                  ></div>
                </div>
              </div>
              <div className="col-4"></div>
            </div>
            <hr />

            {/* end here */}
          </div>
        </div>
        <div className="container mt-4">
          <div className="row" style={{ height: "74vh", overflow: "auto" }}>
            {isLoading ? (
              <LoaderComponent />
            ) : (
              clientData.map((client, index) => {
                return (
                  <>
                    <div key={index} className="col-lg-4 mb-4">
                      <div className="card">
                        <div className="card-body">
                          <h5 className="card-title">{client.name}</h5>
                          <p
                            className="card-text"
                            style={{
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: "16px",
                              color: "#8A8A8A",
                            }}
                          >
                            Mobile No:
                            <span
                              style={{
                                color: "red",
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "16px",
                              }}
                            >
                              {client.phn_no}{" "}
                            </span>
                          </p>
                          <p
                            className="card-text"
                            style={{
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: "16px",
                              color: "#8A8A8A",
                            }}
                          >
                            Email:
                            <span
                              style={{
                                color: "red",
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "16px",
                              }}
                            >
                              {client.email}
                            </span>
                          </p>
                          <button
                            onClick={() => {
                              Navigate(`/CoursesTab/${client._id}`);
                            }}
                            className="btn btn-primary"
                          >
                            View Details
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })
            )}
          </div>
        </div>

        {/* Model to add user start  */}
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          style={{ backdropFilter: "blur(10px)" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="mb-3">
                <label for="formGroupExampleInput" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Name"
                  value={clientName}
                  onChange={(e) => setClientName(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label for="formGroupExampleInput" className="form-label">
                  Mobil Number
                </label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Enter Mobile Number"
                  value={clientMobile}
                  maxLength={10}
                  onChange={(e) => setClientMobile(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label for="exampleInputEmail1" className="form-label">
                  Email address
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter Email"
                  value={clientmail}
                  onChange={(e) => setClientmail(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label for="exampleInputPassword1" className="form-label">
                  Password
                </label>
                <input
                  type={passwordVisible ? "text" : "password"}
                  className="form-control"
                  id="exampleInputPassword1"
                  placeholder="Enter Password"
                  value={clientPassword}
                  onChange={(e) => setClientPassword(e.target.value)}
                />
              </div>
              <div class="mb-3 form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => setPasswordVisible(!passwordVisible)}
                />
                <label class="form-check-label" for="exampleCheck1">
                  Show Password
                </label>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={AddClient} disabled={isloader}>
              {isloader ? <Spinner/> : "Save"}
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Model to add user end */}
      </div>
    </>
  );
};

export default CoursesData;
