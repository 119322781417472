import FreeTabs from "./FreeTabs";
import Header from "../Header/Header";

const StudyMaterial = () => {
  const notification = 45;
  return (
    <>
      <Header />
      <div className="p-2">
        <FreeTabs />
      </div>
    </>
  );
};

export default StudyMaterial;
