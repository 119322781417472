import SearchIcon from "../../Img/ri_search-line.svg";
import { BsFilter } from "react-icons/bs";
import { AiOutlinePlus } from "react-icons/ai";
import mahakal from "../../Img/Group 10.png";
import Upcoming from "./Upcoming";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL, IMAGE_URL } from "../Utils/AuthContextProvider";
import { FaUser } from "react-icons/fa";
const Ongoing = () => {
  const Navigate = useNavigate()
  const [ongoingChat, setOngoingChat] = useState([]);

  const getOpenTickets = async () => {
    try {
      const token = localStorage.getItem("token"); 
      const response = await axios.get(
        `${BASE_URL}/adminchat/open/chats`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setOngoingChat(response?.data?.open_tickets);
      console.log()
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getOpenTickets();
  }, []);
  return (
    <>
      <div className="container-fluid" style={{
        flex: 1, margin: "10PX",
        padding: "20px"
      }}>

        <div className="row">
          <div className="col-12" style={{ backgroundColor: "#2c2c2c", }}>
            {/* Search Bar */}
            <h5 className="text-white text-center p-2 " style={{
              backgroundColor: " #5e5e5e",
              borderRadius: "20px",
              margin: "10px"
            }}>Ongoing</h5>
            <div className="row p-2">
              <div className="col-12">
                <div className="row" style={{ backgroundColor: "#333", borderRadius: "8px", }}>
                  <div className="col-1 mt-2">
                    <img alt="SearchIcon" src={SearchIcon} />
                  </div>
                  <div className="col-9 mt-2">
                    <input
                      className="border-0 w-100"
                      placeholder="Search for Participant"
                      style={{
                        textDecoration: "none",
                        outline: "none",
                        color: "#ccc",
                        backgroundColor: "#333",
                        padding: "5px",
                        borderRadius: "5px",
                        border: "none",
                      
                      }}
                    />
                  </div>
               
                </div>
              </div>
            </div>


            {/* Participants List */}
            <div className="row">
              <div className="col-12">
                <div style={{ overflowY: "scroll", height: "60vh", cursor: "pointer" }}>
                  {ongoingChat?.map((res, key) => (
                    <div
                      className="mb-2 px-2 py-2"
                      style={{
                        backgroundColor: "#333",
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "8px",
                      }}
                      key={key}
                      onClick={() => {
                        Navigate(`/chatroompanel/${res._id}`);
                      }}
                    >
                       {res.image != null ? (
  <img
    src={`${IMAGE_URL}${res.image}`}
    alt="#"
    style={{
      width: "45px",
      height: "45px",
      borderRadius: "50%",
      marginRight: "10px",
      
    }}
  />
) : (
  <FaUser
    style={{
      width: "45px",
      height: "45px",
      borderRadius: "50%",
      marginRight: "10px",
      color: "#fff",
      border: "1px solid"
    }}
  />
)}
                      <div className="chat-item">
                        <h6 className="text-white mb-0 font-weight-bold">{res.participant_name}</h6> 
                        <p className="chat-title mb-0">{res.title}</p> 
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </>
  );
};

export default Ongoing;
