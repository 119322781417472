import { useContext, useEffect, useState } from "react";
import TaskCard from "./TaskCard";
import TaskTableHead from "./TaskTableHead";
import { AUTH_BASE_URL, AuthContext } from "../Utils/AuthContextProvider";
import { toast } from "react-toastify";

const MyTask = () => {
  const [issue, setissue] = useState([]);
  const handleSelectChange = (value,id,date) => {
    update(value,id,date);
  }
  const {taskCount, setTaskCount} = useContext(AuthContext)

  const myApi = async () => {

    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

      const response = await fetch(`${AUTH_BASE_URL}/taskpanel/mytasks?searchQuery=&page=1&limit=9`, requestOptions)
      const result = await response.json();
      if (result.status == 1) {
        setissue(result.data)
        setTaskCount({
          ...taskCount , myTask : result?.total_results
        })
        
      } else {
        toast.error("failed")
      }
    } catch (error) {
      console.log(error)
    }
  }


  const update = async (value,id,date) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow"
      };

   const response = await fetch(` ${AUTH_BASE_URL}/taskpanel/change/status?id=${id}&status=${value}&due_date=${date}`, requestOptions)
    const result = await response.json();
    if(result.status==1){
      myApi()
    
    }else if(result.status=="INT_ERR"){
      toast.error(result.Backend_Error)
    }
    } catch (error) {
      console.log(error)
    }
  }
  
  useEffect(() => {
    myApi()
    // update()
  }, [])

  return (
    <div>
      <TaskTableHead />
      <div style={{ overflowY: "scroll", height: "50vh" }}>
        <TaskCard type={issue} handleSelectChange={handleSelectChange} need={1} />
      </div>
    </div>
  );
};

export default MyTask;
