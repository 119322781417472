import axios from "axios";
import React, { useContext, useEffect, useState} from "react";
import { useNavigate, useParams } from "react-router-dom";
import UserCard from "./UserCard";
import SideBar from "../SideBar/SideBar";
import Header from "../Header/Header";
import TaskSubHeader from "../Task/TaskSubHeader";
import SearchIcon from "../../Img/ri_search-line.svg";
import NotificationIcon from "../../Img/pajamas_notifications.svg";
import Images from "../../Img/blank-profile-picture-973460__340.webp";
import UserData from "../../components/User/UserData";
import { AUTH_BASE_URL, AuthContext } from "../Utils/AuthContextProvider";
import { ToastContainer, toast } from "react-toastify";
const ViewUsers = ( ) => {
const [userss,setuserss]=useState([])
const {id} = useParams();
const Navigate = useNavigate();
const view = async()=>{
  try {
    const myHeaders = new Headers();
myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);


const requestOptions = {
  method: "GET",
  headers: myHeaders,
  redirect: "follow"
};

const response = await fetch(`${AUTH_BASE_URL}/departmentpanel/view/users/department?department_id=${id}`, requestOptions)
const result = await response.json();
if(result.status==1){
  setuserss(result?.data)
}
  } catch (error) {
    console.log(error)
  }
}

  useEffect(() => {
  view()
  }, []);

  return (
    <div className="row">
    <div className="col-2">
      <SideBar />
    </div>
    <div className="col-10" style={{height: "100vh" }}>
      <div className="container" style={{height: "95vh"}}>
        <Header/>
       
       <div className="d-flex justify-content-between" style={{marginLeft:"50px", marginRight:"100px"}}>
        <h4>Showing Users</h4>
        <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                Navigate(-1)
              }}
              style={{
                fontSize: 18,
                backgroundColor: "#EDF7FF",
                padding: "8px 17px",
                border: "1px solid #2188E7",
                borderRadius: 10,
                color: "#2188E7",
                
              }}
            >
              Back
            </button>
            </div>
<div className="row" style={{height: "80vh",overflowY:"scroll"}}>
  {userss?.map((res, index) => (
    <div key={index} className="col-lg-5 col-md-5 mt-4 ms-5" >
      <div className="row"
        style={{
          backgroundColor: "#f5f5f5",
          padding: "1rem",
          borderRadius: "5px",
          height: "150px",
          
        }}
      >
        <div className="col-4" style={{ display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}
        >
          <h5 style={{ fontWeight: "300", fontSize: "17px", lineHeight: "20px",  }}>
            Name:
          </h5>
          <h5 style={{ fontWeight: "300", fontSize: "17px", lineHeight: "20px" }}>
            E-mail:
          </h5>
          <h5 style={{ fontWeight: "300", fontSize: "17px", lineHeight: "20px" }}>
            Salary:
          </h5>
        
        </div>
        <div className="col-8" style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' , display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}
        >
        <h5 style={{ fontWeight: "400", fontSize: "16px", lineHeight: "19px" , }}>
            {res.name}
          </h5>
          <h5 style={{ fontWeight: "400", fontSize: "17px", lineHeight: "19px" }}>
            {res.email}
          </h5>
          <h5 style={{ fontWeight: "400", fontSize: "16px", lineHeight: "19px" }}>
            {res.salary}
          </h5>
        
        </div>
        {/* <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h3 style={{ fontWeight: "300", fontSize: "17px", lineHeight: "20px" }}>
            E-mail:
          </h3>
          <h5 style={{ fontWeight: "400", fontSize: "17px", lineHeight: "19px" }}>
            {res.email}
          </h5>
        </div> */}
        {/* <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h3 style={{ fontWeight: "300", fontSize: "17px", lineHeight: "20px" }}>
            Salary:
          </h3>
          <h5 style={{ fontWeight: "400", fontSize: "16px", lineHeight: "19px" }}>
            {res.salary}
          </h5>
        </div> */}
      </div>
    </div>
  ))}
</div>

  
   
      </div>
    </div>
 


 

  </div>






  
  );
};

export default ViewUsers;
