import { useContext, useEffect, useState } from "react";
import Lottie from "lottie-react";
import Clebrate from "../../Img/lf20_VuglDs.json";
import { toast, ToastContainer } from 'react-toastify';
import { AuthContext, IMAGE_URL, QUIZ_BASE_URL, BB_URl } from "../Utils/AuthContextProvider";
import pro from "../../Img/Group 133.png"
import { Spinner } from "react-bootstrap";

const Question = (props) => {
  const { state } = useContext(AuthContext);
  const [first, setfirst] = useState(0);
  const [Change, setChange] = useState("");
  const [Data, setData] = useState([]);
  const [Value, setValue] = useState("");
  const [checked, setChecked] = useState(false);
  const [SubCategories, setSubCategories] = useState([]);
  const [Name, setName] = useState("");
  const [dataArray, setdataArray] = useState([]);
  const [Image, setImage] = useState();
  const [Names, setNames] = useState([]);
  const [Timer, setTimers] = useState(15);
  const [searchedCategory, setSearchedCategory] = useState([]);
  const [subcatname, setsubcatname] = useState([])
  const [sub_name, setsub_name] = useState([])
  const [cat_id, setcat_id] = useState("")
  const [sub_cat_id, setsub_cat_id] = useState("")
  const [view, setview] = useState([])
  const [img1, setImg1] = useState(pro);
  const [category, setcagegory] = useState([])
  const [check, setcheck] = useState([]);
  const { queRefresh, setQueRefresh } = useContext(AuthContext)
  const [checkLoading, setCheckLoading] = useState(false); 



const [categories, setCategories] = useState([])

  //required destructuring from above state objects
  const { token } = state;



  //function for moving one step forward in offcanvas
  const myFunction = (props) => {
    setfirst(first + 1);
  };

  //function for 
  const [checkedSubCate, setCheckedSubCate] = useState([]);

  const handleChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setCheckedSubCate((prev) => [...prev, value]);
    } else {
      setCheckedSubCate((prev) => prev.filter((item) => item !== value));
    }
  };


  //function for getting category list
  const categorylist = async () => {
    try {
      const category = await fetch(
        `${BB_URl}/api/admin/categorylist`
      );
      const Data = await category.json();
      //console.log(Data);
      setData(Data);
      setSearchedCategory(Data);
    } catch (error) {
      console.log(error);
    }
  };

    

  const viewcat = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow"
      };

      const response = await fetch(`${QUIZ_BASE_URL}/formfill/get/all/category?search=${categorySearch}`, requestOptions)
      const result = await response.json()
      if (result.status == 1) {
        setCategories(result.categories)
      } else if (result.status == "CUSTOM_ERR") {
        toast.error(result.Backend_Error)
      } else if (result.status == "VAL_ERR") {
        toast.error(result.Backend_Error)
      }
    } catch (error) {
      console.log(error);
    }
  }

  const add_subject = async () => {
    // checkLoading(true)
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")} `);

      const raw = JSON.stringify({
        "sub_name": sub_name,
        "cat_id": Value,
        "sub_cat_id": checkedSubCate
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      const response = await fetch(`${QUIZ_BASE_URL}/questionbank/add/subject`, requestOptions)
      const result = await response.json();
      if (result.status == 1) {
        toast.success(result.msg)
        setsub_name("");
        setValue("");
        setQueRefresh(!queRefresh)
        setCheckedSubCate([]);
        setfirst(0)

        props.Handle();
      } else if (result.status == "VAL_ERR") {
        toast.error(result.Backend_Error)
      } else if (result.status == "CUSTOM_ERR") {
        toast.error(result.Backend_Error)
      }

    } catch (error) {
      console.log(error);
    }
    // finally {
    //   setCheckLoading(false); // Stop loading spinner after request completes
    // }
  }

  //function for getting subcategory for selected category
  const Api1 = async () => {
    try {
      const war = await fetch(
        `${BB_URl}/api/admin/subcategorylist?parent_id=${Value}&token=${token}`
      );
      const response1 = await war.json();
      if (response1.status === 1) {
        // console.log(response1.message);
        // console.log(response1);
        setSubCategories(response1.data);
      } else {
        // alert(response1.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //function for adding in subject under selected category and sub category
  const Sub = async () => {
    var myHeaders = new Headers();
    myHeaders.append("location", "");

    var formdata = new FormData();
    formdata.append("subject_name", Change);
    formdata.append("category_id", Value);
    formdata.append("subcategory_id", dataArray);
    formdata.append("token", token);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${BB_URl}/api/admin/subjects`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 1) {
          setfirst(first + 1);
          setdataArray([]);
          setNames([]);
          setChecked(false);
          let interval = setInterval(() => {
            setTimers((lastTimerCount) => {
              lastTimerCount <= 1 && clearInterval(interval);
              return lastTimerCount - 1;
            });
          }, 1000);
          return () => {
            clearInterval(interval);
          };
        } else {
          alert(result.message);
          setdataArray([]);
          setNames([]);
        }
      })
      .catch((error) => console.log("error", error));
  };




  const [subcatdata, setsubcatdata] = useState([])

  const [subCatSearch, setSubCatSearch] = useState("")


  const subCategory = async (id) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")} `);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow"
      };


      const response = await fetch(`${QUIZ_BASE_URL}/formfill/get/all/subcategory?search=${subCatSearch}&cat_id=${id}`, requestOptions)
      const result = await response.json();
      if (result.status == 1) {
        setsubcatdata(result.sub_categories)
      } else if (result.status == "VAL_ERR") {
        toast.error(result.Backend_Error)
      } else if (result.status == "CUSTOM_ERR") {
        toast.error(result.Backend_Error)
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleSubCategorySearch = (e) => {
    const { value } = e.target;
    setSubCatSearch(value);
    subCategory(Value)
  }

  const [categorySearch, setCategorySearch] = useState("")
  //function for handle search for category while adding subjects
  const handleSearchCategory = (e) => {
    const { value } = e.target;
    setCategorySearch(value);
    // const newSearchedCategories = Data.filter((el) => (el.category_name).toLowerCase().startsWith(value.toLowerCase()));
    // //  console.log("filter",newSearchedCategories);
    // setSearchedCategory([...newSearchedCategories]);
  }


  //condition for timer is not intiated
  if (Timer == 0) {
    props.Handle();
  }

  useEffect(() => {
    // categorylist();
    viewcat();
  }, [categorySearch]);


  return (
    <div>
      <div style={{ display: first >= 4 ? "none" : "" }}>
        <span
          style={{ color: "#000", fontWeight: "600" }}
          onClick={() => {
            setfirst(0);
            setChange("");
            setdataArray([]);
            setNames([]);
            props.Handle();
          }}
        >
          <svg
            width="35"
            height="35"
            viewBox="0 0 35 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M27.4913 9.40793C27.616 9.28337 27.7149 9.13547 27.7825 8.97267C27.85 8.80987 27.8849 8.63535 27.885 8.45909C27.8851 8.28283 27.8505 8.10827 27.7831 7.94539C27.7158 7.7825 27.617 7.63448 27.4924 7.50977C27.3679 7.38505 27.22 7.2861 27.0572 7.21854C26.8944 7.15099 26.7199 7.11617 26.5436 7.11606C26.3673 7.11595 26.1928 7.15056 26.0299 7.21791C25.867 7.28527 25.719 7.38404 25.5943 7.5086L17.4999 15.6029L9.40793 7.5086C9.15607 7.25673 8.81446 7.11523 8.45827 7.11523C8.10207 7.11523 7.76047 7.25673 7.5086 7.5086C7.25673 7.76047 7.11523 8.10207 7.11523 8.45827C7.11523 8.81446 7.25673 9.15607 7.5086 9.40793L15.6029 17.4999L7.5086 25.5919C7.38389 25.7166 7.28496 25.8647 7.21747 26.0276C7.14997 26.1906 7.11523 26.3652 7.11523 26.5416C7.11523 26.718 7.14997 26.8926 7.21747 27.0556C7.28496 27.2185 7.38389 27.3666 7.5086 27.4913C7.76047 27.7431 8.10207 27.8846 8.45827 27.8846C8.63464 27.8846 8.80928 27.8499 8.97222 27.7824C9.13517 27.7149 9.28322 27.616 9.40793 27.4913L17.4999 19.3969L25.5943 27.4913C25.8461 27.7428 26.1876 27.884 26.5436 27.8838C26.8996 27.8836 27.2409 27.742 27.4924 27.4901C27.744 27.2382 27.8852 26.8967 27.885 26.5408C27.8848 26.1848 27.7431 25.8435 27.4913 25.5919L19.3969 17.4999L27.4913 9.40793Z"
              fill="black"
            />
          </svg>
        </span>
        <h3
          className="mt-2"
          style={{ marginLeft: "0px", fontWeight: "600", color: "#303030" }}
        >
          Add New Subject
        </h3>
      </div>
      <div style={{ display: first === 0 ? "block" : "none" }}>
        <div className="mb-5">



          <div className="progress" style={{ height: "10px" }}>
            <div
              className="progress-bar"
              role="progressbar"
              style={{
                width: "33%",
                background:
                  "linear-gradient(to right, #f44cc5, #ec40cc, #e335d3, #d72adb, #c922e4)",
              }}
              aria-valuenow="33"
              aria-valuemin="0"
              aria-valuemax="99"
            ></div>
          </div>


          <p style={{ color: "#8A8A8A" }}>
            1/3 <span sytle={{ color: "red" }}>Steps</span>
          </p>

          <div className="row">
            <div style={{ justifyConetnt: "center", textAlign: "center" }}>
              <div>
                <label
                  className="col-5 mb-2"
                  style={{
                    textAlign: "start",
                    color: "#303030",
                    fontWeight: "600",
                  }}
                >
                  Enter name of Subject
                </label>
                <br />
                <input
                  className="col-5 border-0"
                  style={{
                    textAlign: "start",
                    height: "50px",
                    backgroundColor: "#EFEFEF",
                    borderRadius: 7,
                    outline: 1,
                  }}
                  placeholder="  Start typing the name of subject"
                  value={sub_name}
                  onChange={(e) => {
                    setsub_name(e.target.value);
                  }}
                  type="text"
                  maxLength={100}
                />
                <br />
                <span style={{ marginLeft: "200px" }}>{sub_name.length}/100</span>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            justifyContent: "center",
            textAlign: "center",
            backgroundColor: "",
          }}
        >
          <button
            className="col-5 border-0 text-white"
            onClick={() => setfirst(1)}
            style={{
              backgroundColor: "#2188E7",
              height: "40px",
              borderRadius: 7,
            }}
            disabled={sub_name.length >= 2 ? false : true}
          >
            Proceed
          </button>
        </div>
      </div>
      <div style={{ display: first === 1 ? "block" : "none" }}>
        <div className="mb-5">
          <div className="progress" style={{ height: "10px" }}>
            <div
              className="progress-bar"
              role="progressbar"
              style={{
                width: "66%",
                background:
                  "linear-gradient(to right, #f44cc5, #ec40cc, #e335d3, #d72adb, #c922e4)",
              }}
              aria-valuenow="66"
              aria-valuemin="0"
              aria-valuemax="99"
            ></div>
          </div>
          <p style={{ color: "#8A8A8A" }}>
            2/3 <span sytle={{ color: "red" }}>Steps</span>
          </p>

          <div className="row">
            <div className="col-3"></div>
            <div className="col-4 card" style={{ width: "400px" }}>
              <div>
                <label
                  className="col-12 mb-2"
                  style={{
                    textAlign: "start",
                    color: "#303030",
                    fontWeight: "600",
                  }}
                >
                  Select Category
                </label>
                <br />
                <input
                  className="col-12 border-0 mb-4"
                  style={{
                    textAlign: "start",
                    height: "50px",
                    backgroundColor: "#EFEFEF",
                    borderRadius: 7,
                    outline: 1,
                  }}
                  placeholder="  Search for Exam Category"
                  // type="search"
                  onKeyUp={handleSearchCategory}
                />
                <br />
                {/* come here */}
                <div
                  style={{
                    height: "160px",
                    overflowY: "scroll",
                    justifyContent: "start",
                    alignItems: "start",
                  }}
                >
                  {categories?.map((res, key) => {
                    return (
                      <div
                        className="col-12"
                        style={{ display: "flex" }}
                        key={key}
                      >
                        <input
                          type="radio"
                          style={{ width: "20px" }}
                          name="same"
                          onClick={() => {
                            setValue(res._id);
                            setName(res.category_name);
                            // setImage(res.category_image);
                          }}
                        />
                        <div
                          className="ms-2"
                          style={{
                            width: "20px",
                            height: "20px",
                            borderRadius: "50%",
                            // justifyContent: "center",
                            overflow: "hidden",
                            marginTop: "12px",
                          }}
                        >
                          {/* <img alt="#" src={`${IMAGE_URL}${res.image}`} width="100%" /> */}
                          <img
                            style={{
                              width: "100%", // Ensure the image fills the container
                              // height: "100%", // Ensure the image fills the container
                              objectFit: "cover", // Fit the entire image within the container
                            }}
                            alt="#" src={`${IMAGE_URL}${res.image}`} />
                        </div>
                        <p className="mt-2 ms-2">{res.category_name}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <button
            className="col-6 ms-4 border-0 text-white"
            onClick={() => {
              subCategory(Value);

              setfirst(2);
            }}
            style={{
              backgroundColor: "#2188E7",
              height: "40px",
              borderRadius: 7,
            }}
            disabled={Name <= 0 ? true : false}
          >
            Proceed
          </button>
        </div>
      </div>
      <div style={{ display: first === 2 ? "block" : "none" }}>
        <div className="mb-5">
          <div className="progress" style={{ height: "10px" }}>
            <div
              className="progress-bar"
              role="progressbar"
              style={{
                width: "99%",
                background:
                  "linear-gradient(to right, #f44cc5, #ec40cc, #e335d3, #d72adb, #c922e4)",
              }}
              aria-valuenow="99"
              aria-valuemin="0"
              aria-valuemax="99"
            ></div>
          </div>
          <p style={{ color: "#8A8A8A" }}>
            3/3 <span sytle={{ color: "red" }}>Steps</span>
          </p>
          <p>
            <span style={{ fontWidth: 700, fontSize: 18, color: "#303030" }}>
              Categories :{" "}
            </span>
            {Name}
          </p>
          <div className="row">
            <div className="col-3"></div>
            <div className="col-4 card" style={{ width: "400px" }}>
              <div>
                <label
                  className="col-12 mb-2"
                  style={{
                    textAlign: "start",
                    color: "#303030",
                    fontWeight: "600",
                  }}
                >
                  Select Sub Categories
                </label>
                <br />
                <input
                  className="col-12 border-0 mb-4"
                  style={{
                    textAlign: "start",
                    height: "50px",
                    backgroundColor: "#EFEFEF",
                    borderRadius: 7,
                    outline: 1,
                  }}
                  placeholder="  Search for Exam Sub Category"
                  type="text"
                  onKeyUp={handleSubCategorySearch}
                />
                <br />

                <div
                  style={{
                    height: "160px",
                    overflowY: "scroll",
                    justifyContent: "start",
                    alignItems: "start",
                  }}
                >
                  {subcatdata.map((res, key) => {
                    return (
                      <div

                        className="col-12"
                        style={{ display: "flex" }}
                        key={key}
                      >

                        <input
                          style={{ color: "#F6F6F6" }}
                          type="checkbox"
                          id={res._id}
                          value={res._id}
                          checked={checkedSubCate.includes(res._id)}
                          onChange={(e) => handleChange(e)}
                        />
                        <p className="ms-2" style={{ marginTop: "9px" }}> {res.sub_category_name}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <button
            className="col-6 ms-4 border-0 text-white"
            onClick={() => {
              add_subject();
              console.log("count");
            }}
            style={{
              backgroundColor: "#2188E7",
              height: "40px",
              borderRadius: 7,
            }}
            // disabled={checkedSubCate.length >= 1 ? false : true}
          >
           {/* {checkLoading ? <Spinner /> : "Add"} */}
           Add
          </button>
        </div>
      </div>


      <div style={{ display: first === 3 ? "block" : "none" }}>
        <div className="mb-5">
          <div className="progress" style={{ height: "10px" }}>
            <div
              className="progress-bar"
              role="progressbar"
              style={{
                width: "100%",
                background:
                  "linear-gradient(to right, #f44cc5, #ec40cc, #e335d3, #d72adb, #c922e4)",
              }}
              aria-valuenow="50"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
          <p style={{ color: "#8A8A8A" }}>
            4/4 <span sytle={{ color: "red" }}>Steps</span>
          </p>

          <div className="row">
            <div style={{ justifyConetnt: "center", textAlign: "center" }}>
              <div style={{ justifyConetnt: "center", textAlign: "center" }}>
                <span>
                  New Subject "<strong>{Change}</strong>"" will be created{" "}
                  <br /> in "<strong>{Name}</strong>" Exam Category with
                  following
                  <br />
                  <strong>Sub Categories</strong>
                </span>
              </div>
              <div>
                {Names.map((item) => {
                  return (
                    <>
                      {/* <img src={Image} alt="#" style={{width:"10%"}}/> */}
                      <p>{item}</p>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            justifyContent: "center",
            textAlign: "center",
            backgroundColor: "",
          }}
        >
          <button
            className="col-5 border-0 text-white"
            onClick={Sub}
            style={{
              backgroundColor: "#21B867",
              height: "40px",
              borderRadius: 7,
            }}
          >
            Confirm
          </button>
        </div>
      </div>
      <div className="mt-5" style={{ display: first >= 4 ? "block" : "none" }}>
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: first > 3 ? "block" : "none",
          }}
        >
          <div style={{ textAlign: "center", alignItems: "center" }}>
            <h2 style={{ fontSize: "20px" }}>
              New Subject "<span>{Change}</span>"<br /> added Succesfully{" "}
            </h2>
          </div>
          <div
            className="d-flex"
            style={{ textAlign: "center", alignItems: "center" }}
          >
            <div className="col-3 ms-5"></div>
            <Lottie
              animationData={Clebrate}
              loop={true}
              style={{ width: "40%" }}
            />
          </div>
          <div style={{ textAlign: "center", alignItems: "center" }}>
            <button
              onClick={props.Handle}
              className="border-0 col-4 mt-4"
              style={{
                backgroundColor: "#0CBC8B",
                color: "#fff",
                height: "40px",
                borderRadius: 7,
              }}
            >
              Back to Dashboard
            </button>
          </div>
          <div
            className="mt-5"
            style={{ textAlign: "center", alignItems: "center" }}
          >
            <p style={{ color: "#8A8A8A" }}>
              This tab will automatically close in
              <span style={{ color: "#CC1313", fontSize: 20 }}> {Timer} </span>
              Seconds
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Question;
