import React, { useContext, useEffect, useState } from "react";
import TaskCard from "./TaskCard";
import TaskTableHead from "./TaskTableHead";
import { AUTH_BASE_URL, AuthContext } from "../Utils/AuthContextProvider";
import { toast } from "react-toastify";
const Complete = () => {
  const [cmplt, setcmplt] = useState([]);
  const {taskCount, setTaskCount} = useContext(AuthContext)
  const handleSelectChange = (value,id,date) => {
    update(value,id,date);
  }

  const update = async (value,id,date) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow"
      };

   const response = await fetch(` ${AUTH_BASE_URL}/taskpanel/change/status?id=${id}&status=${value}&due_date=${date}`, requestOptions)
   const result = await response.json();
    if(result.status==1){
      complete()
    }else if(result.status=="INT_ERR"){
      toast.error(result.Backend_Error)
    }
    } catch (error) {
      console.log(error)
    }
  }



  const complete = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

  const response = await fetch(` ${AUTH_BASE_URL}/taskpanel/completed/task?searchQuery=&page=1&limit=9`, requestOptions)
      const result = await response.json();
      if(result.status==1){
        setcmplt(result.data)
        setTaskCount({
          ...taskCount , complete : result?.total_results
        })
      }
      else{
        toast.error("failed");
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
   complete()
  }, [])
  
  return (
    <div>
      <TaskTableHead />
      <TaskCard type={cmplt} handleSelectChange={handleSelectChange} need={1} />

    </div>
  );
};

export default Complete;
