import { useContext, useState, useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import SearchIcon from "../../Img/ri_search-line.svg";
import NotificationIcon from "../../Img/pajamas_notifications.svg";
import Images from "../../Img/blank-profile-picture-973460__340.webp";
import UserData from "../../components/User/UserData";
import { AUTH_BASE_URL, AuthContext } from "../Utils/AuthContextProvider";
import TaskCard from "../Task/TaskCard";
import TaskTableHead from "../Task/TaskTableHead";
import { toast } from "react-toastify";
import {useNavigate, useParams} from "react-router-dom";
const Profile = () => {
    const {id} = useParams()
    console.log("Sdsdsd",id)
    const { logoutFun } = useContext(AuthContext);
    const notification = 20;
    const [first, setfirst] = useState(0);
    const [issue, setissue] = useState([]);
    const [profile, setprofile] = useState({dob: '1990-01-01T14:30:00'})
    const { taskCount, setTaskCount } = useContext(AuthContext)
    const Navigate = useNavigate();
    const { handleShowChangePass } = useContext(AuthContext);

    // const handleSelectChange = (value,id,date) => {
    //     update(value,id,date);
    //   }
    const myApi = async () => {

        try {
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow"
            };

            const response = await fetch(`${AUTH_BASE_URL}//userpanel/view/task?id=${id}`, requestOptions)
            const result = await response.json();
            if (result.status == 1) {
                setissue(result.data)
                setTaskCount({
                    ...taskCount, myTask: result?.total_results
                })

            } else {
                toast.error("failed")
            }
        } catch (error) {
            console.log(error)
        }
    }

    //   const update = async (value,id,date) => {
    //     try {
    //       const myHeaders = new Headers();
    //       myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

    //       const requestOptions = {
    //         method: "POST",
    //         headers: myHeaders,
    //         redirect: "follow"
    //       };

    //    const response = await fetch(` ${AUTH_BASE_URL}/taskpanel/change/status?id=${id}&status=${value}&due_date=${date}`, requestOptions)
    //     const result = await response.json();
    //     if(result.status==1){
    //       myApi()

    //     }else if(result.status=="INT_ERR"){
    //       toast.error(result.Backend_Error)
    //     }
    //     } catch (error) {
    //       console.log(error)
    //     }
    //   }

    const view_profile = async () => {
        try {
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow"
            };

            const response = await fetch(`${AUTH_BASE_URL}/userpanel/view/profile?id=${id}`, requestOptions)
            const result = await response.json();
            if (result.status == 1) {
                setprofile(result.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        myApi()
    }, [])


    return (
        <div>
            <div>
                <div className="row">
                    <div className="col-2">
                        <SideBar />
                    </div>
                    <div className="col-10">
                        <div className="container">
                            {/* top bar start */}
                        
                            <div
                                className="row mt-3 mb-2 ms-1"
                                style={{
                                    overflowY: "none",
                                    postition: "fixed",
                                    marginRight: "20px",
                                }}
                            >
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-8">
                                            {/* <div
                                                className="row"
                                                style={{
                                                    border: "1px solid #D9D9D9",
                                                    height: "40px",
                                                    borderRadius: "7px",
                                                }}
                                            >
                                                <div className="col-2 mt-1">
                                                    <img alt="SearchIcon" src={SearchIcon} />
                                                </div>
                                                <div
                                                    className="col-10 mt-1"
                                                    style={{ marginLeft: "-20px" }}
                                                >
                                                    <input
                                                        className="border-0 w-100"
                                                        placeholder="Search within user"
                                                        style={{
                                                            textDecoration: "none",
                                                            outline: "none",
                                                            color: "#272727",
                                                        }}
                                                    />
                                                </div>
                                            </div> */}
                                        </div>
                                        <div className="col-4"></div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div
                                        className="d-flex"
                                        style={{
                                            justifyContent: "end",
                                            textAlign: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        {/* <div
                                            className="d-flex"
                                            style={{
                                                width: "90px",
                                                height: "40px",
                                                borderRadius: 20,
                                                border: "1px solid #D9D9D9",
                                                justifyContent: "center",
                                                backgroundColor: "#F5F5F5",
                                            }}
                                        >
                                            <div className="mt-1">
                                                <img
                                                    alt="#"
                                                    src={NotificationIcon}
                                                    style={{ marginLeft: "10px" }}
                                                />
                                            </div>
                                            <div
                                                className="ms-3"
                                                style={{
                                                    width: "35px",
                                                    marginTop: "1px",
                                                    height: "35px",
                                                    borderRadius: "50%",
                                                    border: "1px solid #D9D9D9",
                                                    justifyContent: "center",
                                                    backgroundColor: "#CC1313",
                                                    color: "white"
                                                }}
                                            >
                                                <p
                                                    style={{
                                                        justifyContent: "center",
                                                        marginTop: "5px",
                                                        justifyItems: "center",
                                                        textAlign: "center",
                                                        color: "#fff",
                                                    }}
                                                >
                                                    {notification} +{" "}
                                                </p>
                                            </div>
                                        </div> */}
                                        <div
                                            className="d-flex ms-4"
                                            style={{ justifyContent: "space-between" }}
                                        >
                                            <div
                                                style={{
                                                    width: "40px",
                                                    height: "40px",
                                                    borderRadius: "50%",
                                                    justifyContent: "center",
                                                    overflow: "hidden",
                                                }}
                                            >
                                                <img alt="" src={Images} width="100%" />
                                            </div>
                                            <div class="nav-item dropdown ms-2 mt-2">
                                                <span
                                                    class="nav-link dropdown-toggle"
                                                    href="#"
                                                    role="button"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                ></span>
                                                 <ul
                          class="dropdown-menu"
                          style={{ backgroundColor: "#000" }}
                        >
                                           <li class="dropdown-item text-white" style={{cursor:"pointer"}} onClick={handleShowChangePass}>Change Password</li>

                          <hr style={{ color: "#fff" }} />
                          <li class="dropdown-item text-white">
                            <span onClick={() => logoutFun()}>Log-Out</span>
                          </li>
                        </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* top bar end */}

 <div className="container" style={{ overflowY: "scroll" }}>
 <div className='d-flex justify-content-between align-items-center mt-3 border'>
    <div className='d-flex'>
        <h6
            style={{ cursor: 'pointer' }}
            onClick={() => setfirst(0)}
            className={first === 0 ? 'active' : ''}
        >
            <p className='px-3 mt-3' style={{ fontSize: '18px', justifyContent: 'center', alignItems: 'center' }}>Tasks</p>
        </h6>
        <h6
            style={{ cursor: 'pointer' }}
            onClick={() => setfirst(1)}
            className={first === 1 ? 'active' : ''}
        >
            <p className='px-3 mt-3' style={{ fontSize: '18px', justifyContent: 'center', alignItems: 'center' }} onClick={() => { view_profile() }}>Profile</p>
        </h6>
    </div>
    <button
        type="button"
        className="btn btn-light"
        onClick={() => {
            Navigate(-1);
        }}
        style={{
            fontSize: 18,
            backgroundColor: "#EDF7FF",
            padding: "10px",
            border: "1px solid #2188E7",
            borderRadius: 10,
            color: "#2188E7",
            marginRight: "10px",
        }}
    >
        Back
    </button>
</div>

                                
                                <div className="row mb-3" style={{ height: "84vh", overflowY: "scroll" }}>
                                    <div className='col-lg-12'>
                                        {
                                            first == 0 ?
                                                <>
                                                    <div className='mt-5'>
                                                        <div class="container">
                                                            <div class="row">
                                                                <TaskTableHead />
                                                                <div style={{ overflowY: "scroll", height: "50vh" }}>
                                                                    <TaskCard type={issue} need={1} />
                                                                </div>
                                                                <div>

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </> : first == 1 ? <>
                                                    <div className='container'>
                                                        <div className='row mt-3 text-center'>
                                                            <div className='col-lg-12 border d-flex flex-column align-items-center justify-content-center' style={{ height: "70vh" }}>
                                                                <div
                                                                    style={{
                                                                        borderRadius: "50%",
                                                                        width: "150px",
                                                                        border: "1px solid #808080",
                                                                        height: "150px",
                                                                    }}
                                                                >
                                                                    <img

                                                                        src={Images}
                                                                        style={{
                                                                            width: "100%",
                                                                            height: "100%",
                                                                            objectFit: "cover",
                                                                            borderRadius: "50%",
                                                                        }}
                                                                        alt=""
                                                                    />
                                                                </div>

                                                                <div class="card-body  mt-2 d-flex">
                                                                    <table>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td className='td'>Name</td>
                                                                                <td> : </td>
                                                                                <td>{profile.name}</td>
                                                                                {/* <td className='pd'><button to=''><i class="bi bi-pencil" style={{ color: '#fff' }}></i></button></td> */}
                                                                            </tr>
                                                                            <tr>
                                                                                <td className='td'>Qualifications</td>
                                                                                <td> :    </td>
                                                                                <td> {profile.qualification}</td>
                                                                                {/* <td className='pd'><button to=''><i class="bi bi-pencil" style={{ color: '#fff' }}></i></button></td> */}
                                                                            </tr>
                                                                            <tr>
                                                                                <td className='td'>Institute Name</td>
                                                                                <td>:</td>
                                                                                <td>{profile.instituteName}</td>
                                                                                {/* <td className='pd'><button to=''><i class="bi bi-pencil" style={{ color: '#fff' }}></i></button></td> */}
                                                                            </tr>

                                                                            <tr>
                                                                                <td className='td'>Email</td>
                                                                                <td>:</td>
                                                                                <td style={{ whiteSpace: 'normal' }}>{profile.email}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className='td'>DOB</td>
                                                                                <td>:</td>
                                                                                <td>{profile.dob.split('T')[0]}</td>
    
                                                                                {/* <td className='pd'><button to=''><i class="bi bi-pencil" style={{ color: '#fff' }}></i></button></td> */}
                                                                            </tr>
                                                                            <tr>
                                                                                <td className='td'>State</td>
                                                                                <td>:</td>
                                                                                <td>{profile.state}</td>
                                                                                {/* <td className='pd'><button to=''><i class="bi bi-pencil" style={{ color: '#fff' }}></i></button></td> */}
                                                                            </tr>
                                                                            <tr>
                                                                                <td className='td'>City</td>
                                                                                <td>:</td>
                                                                                <td>{profile.city}</td>
                                                                                {/* <td className='pd'><button to=''><i class="bi bi-pencil" style={{ color: '#fff' }}></i></button></td> */}
                                                                            </tr>
                                                                            <tr>
                                                                                <td className='td'>Pincode</td>
                                                                                <td>:</td>
                                                                                <td>{profile.pincode}</td>
                                                                                {/* <td className='pd'><button to=''><i class="bi bi-pencil" style={{ color: '#fff' }}></i></button></td> */}
                                                                            </tr>
                                                                            <tr>
                                                                                <td className='td'>Locality</td>
                                                                                <td>:</td>
                                                                                <td>{profile.locality}</td>
                                                                                {/* <td className='pd'><button to=''><i class="bi bi-pencil" style={{ color: '#fff' }}></i></button></td> */}
                                                                            </tr>
                                                                            <tr>
                                                                                <td className='td'>House Number</td>
                                                                                <td>:</td>
                                                                                <td>{profile.houseNumber}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className='td'> Contact Number</td>
                                                                                <td>:</td>
                                                                                <td>{profile.contact}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </> : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Profile
