import { useContext, useEffect, useState } from "react";
import SearchIcon from "../../Img/ri_search-line.svg";
import ProfileIcons from "../../Img/Group 137.png";
import NotificationIcon from "../../Img/pajamas_notifications.svg";
import Images from "../../Img/blank-profile-picture-973460__340.webp";
import ExamTabes from "./ExamTabes";
import { useNavigate } from "react-router-dom";
import { AUTH_BASE_URL, AuthContext, BB_URl } from "../Utils/AuthContextProvider";
import { toast } from "react-toastify";
import { Modal, Button, Form, Spinner } from 'react-bootstrap';

const ExamData = () => {
  const { logoutFun } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const notification = 45;
  let Navigate = useNavigate();
  const [Token, setToken] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    setPassword('');
    setConfirmPassword('');
  }
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const LogOut = () => {
    var formdata = new FormData();
    formdata.append("token", Token);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(`${BB_URl}/api/admin/admin-logout`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.status === "001") {
          localStorage.removeItem("token");
          Navigate("/");
        } else {
          toast(result.message);
        }
      })
      .catch((error) => console.log("error", error));
  };

  // Chnange Password
  const chnagePassword = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

    const raw = JSON.stringify({
      "password": password,
      "confirmpassword": confirmPassword
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${AUTH_BASE_URL}/auth/admin/change/password`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status == 1) {
          toast(result?.message);
          handleClose();

        } else {
          toast(result?.Backend_Error);
        }
      })
      .catch((error) => console.log("error", error));
  };
  const handleLogout = async () => {
    setLoading(true);
    try {
      await logoutFun();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, []);
  return (
    <>
      <div
        className="row mt-3 mb-2 ms-1"
        style={{ overflowY: "none", postition: "fixed", marginRight: "20px" }}
      >
        <div className="col-6">
          <div className="row">
            <div className="" style={{ width: "250px" }}>
              <span className="styled-heading">Exam & Quiz </span>
            </div>
            {/* <div className="col-8">
              <div
                className="row"
                style={{
                  border: "1px solid #D9D9D9",
                  height: "40px",
                  borderRadius: "7px",
                }}
              >
                <div className="col-2 mt-1">
                  <img alt="SearchIcon" src={SearchIcon} />
                </div>
                <div className="col-10 mt-1" style={{ marginLeft: "-20px" }}>
                  <input
                    className="border-0 w-100"
                    placeholder="Search within Dashboard"
                    style={{
                      textDecoration: "none",
                      outline: "none",
                      color: "#272727",
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="row">
                <div className="col-6">
                  <img alt="#" src={ProfileIcons} style={{ width: "85%" }} />
                </div>
                <div className="col-6">
                  <div
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                      border: "1px solid #D9D9D9",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      style={{
                        justifyContent: "center",
                        marginTop: "10px",
                        justifyItems: "center",
                        textAlign: "center",
                        fontSize: "14px",
                      }}
                    >
                      + {notification}
                    </p>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="col-6">
          <div
            className="d-flex"
            style={{
              justifyContent: "end",
              textAlign: "center",
              alignItems: "center",
            }}
          >
            {/* <div
              className="d-flex"
              style={{
                width: "90px",
                height: "40px",
                borderRadius: 20,
                border: "1px solid #D9D9D9",
                justifyContent: "center",
                backgroundColor: "#F5F5F5",
              }}
            >
              <div className="mt-1">
                <img
                  alt="#"
                  src={NotificationIcon}
                  style={{ marginLeft: "10px" }}
                />
              </div>
              <div
                className="ms-3"
                style={{
                  width: "35px",
                  marginTop: "1px",
                  height: "35px",
                  borderRadius: "50%",
                  border: "1px solid #D9D9D9",
                  justifyContent: "center",
                  backgroundColor: "#CC1313",
                }}
              >
                <p
                  style={{
                    justifyContent: "center",
                    marginTop: "5px",
                    justifyItems: "center",
                    textAlign: "center",
                    color: "#fff",
                  }}
                >
                  {notification} +{" "}
                </p>
              </div>
            </div> */}
            <div
              className="d-flex ms-4"
              style={{ justifyContent: "space-between" }}
            >
              <div
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  justifyContent: "center",
                  overflow: "hidden",
                }}
              >
                <img alt="" src={Images} width="100%" />
              </div>
              <div class="nav-item dropdown ms-2 mt-2 ">
                <span
                  class="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                ></span>
                <ul class="dropdown-menu" style={{ backgroundColor: "#000" }}>
                  {/* <li class="dropdown-item text-white">View Profile</li> */}
                  {/* <hr style={{ color: "#fff" }} /> */}
                  <li class="dropdown-item text-white" style={{ cursor: "pointer" }} onClick={handleShow}>Change Password</li>
                  <hr style={{ color: "#fff" }} />
                  <li className="dropdown-item text-white" style={{ cursor: "pointer" }}>
                    <span onClick={handleLogout}>
                      {loading ? <Spinner /> : "Log-Out"}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-2">
        <ExamTabes />
        {/* model for log out */}
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Change Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formPassword">
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter your password"
                  value={password}
                  onChange={handlePasswordChange}
                />
              </Form.Group>
              <Form.Group controlId="formConfirmPassword">
                <Form.Label className="mt-2">Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Confirm your password"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={chnagePassword}>Confirm</Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default ExamData;
