import { useState, useEffect, useContext } from "react";
import SearchIcon from "../../Img/ri_search-line.svg";
import NotificationIcon from "../../Img/pajamas_notifications.svg";
import Images from "../../Img/blank-profile-picture-973460__340.webp";
import Overview from "./Overview";
import { AUTH_BASE_URL, AuthContext } from "../Utils/AuthContextProvider";
import Transitions from "./Transitions";
import Expenses from "./Expenses";
import InCome from "./InCome";
import { ToastContainer } from "react-toastify";
import Chat1 from "../../Img/mouth-face.png";
import { BsArrowRight } from "react-icons/bs";
import Chat31 from "../../Img/money-bag.png";
import Chat3 from "../../Img/chart3.jpg";
import { IoMdClose } from "react-icons/io";

import CreditTransitions from "./CreditTransitions";

import DebitTransection from "./DebitTransection";

const SalesData = () => {
  const { logoutFun, handleShowChangePass } = useContext(AuthContext);
  let notification = 40;
  let Dates = new Date().toLocaleString();
  console.log(Dates);
  const [first, setfirst] = useState("");
  const [first1, setfirst1] = useState("");
  const [first2, setfirst2] = useState("");
  const [Sales, setSales] = useState();
  const { salesSearch, setSalesSearch } = useContext(AuthContext);

  const Api = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("token")}`
      );
      // console.log(myHeaders,)
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        `${AUTH_BASE_URL}/account/overview/statics`,
        requestOptions
      );
      const result = await response.json();
      if (result?.status == 1) {
        setfirst(result.total_in_money);
        setfirst1(result.total_out_money);
        setfirst2(result.total_remaining_money);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    Api();
  }, []);

  useEffect(() => {
    setSales("Transactions");
  }, []);

  return (
    <div>
      <div
        className="row mt-3 mb-2 ms-1"
        style={{ overflowY: "none", postition: "fixed" }}
      >
        <div className="col-6">
          <div className="row">
            <div className="col-8" style={{ backgroundColor: "#fff" }}>
            <div className="" style={{width: "150px"}}>
                <span className="styled-heading">Sales</span>
              </div>
            </div>
            <div className="col-4"></div>
          </div>
        </div>
        <div className="col-6">
          <div
            className="d-flex"
            style={{
              justifyContent: "end",
              textAlign: "center",
              alignItems: "center",
            }}
          >
            {/* <div
              className="d-flex"
              style={{
                width: "80px",
                height: "40px",
                borderRadius: 20,
                border: "1px solid #D9D9D9",
                justifyContent: "center",
                backgroundColor: "#F5F5F5",
              }}
            >
              <div className="mt-1">
                <img alt="#" src={NotificationIcon} />
              </div>
              <div
                className="ms-3"
                style={{
                  width: "35px",
                  marginTop: "1px",
                  height: "35px",
                  borderRadius: "50%",
                  border: "1px solid #D9D9D9",
                  justifyContent: "center",
                  backgroundColor: "#CC1313",
                }}
              >
                <p
                  style={{
                    justifyContent: "center",
                    marginTop: "5px",
                    justifyItems: "center",
                    textAlign: "center",
                    color: "#fff",
                  }}
                >
                  {notification} +{" "}
                </p>
              </div>
            </div> */}
            <div
              className="d-flex ms-4"
              style={{ justifyContent: "space-between", marginRight: "30px" }}
            >
              <div
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  justifyContent: "center",
                  overflow: "hidden",
                }}
              >
                <img alt="" src={Images} width="100%" />
              </div>
              <div class="nav-item dropdown ms-2 mt-2 ">
                <span
                  class="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                ></span>
                <ul class="dropdown-menu" style={{ backgroundColor: "#000" }}>
                <li class="dropdown-item text-white" style={{cursor:"pointer"}} onClick={handleShowChangePass}>Change Password</li>

                  <hr style={{ color: "#fff" }} />
                  <li class="dropdown-item text-white">
                    <span onClick={() => logoutFun()}>Log-Out</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Card data of total money and total monwy out */}
      <div>
        <ToastContainer />
        <div className="row">
          <div className="col-ms-12 col-md-4 col-lg-4">
            <div
              className="card"
              style={{ borderRadius: "10px", border: "2px solid #D9D9D9" }}
            >
              <div className="row">
                <div className="col-3">
                  <img
                    src={Chat1}
                    alt="hello"
                    style={{ width: "70%", margin: "13px" }}
                  />
                </div>
                <div className="col-6">
                  <p
                    className="mt-2"
                    style={{
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "13px",
                      lineHeight: "19px",
                      color: "#8A8A8A",
                    }}
                  >
                    Total Money In
                  </p>
                  <p
                    style={{
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "16px",
                      color: "#333333",
                    }}
                  >
                    ₹ {first}
                  </p>
                </div>
                <div className="col-3 mt-4">
                  <BsArrowRight />
                </div>
              </div>
            </div>
          </div>
          <div className="col-ms-12 col-md-4 col-lg-4">
            <div
              className="card"
              style={{ borderRadius: "10px", border: "2px solid #D9D9D9" }}
            >
              <div className="row">
                <div className="col-3">
                  <img
                    src={Chat31}
                    alt="hello"
                    style={{ width: "70%", margin: "13px" }}
                  />
                </div>
                <div className="col-6">
                  <p
                    className="mt-2"
                    style={{
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "13px",
                      lineHeight: "19px",
                      color: "#8A8A8A",
                    }}
                  >
                    Total Money Out
                  </p>
                  <p
                    style={{
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "16px",
                      color: "#333333",
                    }}
                  >
                    ₹ {first1}
                  </p>
                </div>
                <div className="col-3 mt-4">
                  <BsArrowRight />
                </div>
              </div>
            </div>
          </div>
          <div className="col-ms-12 col-md-4 col-lg-4">
            <div
              className="card"
              style={{ borderRadius: "10px", border: "2px solid #D9D9D9" }}
            >
              <div className="row">
                <div className="col-3">
                  <img
                    src={Chat3}
                    alt="hello"
                    style={{ width: "70%", margin: "13px" }}
                  />
                </div>
                <div className="col-6">
                  <p
                    className="mt-2"
                    style={{
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "13px",
                      lineHeight: "19px",
                      color: "#8A8A8A",
                    }}
                  >
                    Total Amount Remaining
                  </p>
                  <p
                    style={{
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "16px",
                      color: "#333333",
                    }}
                  >
                    ₹ {first2}
                  </p>
                </div>
                <div className="col-3 mt-4">
                  <BsArrowRight />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Card data end here */}

      <div className="row mt-4">
        <div className="col-12 mb-4">
          {/* Modified this row to include align-items-center for vertical alignment */}
          <div className="row align-items-center">
            <div className="col-9">
              <div className="row">
                <div className="col-3">
                  <span
                    style={{
                      fontWeight: Sales === "Transactions" ? "600" : "400",
                      color: Sales === "Transactions" ? "#000000" : "#8A8A8A",
                      fontSize: 17,
                      cursor: "pointer",
                    }}
                    onClick={() => setSales("Transactions")}
                  >
                    Latest Transactions
                  </span>
                </div>
                <div className="col-3">
                  <span
                    style={{
                      fontWeight: Sales === "Overview" ? "600" : "400",
                      color: Sales === "Overview" ? "#000000" : "#8A8A8A",
                      fontSize: 17,
                      cursor: "pointer",
                    }}
                    onClick={() => setSales("Overview")}
                  >
                    Debit Transactions
                  </span>
                </div>
                <div className="col-3">
                  <span
                    style={{
                      fontWeight: Sales === "Credit" ? "600" : "400",
                      color: Sales === "Credit" ? "#000000" : "#8A8A8A",
                      fontSize: 17,
                      cursor: "pointer",
                    }}
                    onClick={() => setSales("Credit")}
                  >
                    Credit Transactions
                  </span>
                </div>
              </div>
            </div>
            {/* Moved search bar div here to align with the tab row */}
            <div className="col-3">
              <div
                className="row align-items-center"
                style={{
                  border: "1px solid #D9D9D9",
                  height: "40px",
                  borderRadius: "7px",
                  display: "flex"
                }}
              >
                <div className="col-1 d-flex justify-content-center align-items-center">
                  <img alt="SearchIcon" src={SearchIcon} />
                </div>
                <div className="col-9">
                  <input
                    className="border-0 w-100"
                    placeholder="Search for Transaction"
                    style={{
                      textDecoration: "none",
                      outline: "none",
                      color: "#272727",
                      height: "100%",
                    }}
                    value={salesSearch}
                    onChange={(e) => {
                      setSalesSearch(e.target.value);
                    }}
                  />
                </div>

              {salesSearch && (
                   <div 
                   className="col-1 justify-content-center align-items-center"  
                   style={{ cursor: "pointer" }}  
                   onClick={() => setSalesSearch("")}  
                 >
                           <IoMdClose
                             style={{
                               fontSize: "25px",
                             }}
                           />
                           {/* <button
                       className="border-0 bg-white"
                       style={{
                         background: "linear-gradient(180deg, rgba(35,176,101,1) 0%, rgba(46,135,96,1) 100%)",
                         color: "#fff",
                         height: "40px",
                         borderTopRightRadius: 10,
                         borderBottomRightRadius: 10,
                       }}
                      
                     >
                       Search
                     </button> */}
                         </div>
              )}
                


              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="row">
              <div
                className="col-2"
                style={{
                  borderBottom:
                    Sales === "Transactions" ? "2px solid #000000" : "",
                }}
              ></div>
              <div
                className="col-2"
                style={{
                  borderBottom: Sales === "Overview" ? "2px solid #000000" : "",
                }}
              ></div>
              <div
                className="col-3"
                style={{
                  borderBottom: Sales === "Credit" ? "2px solid #000000" : "",
                }}
              ></div>
            </div>
          </div>
        </div>
        <hr />
      </div>

      {Sales === "Overview" ? (
        <div>
          <DebitTransection />
        </div>
      ) : (
        <></>
      )}

      {Sales === "Credit" ? (
        <div>
          <CreditTransitions />
        </div>
      ) : (
        <></>
      )}

      {Sales === "Transactions" ? (
        <div>
          <Transitions />
        </div>
      ) : (
        <></>
      )}
      <div></div>
    </div>
  );
};

export default SalesData;
