import { useState, useEffect } from "react";
import SearchIcon from "../../Img/ri_search-line.svg";
import { FaArrowRight } from "react-icons/fa";
import { AiFillEdit } from "react-icons/ai";
import { IoFilterSharp } from "react-icons/io5";
import FreePdfData from "./FreePdfData";
import Lottie from "lottie-react";
import Clebrate from "../../Img/lf20_VuglDs.json";
import PdfImg from "../../Img/pdf.svg";
import "./FreePdf1.css";
import Thaks from "../../Img/lf20_snmbndsh.json";
import Offcanvas from "react-bootstrap/Offcanvas";
import EmptyContainer from "../Utils/EmptyContainer";
import LoaderComponent from "../Utils/LoaderComponent";
import { toast } from "react-toastify";
import { Form, Spinner } from "react-bootstrap";
import { BB_URl, IMAGE_URL, PREREC_BASE_URL, QUIZ_BASE_URL } from "../Utils/AuthContextProvider";
import Table from "react-bootstrap/Table";
import { MdDelete } from "react-icons/md";
import { Modal } from "react-bootstrap";

import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

//initial conditions for loader;
const initLoader = {
  isLoading: false,
  isError: false,
};

//initial pdf form data;
const initUpdatedSTM = {
  pdf: "",
  pdf1: "",
  pdf_cat: "",
  category_id: "",
  display_name: "",
  pdf_id: "",
};

const FreePdf = () => {
  const [category_id, setcategory_id] = useState("")
  const [pdf_type_id, setPdfTypeID] = useState("")
  const [ImgCat, setImgCat] = useState("");
  const [CatName, setCatName] = useState("");
  const [NameCat, setNameCat] = useState("");
  const [Token, setToken] = useState("");
  const [Click, setClick] = useState(0);
  const [Categories, setCategories] = useState([]);
  const [File, setFile] = useState({
    name: "",
  });
  const [categories, setcategories] = useState([])
  const [File1, setFile1] = useState();
  const [Name, setName] = useState("");
  const [CatId, setCatId] = useState("");
  const [Value, setValue] = useState("");
  const [View, setView] = useState(0);
  const [het, setHet] = useState([]);
  const [IdFor, setIdFor] = useState("");
  const [hets, setHets] = useState([]);
  const [Display, setDisplay] = useState(0);
  const [Category, setCategory] = useState(0);
  const [DisplayChag, setDisplayChag] = useState("");
  const [CategoryChag, setCategoryChag] = useState("");
  const [PdfChan1, setPdfChan1] = useState("");
  const [PdfChan, setPdfChan] = useState("");
  const [DisplayChan, setDisplayChan] = useState("");
  const [ApiIds, setApiIds] = useState("");
  const [DeleteId, setDeleteId] = useState("");
  const [show, setShow] = useState(false);
  const [Timers1, setTimers1] = useState(15);
  const [Notes_id, setNotes_id] = useState();
  const [toSearch, setToSearch] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [loader, setLoader] = useState(initLoader);
  const [filterCategories, setFilteerCatories] = useState();
  const [updatedSTM, setUpdatedSTM] = useState([]);
  const [replaced_file, setReplacedFile] = useState();

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0)
  const [materialSearch, setMatSearch] = useState("")
  const [materialData, setMatData] = useState([])
  const [isLoadingMaterial, setLoadingMaterial] = useState(false)

  //required destructuring from the above state objects
  const { isLoading } = loader;

  //function for handling the on/off of offcanvas
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // for seeing study materials
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);


  const [materialID, setMaterialId] = useState("");
  const [show10, setShow10] = useState(false);
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);

  //function for handling of moving top next step of offcanvas
  const MyFun = () => {
    setClick(Click + 1);
  };
  const handleProceedClick = () => {
    if (File1?.name?.length === 0) {
      toast.error("Please upload a file.");
      return;
    }
    if (Name?.length === 0) {
      toast.error("Please enter a name.");
      return;
    }
    setClick(Click + 1);
    // Optionally, you can display a success message
    toast.success("Proceeding...");
  };

  //function for delete particular particular study material
  const DeleteApi = async () => {
    try {
      const Res = await fetch(
        `${BB_URl}/api/admin/studymatdelete?id=${DeleteId}`
      );
      const Data = await Res.json();
      if (Data.status == 1) {
      }
    } catch (error) {
      console.log(error);
    }
  };

  //function for Add pdf from card;
  const Api = async () => {
    var formdata = new FormData();
    formdata.append("token", Token);
    formdata.append("file_path_en", File);
    formdata.append("notes_id", Value);
    formdata.append("category_id", CatId);
    formdata.append("file_title", Name);
    formdata.append("file_path_hi", File1);
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    fetch(`${BB_URl}/api/admin/studymat`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status == 1) {
          setClick(Click + 1);
          let interval = setInterval(() => {
            setTimers1((lastTimerCount) => {
              lastTimerCount <= 1 && clearInterval(interval);
              return lastTimerCount - 1;
            });
          }, 1000);
          return () => {
            clearInterval(interval);
          };
        } else {
          //pdf work over the less then 10mb only
          toast(result.message);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const viewcat = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow"
      };

      const response = await fetch(`${QUIZ_BASE_URL}/formfill/get/all/category?search=${""}`, requestOptions)
      const result = await response.json()
      if (result.status == 1) {
        setcategories(result.categories)
      } else if (result.status == "CUSTOM_ERR") {
        toast.error(result.Backend_Error)
      } else if (result.status == "VAL_ERR") {
        toast.error(result.Backend_Error)
      }
    } catch (e) {
      console.log(e);
    }
  }

  //function for getting all the categories
  const Categories12 = async () => {
    try {
      const Dar = await fetch(
        `${BB_URl}/api/admin/categorylist`
      );
      const res = await Dar.json();
      setCategories(res);
      console.log(res)
      setFilteerCatories(res);
    } catch (error) {
      console.log(error);
    }
  };

  //function for get particular study material by their id;
  const Api3 = async (id) => {
    try {
      const Responce = await fetch(
        `${BB_URl}/api/admin/studymatpdf?id=${id}`
      );

      const Data = await Responce.json();
      if (Data.status == 1) {
        setHets(Data.data);
        setUpdatedSTM(Data.data[0]);
        setUpdatedSTM({
          ...updatedSTM,
          file_path_en: null,
          file_path_hi: null,
        });
        //set state for updated stm
        setIdFor(Data.data[0].notes_id);
      } else {
        alert(Data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //function for update particular study material data
  const ApiSend = async (id) => {
    var myHeaders = new Headers();
    myHeaders.append("location", "");
    var formdata = new FormData();
    Object.entries(updatedSTM).forEach(([key, value]) => {
      if (value !== null) {
        formdata.append(key, value);
      }
    });
    formdata.append("id", id);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    fetch(`${BB_URl}/api/admin/studymatupdate`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 1) {
          toast(result.message);
        } else {
          toast(result.message);
        }
      })
      .catch((error) => console.log("error", error));
  };

  //function for get all the study material
  const Api1 = async () => {
    setLoader({
      ...loader,
      isLoading: true,
    });
    try {

      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        "cat_id": category_id
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      const Responce = await fetch(`${PREREC_BASE_URL}/study/get/pdf/type/for/particular/category`, requestOptions)
      const Data = await Responce.json();
      setLoader({
        ...loader,
        isLoading: false,
      });
      if (Data?.status === 1) {
        setSearchData(Data?.send_data);
        console.log("dsds", searchData);
      }
    } catch (error) {
      setLoader({
        ...loader,
        isLoading: false,
      });
    }
  };


  const deleteMaterialById = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };
      const Responce = await fetch(`${PREREC_BASE_URL}/study/delete/material/by/id?material_id=${materialID}`, requestOptions)
      const Data = await Responce.json();
      if (Data?.status === 1) {
        toast.success("PDF deleted Successfully");
        getMaterials(pdf_type_id)
        handleClose10()
        handleShow1()
      }
    } catch (error) {
     
    }
  };


  //function for get all the study material
  const AddNewPdf = async () => {
    setLoader({
      ...loader,
      isLoading: true,
    });
  
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
  
      const formdata = new FormData();
      formdata.append("cat_id", category_id);
      formdata.append("display_name", Name);
      formdata.append("pdf_type", pdf_type_id);
      formdata.append("pdf", File1);
  
      const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
        headers: myHeaders // Include headers in requestOptions
      };
  
      const response = await fetch(`${PREREC_BASE_URL}/study/add/material`, requestOptions);
      const data = await response.json();
  
      if (data?.status === 1) {
        toast.success("Study Material Added Successfully");
        setName("");
        setFile1();
        handleClose();
        setClick(0);
        Api1();
      } else if (data?.status === "CUSTOM_ERR") {
        toast.error(data?.Backend_Error); // Ensure you use toast.error for error messages
      }
    } catch (error) {
      toast.error("An unexpected error occurred. Please try again."); // Optionally, add a generic error message
    } finally {
      // Ensure the loader is stopped whether there is an error or not
      setLoader({
        ...loader,
        isLoading: false,
      });
    }
  }



  //function for getting notes by id
  const getMaterials = async (pdfID) => {
    try {
      setLoadingMaterial(true)
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

      const raw = "";

      const requestOptions = {
        method: "POST",
        body: raw,
        redirect: "follow"
      };

      const Responce = await fetch(`${PREREC_BASE_URL}/study/get/studymaterials/of/pdf/type/of/particular/category?cat_id=${category_id}&page=${currentPage}&limit=10&pdf_type=${pdfID}&search=${materialSearch}`, requestOptions)
      const Data = await Responce.json();
      if (Data?.status === 1) {
        setLoadingMaterial(false)
        setMatData(Data?.data)
        setTotalPages(Data?.totalPages)
        handleShow1()
      }
    } catch (error) {

    }
  };

  //function for search functionality
  const searchFunction = () => {
    setLoader({
      ...loader,
      isLoading: true,
    });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      token: localStorage.getItem("token"),
      search: toSearch,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${BB_URl}/api/admin/search-pdf`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        const { data } = result;
        if (!data) {
          setSearchData([]);
          setLoader({
            ...loader,
            isLoading: false,
          });
          return;
        }
        setSearchData(result.data);
        setLoader({
          ...loader,
          isLoading: false,
        });
      })
      .catch((error) => {
        console.log("error", error);
        setLoader({
          ...loader,
          isLoading: false,
        });
      });
  };

  //function for handle search functionlity by using enter key on keyboard
  const handleSearchFunctionlity = (e) => {
    const { key, target } = e;
    const { value } = target;
    setToSearch(value);
    if (key === "Enter") {
      searchFunction();
    }
  };

  console.log("slecting file", File?.name.length);

  useEffect(() => {
    Categories12();
    viewcat()
  }, []);


  useEffect(() => {
    Api1()
  }, [category_id]);

  const handleChangePagi = (event, value) => {
    setCurrentPage(value);
  };


  useEffect(() => {
    if (materialSearch == "") {
      getMaterials(pdf_type_id)
    }
  }, [materialSearch])

  useEffect(() => {
    getMaterials(pdf_type_id)
  }, [currentPage])


  return (
    <div style={{
      overflow: "hidden"
    }}>


      <div className="row mt-2" >
        <div className="col-6 d-flex">
          <div className="col-8">
            <h5 className="mt-1" style={{ fontWeight: 600 }}>
              Showing total
              <span style={{ color: "#CC1313" }}> {searchData?.length} </span>
              Free PDFs
            </h5>
          </div>
          <div className="col-4">
            {/* <button
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRight"
              aria-controls="offcanvasRight"
              style={{
                backgroundColor: "#EDF7FF",
                border: "1px solid #2188E7",
                height: "40px",
                borderRadius: "10px",
                color: "#2188E7",
              }}
            >
              <span className="p-3"> + Add PDF</span>
            </button> */}
          </div>
        </div>
        <div className="col-6">
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Select
              onChange={(e) => {
                setcategory_id(e.target.value.split(" ")[0])
                setCatName(e.target.value.split(" ")[1])
              }}
              name="categoryId"
              value={`${category_id} ${CatName}`}
              autoFocus
            >
              <option unselectable={true} hidden value={""}>---select category ---</option>
              {categories?.map((el) => (
                <option value={`${el._id} ${el.category_name}`}>{el.category_name}</option>
              ))}
            </Form.Select>
          </Form.Group>
        </div>
      </div>







      <section style={{
        height: "60vh",
        overflowY: "scroll",
        overflowX: "hidden"

      }}>
        <div className="container mt-4">
          <div className="row">
            {isLoading ? (
              <LoaderComponent />
            )

              : searchData?.length === 0 ? (
                <EmptyContainer
                  message="Please Select an Category"
                />
              ) :


                (
                  searchData?.map((res, key) => {
                    return (
                      <div className="col-lg-3 col-md-6 col-sm-12 my-4" key={key}>
                        <div class="card" style={{ borderRadius: 15, width: "100%" }}>
                          <div class="card-body">
                            <h4 class="card-title  align-item-center">
                              {res?.name}
                              <FaArrowRight
                                data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasRight2"
                                className="mt-1"
                                style={{
                                  float: "right",
                                  color: "#000",
                                  fontSize: 17,
                                }}
                                onClick={() => {
                                  setValue(res.notes_id);
                                  setNameCat(res.title);
                                }}
                              />
                            </h4>

                            <p class="card-text" style={{ color: "#8A8A8A" }}>
                              Total
                              <span style={{ color: "red" }}> {res?.count} </span>
                              PDFs
                            </p>

                            <div
                              className="px-2"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <span
                                className=""
                                // data-bs-toggle="offcanvas"
                                // data-bs-target="#offcanvasRight4"
                                // aria-controls="offcanvasRight"
                                style={{
                                  cursor: "pointer",
                                  backgroundColor: "#F7F7F7",
                                  padding: "7px 15px",
                                  borderRadius: 10,
                                }}
                                onClick={() => {
                                  setPdfTypeID(res._id);
                                  getMaterials(res._id);

                                }}
                              >
                                View Details
                              </span>

                              <button
                                type="btn"
                                style={{
                                  border: "none",
                                  padding: "5px 10px",
                                  borderRadius: 10,
                                  backgroundColor: "#22BD69",
                                  color: "#fff",
                                }}
                                onClick={() => {

                                  handleShow()
                                  setPdfTypeID(res._id);
                                }}
                              >
                                + Add
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                )}


          </div>
        </div>
      </section>




      {/* offcanvas for add pdf from card */}
      <Offcanvas
        show={show}
        onHide={() => {
          setName("")
          setFile1()
          handleClose()
        }}
        placement="end"
        className="w-50"
        style={{ width: "50%", borderRadius: "15px 0px 0px 0px" }}
      >
        <Offcanvas.Header>
          <span
            onClick={() => {
              setClick(0);
              setName("")
              setFile1()
              handleClose()
            }}
            style={{ color: "#000", fontWeight: "600" }}
          >
            <svg
              width="35"
              height="35"
              viewBox="0 0 35 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M27.4913 9.40793C27.616 9.28337 27.7149 9.13547 27.7825 8.97267C27.85 8.80987 27.8849 8.63535 27.885 8.45909C27.8851 8.28283 27.8505 8.10827 27.7831 7.94539C27.7158 7.7825 27.617 7.63448 27.4924 7.50977C27.3679 7.38505 27.22 7.2861 27.0572 7.21854C26.8944 7.15099 26.7199 7.11617 26.5436 7.11606C26.3673 7.11595 26.1928 7.15056 26.0299 7.21791C25.867 7.28527 25.719 7.38404 25.5943 7.5086L17.4999 15.6029L9.40793 7.5086C9.15607 7.25673 8.81446 7.11523 8.45827 7.11523C8.10207 7.11523 7.76047 7.25673 7.5086 7.5086C7.25673 7.76047 7.11523 8.10207 7.11523 8.45827C7.11523 8.81446 7.25673 9.15607 7.5086 9.40793L15.6029 17.4999L7.5086 25.5919C7.38389 25.7166 7.28496 25.8647 7.21747 26.0276C7.14997 26.1906 7.11523 26.3652 7.11523 26.5416C7.11523 26.718 7.14997 26.8926 7.21747 27.0556C7.28496 27.2185 7.38389 27.3666 7.5086 27.4913C7.76047 27.7431 8.10207 27.8846 8.45827 27.8846C8.63464 27.8846 8.80928 27.8499 8.97222 27.7824C9.13517 27.7149 9.28322 27.616 9.40793 27.4913L17.4999 19.3969L25.5943 27.4913C25.8461 27.7428 26.1876 27.884 26.5436 27.8838C26.8996 27.8836 27.2409 27.742 27.4924 27.4901C27.744 27.2382 27.8852 26.8967 27.885 26.5408C27.8848 26.1848 27.7431 25.8435 27.4913 25.5919L19.3969 17.4999L27.4913 9.40793Z"
                fill="black"
              />
            </svg>
          </span>
        </Offcanvas.Header>
        <Offcanvas.Body>

          <div style={{ display: Click === 0 ? "block" : "none" }}>
            <div>
              <h5 style={{ fontWeight: 600 }}>Add PDF</h5>
              <div className="progress" style={{ height: "10px" }}>
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{
                    width: "50%",
                    background:
                      "linear-gradient(to right, #f44cc5, #ec40cc, #e335d3, #d72adb, #c922e4)",
                  }}
                  aria-valuenow="50"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <p>steps 1/2</p>

              <div className="row">
                <div className="col-3"></div>
                <div className="card col-6" style={{ borderColor: "#EFEFEF" }}>


                  <lable
                    className="mt-2 mb-2"
                    style={{ fontWeight: "bolder", fontSize: 16 }}
                  >
                    Upload PDF{" "}
                  </lable>


                  <label
                    className="filelabel1 w-100"
                    style={{
                      height: "70px",
                      marginLeft: "0px",
                      backgroundColor: "#F5F5F5",
                    }}
                  >
                    <span className="title1">Select .pdf file</span>
                    <input
                      className="FileUpload1"
                      type="file"
                      accept=".pdf"
                      name="booking_attachment2"
                      onChange={(e) => {
                        setFile1(e.target.files[0]);
                      }}
                    />
                  </label>

                  <input
                    className="mt-3 mb-3"
                    placeholder="  Enter Display Name"
                    style={{
                      border: "2px solid #EDEDED",
                      height: "45px",
                      outline: 1,
                      color: "#303030",
                    }}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  justifyContent: "center",
                  textAlign: "center",
                  backgroundColor: "",
                }}
              >
                <button
                  className="col-6 border-0 mt-3 text-white"
                  onClick={handleProceedClick}
                  disabled={File1?.name?.length === 0 || Name?.length === 0}
                  style={{
                    backgroundColor: "#2188E7",
                    height: "40px",
                    borderRadius: 7,
                    cursor: "pointer"
                  }}
                >
                  Proceed
                </button>
              </div>
            </div>
          </div>

          <div style={{ display: Click === 1 ? "block" : "none" }}>
            <div>
              <h5 style={{ fontWeight: 600 }}>Add PDF</h5>
              <div className="progress" style={{ height: "10px" }}>
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{
                    width: "100%",
                    background:
                      "linear-gradient(to right, #f44cc5, #ec40cc, #e335d3, #d72adb, #c922e4)",
                  }}
                  aria-valuenow="100"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <p>steps 2/2</p>

              <div className="row">
                <div className="col-3"></div>
                <div
                  className="col-6"
                  style={{
                    borderColor: "#EFEFEF",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <p style={{ fontSize: 22 }}>
                    New PDF{" "}
                    <span style={{ fontSize: 23, fontWeight: "bolder" }}>
                      {Name} {NameCat}
                    </span>{" "}
                    will be added to{" "}
                    <span style={{ fontSize: 23, fontWeight: "bolder" }}>
                      {CatName}
                    </span>
                  </p>

                </div>
              </div>
              <div
                style={{
                  justifyContent: "center",
                  textAlign: "center",
                  backgroundColor: "",
                }}
              >
                {/* //seen this */}
                <button
      className="col-6 border-0 mt-3 text-white"
      onClick={() => AddNewPdf()}
      style={{
        backgroundColor: "#2188E7",
        height: "40px",
        borderRadius: 7,
        position: 'relative', // Add position relative to control spinner positioning
        overflow: 'hidden', // Ensure spinner does not overflow button
      }}
    >
      {isLoading ? (
        <div className="spinner"><Spinner /></div> // Spinner CSS class
      ) : (
        'Confirm'
      )}
    </button>
              </div>
            </div>
          </div>

        </Offcanvas.Body>
      </Offcanvas>


      {/* offcanva to see details */}
      <Offcanvas
        show={show1}
        onHide={() => {
          handleClose1()
        }}
        placement="end"
        className="w-50"
        style={{ width: "50%", borderRadius: "15px 0px 0px 0px" }}
      >
        <Offcanvas.Header>
          <span
            onClick={() => {
              handleClose1()
            }}
            style={{ color: "#000", fontWeight: "600" }}
          >
            <svg
              width="35"
              height="35"
              viewBox="0 0 35 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M27.4913 9.40793C27.616 9.28337 27.7149 9.13547 27.7825 8.97267C27.85 8.80987 27.8849 8.63535 27.885 8.45909C27.8851 8.28283 27.8505 8.10827 27.7831 7.94539C27.7158 7.7825 27.617 7.63448 27.4924 7.50977C27.3679 7.38505 27.22 7.2861 27.0572 7.21854C26.8944 7.15099 26.7199 7.11617 26.5436 7.11606C26.3673 7.11595 26.1928 7.15056 26.0299 7.21791C25.867 7.28527 25.719 7.38404 25.5943 7.5086L17.4999 15.6029L9.40793 7.5086C9.15607 7.25673 8.81446 7.11523 8.45827 7.11523C8.10207 7.11523 7.76047 7.25673 7.5086 7.5086C7.25673 7.76047 7.11523 8.10207 7.11523 8.45827C7.11523 8.81446 7.25673 9.15607 7.5086 9.40793L15.6029 17.4999L7.5086 25.5919C7.38389 25.7166 7.28496 25.8647 7.21747 26.0276C7.14997 26.1906 7.11523 26.3652 7.11523 26.5416C7.11523 26.718 7.14997 26.8926 7.21747 27.0556C7.28496 27.2185 7.38389 27.3666 7.5086 27.4913C7.76047 27.7431 8.10207 27.8846 8.45827 27.8846C8.63464 27.8846 8.80928 27.8499 8.97222 27.7824C9.13517 27.7149 9.28322 27.616 9.40793 27.4913L17.4999 19.3969L25.5943 27.4913C25.8461 27.7428 26.1876 27.884 26.5436 27.8838C26.8996 27.8836 27.2409 27.742 27.4924 27.4901C27.744 27.2382 27.8852 26.8967 27.885 26.5408C27.8848 26.1848 27.7431 25.8435 27.4913 25.5919L19.3969 17.4999L27.4913 9.40793Z"
                fill="black"
              />
            </svg>
          </span>
        </Offcanvas.Header>
        <Offcanvas.Body>

          <div style={{ height: "100%" }}>

            <div className="d-flex justify-content-center">
              <div
                className="row mx-1    "
                style={{
                  border: "1px solid #D9D9D9",
                  height: "40px",
                  borderRadius: "7px",
                  width: "60%",
                }}
              >
                <div className="col-2 mt-1">
                  {
                    materialSearch?.length > 0 && <>

                      <button className="border-0 rounded "
                        style={{}}
                        onClick={() => {
                          setMatSearch("")
                        }}>
                        <div className="">
                          X
                        </div>
                      </button>

                    </>
                  }
                </div>
                <div className="col-8 mt-1" style={{ marginLeft: "-30px" }}>
                  <input
                    className="border-0 w-100"
                    placeholder="Search for Title of PDF"
                    style={{
                      textDecoration: "none",
                      outline: "none",
                      color: "#272727",
                    }}
                    value={materialSearch}
                    onChange={(e) => {
                      setMatSearch(e.target.value)
                    }}
                  />

                </div>

              </div>

              <button className="border-0 rounded "
                style={{ backgroundColor: "#22BD69" }}
                onClick={() => {
                  setCurrentPage(1)
                  getMaterials(pdf_type_id);
                }}>
                <div className="">
                  <img alt="SearchIcon" src={SearchIcon} />
                </div>
              </button>
            </div>



            {isLoadingMaterial ? (
              <LoaderComponent />
            )

              : materialData?.length === 0 ? (
                <EmptyContainer
                  message="No Study Material Found!"
                />
              ) :

                <section className="py-2" style={{ height: "80%", overflowY: "scroll" }}>
                  <div className="container mt-4">
                    <div className="row">

                      {
                        materialData?.map((res, key) => {
                          return (
                            <div className="col-6 p-2" style={{ height: "fit-content" }}>
                              <div class="card" style={{ borderRadius: 15, width: "100%" }}>
                                <div class="card-body">

                                  <div style={{ width: "100%", overflowX: "auto" }} >
                                    <h4 className="card-title  align-item-center text-center text-nowrap text-truncate">
                                      {res?.display_name}

                                    </h4>
                                  </div>


                                  <div
                                    className=""
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span
                                      className=""
                                      style={{
                                        cursor: "pointer",
                                        backgroundColor: "#F7F7F7",
                                        padding: "7px 15px",
                                        borderRadius: 10,
                                      }}
                                    >
                                      <a href={`${IMAGE_URL}${res?.filename}`} target="_blank">
                                        View Details
                                      </a>
                                    </span>

                                    <button
                                      type="btn"
                                      style={{
                                        border: "none",
                                        padding: "5px 10px",
                                        borderRadius: 10,
                                        backgroundColor: "red",
                                        color: "#fff",
                                      }}
                                      onClick={() => {
                                        setMaterialId(res?._id)
                                        handleShow10()
                                      }}
                                    >
                                      <MdDelete />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                        )
                      }

                    </div>
                  </div>
                </section>


            }





            <div>
              <Stack spacing={2} className="d-flex justify-content-center mt-3 align-items-center" >
                <Pagination count={totalPages} page={currentPage} onChange={handleChangePagi} />
              </Stack>
            </div>

          </div>

        </Offcanvas.Body>
      </Offcanvas>





      {/* Offcanvas */}




      {/* for add pdf from the top of free pdf page offcanvas start */}
      <div
        className="offcanvas offcanvas-end w-50"
        tabindex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
        style={{ borderTopLeftRadius: 30, backgroundColor: "#F5F5F5" }}
      >
        <div className="offcanvas-body">
          <FreePdfData />
        </div>
      </div>
      {/* ends here */}



      {/* +Add button at top offcanvas start */}



      <div
        class="offcanvas offcanvas-end"
        tabindex="-1"
        id="offcanvasRight2"
        aria-labelledby="offcanvasRightLabel"
        style={{ width: "50%", borderRadius: "15px 0px 0px 0px" }}
      >
        <div class="offcanvas-header">
          <span
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={() => {
              setClick(0);
            }}
            style={{ color: "#000", fontWeight: "600" }}
          >
            <svg
              width="35"
              height="35"
              viewBox="0 0 35 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M27.4913 9.40793C27.616 9.28337 27.7149 9.13547 27.7825 8.97267C27.85 8.80987 27.8849 8.63535 27.885 8.45909C27.8851 8.28283 27.8505 8.10827 27.7831 7.94539C27.7158 7.7825 27.617 7.63448 27.4924 7.50977C27.3679 7.38505 27.22 7.2861 27.0572 7.21854C26.8944 7.15099 26.7199 7.11617 26.5436 7.11606C26.3673 7.11595 26.1928 7.15056 26.0299 7.21791C25.867 7.28527 25.719 7.38404 25.5943 7.5086L17.4999 15.6029L9.40793 7.5086C9.15607 7.25673 8.81446 7.11523 8.45827 7.11523C8.10207 7.11523 7.76047 7.25673 7.5086 7.5086C7.25673 7.76047 7.11523 8.10207 7.11523 8.45827C7.11523 8.81446 7.25673 9.15607 7.5086 9.40793L15.6029 17.4999L7.5086 25.5919C7.38389 25.7166 7.28496 25.8647 7.21747 26.0276C7.14997 26.1906 7.11523 26.3652 7.11523 26.5416C7.11523 26.718 7.14997 26.8926 7.21747 27.0556C7.28496 27.2185 7.38389 27.3666 7.5086 27.4913C7.76047 27.7431 8.10207 27.8846 8.45827 27.8846C8.63464 27.8846 8.80928 27.8499 8.97222 27.7824C9.13517 27.7149 9.28322 27.616 9.40793 27.4913L17.4999 19.3969L25.5943 27.4913C25.8461 27.7428 26.1876 27.884 26.5436 27.8838C26.8996 27.8836 27.2409 27.742 27.4924 27.4901C27.744 27.2382 27.8852 26.8967 27.885 26.5408C27.8848 26.1848 27.7431 25.8435 27.4913 25.5919L19.3969 17.4999L27.4913 9.40793Z"
                fill="black"
              />
            </svg>
          </span>
        </div>
        <div className="offcanvas-body px-5">
          <div style={{ display: Click === 0 ? "block" : "none" }}>
            <h5 id="offcanvasRightLabel" style={{ fontWeight: 600 }}>
              Add PDF
            </h5>
            <div className="progress" style={{ height: "10px" }}>
              <div
                className="progress-bar"
                role="progressbar"
                style={{
                  width: "33%",
                  background:
                    "linear-gradient(to right, #f44cc5, #ec40cc, #e335d3, #d72adb, #c922e4)",
                }}
                aria-valuenow="100"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <p>steps 1/3</p>

            <div className="row">
              <div className="col-3"></div>
              <div
                className="card col-6"
                style={{ justifyConetnt: "center", textAlign: "center" }}
              >
                <div>
                  <label
                    className="col-12 mb-2 mt-3"
                    style={{
                      textAlign: "start",
                      color: "#303030",
                      fontWeight: "600",
                    }}
                  >
                    Select Category
                  </label>
                  <br />
                  <input
                    className="col-12 border-0 mb-3"
                    style={{
                      textAlign: "start",
                      height: "50px",
                      backgroundColor: "#EFEFEF",
                      borderRadius: 7,
                      outline: 1,
                    }}
                    placeholder="  Search for Category"
                    type="search"
                  />
                  <br />
                </div>
                <div
                  style={{
                    height: "160px",
                    overflowY: "scroll",
                    justifyContent: "start",
                    alignItems: "start",
                  }}
                >
                  {filterCategories?.map((res, key) => {
                    return (
                      <>
                        <div className="d-flex mt-3" key={key}>
                          <input
                            style={{ color: "#F6F6F6" }}
                            type="radio"
                            name="Select"
                            id={res.id}
                            onChange={(e) => {
                              setCatId(e.target.id);
                              setCatName(res.category_name);
                              setImgCat(res.category_image);
                            }}
                          />
                          <img
                            src={res.category_image}
                            alt="#"
                            className="mx-3"
                            style={{
                              width: 30,
                              height: 30,
                              borderRadius: "50%",
                            }}
                          />
                          <span className="ms-2 fw-bolder mt-1">
                            {res.category_name}
                          </span>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
            <div
              style={{
                justifyContent: "center",
                textAlign: "center",
                backgroundColor: "",
              }}
            >
              <button
                className="col-6 border-0 mt-3 text-white"
                onClick={MyFun}
                style={{
                  backgroundColor: "#2188E7",
                  height: "40px",
                  borderRadius: 7,
                }}
              >
                Proceed
              </button>
            </div>
          </div>

          <div style={{ display: Click === 1 ? "block" : "none" }}>
            <div>
              <h5 style={{ fontWeight: 600 }}>Add PDF</h5>
              <div className="progress" style={{ height: "10px" }}>
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{
                    width: "75%",
                    background:
                      "linear-gradient(to right, #f44cc5, #ec40cc, #e335d3, #d72adb, #c922e4)",
                  }}
                  aria-valuenow="100"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <p>steps 2/3</p>

              <div className="row">
                <div className="col-3"></div>
                <div className="card col-6" style={{ borderColor: "#EFEFEF" }}>
                  <lable
                    className="mt-2 mb-2"
                    style={{ fontWeight: "bolder", fontSize: 16 }}
                  >
                    Upload PDF
                  </lable>
                  {/* <input style={{border:"2px solid #EDEDED",backgroundColor:"#EDEDED",height:"50px"}} type="file" title='Select .pdf file' accept='.pdf'/> */}
                  {/* <label className="filelabel1 w-100" style={{height:"50px",marginLeft:"0px",backgroundColor:"#F5F5F5"}}>
                           
                           <span className="title1">
                           Select .pdf file
                           </span>
                           <input className="FileUpload1" id="FileInput1" name="booking_attachment1" type="file" accept='.pdf' onChange={(e)=>{setFile(e.target.files[0])}}/>
                       </label>  */}

                  <label
                    className="filelabel1 w-100"
                    style={{
                      height: "70px",
                      marginLeft: "0px",
                      backgroundColor: "#F5F5F5",
                    }}
                  >
                    <span className="title1">Select .pdf file</span>
                    <input
                      className="FileUpload1"
                      type="file"
                      accept=".pdf"
                      name="booking_attachment2"
                      onChange={(e) => {
                        setFile(e.target.files[0]);
                      }}
                    />
                  </label>

                  <lable
                    className="mt-2 mb-2"
                    style={{ fontWeight: "bolder", fontSize: 16 }}
                  >
                    Upload Translated PDF{" "}
                    <span style={{ fontSize: 14, fontWeight: 400 }}>
                      (Optional)
                    </span>
                  </lable>
                  {/* <input style={{border:"2px solid #EDEDED",backgroundColor:"#EDEDED",height:"60px"}} type="file" title='Select .pdf file' accept='.pdf'/> */}
                  {/* 
               <label className="filelabel1 w-100" style={{height:"60px",marginLeft:"0px",backgroundColor:"#F5F5F5"}}>
                           
                           <span className="title1">
                           Select .pdf file
                           </span>
                           <input className="FileUpload1" id="FileInput1" name="booking_attachment2" type="file" accept='.pdf' onChange={(e)=>{setFile1(e.target.files[0])}}/>
                       </label>  */}

                  <label
                    className="filelabel1 w-100"
                    style={{
                      height: "70px",
                      marginLeft: "0px",
                      backgroundColor: "#F5F5F5",
                    }}
                  >
                    <span className="title1">Select .pdf file</span>
                    <input
                      className="FileUpload1"
                      type="file"
                      accept=".pdf"
                      name="booking_attachment2"
                      onChange={(e) => {
                        setFile1(e.target.files[0]);
                      }}
                    />
                  </label>

                  <input
                    className="mt-3 mb-3"
                    placeholder="  Enter Display Name"
                    style={{
                      border: "2px solid #EDEDED",
                      height: "45px",
                      outline: 1,
                      color: "#303030",
                    }}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  justifyContent: "center",
                  textAlign: "center",
                  backgroundColor: "",
                }}
              >
                <button
                  className="col-6 border-0 mt-3 text-white"
                  onClick={MyFun}
                  style={{
                    backgroundColor: "#2188E7",
                    height: "40px",
                    borderRadius: 7,
                  }}
                >
                  Proceed
                </button>
              </div>
            </div>
          </div>

          <div style={{ display: Click === 2 ? "block" : "none" }}>
            <div>
              <h5 style={{ fontWeight: 600 }}>Add PDF</h5>
              <div className="progress" style={{ height: "10px" }}>
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{
                    width: "75%",
                    background:
                      "linear-gradient(to right, #f44cc5, #ec40cc, #e335d3, #d72adb, #c922e4)",
                  }}
                  aria-valuenow="100"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <p>steps 3/3</p>

              <div className="row">
                <div className="col-3"></div>
                <div
                  className="col-6"
                  style={{
                    borderColor: "#EFEFEF",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <p style={{ fontSize: 22 }}>
                    New PDF{" "}
                    <span style={{ fontSize: 23, fontWeight: "bolder" }}>
                      {Name} {NameCat}
                    </span>{" "}
                    will be added to{" "}
                    <span style={{ fontSize: 23, fontWeight: "bolder" }}>
                      {CatName}
                    </span>
                  </p>
                  <img
                    className="mt-3 mb-3"
                    src={ImgCat}
                    alt="#"
                    style={{ width: 120 }}
                  />
                </div>
              </div>
              <div
                style={{
                  justifyContent: "center",
                  textAlign: "center",
                  backgroundColor: "",
                }}
              >
                <button
                  className="col-6 border-0 mt-3 text-white"
                  onClick={() => {
                    Api();
                  }}
                  style={{
                    backgroundColor: "#2188E7",
                    height: "40px",
                    borderRadius: 7,
                  }}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>

          <div style={{ display: Click === 3 ? "block" : "none" }}>
            <div className="row">
              <div className="col-3"></div>
              <div
                className="col-6"
                style={{
                  borderColor: "#EFEFEF",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <p style={{ fontSize: 22 }}>
                  New PDF{" "}
                  <span style={{ fontSize: 23, fontWeight: "bolder" }}>
                    {Name} {NameCat}
                  </span>{" "}
                  will be added to{" "}
                  <span style={{ fontSize: 23, fontWeight: "bolder" }}>
                    {CatName}
                  </span>
                </p>
                {/* <img className="mt-3 mb-3" src={ImgCat} alt="#" style={{width:120}}/> */}

                <Lottie
                  className="ms-5"
                  animationData={Clebrate}
                  loop={true}
                  style={{ width: "80%" }}
                />

                <button
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                  className="border-0 col-12 mt-4"
                  style={{
                    backgroundColor: "#0CBC8B",
                    color: "#fff",
                    height: "40px",
                    borderRadius: 7,
                  }}
                >
                  Back to Dashboard
                </button>

                <div
                  className="mt-5"
                  style={{ textAlign: "center", alignItems: "center" }}
                >
                  <p style={{ color: "#8A8A8A" }}>
                    This tab will automatically close in
                    <span style={{ color: "#CC1313", fontSize: 20 }}>
                      {" "}
                      {15}{" "}
                    </span>
                    Seconds
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
















      <div
        className="offcanvas offcanvas-end"
        tabindex="-1"
        id="offcanvasRight4"
        aria-labelledby="offcanvasRightLabel"
        style={{ width: "60%", borderRadius: "20px 5px 0px 0px" }}
      >
        <div className="offcanvas-header">
          {/* <h5 id="offcanvasRightLabel4">Offcanvas right</h5> */}
          <span
            className="col-10"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            style={{ color: "#000", fontWeight: "600" }}
            onClick={() => {
              setView(0);
              setDisplay(0);
              Api1();
            }}
          >
            <svg
              width="35"
              height="35"
              viewBox="0 0 35 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M27.4913 9.40793C27.616 9.28337 27.7149 9.13547 27.7825 8.97267C27.85 8.80987 27.8849 8.63535 27.885 8.45909C27.8851 8.28283 27.8505 8.10827 27.7831 7.94539C27.7158 7.7825 27.617 7.63448 27.4924 7.50977C27.3679 7.38505 27.22 7.2861 27.0572 7.21854C26.8944 7.15099 26.7199 7.11617 26.5436 7.11606C26.3673 7.11595 26.1928 7.15056 26.0299 7.21791C25.867 7.28527 25.719 7.38404 25.5943 7.5086L17.4999 15.6029L9.40793 7.5086C9.15607 7.25673 8.81446 7.11523 8.45827 7.11523C8.10207 7.11523 7.76047 7.25673 7.5086 7.5086C7.25673 7.76047 7.11523 8.10207 7.11523 8.45827C7.11523 8.81446 7.25673 9.15607 7.5086 9.40793L15.6029 17.4999L7.5086 25.5919C7.38389 25.7166 7.28496 25.8647 7.21747 26.0276C7.14997 26.1906 7.11523 26.3652 7.11523 26.5416C7.11523 26.718 7.14997 26.8926 7.21747 27.0556C7.28496 27.2185 7.38389 27.3666 7.5086 27.4913C7.76047 27.7431 8.10207 27.8846 8.45827 27.8846C8.63464 27.8846 8.80928 27.8499 8.97222 27.7824C9.13517 27.7149 9.28322 27.616 9.40793 27.4913L17.4999 19.3969L25.5943 27.4913C25.8461 27.7428 26.1876 27.884 26.5436 27.8838C26.8996 27.8836 27.2409 27.742 27.4924 27.4901C27.744 27.2382 27.8852 26.8967 27.885 26.5408C27.8848 26.1848 27.7431 25.8435 27.4913 25.5919L19.3969 17.4999L27.4913 9.40793Z"
                fill="black"
              />
            </svg>
          </span>
        </div>
        <div className="offcanvas-body px-5">
          <div style={{ display: View === 0 ? "block" : "none" }}>
            <h2 style={{ color: "#000" }}>Question Papers</h2>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                className="row mx-1    "
                style={{
                  border: "1px solid #D9D9D9",
                  height: "40px",
                  borderRadius: "7px",
                  width: "60%",
                }}
              >
                <div className="col-2 mt-1">
                  <img alt="SearchIcon" src={SearchIcon} />
                </div>
                <div className="col-8 mt-1" style={{ marginLeft: "-30px" }}>
                  <input
                    className="border-0 w-100"
                    placeholder="Search for question paper PDF"
                    style={{
                      textDecoration: "none",
                      outline: "none",
                      color: "#272727",
                    }}
                  />
                </div>
              </div>

              <div className="col-3 mt-1 mx-5">
                <button
                  type="btn btn"
                  style={{
                    border: "none",
                    backgroundColor: "#EFEFEF",
                    padding: "10px 15px",
                    borderRadius: 8,
                  }}
                >
                  <IoFilterSharp className="me-3" />
                  Filters
                </button>
              </div>
            </div>

            <div className="row">
              {het.map((index, item) => {
                return (
                  <div
                    className="col-lg-6 col-sm-12 mt-5"
                    key={index.id}
                    item={item}
                  >
                    <div
                      className="card"
                      style={{ width: "100%", borderRadius: 10 }}
                    >
                      <div className="card-body">
                        <h4 className="card-title" style={{ fontWeight: 600 }}>
                          {index.file_title}
                        </h4>

                        <div
                          className="mt-2"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <p
                            className="card-text  "
                            style={{
                              backgroundColor: "#EFFFF6",
                              color: "#129C73",
                              textAlign: "left",
                              padding: "5px 2px",
                              borderRadius: 8,
                              fontWeight: 500,
                            }}
                          >
                            {"Translation Enabled"}
                          </p>
                          <p
                            className="mx-5"
                            style={{ color: "#8A8A8A", fontWeight: 500 }}
                          >
                            {index.created_at}
                          </p>
                        </div>

                        <h4 className="my-1">
                          <img
                            src={index.category_image}
                            alt="#"
                            className="me-4"
                            style={{ width: 40 }}
                          />
                          {index.category_name}
                        </h4>

                        <button
                          type="button"
                          className="mt-2"
                          style={{
                            width: "100%",
                            padding: "10px 20px",
                            borderRadius: 10,
                            fontSize: 18,
                            border: "1px solid #EFEFEF",
                            backgroundColor: "#fff",
                            color: "#2E2E2E",
                          }}
                          onClick={() => {
                            setView(View + 1);
                            Api3(index.id);
                          }}
                        >
                          {/* come here */}
                          View
                        </button>
                        <button
                          type="button"
                          className="mt-3 mb-3"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          style={{
                            width: "100%",
                            padding: "10px 20px",
                            borderRadius: 10,
                            fontSize: 18,
                            border: "1px solid #EFEFEF",
                            backgroundColor: "#FFF1F1",
                            color: "#CC1313",
                          }}
                          onClick={() => {
                            setDeleteId(index.id);
                          }}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {/* offcanvas section for view section from edit offcanvas */}
          <div style={{ display: View === 1 ? "block" : "none" }}>
            {hets.map((res, key) => {
              return (
                <div className="row" key={key}>
                  <div
                    style={{
                      display: "flex",
                      // flexDirection:"column-reverse",
                      justifyContent: "flex-end",
                    }}
                  >
                    <button
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                      className="col-2 border-0"
                      style={{
                        border: "none",
                        borderRadius: "10px",
                        padding: "10px 12px",
                        color: "#2188E7",
                        backgroundColor: "#EDF7FF",
                        display: View === 1 ? "block" : "none",
                      }}
                      onClick={() => {
                        setDisplay(0);
                        setView(0);

                        //check weather we have to id ot other id that are come from;
                        ApiSend(res.notes_id);
                      }}
                    >
                      Save
                    </button>
                  </div>

                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <span
                      className="col-3 mt-1"
                      style={{
                        fontSize: 16,
                        fontWeight: 600,
                        color: "#8A8A8A",
                      }}
                    >
                      Display Name
                    </span>

                    <span className="col-3">
                      <button
                        className="border-0 w-50 rounded text-white"
                        style={{ backgroundColor: "#2E2E2E", height: "35px" }}
                        onClick={() => {
                          setDisplay(1);
                        }}
                      >
                        Edit
                      </button>
                    </span>
                  </div>

                  <span
                    className="mt-3"
                    style={{
                      fontSize: 22,
                      fontWeight: 600,
                      display: Display === 1 ? "none" : "block",
                    }}
                  >
                    {res.file_title}
                  </span>

                  <input
                    className="mt-3 rounded"
                    style={{
                      height: "35px",
                      display: Display === 1 ? "block" : "none",
                    }}
                    placeholder={res.file_title}
                    //come back
                    value={DisplayChag}
                    onChange={(e) => {
                      setUpdatedSTM({
                        ...updatedSTM,
                        file_title: e.target.value,
                      });
                      setDisplayChag(e.target.value);
                      setDisplayChan(res.category_id);
                      if (View === 1) {
                        setApiIds(res.notes_id);
                      }
                    }}
                  />
                  <span
                    className="col-2 mt-5"
                    style={{ fontSize: 16, fontWeight: 600, color: "#8A8A8A" }}
                  >
                    Category
                  </span>
                  <span className="col-3 mt-5">
                    <button
                      className="border-0 w-50 rounded text-white"
                      style={{ backgroundColor: "#2E2E2E", height: "35px" }}
                      onClick={() => {
                        setCategory(1);
                      }}
                    >
                      Edit
                    </button>
                  </span>
                  <div className="d-flex">
                    <img
                      className="mt-3"
                      style={{
                        width: "35px",
                        height: "35px",
                        borderRadius: "50%",
                      }}
                      src={res.category_image}
                      alt="#"
                    />
                    <span
                      className="mt-3 ps-2"
                      style={{
                        fontSize: 22,
                        fontWeight: 600,
                        display: Category === 1 ? "none" : "block",
                      }}
                    >
                      {res.category_name}
                    </span>
                  </div>
                  <input
                    className="mt-3 rounded"
                    style={{
                      height: "35px",
                      display: Category === 1 ? "block" : "none",
                    }}
                    placeholder={res.pdf_cat}
                    value={CategoryChag}
                    onChange={(e) => {
                      setUpdatedSTM({
                        ...updatedSTM,
                        pdf_cat: e.target.value,
                      });
                      setCategoryChag(e.target.value);
                    }}
                  />
                  <span className="col-12 mt-2">
                    <button
                      className="border-0 rounded"
                      style={{
                        bckgroundColor: "#E3FFF3",
                        color: "#2E8760",
                        width: "220px",
                        height: "35px",
                        display: "flex",
                        alignItems: "cneter",
                        justifyContent: "center",
                      }}
                    >
                      Translation Enabled
                    </button>
                  </span>

                  <span
                    className="col-12 mt-3"
                    style={{ fontSize: 16, fontWeight: 600, color: "#8A8A8A" }}
                  >
                    Original File
                  </span>
                  <span
                    className="col-6 rounded"
                    style={{
                      border: "2px solid #EFEFEF",
                      height: "80px",
                      overflow: "hidden",
                    }}
                  >
                    <span className="row  mt-4">
                      <img
                        className="col-3 mb-3"
                        src={PdfImg}
                        alt=""
                        style={{ width: "10%" }}
                      />
                      <p className="col-9" style={{ fontSize: "12px" }}>
                        {res.file_path_en}
                      </p>
                    </span>
                  </span>

                  <div className="row mt-4">
                    <div className="col-lg-3">
                      <button
                        onClick={() => {
                          window.open(
                            `${BB_URl}/public/Notes/${res.file_path_en}`
                          );
                        }}
                        // come here
                        type="button"
                        style={{
                          border: "none",
                          borderRadius: "10px",
                          padding: "10px 12px",
                          width: "80%",
                          backgroundColor: "#EFEFEF",
                        }}
                      >
                        View PDF
                      </button>
                    </div>

                    <div className="col-lg-3">
                      <label
                        className="filelabel2"
                        style={{ backgroundColor: "#F5F5F5" }}
                      >
                        <span className="title2">Replace File</span>
                        <input
                          className="FileUpload2"
                          id="FileInput2"
                          name="booking_attachment2"
                          type="file"
                          accept=".pdf"
                          value={PdfChan}
                          onChange={(e) => {
                            setPdfChan(e.target.value);
                            setReplacedFile(e.target.files);
                            setUpdatedSTM({
                              ...updatedSTM,
                              file_path_en: e.target.files[0],
                            });
                          }}
                        />
                      </label>
                    </div>
                  </div>

                  {/* second pdf start */}

                  <span
                    className="col-12 mt-5"
                    style={{ fontSize: 16, fontWeight: 600, color: "#8A8A8A" }}
                  >
                    Translated File
                  </span>
                  <span
                    className="col-6 rounded"
                    style={{
                      border: "2px solid #EFEFEF",
                      height: "80px",
                      overflow: "hidden",
                    }}
                  >
                    <span className="row  mt-4">
                      <img
                        className="col-3 mb-3"
                        src={PdfImg}
                        alt=""
                        style={{ width: "10%" }}
                      />
                      <p className="col-9" style={{ fontSize: "12px" }}>
                        {res.file_path_hi}
                      </p>
                    </span>
                  </span>

                  <div className="row mt-4">
                    <div className="col-lg-3">
                      <button
                        // onClick={() => {
                        //   window.location.href = `${BB_URl}/public/Notes/${res.file_path_en}`;
                        // }}
                        type="button"
                        style={{
                          border: "none",
                          borderRadius: "10px",
                          padding: "10px 12px",
                          width: "80%",
                          backgroundColor: "#EFEFEF",
                        }}
                      >
                        View PDF
                      </button>
                    </div>

                    <div className="col-lg-3">
                      <label
                        className="filelabel2"
                        style={{ backgroundColor: "#F5F5F5" }}
                      >
                        <span className="title2">Replace File</span>
                        <input
                          className="FileUpload2"
                          id="FileInput2"
                          name="booking_attachment2"
                          type="file"
                          accept=".pdf"
                          value={PdfChan1}
                          onChange={(e) => {
                            // console.log()
                            setPdfChan1(e.target.value);
                            setUpdatedSTM({
                              ...updatedSTM,
                              file_path_hi: e.target.files[0],
                            });
                          }}
                        />
                      </label>
                    </div>
                  </div>
                  {/* second pdf end */}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {/* Edit button  offcanvas end */}

      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ backdropFilter: "blur(10px)" }}
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header" style={{ border: "none" }}>
              {/* <h5 class="modal-title" id="exampleModalLabel">Modal title</h5> */}
              {/* <button type="button" class="btn-close" ></button> */}
            </div>
            <div class="modal-body">
              <div style={{ display: Click === 0 ? "block" : "none" }}>
                <h3 className="text-center mb-3">Are you sure?</h3>

                <p
                  style={{
                    color: "#8a8a8a",
                    textAlign: "center",
                    fontSize: "18px",
                  }}
                >
                  Do you really want to delete this pdf
                </p>

                <div
                  className="mt-3"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <button
                    type="button"
                    className="btn btn mx-4"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    style={{
                      backgroundColor: "#E1DDDD",
                      color: "#fff",
                      padding: "8px 30px",
                    }}
                  >
                    Cancel
                  </button>

                  <button
                    type="button"
                    className="btn btn mx-4"
                    style={{
                      backgroundColor: "#cc1313",
                      color: "#fff",
                      padding: "8px 30px",
                    }}
                    onClick={() => {
                      setClick(Click + 1);
                      DeleteApi();
                    }}
                  >
                    Yes Delete
                  </button>
                </div>
              </div>
              <div style={{ display: Click === 1 ? "block" : "none" }}>
                <div
                  className="d-flex"
                  style={{ justifyContent: "center", alignItems: "center" }}
                >
                  <Lottie animationData={Thaks} style={{ width: "40%" }} />
                </div>
                <p
                  style={{
                    textAlign: "center",
                    color: "#8a8a8a",
                    fontSize: "18px",
                  }}
                >
                  “PDF” is successfully deleted
                </p>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <p
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    style={{
                      backgroundColor: "#2188E7",
                      color: "#fff",
                      textAlign: "center",
                      padding: "10px 40px",
                      borderRadius: "10px",
                    }}
                    onClick={() => {
                      setClick(0);
                    }}
                  >
                    ok
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <Modal
        show={show10}
        onHide={handleClose10}
        keyboard={false}
        centered
        className="shadow-lg"
      >
        <Modal.Header>
          <Modal.Title style={{ flex: 1, textAlign: "center" }} > <h1>Delete Study Material ?</h1></Modal.Title>
        </Modal.Header>
        <Modal.Body>


          <div style={{ textAlign: "center" }}>
            <p className='action'>Thic action can not be reversed ?</p>
            <div className='d-flex justify-content-center'>
              <button style={{ borderRadius: "5px", backgroundColor: "#22BD69" }} className=' ahead ' onClick={handleClose10}>Return Back</button>
            </div>
            <div className='d-flex justify-content-center mt-2'>
              <button className='btn btn-danger ' style={{ borderRadius: "5px" }}
                onClick={() => { deleteMaterialById() }}>Yes , Delete</button>
            </div>
          </div>

        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>


      </Modal>



    </div>
  );
};
export default FreePdf;
