import { useEffect, useState } from "react";
import SearchIcon from "../../Img/ri_search-line.svg";
import { AuthContext, PREREC_BASE_URL, QUIZ_BASE_URL } from "../Utils/AuthContextProvider";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import LoaderComponent from "../Utils/LoaderComponent";
import EmptyContainer from "../Utils/EmptyContainer";
import { toast } from "react-toastify";
import { FaEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";





const Tags = () => {
  const Navigate = useNavigate();
  // const [isLoading, setIsLoading] = useState(false);
  const [tagsData, setTagsData] = useState([]);
  const [tagName, setTagName] = useState("");
  const [editTag, setEditTag] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const [show1, setShow1] = useState(false);
  const handleShow1 = () => setShow1(true);
  const handleClose1 = () => setShow1(false);


  const getTags = async () => {
    setIsLoading(true)
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${PREREC_BASE_URL}/blogs/view/tag`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setIsLoading(false)

        if (result?.status == 1) {
          setTagsData(result?.data)
        } else if (result.status == "TOKEN_ERR") {
          localStorage.removeItem("api_url");
          Navigate("/");
          toast("Logged in other device! Please login again");
        } else {
          console.log(result?.Backend_Error);
        }
      })
      .catch((error) => console.log("error", error));

  }


  const EditTag = () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${PREREC_BASE_URL}/blogs/edit/tag?name=${editTag?.name}&id=${editTag?._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {

        if (result?.status == 1) {
          toast.success(result?.message);
          handleClose1()
          getTags()
          setEditTag({})
        } else if (result.status == "TOKEN_ERR") {
          localStorage.removeItem("api_url");
          Navigate("/");
          toast("Logged in other device! Please login again");
        } else {
          toast.error(result?.Backend_Error);
        }
      })
      .catch((error) => console.log("error", error));
  };


  const AddTag = () => {
    setIsLoading(true); // Start loading

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

    const raw = JSON.stringify({
      "name": tagName
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${PREREC_BASE_URL}/blogs/add/tag`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === 1) {
          toast.success(result?.message);
          handleClose();
          getTags();
          setTagName("");
        } else {
          toast.error(result?.Backend_Error);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setIsLoading(false); // End loading
      });
  };



  useEffect(() => {
    getTags();
  }, []);
  return (
    <>
      <div>



        {/* modal for Add category */}
        <Modal show={show} onHide={() => {
          handleClose()
          setTagName("")
        }}>
          <Modal.Header closeButton>
            <Modal.Title>Add Tag</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Category Name</Form.Label>
                <Form.Control
                  type="text"
                  name="category_name"
                  value={tagName}
                  onChange={(e) => {
                    setTagName(e.target.value)
                  }}
                  placeholder="Enter tag name...."
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>

            <Button
              disabled={tagName?.length === 0 || isLoading} // Disable button when loading
              variant="primary"
              onClick={AddTag}
            >
              {isLoading ? <Spinner /> : "Add"}
            </Button>
          </Modal.Footer>
        </Modal>



        {/* modal for Edit category */}
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton1>
            <Modal.Title>Edit Tag</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Name Of Tag</Form.Label>
                <Form.Control
                  value={editTag?.name}
                  onChange={(e) => {
                    setEditTag({
                      ...editTag,
                      name: e.target.value
                    })
                  }}
                  type="text"
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>

            <Button
              disabled={editTag?.name?.length === 0 ? true : false}
              variant="primary"
              onClick={() => {
                EditTag()
              }}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>



        <div className="row">
          <div className="col-6 d-flex">
            <div className="col-7">
              <h5 className="mt-1">
                Showing total <span style={{ color: "red" }}>{" "}{tagsData?.length}{" "}</span>
                Tags
              </h5>
            </div>
            <div className="col-5">
              <button
                onClick={handleShow}
                style={{
                  backgroundColor: "#EDF7FF",
                  border: "1px solid #2188E7",
                  height: "40px",
                  borderRadius: "10px",
                  color: "#2188E7",
                }}
              >
                <span className="p-3"> +Add Tag</span>
              </button>
            </div>
          </div>
          <div className="col-6">
            <div className="row">
              <div className="col-4"></div>
              {/* <div className="col-6">
              <div
                className="row"
                style={{
                  border: "1px solid #D9D9D9",
                  height: "40px",
                  borderRadius: "7px",
                }}
              >
                <div className="col-2 mt-1">
                  <img alt="SearchIcon" src={SearchIcon} />
                </div>
                <div className="col-10 mt-1 ">
                  <input
                    className="border-0 w-100"
                    placeholder="Search for Tags"
                    style={{
                      textDecoration: "none",
                      outline: "none",
                      color: "#272727",
                    }}
                  />
                </div>
              </div>
                  </div>*/}
              {/* <div className="col-2">
              <button
                className="border-0 bg-white"
                style={{
                  background:
                    "linear-gradient(180deg, rgba(35,176,101,1) 0%, rgba(46,135,96,1) 100%)",
                  color: "#fff",
                  height: "40px",
                  borderTopRightRadius: 10,
                  borderBottomRightRadius: 10,
                  marginLeft: "-20px",
                }}
              >
                Search
              </button>
              </div>*/}
            </div>
          </div>
        </div>

        <div className="container" style={{ padding: "10px 73px 10px 73px", overflowY: "scroll", height: "65vh" }}>
          <div className="row">


            {isLoading ? (
              <LoaderComponent />
            ) : tagsData?.length === 0 ? (
              <EmptyContainer
                message="Data Not Found"
              />
            ) : (
              tagsData?.map((res, key) => {
                return (
                  <>
                    <div className="col-4" key={key} style={{ textAlign: "center" }}>
                      <div className="card mt-3 d-flex " style={{ width: "90%" }} >


                        <div className="card-body row">


                          <section className="text-nowrap col-10" style={{ paddingTop: "5px", fontWeight: "400", overflowX: "auto", fontSize: "20px" }}>
                            {res?.name}
                          </section>

                          <div className="col-2">
                            <FaEdit className="mt-2" onClick={() => {
                              setEditTag(res)
                              handleShow1();
                            }} style={{

                              fontSize: "1.3rem",
                              color: "blue",
                              cursor: "pointer"
                            }} />
                          </div>

                        </div>

                      </div>
                    </div>
                  </>
                )
              })
            )}
          </div>
        </div>


      </div>

    </>
  )
}

export default Tags