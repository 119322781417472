import { useEffect } from "react";
import SideBar from "../SideBar/SideBar";

import { useNavigate } from "react-router-dom";
import ChatRoom from "./ChatRoom";

const ChatroomPanel = () => {
  const Navigate = useNavigate();

  // const CheckToken = async (Token) => {
  //   var formdata = new FormData();
  //   formdata.append("token", Token);

  //   var requestOptions = {
  //     method: "POST",
  //     body: formdata,
  //     redirect: "follow",
  //   };

  //   fetch("https://brainbucks.co.in/api/admin/check-token", requestOptions)
  //     .then((response) => response.json())
  //     .then((result) => {
  //       if (result?.status == 1) {
  //       } else {
  //        ;
  //       }
  //     })
  //     .catch((error) => console.log("error", error));
  // };

  // useEffect(() => {
  //   if (!localStorage.getItem("token")) {
  //     Navigate("/");
  //   } else {
  //     CheckToken(localStorage.getItem("token"));
  //   }
  // }, []);
  return (
    <div style={{ overflow: "hidden" }}>
      <div className="row">
        <div className="col-2">
          <SideBar />
        </div>
        <div className="col-10">
          <div className="container d-flex flex-column" style={{height:"100vh" , overflow:"hidden"}}>
            <ChatRoom />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatroomPanel;
