import { useState, useEffect, useContext } from "react";
import SideBar from "../SideBar/SideBar";
import NotificationIcon from "../../Img/pajamas_notifications.svg";
import Images from "../../Img/blank-profile-picture-973460__340.webp";

import ReelModal from "./ReelModal";
import "./Modal.css";
import { AuthContext, IMAGE_URL } from "../Utils/AuthContextProvider";
import Stack from "@mui/material/Stack";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../Utils/AuthContextProvider";
import Pagination from "@mui/material/Pagination";
import axios from "axios";
import AddReelModal from "./AddReelModal";
import { BsArrowLeft } from "react-icons/bs";

const ViewReels = () => {
  let notification = 40;
  const { logoutFun, handleShowChangePass } = useContext(AuthContext);
  const [currentPage, setCurrentPage] = useState(1);
  const Navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({ title: "", videoUrl: "" });
  const { id } = useParams();
  const [Id, setId] = useState([]);
  const [reelsData, setReelsData] = useState([]);

  const handleChangePagi = (event, value) => {
    setCurrentPage(value);
  };
  const handleShowModal = (res) => {
    setModalData({ show: true, title: res.name, videoUrl: res?.blobName });
  };

  const handleCloseModal = () => {
    setModalData({ show: false, title: "", videoUrl: "" });
  };

  const handleCloseModal1 = () => {
    setShowModal(false);
  };

  useEffect(() => {
    fetchReels();
  }, []);

  const fetchReels = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${BASE_URL}/admin/reels/get/reels/of/tag`,
        { tag_id: id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const fetchedReels = response?.data?.reels || [];
      setReelsData(fetchedReels);
    } catch (err) {
      console.log(err);
    }
  };

  const handleShowModal1 = (reelId) => {
    setShowModal(true);
    setId(reelId);
  };

  return (
    <>
      <div>
        <div style={{ overflow: "hidden" }}>
          <div className="row">
            <div className="col-2">
              <SideBar />
            </div>
            <div className="col-10">
              <div className="container">
                <div
                  className="row mt-3 mb-2 ms-1"
                  style={{ overflowY: "none", postition: "fixed" }}
                >
                  <div className="col-6">
                    <div className="col-4">
                      <button
                        style={{
                          backgroundColor: "rgb(237, 247, 255)",
                          border: "1px solid rgb(33, 136, 231)",
                          height: "40px",
                          borderRadius: "10px",
                          color: "rgb(33, 136, 231)",
                        }}
                        className="btn btn-secondary mb-2"
                        onClick={() => {
                          Navigate("/reels");
                        }}
                      >
                        <BsArrowLeft />
                        Back
                      </button>
                    </div>
                  </div>
                  <div className="col-6">
                    <div
                      className="d-flex"
                      style={{
                        justifyContent: "end",
                        textAlign: "center",
                        alignItems: "center",
                      }}
                    >
                      {/* <div
                className="d-flex"
                style={{
                  width: "80px",
                  height: "40px",
                  borderRadius: 20,
                  border: "1px solid #D9D9D9",
                  justifyContent: "center",
                  backgroundColor: "#F5F5F5",
                }}
              >
                <div className="mt-1">
                  <img alt="#" src={NotificationIcon} />
                </div>
                <div
                  className="ms-3"
                  style={{
                    width: "35px",
                    marginTop: "1px",
                    height: "35px",
                    borderRadius: "50%",
                    border: "1px solid #D9D9D9",
                    justifyContent: "center",
                    backgroundColor: "#CC1313",
                  }}
                >
                  <p
                    style={{
                      justifyContent: "center",
                      marginTop: "5px",
                      justifyItems: "center",
                      textAlign: "center",
                      color: "#fff",
                    }}
                  >
                    {notification} +{" "}
                  </p>
                </div>
              </div> */}
                      <div
                        className="d-flex ms-4"
                        style={{
                          justifyContent: "space-between",
                          marginRight: "30px",
                        }}
                      >
                        <div
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                            justifyContent: "center",
                            overflow: "hidden",
                          }}
                        >
                          <img alt="" src={Images} width="100%" />
                        </div>
                        <div className="nav-item dropdown ms-2 mt-2 ">
                          <span
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          ></span>
                          <ul
                            className="dropdown-menu"
                            style={{ backgroundColor: "#000" }}
                          >
                            <li
                              class="dropdown-item text-white"
                              style={{ cursor: "pointer" }}
                              onClick={handleShowChangePass}
                            >
                              Change Password
                            </li>
                            <hr style={{ color: "#fff" }} />
                            <li className="dropdown-item text-white">
                              <span onClick={() => logoutFun()}>Log-Out</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-6">
                    <div
                      className="d-flex"
                      style={{
                        justifyContent: "end",
                        textAlign: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className="d-flex"
                        style={{
                          width: "80px",
                          height: "40px",
                          borderRadius: 20,
                          border: "1px solid #D9D9D9",
                          justifyContent: "center",
                          backgroundColor: "#F5F5F5",
                        }}
                      >
                        <div className="mt-1">
                          <img alt="#" src={NotificationIcon} />
                        </div>
                        <div
                          className="ms-3"
                          style={{
                            width: "35px",
                            marginTop: "1px",
                            height: "35px",
                            borderRadius: "50%",
                            border: "1px solid #D9D9D9",
                            justifyContent: "center",
                            backgroundColor: "#CC1313",
                          }}
                        >
                          <p
                            style={{
                              justifyContent: "center",
                              marginTop: "5px",
                              justifyItems: "center",
                              textAlign: "center",
                              color: "#fff",
                            }}
                          >
                            {notification} +{" "}
                          </p>
                        </div>
                      </div>
                      <div
                        className="d-flex ms-4"
                        style={{ justifyContent: "space-between", marginRight: "30px" }}
                      >
                        <div
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                            justifyContent: "center",
                            overflow: "hidden",
                          }}
                        >
                          <img alt="" src={Images} width="100%" />
                        </div>
                        <div className="nav-item dropdown ms-2 mt-2 ">
                          <span
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          ></span>
                          <ul
                            className="dropdown-menu"
                            style={{ backgroundColor: "#000" }}
                          >
                            <li className="dropdown-item text-white">View Profile</li>
                            <hr style={{ color: "#fff" }} />
                            <li className="dropdown-item text-white">View Task</li>
                            <hr style={{ color: "#fff" }} />
                            <li className="dropdown-item text-white">
                              <span
                              // onClick={() => logoutFun()}
                              >Log-Out</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                <hr></hr>
                <div className="col-6">
                  <h5 className="mt-1">
                    Showing total{" "}
                    <span style={{ color: "red" }}>{reelsData?.length}</span>{" "}
                    Brain Booster
                  </h5>
                </div>
                <section
                  className="mt-3"
                  style={{
                    height: "57vh",
                    overflowY: "scroll",
                    overflowX: "hidden",
                  }}
                >
                  <div className="row">
                    {reelsData?.map((res, index) => (
                      <div className="col-lg-3 col-md-6 mb-4" key={index}>
                        <div className="card">
                          <img
                            src={`${IMAGE_URL}${res.banner}`}
                            className="card-img-top"
                            alt=""
                            style={{
                              width: "100%",
                              height: "150px",
                              objectFit: "cover",
                            }}
                          />
                          <div className="card-body">
                            <h6 className="card-title">{res.name}</h6>
                            <div className="d-flex justify-content-between">
                              {res.blobName === null ? (
                                <button
                                  className="btn btn-secondary mb-2"
                                  onClick={() => handleShowModal1(res._id)}
                                >
                                  Add Brain Booster
                                </button>
                              ) : (
                                <button
                                  className="btn btn-secondary mb-2"
                                  style={{
                                    backgroundColor: "#9f9696",
                                    marginRight: "10px",
                                  }}
                                  onClick={() => handleShowModal1(res._id)}
                                >
                                  Replace Brain Booster
                                </button>
                              )}

                              {res?.blobName === null ? (
                                <></>
                              ) : (
                                <button
                                  className="btn btn-primary mb-2"
                                  onClick={() => handleShowModal(res)}
                                >
                                  View Brain Booster
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </section>

                <ReelModal
                  show={modalData.show}
                  onClose={handleCloseModal}
                  // title={modalData.title}
                  videoUrl={modalData.videoUrl}
                />
                <AddReelModal
                  show={showModal}
                  handleClose={handleCloseModal1}
                  Id={Id}
                />
              </div>

              <div style={{ padding: "40px" }}>
                <Stack
                  spacing={2}
                  className="d-flex justify-content-center mt-3 align-items-center"
                >
                  <Pagination count={6} page={1} />
                </Stack>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewReels;
