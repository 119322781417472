import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  
  ProgressBar,
  Spinner,
  ToastContainer,
} from "react-bootstrap";
import Wallet from "../../Img/Wallet.svg";
import { AuthContext, IMAGE_URL, QUIZ_BASE_URL } from "../Utils/AuthContextProvider";
import { AiFillAlert } from "react-icons/ai";
import Image3 from "../../Img/bb coin 1.svg";
import DatesImg from "../../Img/Date.svg";
import clock from "../../Img/clock.svg";
import EmptyContainer from "../Utils/EmptyContainer";
import LoaderComponent from "../Utils/LoaderComponent";



const UpcomingQuiz = (props) => {
  const [upcomingQuiz, setUpcomingQuiz] = useState([]);
  const [quizLoading, setQuizLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false); // state to manage loading status
  const {setIdNotify} = useContext(AuthContext);
  

  
  

  // api to fetch upcoming quiz
  const getUpcomingQuiz = async (pageNum) => {
    setLoading(true); // set loading to true when API request starts
    setQuizLoading(true);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")} `
    );

    const raw = JSON.stringify({
      quiz_id: props?.view_quizId,
      page: pageNum,
      limit: 10,
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${QUIZ_BASE_URL}/active/quiz/view/upcoming/of/activequiz`,
        requestOptions
      );
      const result = await response.json();
      if (result?.status === 1) {
        setUpcomingQuiz((prev) => [...prev, ...result.data2]);
        if (result.data2.length < 10) {
          setHasMore(false);
        }
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.log("error", error);
      setHasMore(false);
    } finally {
      setLoading(false); // set loading to false when API request is complete
      setQuizLoading(false);
    }
  };

  

  useEffect(() => {
    getUpcomingQuiz(page);
  }, [page]);

  const loadMore = () => {
    setPage((prev) => prev + 1);
  };

  return (
    <>
      <ToastContainer />
      <div style={{ height: "60vh", overflowY: "scroll" }}>
        {quizLoading ? (
          <LoaderComponent />
        ) : upcomingQuiz.length === 0 ? (
          <EmptyContainer
            actionName="Fetch All Data"
            // action={actionToFetchBack}
            message="No Data Found"
          />
        ) : (
          upcomingQuiz.map((res, key) => (
            <div className="row mt-2 ms-2" key={key}>
              <Card className="col-8">
                <Card.Body>
                  <Card.Title
                    className="d-flex"
                    style={{ borderBottom: "1px solid #EFEFEF" }}
                  >
                    <span className="col-2">
                      <div
                        className=""
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          justifyContent: "center",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          alt="#"
                          src={`${IMAGE_URL}${res.banner}`}
                          width="100%"
                        />
                      </div>
                    </span>
                    <div className="col-8 mt-2">
                      <span className="ml-2">
                        <p style={{ fontSize: 16, fontWeight: 600 }}>
                          {res.quiz_name}
                        </p>
                      </span>
                    </div>
                    <span
                      className="col-2"
                      onClick={() => {
                        props?.handleShowNotify();
                        setIdNotify(res?._id)
                      }}
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <AiFillAlert style={{ color: "red", fontSize: "25px" }} />
                    </span>
                  </Card.Title>

                  <Card.Text>
                    <div className="row">
                      <div className="col-2 d-flex">
                        <img alt="#" src={Image3} style={{ width: 25 }} />
                        <p className="mt-3 ms-2">{res.entryFees}</p>
                      </div>
                      <div
                        className="col-6 d-flex"
                        style={{
                          flexDirection: "row",
                        }}
                      >
                        <div className="col-7 d-flex no-wrep">
                          <img src={DatesImg} alt="#" style={{ width: 25 }} />
                          <div
                            className="mt-3 ms-2"
                            style={{
                              fontSize: 16,
                              fontWeight: 600,
                              color: "#333333",
                              marginTop: 10,
                            }}
                          >
                            {res.sch_time.split(" ")[0]}
                          </div>
                        </div>
                        <div className="col-6 d-flex">
                          <img src={clock} alt="#" style={{ width: 25 }} />
                          <div
                            className="mt-3 ms-2"
                            style={{
                              fontSize: 16,
                              fontWeight: 600,
                              color: "#333333",
                              marginTop: 10,
                            }}
                          >
                            {res.sch_time.split(" ")[1]}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-2 mb-3">
                      <div className="col-4 d-flex">
                        <img alt="#" src={Wallet} style={{ width: 25 }} />
                        <div
                          className="ms-2"
                          style={{
                            fontSize: 16,
                            fontWeight: 600,
                            color: "#333333",
                          }}
                        >
                          <span style={{ color: "#2188E7" }}>
                            {res.slot_aloted}
                          </span>
                          /{res.slots}
                        </div>
                      </div>
                      <ProgressBar
                        now={res.slot_aloted}
                        max={res.slots}
                        style={{ height: "12px" }}
                      />
                    </div>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          ))
          
        )}
        {hasMore && (
          <div className="d-flex justify-content-center pt-3">
            <Button variant="primary" onClick={loadMore} disabled={loading}>
              {loading ? <Spinner /> : "Load More"}
            </Button>
          </div>
        )}
        
      </div>

      
    </>
  );
};

export default UpcomingQuiz;
