import { useState, useEffect, useContext } from "react";
import Indu from "../../Img/Group 65.png";
import Bulk from "../../Img/Group 66.png";
import Translate from "../../Img/Translate.png";
import Lottie from "lottie-react";
import Any from "../../Img/Any.json";
import Trans1 from "../../Img/Trans.json";
import File from "../../Img/file.json";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import TranslateIm from "../../Img/icons8.svg";
import Dlf10 from "../../Img/dlf10_g9gACcXlja.json";
import "./AddQuestion.css";
import QuestionComp from "./QuestionComp";
import axios from "axios";
import { QUIZ_BASE_URL, IMAGE_URL, BB_URl } from "../Utils/AuthContextProvider";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { Spinner } from "react-bootstrap";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const AddQuestion = (props, refreshSubjects) => {
  const init = [];
  const [Token, setToken] = useState("");
  // const [Click, setClick] = useState(0);
  const [first, setfirst] = useState(0);
  const [Hadding, setHadding] = useState("Please Wait While We");
  const [Hadding1, setHadding1] = useState("Analyse Your Questions");
  const [Pra, setPra] = useState("Do not referesh or Close this Screen");
  const [Lotties, setLotties] = useState(Any);
  const [click, setclick] = useState(false);
  const [Da, setDa] = useState(0);
  const [Question, setQuestion] = useState({});
  const [Opa, setOpa] = useState([]);
  const [SubjectName, setSubjectName] = useState("");
  const [file, setFile] = useState();
  const [Subject, setSubject] = useState([]);
  const [SubjectId, setSubjectId] = useState("");
  const [XlInput, setXlInput] = useState(null);
  const [Que, setQue] = useState([]);
  //const [ques, setQues] = useState({}); 
  const [aOpt, setAOpt] = useState([]);
  // const [randome, setrandome] = useState(init);
  const [filterSubjects, setFilterSubjects] = useState([]);
  const [total_question, setTotalQuestions] = useState("");
  const [repeated_questions, setRepaetedQuestions] = useState("");
  const [unique_quesions, setUniqueQuestion] = useState("");
  const [search, setSearch] = useState("");
  const [Timer, setTimers] = useState(15);
  const [lan, setLan] = useState("en");
  const [subjectsData, setSubjectsData] = useState([]);
  const [checkLoading, setCheckLoading] = useState(false)
  const [uniqueQuestionsCount, setUniqueQuestionsCount] = useState(0);
  const [repeatQuestionsCount, setRepeatQuestionsCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate();

  // const [lanck,setLanck] = useState(false);

  // for add single questions

  const [singleQuestion, setSingleQuestion] = useState('');
  const [option1, setOption1] = useState(null);
  const [option2, setOption2] = useState(null);
  const [option3, setOption3] = useState(null);
  const [option4, setOption4] = useState(null);
  const [isImageOption, setIsImageOption] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [questionImage, setQuestionImage] = useState(null);
  // const [show1, setShow1] = useState();
  const [correctAnswerSelected, setCorrectAnswerSelected] = useState(false);


  // for add single questions
  const handleAddTextOption = () => {
    setIsImageOption(false);
  };

  const handleAddImageOption = () => {
    setIsImageOption(true);
  };

  // api to add single questions

  const handleSubmit = () => {
    // Check if any option is selected
    // if (!correctAnswerSelected) {
    //   toast.error("Please choose the correct answer.", {
    //   });
    //   return;
    // }
    setCheckLoading(true)

    const formdata = new FormData();
    formdata.append("question", singleQuestion);
    formdata.append("is_opt_img", isImageOption ? "1" : "0");
    formdata.append("ans", parseInt(selectedOption));
    formdata.append("sub_id", SubjectId);
    // Append question image if available
    if (questionImage) {
      formdata.append("question_url", questionImage);
    }


    // Append option files only if isImageOption is true
    if (isImageOption) {
      formdata.append("option1", option1);
      formdata.append("option2", option2);
      formdata.append("option3", option3);
      formdata.append("option4", option4);
    } else {
      // Append text options
      formdata.append("option1", option1);
      formdata.append("option2", option2);
      formdata.append("option3", option3);
      formdata.append("option4", option4);
    }


    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow"
    };

    fetch(`${QUIZ_BASE_URL}/questionbank/add/single/question`, requestOptions)
      .then((response) => response.json())
      .then(result => {
        // Check if the result contains a success message
        if (result && result.status === 1) {
          toast.success(result?.msg);
          setSelectedOption(null)
        }
        else if (result.status == "TokenError") {
          navigate("/")
          localStorage.removeItem('token');
        }
        else {
          toast.error(result?.Backend_Error);
        }
      })
      .catch(error => {
        console.error(error);
        toast.error("An error occurred. Please try again later.")
      }
      )
      .finally(() => {
        setCheckLoading(false);
      });
  };

  const [data, setData] = useState([
    {
      id: 1,
      value: [],
    },
    {
      id: 1,
      value: [],
    },
  ]);

  var OpaData = new Array(aOpt);
  const newOpt = [];

  // console.log(Timer);

  //function for delete particuler question
  const functionDelete = async (id) => {
    // console.log(id);
    try {
      let res = await axios.post(
        `${BB_URl}/api/admin/delete-questions`,
        {
          token: localStorage.getItem("token"),
          question_id: id,
        }
      );
      const newData = Que.filter((el) => el.id !== id);
      setQue([...newData]);
      // console.log(newData);
      // console.log(res);
    } catch (err) {
      console.log(err);
    }
  };

  // function for the filteration of subject for searching
  async function updateResultSubject(search) {
    const newData = Subject.filter((el) => {
      if (search.length === 0) {
        return el;
      }
      return el.subject_name.toLowerCase().startsWith(search.toLowerCase());
    });
    setFilterSubjects([...newData]);
  }
  const fileName = XlInput ? XlInput.name : '';
  click ? (
    setTimeout(() => {
      if (Da === 0) {
        setPra("Do not referesh or Close this Screen");
        setHadding("Please wait, while we ");
        setHadding1("Translate Questions");
        setLotties(Trans1);
        setDa(1);
        // setTimeout
      } else if (Da === 1) {
        setPra("This can take a while, do not refresh or close this tab.");
        setHadding("Questions Analysed ");
        setHadding1("Successfully, Preparing Report");
        setLotties(File);
        setDa(2);
      } else {
        setfirst(4);
        setclick(false);
      }
    }, 3000)
  ) : (
    <></>
  );

  function handleChange(e) {
    // console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
  }



  const APi3 = () => {
    setIsLoading(true); // Set loading to true before starting the fetch
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow"
    };

    fetch(`${QUIZ_BASE_URL}/questionbank/get/all/subjects?search=${""}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setIsLoading(false); // Set loading to false after receiving the response
        if (result.status === 1) {
          setSubjectsData(result.subjects);
        } else if (result.status === "TOKEN_ERR") {
          toast.error(result.Backend_Error);
          navigate("/");
        } else {
          toast.error(result.Backend_Error);
        }
      })
      .catch((error) => {
        setIsLoading(false); // Set loading to false if an error occurs
        console.error(error);
      });
  };

  const handleSubjectSelection = (subjectId, subjectName) => {
    setSubjectId(subjectId);
    setSubjectName(subjectName);
  };

  const Api1 = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
      const formdata = new FormData();
      formdata.append("sub_id", SubjectId);
      formdata.append("excel", XlInput);

      const requestOptions = {
        method: "POST",
        body: formdata,
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(`${QUIZ_BASE_URL}/questionbank/add/excel/questions`, requestOptions);
      const data = await response.json();
      
      setUniqueQuestionsCount(data.unique);
      setRepeatQuestionsCount(data.repeat);

      if (data.status === 1) {
        toast.success(data.msg);
      } else if (data.status === "FILE_ERR" || data.status === "CUSTOM_ERR") {
        toast.error(data.Backend_Error);
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while uploading the Excel file");
    } 
  };



  const Api2 = async (id) => {
    var formdata = new FormData();
    formdata.append("question_id", id);
    formdata.append("token", Token);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${BB_URl}/api/admin/get-single-question`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status == "001") {
          setQuestion(result.data.question);
          setAOpt(JSON.parse(result.data.options));
          setOpa(JSON.parse(result.data.options));
        }
      })
      .catch((error) => console.log("error", error));
  };

  if (Timer == 0) {
    props.Handle();
  }

  const hori = {
    borderLeft: "1px solid #D9D9D9",
    height: "300px",
    marginLeft: "40px",
  };

  const myFunction = () => {
    setfirst(1);
  };

  const myFunction2 = () => {
    setfirst(5);
  };

  const MySecond = () => {
    setfirst(first - 1);
  };


  useEffect(() => {
    localStorage.setItem("language", "hi");
    setToken(localStorage.getItem("token"));
    APi3()

  }, []);

  useEffect(() => {
    if (first === 2) {
      const timer = setTimeout(() => {
        setfirst(3);
      }, 2000); // 10 seconds
      return () => clearTimeout(timer);

    }
  }, [first]);

  useEffect(() => {
    if (first === 2) {
      Api1();
      // refreshSubjects()
    }
  }, [first]);

  const handleDownloadExcel = () => {
    // Prepare data for download (dummy example)
    const data = [
      { question: 'What is React?', option1: 'library', option2: 'Framework', option3: 'library&framwork', option4: 'None of these', ans: '1' },
      { question: 'What is Node.js?', option1: 'library', option2: 'Framework', option3: 'library&framwork', option4: 'None of these', ans: '1' },
    ];

    // Create a workbook with a worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Questions');

    // Generate Excel file and save using FileSaver.js
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, 'bulk_questions.xlsx');
  };
  const myFunction1 = () => {
    if (fileName) {
      setfirst(2);
    } else {
      toast.error('Please upload a file first.');
    }
  };
  return (
    <>
      <div>
        <div
          style={{
            display:
              first === 2 ||
                first === 2 ||
                first === 3 ||
                first === 4 ||
                first === 6
                ? "none"
                : "",
          }}
        >
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <span
              onClick={() => {
                props.Handle();
                setfirst(0);
              }}
              style={{ color: "#000", fontWeight: "600" }}
            >
              <svg
                width="35"
                height="35"
                viewBox="0 0 35 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M27.4913 9.40793C27.616 9.28337 27.7149 9.13547 27.7825 8.97267C27.85 8.80987 27.8849 8.63535 27.885 8.45909C27.8851 8.28283 27.8505 8.10827 27.7831 7.94539C27.7158 7.7825 27.617 7.63448 27.4924 7.50977C27.3679 7.38505 27.22 7.2861 27.0572 7.21854C26.8944 7.15099 26.7199 7.11617 26.5436 7.11606C26.3673 7.11595 26.1928 7.15056 26.0299 7.21791C25.867 7.28527 25.719 7.38404 25.5943 7.5086L17.4999 15.6029L9.40793 7.5086C9.15607 7.25673 8.81446 7.11523 8.45827 7.11523C8.10207 7.11523 7.76047 7.25673 7.5086 7.5086C7.25673 7.76047 7.11523 8.10207 7.11523 8.45827C7.11523 8.81446 7.25673 9.15607 7.5086 9.40793L15.6029 17.4999L7.5086 25.5919C7.38389 25.7166 7.28496 25.8647 7.21747 26.0276C7.14997 26.1906 7.11523 26.3652 7.11523 26.5416C7.11523 26.718 7.14997 26.8926 7.21747 27.0556C7.28496 27.2185 7.38389 27.3666 7.5086 27.4913C7.76047 27.7431 8.10207 27.8846 8.45827 27.8846C8.63464 27.8846 8.80928 27.8499 8.97222 27.7824C9.13517 27.7149 9.28322 27.616 9.40793 27.4913L17.4999 19.3969L25.5943 27.4913C25.8461 27.7428 26.1876 27.884 26.5436 27.8838C26.8996 27.8836 27.2409 27.742 27.4924 27.4901C27.744 27.2382 27.8852 26.8967 27.885 26.5408C27.8848 26.1848 27.7431 25.8435 27.4913 25.5919L19.3969 17.4999L27.4913 9.40793Z"
                  fill="black"
                />
              </svg>

            </span>
            {/* <div>
              <span className="bach_step"
                style={{
                  display: first <= 0 ? "none" : "block",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setfirst(first - 1)
                }}
              >
                <HiArrowNarrowLeft size="30px" style={{ display: first === 2 ? "none" : "block" }} />
              </span>
            </div> */}
          </div>
          <div
            className="d-flex mb-2"
            style={{ justifyContent: "space-between" }}
          >

            <h3
              className="mt-2"
              style={{ marginLeft: "0px", fontWeight: "600", color: "#303030" }}
            >
              Add Questions
            </h3>

            <button
              className="border-0"
              style={{
                display: first === 4 ? "block" : "none",
                color: "#2188E7",
                borderRadius: 10,
                width: "160px",
                backgroundColor: "#EDF7FF",
              }}
            // onClick={() => {
            //   setfirst(first);
            // }}
            >
              Save & Publish
            </button>
          </div>
        </div>

        <div style={{ display: first === 0 ? "block" : "none" }}>

          <div className="mb-5">
            <div className="progress" style={{ height: "10px" }}>
              <div
                className="progress-bar"
                role="progressbar"
                style={{
                  width: "1%",
                  background:
                    "linear-gradient(to right, #f44cc5, #ec40cc, #e335d3, #d72adb, #c922e4)",
                }}
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <p style={{ color: "#8A8A8A" }}>
              0/2 <span sytle={{ color: "red" }}>Steps Completed</span>
            </p>

            <div className="row">
              <div className="col-3"></div>
              <div
                className="card col-6"
                style={{ justifyConetnt: "center", textAlign: "center" }}
              >
                <div>
                  <label
                    className="col-12 mb-2 mt-3"
                    style={{
                      textAlign: "start",
                      color: "#303030",
                      fontWeight: "600",
                    }}
                  >
                    Select Subject
                  </label>
                  <br />
                  <input
                    className="col-12 border-0 mb-3"
                    style={{
                      textAlign: "start",
                      height: "50px",
                      backgroundColor: "#EFEFEF",
                      borderRadius: 7,
                      outline: 1,
                    }}
                    placeholder="  Search for "
                    type="search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}

                  />
                  <br />
                </div>
                <div
                  style={{
                    height: "160px",
                    overflowY: "scroll",
                    justifyContent: "start",
                    alignItems: "start",
                  }}
                >
                  {isLoading ? (
                    <div className="loader"><Spinner /></div>
                  ) : (
                    subjectsData?.map((res, key) => (
                      <div className="d-flex mt-3" key={key}>
                        <input
                          style={{ color: "#F6F6F6" }}
                          type="radio"
                          id={res._id}
                          name="Select"
                          value={res.sub_name}
                          onClick={() => handleSubjectSelection(res._id, res.sub_name)}
                        />
                        <span className="ms-3">{res.sub_name}</span>
                      </div>
                    ))
                  )}

                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              justifyContent: "center",
              textAlign: "center",
              backgroundColor: "",
            }}
          >
            <button
              disabled={SubjectName.length >= 1 ? false : true}
              className="col-5 border-0 text-white"
              onClick={myFunction}
              style={{
                backgroundColor: "#2188E7",
                height: "40px",
                borderRadius: 7,
              }}
            >
              process
            </button>
          </div>
        </div>

        <div style={{ display: first === 1 ? "block" : "none" }}>
          <div className="mb-5">
            <div className="progress" style={{ height: "10px" }}>
              <div
                className="progress-bar"
                role="progressbar"
                style={{
                  width: "25%",
                  background:
                    "linear-gradient(to right, #f44cc5, #ec40cc, #e335d3, #d72adb, #c922e4)",
                }}
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <p style={{ color: "#8A8A8A" }}>
              1/2 <span sytle={{ color: "red" }}>Steps Completed</span>
            </p>

            <div className="row">
              <div className="col-2"></div>
              <div className="col-4" >
                <img
                  src={Indu}
                  alt="#"
                  style={{ width: "80%", height: "140px" }}
                />
                <h3 className="col-12" style={{ padding: "10px" }}>Add Individual Questions</h3>
                {/* <p>View Sample Format</p> */}
                <button
                  className="col-12 border-0"
                  style={{
                    backgroundColor: "#E79821",
                    color: "#fff",
                    height: "40px",
                    borderRadius: 7,
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    myFunction2();
                  }}
                >
                  +Add Questions
                </button>
              </div>
              <span className="col-1" style={hori}></span>
              <div className="col-4" style={{ marginTop: "-30px" }}>
                <label
                  className="filelabel10"
                  style={{ overflow: "hidden", borderRadius: "50%" }}
                >
                  <img src={Bulk} style={{ width: "100%", height: "140px" }} />
                  <span className="title">
                    300px <span sytle={{}}>×</span>150px
                  </span>

                </label>

                <h3 className="col-12 mb-2">Upload Bulk Questions</h3>

                <p>
                  {fileName ? (
                    fileName
                  ) : (
                    <button className="btn btn-primary mb-2" onClick={handleDownloadExcel}>
                      Download Sample .xlsx
                    </button>
                  )}
                </p>

                {/* radio btns added here */}

                <div
                  className="mt-3"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: ".5rem",
                    textAlign: "center",
                  }}
                >
                  <div style={{ position: "relative", display: "inline-block" }}>
                    <label
                      htmlFor="FileInput"
                      className="col-12 border-0"
                      style={{
                        backgroundColor: "#576c89",
                        color: "#fff",
                        height: "40px",
                        borderRadius: 7,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                    >
                      +Add Bulk Questions
                    </label>
                    <input
                      className="FileUpload10"
                      id="FileInput"
                      type="file"
                      onChange={(e) => {
                        setXlInput(e.target.files[0]);
                      }}
                      accept=".xlsx"
                      style={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        width: "100%",
                        height: "100%",
                        opacity: 0,
                        cursor: "pointer",
                      }}
                    />
                  </div>
                  {/* <div
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                  >
                    <div className="form-check">
                      <input
                        value="en"
                        checked={lan === "en"}
                        onChange={(e) => setLan(e.target.value)}
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault1"
                      >
                        English
                      </label>
                    </div>
                    <div className="form-check ">
                      <input
                        value="hi"
                        checked={lan === "hi"}
                        onChange={(e) => setLan(e.target.value)}
                        class="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault2"
                      >
                        Hindi
                      </label>
                    </div>
                  </div> */}
                </div>

                {/* come here */}
                {/* btn for add bulk questions */}
                <button
                  // disabled={!fileName}
                  onClick={myFunction1}
                  className="col-12 border-0 mt-4"
                  style={{
                    backgroundColor: '#2188E7',
                    color: '#fff',
                    height: '40px',
                    borderRadius: 7,
                    cursor: 'pointer',
                  }}
                >
                  Upload
                </button>
              </div>
              <div className="col-1"></div>
            </div>
          </div>
        </div>
      </div>



      <div className="mt-3" style={{ display: first === 2 ? "block" : "none" }}>
        <div
          style={{
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
          }}
        >
          <Lottie
            animationData={Lotties}
            style={{ width: "50%", marginLeft: "160px" }}
          />
          <p className="mt-3" style={{ fontSize: "26px", fontWeight: 600 }}>
            {Hadding}
            <br />
            {Hadding1}
          </p>
          <p>{Pra}</p>
        </div>
      </div>

      <div style={{ display: first === 3 ? "block" : "none" }}>
        <div
          className="mt-5"
          style={{
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
          }}
        >
          <p style={{ fontSize: "26px", fontWeight: 600 }}>Report Generated</p>
          {/* come here     */}
          <div
            style={{ width: "20%", alignItems: "center", marginLeft: "40%" }}
          >
            <CircularProgressbarWithChildren
              value={total_question - repeated_questions}
              styles={{
                // Customize the root svg element
                root: {},
                // Customize the path, i.e. the "completed progress"
                path: {
                  // Path color
                  stroke: `#367CFF`,
                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'

                  strokeWidth: "10px",
                  // Customize transition animation
                  transition: "stroke-dashoffset 0.5s ease 0s",
                  // Rotate the path
                  transform: "rotate(0.50turn)",
                  transformOrigin: "center center",
                },
                // Customize the circle behind the path, i.e. the "total progress"
                trail: {
                  // Trail color
                  stroke: "#FF8E09",
                  strokeWidth: "10px",
                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                  strokeLinecap: "round",
                  // Rotate the trail
                  transform: "rotate(0.25turn)",
                  transformOrigin: "center center",
                },
                // Customize the text

                // Customize background - only used when the `background` prop is true
                background: {
                  fill: "rgb(16, 227, 163)",
                  backgroundColor: "rgb(16, 227, 163)",
                  strokeWidth: "10px",
                },
              }}
            ></CircularProgressbarWithChildren>
          </div>
          <p className="mt-5 fs-500" style={{ color: "#303030", fontSize: 16 }}>
            Total Questions Uploaded
            <span
              style={{ color: "#000000", fontSize: 20, fontWeight: "bolder" }}
            >
              {" "}
              {uniqueQuestionsCount + repeatQuestionsCount}
            </span>
          </p>
          <div className="row">
            <div className="col-4"></div>
            <div className="col-6 d-flex">
              <span
                className="col-1 mt-1"
                style={{
                  width: "15px",
                  height: "15px",
                  backgroundColor: "#367CFF",
                }}
              ></span>
              <p className="col-11" style={{ marginLeft: "-40px" }}>
                Unique Questions
                <span style={{ color: "#367CFF" }}> {uniqueQuestionsCount}</span>
              </p>
            </div>
            <div className="col-4"></div>
            <div className="col-6 d-flex">
              <span
                className="col-1 mt-1"
                style={{
                  width: "15px",
                  height: "15px",
                  backgroundColor: "#FF8E09",
                }}
              ></span>
              <p className="col-11" style={{ marginLeft: "-40px" }}>
                Repeated Questions
                <span style={{ color: "#FF8E09" }}> {repeatQuestionsCount}</span>
              </p>
            </div>
          </div>
          <div className="mt-5">
            <button
              className="border-0"
              style={{
                color: "#fff",
                backgroundColor: "#2188E7",
                borderRadius: 7,
                width: "250px",
                height: "40px",
              }}
              // disabled={Que.length >= 1 ? false : true}
              onClick={props.Handle}
            >
              Back to Dashboard
            </button>
          </div>
        </div>
      </div>

      <div style={{ display: first === 4 ? "block" : "none" }}>
        <div className="progress" style={{ height: "10px" }}>
          <div
            className="progress-bar"
            role="progressbar"
            style={{
              width: "50%",
              background:
                "linear-gradient(to right, #f44cc5, #ec40cc, #e335d3, #d72adb, #c922e4)",
            }}
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
        <p style={{ color: "#8A8A8A" }}>
          2/4 <span sytle={{ color: "red" }}>Steps Compleated</span>
        </p>
        <div style={{ justifyContent: "center", textAlign: "center" }}>
          <h3 style={{ fontWeight: "600" }}>Preview Questions</h3>
        </div>

        {Que.map((res, key) => (
          <QuestionComp res={res} ind={key} deleteFun={functionDelete} />
        ))}
      </div>


      {/* add single questions on button click starts */}

      <div style={{ display: first === 5 ? "block" : "none" }}>
        <div className="mb-5">
          <div className="container mt-5">
            <div className="card">
              <div className="card-body">
                <div className="form-group">
                  <label htmlFor="question">Question:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="question"
                    name="question"
                    placeholder="Enter your question"
                    value={singleQuestion}
                    onChange={(e) => setSingleQuestion(e.target.value)}
                  />
                  <input
                    type="file"
                    className="form-control-file my-3"
                    id="questionImage"
                    accept="image/*"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      setQuestionImage(file);
                    }}
                  />
                </div>
                <div className="form-group py-2">

                  <label htmlFor="option1">Option A</label>
                  <input
                    type="radio"
                    className="form-check-input me-1 ms-2"
                    name="option"
                    id="option1_radio"
                    value={1}
                    checked={selectedOption === "option1"}
                    onChange={(e) => setSelectedOption(e.target.value)}
                  />

                  {
                    isImageOption ? <>
                      <input
                        type="file"
                        className="form-control"
                        id="option1_image"
                        accept="image/*"
                        name="option1"
                        placeholder='Upload image'

                        onChange={(e) => {
                          setOption1(e.target.files[0])
                        }}
                      />

                    </>
                      :
                      <>
                        <input
                          type='text'
                          className="form-control"
                          id="option1_text"
                          name="option1"
                          placeholder='Enter option A'
                          value={option1}
                          onChange={(e) => setOption1(e.target.value)}
                        />
                      </>
                  }

                </div>
                <div className="form-group">

                  <label htmlFor="option2">Option B</label>
                  <input
                    type="radio"
                    className="form-check-input me-1 ms-2"
                    name="option"
                    id="option2_radio"
                    value={2}
                    checked={selectedOption === "option2"}
                    onChange={(e) => setSelectedOption(e.target.value)}
                  />
                  {
                    isImageOption ? <>
                      <input
                        type="file"
                        className="form-control"
                        id="option2_image"
                        accept="image/*"
                        name="option2"
                        placeholder='Upload image'

                        onChange={(e) => {
                          setOption2(e.target.files[0])
                        }}
                      />

                    </>
                      :
                      <>
                        <input
                          type='text'
                          className="form-control"
                          id="option2_text"
                          name="option2"
                          placeholder='Enter option B'
                          value={option2}
                          onChange={(e) => setOption2(e.target.value)}
                        />
                      </>
                  }
                </div>
                <div className="form-group py-2">

                  <label htmlFor="option3">Option C</label>
                  <input
                    type="radio"
                    className="form-check-input me-1 ms-2"
                    name="option"
                    id="option3_radio"
                    value={3}
                    checked={selectedOption === "option3"}
                    onChange={(e) => setSelectedOption(e.target.value)}
                  />
                  {
                    isImageOption ? <>
                      <input
                        type="file"
                        className="form-control"
                        id="option3_image"
                        accept="image/*"
                        name="option3"
                        placeholder='Upload image'
                        onChange={(e) => {
                          setOption3(e.target.files[0])
                        }}
                      />

                    </>
                      :
                      <>
                        <input
                          type='text'
                          className="form-control"
                          id="option3_text"
                          name="option3"
                          placeholder='Enter option A'
                          value={option3}
                          onChange={(e) => setOption3(e.target.value)}
                        />
                      </>
                  }
                </div>
                <div className="form-group">

                  <label htmlFor="option4">Option D</label>
                  <input
                    type="radio"
                    className="form-check-input me-1 ms-2"
                    name="option"
                    id="option4_radio"
                    value={4}
                    checked={selectedOption === "option4"}
                    onChange={(e) => setSelectedOption(e.target.value)}
                  />
                  {
                    isImageOption ? <>
                      <input
                        type="file"
                        className="form-control"
                        id="option4_image"
                        accept="image/*"
                        name="option4"
                        placeholder='Upload image'
                        onChange={(e) => {
                          setOption4(e.target.files[0])
                        }}
                      />

                    </>
                      :
                      <>
                        <input
                          type='text'
                          className="form-control"
                          id="option4_text"
                          name="option4"
                          placeholder='Enter option A'
                          value={option4}
                          onChange={(e) => setOption4(e.target.value)}
                        />
                      </>
                  }
                </div>
                <div className="d-grid gap-2 d-md-block mt-3">
                  <button className="btn btn-success me-2" type="button" onClick={handleAddTextOption}>
                    Add Text Option
                  </button>
                  <button className="btn btn-success ms-2" type="button" onClick={handleAddImageOption}>Add Image Option</button>
                </div>
                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                  <button className="btn btn-success me-md-2" type="button" onClick={handleSubmit} >
                    {checkLoading ? <Spinner /> : "Proceed"}
                    {/* Proceed */}
                  </button>
                </div>
              </div>
            </div>
          </div>



        </div>
      </div>

      {/* add single questions on button click ends */}





    </>
  );
};

export default AddQuestion;