import { useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import ComplaintsData from "./ComplaintsData";
import { useNavigate } from "react-router-dom";

const Complaints = () => {
 

 
  return (
    <div style={{ overflow: "hidden" }}>
      <div className="row">
        <div className="col-2">
          <SideBar />
        </div>
        <div className="col-10">
          <div className="container">
            <ComplaintsData />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Complaints;
