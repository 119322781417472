import React, { useContext,  useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { AUTH_BASE_URL, AuthContext } from '../Utils/AuthContextProvider';
import { toast } from 'react-toastify';

const ChangePass = () => {
    const { showChangePass, handleCloseChangePass } = useContext(AuthContext);
    const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  
  
  

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  // Chnange Password
  const chnagePassword = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

    const raw = JSON.stringify({
      "password": password,
      "confirmpassword": confirmPassword
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${AUTH_BASE_URL}/auth/admin/change/password`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status == 1) {
          toast(result?.message);
          handleCloseChangePass();
          setConfirmPassword("");
          setPassword("");
         
        } else {
          toast(result?.Backend_Error);
        }
      })
      .catch((error) => console.log("error", error));
  };



  return (
    <>
      <Modal
        show={showChangePass}
        onHide={handleCloseChangePass}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form>
          <Form.Group controlId="formPassword">
            <Form.Label>New Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter your password"
              value={password}
              onChange={handlePasswordChange}
            />
          </Form.Group>
          <Form.Group controlId="formConfirmPassword">
            <Form.Label className="mt-2">Confirm Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Confirm your password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
            />
          </Form.Group>
        </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {
            handleCloseChangePass();
            setConfirmPassword("");
          setPassword("");

          }}>
            Close
          </Button>
          <Button variant="primary" onClick={chnagePassword}>Confirm</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ChangePass