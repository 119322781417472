import { useState, useEffect, useContext } from "react";
import SearchIcon from "../../Img/ri_search-line.svg";
import NotificationIcon from "../../Img/pajamas_notifications.svg";
import Images from "../../Img/blank-profile-picture-973460__340.webp";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form, Spinner } from "react-bootstrap";
import { AuthContext, BASE_URL, IMAGE_URL, } from "../Utils/AuthContextProvider";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import LoaderComponent from "../Utils/LoaderComponent";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { FaArrowRight } from "react-icons/fa";
import { toast } from "react-toastify";

const ReelsData = () => {


  const { logoutFun, handleShowChangePass } = useContext(AuthContext);
  let notification = 40;

  const initLoader = {
    isLoading1: false,
    isError1: false,
  };
  const [loader, setLoader] = useState(initLoader);
  const { isLoading } = loader;
  const [filterData, setFilterData] = useState([]);

  const Navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [toShow, setToShow] = useState([])
  const [selectedOptions, setSelectedOptions] = useState([]);

  // to add tag model
  const [tagTitle, setTagTitle] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [bannerImage, setBannerImage] = useState(null);
  const [previewImage, setPreviewImage] = useState("");

  // to add reel model
  const [videoTitle, setVideoTitle] = useState("");
  const [showReel, setShowReel] = useState(false);
  const handleCloseReel = () => setShowReel(false);
  const handleShowReel = () => setShowReel(true);
  const [bannerImageReel, setBannerImageReel] = useState(null);
  const [previewImageReel, setPreviewImageReel] = useState("");
  const [caption, setCaption] = useState("");
  const [isloader, setIsLoading] = useState(false)

  // function to reset states of add tag
  const habdleTagStates = () => {
    setTagTitle();
    setBannerImage(null);
    setPreviewImage("");
  };

  // function to reset states of add video
  const handleVideosStates = () => {
    setBannerImageReel(null);
    setPreviewImageReel("");
    setVideoTitle("");
    setToShow([]);
    setSelectedOptions([]);
  };


  const handleSelectChange = (selectedOptions) => {
    console.log(selectedOptions, "selected options in handle select change");
    setToShow(selectedOptions)
    const selectedValues = selectedOptions.map(option => option.value);
    setSelectedOptions(selectedValues)
  };


  // Api to add new tag
  const addNewTag = () => {
    setIsLoading(true); // Start loading

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")} `);

    const formdata = new FormData();
    formdata.append("tag_name", tagTitle);
    formdata.append("banner", previewImage);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${BASE_URL}/admin/reels/create/tags`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status == 1) {
          handleClose();
          habdleTagStates();
          getAllTags();
          toast.success(result.msg);
        } else if (result.status == "TokenError") {
          Navigate("/");
          localStorage.removeItem("token");
        } else {
          toast.error(result?.Backend_Error);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setIsLoading(false); // End loading
      });
  };

  // Api to get all tags data
  const getAllTags = () => {
    setLoader({
      ...loader,
      isLoading: true,
    });
    const myHeaders = new Headers();

    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")} `
    );



    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${BASE_URL}/admin/reels/get/tags`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status == 1) {
          setFilterData(result?.tags)
          setLoader({
            ...loader,
            isLoading: false,
          });
          toast.success(result.msg);
        } else if (result.status == "TokenError") {
          Navigate("/");
          localStorage.removeItem("token");
        } else {
          toast.error(result?.Backend_Error);
        }
      })
      .catch((error) => console.error(error));
  };

  // Api To Add Reel
  const addNewReel = () => {
    setIsLoading(true)
    const myHeaders = new Headers();

    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")} `
    );
    const formdata = new FormData();
    formdata.append("tags", JSON.stringify(selectedOptions));
    formdata.append("name", videoTitle);
    formdata.append("banner", previewImageReel);
    formdata.append("caption", caption)

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,

      redirect: "follow",
    };

    fetch(`${BASE_URL}/admin/reels/create/reels/intag`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status == 1) {
          handleCloseReel();
          handleVideosStates();
          getAllTags();
          toast.success(result.msg);
        } else if (result.status == "TokenError") {
          Navigate("/");
          localStorage.removeItem("token");
        } else {
          toast.error(result?.Backend_Error);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setIsLoading(false); // End loading
      });
  };

  console.log("sdsad", filterData);

  useEffect(() => {
    getAllTags();
  }, [])


  return (
    <>
      <div>
        <div
          className="row mt-3 mb-2 ms-1"
          style={{ overflowY: "none", postition: "fixed" }}
        >
          <div className="col-6">
            <div className="row">
              <div className="" style={{ width: "250px" }}>
                <span className="styled-heading">Brain Booster</span>
              </div>
              {/* <div className="col-8" style={{ backgroundColor: "#fff" }}>
                <div
                  className="row"
                  style={{
                    border: "1px solid #D9D9D9",
                    height: "40px",
                    borderRadius: "7px",
                  }}
                >
                  <div className="col-2 mt-1">
                    <img alt="SearchIcon" src={SearchIcon} />
                  </div>
                  <div className="col-10 mt-1" style={{ marginLeft: "-20px" }}>
                    <input
                      className="border-0 w-100"
                      placeholder="Search within Dashboard"
                      style={{
                        textDecoration: "none",
                        outline: "none",
                        color: "#272727",
                      }}
                    />
                  </div>
                </div>
              </div> */}
              <div className="col-4"></div>
            </div>
          </div>
          <div className="col-6">
            <div
              className="d-flex"
              style={{
                justifyContent: "end",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              {/* <div
                className="d-flex"
                style={{
                  width: "80px",
                  height: "40px",
                  borderRadius: 20,
                  border: "1px solid #D9D9D9",
                  justifyContent: "center",
                  backgroundColor: "#F5F5F5",
                }}
              >
                <div className="mt-1">
                  <img alt="#" src={NotificationIcon} />
                </div>
                <div
                  className="ms-3"
                  style={{
                    width: "35px",
                    marginTop: "1px",
                    height: "35px",
                    borderRadius: "50%",
                    border: "1px solid #D9D9D9",
                    justifyContent: "center",
                    backgroundColor: "#CC1313",
                  }}
                >
                  <p
                    style={{
                      justifyContent: "center",
                      marginTop: "5px",
                      justifyItems: "center",
                      textAlign: "center",
                      color: "#fff",
                    }}
                  >
                    {notification} +{" "}
                  </p>
                </div>
              </div> */}
              <div
                className="d-flex ms-4"
                style={{ justifyContent: "space-between", marginRight: "30px" }}
              >
                <div
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    justifyContent: "center",
                    overflow: "hidden",
                  }}

                >
                  <img alt="" src={Images} width="100%" />
                </div>
                <div className="nav-item dropdown ms-2 mt-2 ">
                  <span
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  ></span>
                  <ul
                    className="dropdown-menu"
                    style={{ backgroundColor: "#000" }}
                  >
                    <li class="dropdown-item text-white" style={{ cursor: "pointer" }} onClick={handleShowChangePass}>Change Password</li>
                    <hr style={{ color: "#fff" }} />
                    <li className="dropdown-item text-white">
                      <span onClick={() => logoutFun()}>Log-Out</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row mt-4">
            <div className="col-7 mb-4">
              <div className="row">
                {/* <div className="col-2">
                  <span
                    style={{
                      fontWeight: "600",
                      color: "#000000",
                      fontSize: 17,
                      cursor: "pointer",
                    }}
                  >
                    Users
                  </span>
                </div> */}
              </div>
            </div>
            <div className="col-5">
              <div className="row" style={{ marginRight: "20px" }}>
                <div className="col-2"></div>

                <div className="col-4">
                  <button
                    onClick={handleShow}
                    style={{
                      backgroundColor: "#EDF7FF",
                      border: "1px solid #2188E7",
                      height: "40px",
                      borderRadius: "10px",
                      color: "#2188E7",
                    }}
                  >
                    <span className="pe-2 ps-2"> +Add Tag</span>
                  </button>
                </div>
                <div className="col-6">
                  <button
                    onClick={handleShowReel}
                    style={{
                      backgroundColor: "#EDF7FF",
                      border: "1px solid #2188E7",
                      height: "40px",
                      borderRadius: "10px",
                      color: "#2188E7",
                    }}
                  >
                    <span className="pe-2 ps-2"> +Add Brain Booster</span>
                  </button>
                </div>
              </div>
            </div>

            {/* start here */}

            <div className="row">
              <div className="col-9">
                <div className="row">
                  <div className="col-2 mt-2"></div>
                </div>
              </div>
              <div className="col-4"></div>
            </div>
            <hr />

            {/* end here */}
          </div>
        </div>

        <div
          className="row"
          style={{
            height: "6vh",
            overflow: "hidden",
          }}
        >
          <div className="col-6 d-flex">
            <div className="col-8">
              <h5 className="mt-1">
                Showing total <span style={{ color: "red" }}>{filterData.length}</span> Tags
              </h5>
            </div>
          </div>

          <div className="col-6">
            <div className="row">
              <div className="col-4"></div>
              <div className="col-6">
                <div
                  className="row"
                  style={{
                    border: "1px solid #D9D9D9",
                    height: "40px",
                    borderRadius: "7px",
                  }}
                >
                  <div className="col-2 mt-1">
                    <img alt="SearchIcon" src={SearchIcon} />
                  </div>
                  <div className="col-10 mt-1 ">
                    <input
                      className="border-0 w-100"
                      placeholder="Search for Brain Booster Tags"
                      value={search}
                      style={{
                        textDecoration: "none",
                        outline: "none",
                        color: "#272727",
                      }}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-2">
                <button
                  className="border-0 bg-white"
                  style={{
                    background:
                      "linear-gradient(180deg, rgba(35,176,101,1) 0%, rgba(46,135,96,1) 100%)",
                    color: "#fff",
                    height: "40px",
                    borderTopRightRadius: 10,
                    borderBottomRightRadius: 10,
                    marginLeft: "-20px",
                  }}
                // onClick={handleSearch}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>

        <section
          className="mt-3"
          style={{ height: "57vh", overflowY: "scroll", overflowX: "hidden" }}
        >
          <div className="row pe-3">
            {isLoading ? (
              <LoaderComponent />
            ) : (
              filterData?.map((res1, key1) => {
                return (
                  <>
                    <div key={key1} className="col-4 mt-3">
                      <span
                        className="card"
                        onClick={() => Navigate(`/viewreels/${res1?._id}`)}
                        style={{ cursor: "pointer" }}
                      >
                        <div className="d-flex ps-2">
                          <span className="col-2">
                            <div
                              className="mt-2"
                              style={{
                                width: "40px",
                                height: "40px",
                                border: "1px solid #D9D9D9",
                                borderRadius: "50px",
                                justifyContent: "center",
                                objectFit: "cover",
                                overflow: "hidden",
                              }}
                            >
                              <img
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                                src={`${IMAGE_URL}${res1.banner}`}
                                alt="#"
                              />
                            </div>
                          </span>
                          <div className="col-8 mt-2">
                            <span className="ml-5">
                              <p
                                style={{
                                  marginBottom: "1px",
                                  fontStyle: "normal",
                                  fontWeight: "600",
                                  fontSize: "20px",
                                  color: "#000000",
                                }}
                              >
                                {res1?.tag_name}
                              </p>
                              <p
                                style={{
                                  marginTop: "-10px",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  color: "#8A8A8A",
                                }}
                              >
                                Total Brain Booster-
                                <span
                                  style={{
                                    color: "red",
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    fontSize: "16px",
                                    color: "#CC1313",
                                  }}
                                >
                                  {res1?.reels_count}
                                </span>
                              </p>
                            </span>
                          </div>
                          <span
                            className="col-2 mt-3"
                            style={{
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <FaArrowRight />
                          </span>
                        </div>
                      </span>
                    </div>
                  </>
                );
              })
            )}
          </div>
        </section>

        <div>
          <Stack
            spacing={2}
            className="d-flex justify-content-center mt-3 align-items-center"
          >
            <Pagination count={6} page={1} />
          </Stack>
        </div>

        {/* Model to add user start  */}
        <Modal
          show={show}
          onHide={() => {
            handleClose();
            habdleTagStates();
          }}
          backdrop="static"
          keyboard={false}
          style={{ backdropFilter: "blur(10px)" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Tag</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Tag Name</Form.Label>
                <Form.Control
                  onChange={(e) => setTagTitle(e.target.value)}
                  name="title"
                  // value={title}
                  type="text"
                  placeholder="Enter Tag here"
                  autoFocus
                />
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput2"
              >
                <Form.Label>Tag Image</Form.Label>
                <label
                  className=" w-100"
                  style={{
                    height: "150px",
                    marginLeft: "0px",
                    backgroundColor: "#F5F5F5",
                    color: "#8A8A8A",
                    textAlign: "center",
                  }}
                >
                  {bannerImage ? (
                    <span className="" style={{ color: "#8A8A8A" }}>
                      <img
                        className="d-flex"
                        src={bannerImage}
                        alt="banner"
                        style={{ width: "100%", height: "150px" }}
                      />
                    </span>
                  ) : (
                    <span
                      className="title1"
                      style={{ fontSize: 20, fontWeight: 600 }}
                    >
                      Click here to upload banner
                    </span>
                  )}
                  <input
                    className="FileUpload1"
                    id="FileInput1"
                    name="booking_attachment1"
                    type="file"
                    accept=".png,.jpg"
                    onChange={(e) => {
                      setBannerImage(URL.createObjectURL(e.target.files[0]));
                      setPreviewImage(e.target.files[0]);
                    }}
                  />
                </label>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                handleClose();
                habdleTagStates();
              }}
            >
              Close
            </Button>
            <Button variant="primary" onClick={addNewTag} disabled={isloader}>
              {isloader ? <Spinner /> : "Save"}
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Model to add user end */}

        {/* Model to add reel start  */}
        <Modal
          show={showReel}
          onHide={() => {
            handleCloseReel();
            handleVideosStates();
          }}
          backdrop="static"
          keyboard={false}
          style={{ backdropFilter: "blur(10px)" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Brain Booster</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Reel Name</Form.Label>
                <Form.Control
                  onChange={(e) => setVideoTitle(e.target.value)}
                  name="title"
                  // value={title}
                  type="text"
                  placeholder="Enter Reel Name here"
                  autoFocus
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                <Form.Label>Reel Caption</Form.Label>
                <Form.Control
                  onChange={(e) => setCaption(e.target.value)}
                  name="caption"
                  type="text"
                  placeholder="Enter Reel Caption here"
                />
              </Form.Group>
              <div>
                <Form.Label>Select Tags</Form.Label>

                <div>
                  <Select
                    isMulti
                    options={filterData?.map((res) => ({
                      label: res.tag_name,
                      value: res._id, // Using tag_name as value
                    }))}

                    onChange={handleSelectChange}
                    value={toShow}
                    styles={{
                      menu: (provided, state) => ({
                        ...provided,
                        maxHeight: 'fit-content', // Adjust the max height as needed
                        overflowY: "scroll",
                      }),
                    }}
                  />
                </div>
              </div>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput2"
              >
                <Form.Label>Reel Bannner Image</Form.Label>
                <label
                  className=" w-100"
                  style={{
                    height: "100px",
                    marginLeft: "0px",
                    backgroundColor: "#F5F5F5",
                    color: "#8A8A8A",
                    textAlign: "center",
                  }}
                >
                  {bannerImageReel ? (
                    <span className="" style={{ color: "#8A8A8A" }}>
                      <img
                        className="d-flex"
                        src={bannerImageReel}
                        alt="banner"
                        style={{ width: "100%", height: "100px" }}
                      />
                    </span>
                  ) : (
                    <span
                      className="title1"
                      style={{ fontSize: 20, fontWeight: 600 }}
                    >
                      Click here to upload banner
                    </span>
                  )}
                  <input
                    className="FileUpload1"
                    id="FileInput1"
                    name="booking_attachment1"
                    type="file"
                    accept=".png,.jpg"
                    onChange={(e) => {
                      setBannerImageReel(
                        URL.createObjectURL(e.target.files[0])
                      );
                      setPreviewImageReel(e.target.files[0]);
                    }}
                  />
                </label>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                handleCloseReel();
                handleVideosStates();
              }}
            >
              Close
            </Button>
            <Button variant="primary" onClick={addNewReel} disabled={isloader}>
              {isloader ? <Spinner /> : "Create Reel"}
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Model to add reel end */}
      </div>
    </>
  );
};

export default ReelsData;